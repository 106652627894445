import { MenuItem } from "../../molecules";
import { MenuSectionProps, MenuSetionDefaultProps } from "./menuSection.type";

const MenuSection = (props: MenuSectionProps) => {
  const { title, items, ...rest } = props;

  return (
    <div {...rest}>
      <p className="mf-overline-bold mf-text-secondary mb-2">{title}</p>
      {items.map((item, index) => (
        <MenuItem key={index} {...item} className="py-3 px-4" />
      ))}
    </div>
  );
};

MenuSection.defaultProps = MenuSetionDefaultProps;

export default MenuSection;
