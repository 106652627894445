import { createSlice } from "@reduxjs/toolkit";

import { BotState } from "../../types/bot.types";

const initialState: BotState = {
  isRunning: false,
  startingService: false,
};

const botSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {
    setIsRunning: (state, action) => {
      state.isRunning = action.payload ?? false;
    },

    startBot: (state) => {
      state.isRunning = true;
    },

    stopBot: (state) => {
      state.isRunning = false;
    },

    setStartingService: (state, action) => {
      state.startingService = action.payload ?? false;
    },
  },
});

export const { startBot, stopBot, setIsRunning, setStartingService } = botSlice.actions;

export default botSlice.reducer;
