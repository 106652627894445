import i18next from "i18next";
import moment from "moment-timezone";

import { IWalletTransactionItemProps } from "../walletTransactionItem/walletTransactionItem.types";
import { GetTransactionsResponse } from "./walletTransactions.types";

export const getStationInfo = (station = "") => {
  if (!station) return null;

  const name = station.split(/(-|\()/)[0];

  const id = station.split("(")[1].split(")")[0];

  return { name, id };
};

export const getTransactionDuration = (hoursW: number) => {
  const hours = Math.floor(hoursW);
  const minutes = Math.round((hoursW - hours) * 60);

  return hours ? hours + (minutes ? `:${minutes}` : "") : "";
};

export const transformTransaction = (
  respTransaction: GetTransactionsResponse,
): IWalletTransactionItemProps => {
  const desc = respTransaction.description.toLowerCase();

  let type: IWalletTransactionItemProps["type"] = "deposit";

  const station = getStationInfo(respTransaction.serviceAreaName);

  let description = i18next.t("home:wallet.balance-up", { ns: "home" });

  if (respTransaction.type === "expense") {
    if (desc.includes("block")) {
      type = "package";
      description = i18next.t("home:wallet.block-captured", { ns: "home" });
    } else {
      type = "instant-offer";
      description = i18next.t("home:wallet.instant-offer", { ns: "home" });
    }
  }

  const amount = type === "deposit" ? respTransaction.amount : -respTransaction.amount;

  return {
    amount,
    date: moment(respTransaction.createdAt ?? respTransaction.createAt).toDate(),
    type,
    id: respTransaction._id,
    description,
    duration: getTransactionDuration(respTransaction.hoursWorked),
    stationId: station?.id,
    stationName: station?.name,
    balance: respTransaction.balance,
  };
};
