import { Navigate, Route, Routes, useParams } from "react-router-dom";

import PaymentConfirmScreen from "../components/pages/private/paymentConfirm/PaymentConfirmScreen";
import MaintenancePage from "../components/pages/public/maintenance/Maintenance";
import AuthRoutes from "./auth/AuthRoutes";
import InfoRoutes from "./info/InfoRoutes";
import MainRoutes from "./main/MainRoutes";
import Maintenance from "./Maintenance";

const Invite = () => {
  const params = useParams();
  return <Navigate to={`/auth/register?ref=${params.code}`} />;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Maintenance />}>
        <Route path="auth/*" element={<AuthRoutes />} />
        <Route path="info/*" element={<InfoRoutes />} />
        <Route path="invite/:code" element={<Invite />} />
        <Route path="payment-confirm" element={<PaymentConfirmScreen />} />
        <Route path="/*" element={<MainRoutes />} />
        <Route path="login" element={<Navigate to="auth" />} />
      </Route>
      <Route path="/maintenance" element={<MaintenancePage />} />
    </Routes>
  );
};

export default AppRoutes;
