import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const spinner = (state: RootState) => state.spinnerFullScreen;

const useSpinnerFullScreenSelector = () => {
  return {
    spinner: useAppSelector(spinner),
  };
};

export default useSpinnerFullScreenSelector;
