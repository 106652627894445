import { useEffect, useState } from "react";

import type { SearchEngineProps } from "../../../molecules/searchEngine/searchEngine.types";
import type { BlocksOptions } from "./formLocation.types";
import { UseSearchProps } from "./StationsModal.types";

const useSearch = (props: UseSearchProps) => {
  const { stations, onChange, generateBlocks } = props;
  const [list, setList] = useState<BlocksOptions>([]);
  const [searchData, setSearchData] = useState<BlocksOptions>([]);

  const [searchEngine, setSearchEngine] = useState<SearchEngineProps>({
    data: [],
    onSearch: () => null,
    pathsSearch: [""],
  });

  useEffect(() => {
    setSearchData(generateBlocks({ stations, onChange }));
  }, [stations, onChange, generateBlocks]);

  useEffect(() => {
    setSearchEngine({
      pathsSearch: ["typography.bodyText", "typography.smTitle"],
      data: searchData,
      onSearch: (data: any) => {
        setList(data);
      },
      onDefault: (data) => {
        setList(data);
      },
    });
  }, [searchData]);

  return { list, searchEngine };
};

export default useSearch;
