import {
  cleanSpinnerFullScreen,
  setSpinnerFullScreen,
} from "../reducers/spinnerFullScreen/actions";
import { ISetStateSpinnerFullScreen } from "../types/spinnerFullScreen.types";
import useActions from "./useActions";

const useStateSpinnerFullScreen = () => {
  const setSpinnerFullScreenD: ISetStateSpinnerFullScreen =
    useActions(setSpinnerFullScreen);
  const cleanSpinnerFullScreenD = useActions(cleanSpinnerFullScreen);
  return { setSpinnerFullScreenD, cleanSpinnerFullScreenD };
};

export default useStateSpinnerFullScreen;
