import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";

const ZelleInfoPage = lazy(() =>
  import(
    /* webpackChunkName: "ZelleInfoPage" */ "../../components/pages/private/subscription/zelle/Info"
  ).then((module) => ({ default: module.ZelleInfoPage })),
);

export const ZelleRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route index element={<ZelleInfoPage />} />
      </Routes>
    </Suspense>
  );
};
