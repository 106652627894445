import { createSlice } from "@reduxjs/toolkit";

import { AmzAccountState } from "../../types/amzAccount.types";
import * as reducers from "./actions";

const initialState: AmzAccountState = {
  amzAccount: undefined,
  extraData: undefined,
  devices: undefined,
};

const amzAccountSlice = createSlice({
  name: "amzAccount",
  initialState,
  reducers,
});

export const {
  deleteAmzAccount,
  reset,
  setAmzAccount,
  setExtraData,
  addStations,
  addDevice,
  setDevices,
} = amzAccountSlice.actions;

export default amzAccountSlice.reducer;
