import { useTranslation } from "react-i18next";

import { envs } from "../../../config/env";
import { Typhography } from "../../atoms";
import RadioInput from "../radioInput/RadioInput";
import { IPriceRadioProps } from "./priceRadio.types";

const PriceRadio = (props: IPriceRadioProps) => {
  const { t } = useTranslation(["membership"]);

  const days = props.price === 40 ? 30 : 60;

  const expiredLabel = envs.ENABLED_BALANCE_EXPIRATION ? (
    <Typhography type="h-3" className="mf-text-secondary pl-2">
      {t("membership:wallet.expire-in", { count: days })}
    </Typhography>
  ) : null;

  return (
    <RadioInput.Formik className="my-3 py-2 px-3" input={props.radioInput}>
      <div className="is-flex is-align-items-center ml-3">
        <Typhography type="h-1" className="mf-text-secondary mr-3">
          +
        </Typhography>
        <Typhography type="h-2" className="mf-text-secondary pt-1">
          $
        </Typhography>
        <Typhography type="h-1" className="is-size-4">
          {props.price}
        </Typhography>
        {expiredLabel}
      </div>
    </RadioInput.Formik>
  );
};
export default PriceRadio;
