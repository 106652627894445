import { magicAPI } from "../../api";
import { GetWalletResponse } from "./types";

type LastPaymentResponse = {
  lastPayment: any;
  paymentDisabled: boolean;
};

export const getLastPayment = async (): Promise<LastPaymentResponse> => {
  const { data } = await magicAPI.get("/accountPayment/last-payment");

  return data.data;
};

export const getWallet = async () => {
  const { data } = await magicAPI.get<GetWalletResponse>("/wallet");

  return data.data;
};

export const acceptWalletExpiration = async () => {
  await magicAPI.put("/wallet/accept-expiration");
};

export const getMembership = async () => {
  const { data } = await magicAPI.get<GetMembershipResponse>("/membership");

  return data.data;
};

export interface GetMembershipResponse {
  request: boolean;
  message: string;
  data: Membership;
}

export interface Membership {
  _id: string;
  expirationDate?: Date;
  membershipName: "WALLET" | "BASIC";
  accountPayments?: [{ cardLast4: string }];
}
