import useMembershipTime from "../../components/templates/private/subscription/status/hooks/useMembershipTime";
import { useModalInfoDispatch } from "../../store/useDispatch";
import { useLastPaymentQuery } from "./useLastPayment.query";

type Props<T> = {
  handleSubmit: (values: T) => void;
};

type ReturnType<T> = {
  onSubmit: (values: T) => void;
};

export const useDisablePayment = <T = any>(props: Props<T>): ReturnType<T> => {
  const { handleSubmit } = props;

  const { setModalInfoCD } = useModalInfoDispatch();
  const { remainingTime } = useMembershipTime();
  const lastPaymentQuery = useLastPaymentQuery();

  const isEnable = remainingTime.days <= 0 && remainingTime.hours <= 12;

  const showPaymentDisabledTime = () => {
    if (lastPaymentQuery.data?.paymentDisabled) {
      setModalInfoCD({
        modalType: "payment-blocked-time",
        enabled: true,
      });
    }
  };

  const showWarning = () => {
    setModalInfoCD({
      modalType: "payment-unavailable-yet",
      enabled: true,
    });
  };

  return {
    onSubmit: isEnable
      ? lastPaymentQuery.data?.paymentDisabled
        ? showPaymentDisabledTime
        : handleSubmit
      : showWarning,
    // onSubmit: handleSubmit,
  };
};
