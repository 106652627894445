import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectAmzAccounts = (state: RootState) => state.amzAccounts.amzAccounts;
const selectLoading = (state: RootState) => state.amzAccounts.loading;
const selectError = (state: RootState) => state.amzAccounts.error;

const useAmzAccountsSelector = () => {
  return {
    amzAccounts: useAppSelector(selectAmzAccounts),
    loading: useAppSelector(selectLoading),
    error: useAppSelector(selectError),
  };
};

export default useAmzAccountsSelector;
