import { createSlice } from "@reduxjs/toolkit";

const arrivedSlice = createSlice({
  name: "arrived",
  initialState: {
    active: false,
    id: undefined,
    confirmed: false,
  },
  reducers: {
    setArrived: (state, action) => {
      state.active = action.payload.active;
      state.id = action.payload.id;
      state.confirmed = action.payload.confirmed;
    },
    confirm: (state) => {
      state.confirmed = true;
    },
    disable: (state) => {
      state.active = false;
    },
  },
});

export const { setArrived, disable, confirm } = arrivedSlice.actions;

export default arrivedSlice.reducer;
