import { useCallback, useEffect } from "react";

import { useStateSpinnerFullScreen } from "../../store/useDispatch";
import useOffersDispatch from "../../store/useDispatch/useOffersDispatch";
import { useAmzAccountSelector } from "../../store/useSelector";
import useOffersSelector from "../../store/useSelector/useOffersSelector";
import serviceAPI from "../../utils/services/servicesAPI";
import {
  filterCalendarOffersByTime,
  sortCalendarOffersByTime,
} from "./useOffers.functions";
import { OfferLog } from "./useOffers.types";
import useOfferApi from "./useOffersApi";

const useOffers = () => {
  const { setSpinnerFullScreenD, cleanSpinnerFullScreenD } = useStateSpinnerFullScreen();
  const { amzAccount } = useAmzAccountSelector();

  const { offersLogs, calendarOffers } = useOffersSelector();

  const { getCalendarOffers, calendarOffersResp } = useOfferApi();

  const { setOfferLogsD, setCalendarOffersD, deleteCalendarOfferD } = useOffersDispatch();

  const setOffersLogs = (offersLogs: OfferLog[] = []) => setOfferLogsD(offersLogs);

  const reloadCalendarOffers = useCallback(async () => {
    if (!amzAccount) return;

    getCalendarOffers();
  }, [amzAccount]);

  const discardOffer = useCallback(
    async (blockId: number) => {
      setSpinnerFullScreenD({
        color: "green",
        enabled: true,
      });

      const { request, message } = await serviceAPI.discardReservation(
        amzAccount?._id,
        blockId,
      );

      cleanSpinnerFullScreenD();

      if (request) deleteCalendarOfferD(blockId);

      return { success: request, message };
    },
    [amzAccount],
  );

  useEffect(() => {
    if (!calendarOffersResp.error) {
      const filteredCalendarOffers = filterCalendarOffersByTime(
        calendarOffersResp.data.data,
      );

      const sortedCalendarOffers = sortCalendarOffersByTime(filteredCalendarOffers);

      sortedCalendarOffers && setCalendarOffersD(sortedCalendarOffers);
    }
  }, [calendarOffersResp.data]);

  return {
    discardOffer,
    setOffersLogs,
    reloadCalendarOffers,
    calendarOffers,
    offersLogs,
    loading: calendarOffersResp.loading,
  };
};

export default useOffers;
