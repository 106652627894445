import { useEffect, useState } from "react";

import useTimer from "./useOtpTimer";
import { getFormikConfig } from "./useVerifyOtpForm.functions";
import { UseVerifyOtpForm } from "./useVerifyOtpForm.types";

const useVerifyOtpForm: UseVerifyOtpForm = (params) => {
  const { onSubmit, otpExpirationTime, onTimeOut } = params;

  const [time, setTime] = useState<number>(otpExpirationTime);

  const { getTime } = useTimer({
    time,
    setTime: () => setTime((time) => time - 1),
    onTimeout: onTimeOut,
  });

  useEffect(() => {
    setTime(otpExpirationTime);
  }, [otpExpirationTime]);

  return {
    onSubmit,
    otpExpirationTime,
    getTimeString: getTime,
    formikConfig: getFormikConfig(onSubmit),
  };
};

export default useVerifyOtpForm;
