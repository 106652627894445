import { useEffect } from "react";

import useLoadStations from "../../components/pages/settings/registerAmzAccountScreen/hooks/useLoadStations";
import { useSetUpFilters } from "../../store/useDispatch";
import { useAmzAccountSelector } from "../../store/useSelector";
import useAmzAccountExtraData from "../useAmzAccount/useAmzAccountExtraData";
import useOffers from "../useOffers/useOffers";

const useLoadInitialData = () => {
  const { amzAccount } = useAmzAccountSelector();
  const { reloadExtraData } = useAmzAccountExtraData();
  const { reloadCalendarOffers } = useOffers();
  const { loadStations } = useLoadStations();

  const { setUpStationD } = useSetUpFilters();

  useEffect(() => {
    reloadCalendarOffers();
  }, [reloadCalendarOffers]);

  useEffect(() => {
    if (amzAccount) setUpStationD(amzAccount.stationsAvailables);
  }, [amzAccount]);

  useEffect(() => {
    reloadExtraData();
  }, [amzAccount]);

  useEffect(() => {
    if (!amzAccount?.stationsAvailables || amzAccount?.stationsAvailables?.length === 0) {
      loadStations();
    }
  }, []);
};

export default useLoadInitialData;
