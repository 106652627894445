// import { IconCustom } from "../icons";
// import { InputTypeProsp } from './inputTime.types';
import "./iconModal.styles.scss";

import { IconCustom } from "../icons";
import ImageCustom from "../imageCutom/ImageCustom";
import { IconModalTitleProps } from "./iconModalTitle.types";

const IconModalTitle = (props: IconModalTitleProps): JSX.Element => {
  return (
    <>
      {props.image?.typeImage ? (
        <div className={`image-modal-title`}>
          <ImageCustom {...props.image} typeImage={props.image.typeImage} alt="flexi" />
        </div>
      ) : (
        <div className={`icon-modal-title icon-modal-title-${props.type}`}>
          <IconCustom {...props.icon} />
        </div>
      )}
    </>
  );
};

IconModalTitle.defaultProps = {
  icon: {
    typeIcon: "",
    fill: "#FF6464",
    with: 26,
    height: 26,
  },
  image: {
    typeFlex: "",
  },
} as Partial<IconModalTitleProps>;

export default IconModalTitle;
