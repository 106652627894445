import "./inputCustom.styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useField } from "formik";
import { useRef, useState } from "react";

import { IconCustom } from "../icons";
import Input from "../input/Input";
import LabelCustom from "../labelCustom/LabelCustom";
import InputCustomProps from "./inputCustom.types";

const EyeIcon = ({ type, setInputType }: any) => {
  const initialType = useRef(type);

  if (initialType.current !== "password") return <></>;

  const icon = type === "password" ? "eye-crossed" : "eye";

  const toggleInputType = () => {
    setInputType(type === "password" ? "text" : "password");
  };

  return (
    <span
      className="icon is-small is-right mf-input-custom-eye-icon"
      role="button"
      tabIndex={0}
      onKeyUp={toggleInputType}
      onClick={toggleInputType}
    >
      <IconCustom typeIcon={icon} width={19} height={19} className="mf-text-secondary" />
    </span>
  );
};

const InputCustom = (props: InputCustomProps) => {
  const { inputProps, subLabel, iconInput } = props;
  const [field, meta] = useField(inputProps);

  const [inputType, setInputType] = useState(inputProps.type || "text");

  let statusClass = "";

  if (meta.touched) {
    statusClass = meta.error ? "is-danger" : "is-success";
  }

  const showIconClass = iconInput ? "has-icons-left" : "";

  return (
    <div className="field">
      <LabelCustom label={inputProps.label} subLabel={subLabel} />
      <p className={`control has-icons-right ${showIconClass}`}>
        <Input
          className={statusClass}
          {...field}
          {...inputProps}
          type={inputType}
          autoComplete="on"
        />
        {iconInput && (
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={iconInput} className="mf-text-secondary" />
          </span>
        )}

        <EyeIcon type={inputType} setInputType={setInputType} />
      </p>
      <ErrorMessage name={inputProps.name} className="has-text-danger" component="span" />
    </div>
  );
};

InputCustom.defaultProps = {
  inputProps: {
    label: "Example",
    placeholder: "Example",
    name: "example",
    type: "text",
  },
  subLabel: "",
} as Partial<InputCustomProps>;

export default InputCustom;
