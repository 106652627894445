import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useModalInfoDispatch } from "../../../store/useDispatch";
import { getTypesModals } from "./modalInfo.constants";
import {
  Event,
  ModalInfoProps,
  ModalInfoStructure,
  ReturnUseModalType,
  StorgaeType,
} from "./modalInfo.types";

const useModalInfo = (): ReturnUseModalType => {
  const modalInfoC = useSelector(
    (state: StorgaeType): ModalInfoProps => state?.modalInfoC,
  );

  const { t } = useTranslation(["popup"]);

  const modalPropsOfType = getTypesModals(t)[`${modalInfoC.modalType || "default"}`];

  //Cases modal;
  const objectProps = modalInfoC?.modalInfoProps || modalPropsOfType;

  const { setModalInfoCD } = useModalInfoDispatch();

  //* This execute the close event
  const handleClose = useCallback(() => {
    setModalInfoCD({ ...modalInfoC, enabled: false });
    modalInfoC?.handleClose && modalInfoC.handleClose();
  }, [modalInfoC, setModalInfoCD]);

  //* This execute the cancel event
  const handleCancel = useCallback(() => {
    setModalInfoCD({ ...modalInfoC, enabled: false });
    modalInfoC?.handleCancel && modalInfoC.handleCancel();
  }, [modalInfoC, setModalInfoCD]);

  //* This execute the accept event
  const handleAccept = useCallback(() => {
    setModalInfoCD({ ...modalInfoC, enabled: false });
    modalInfoC?.handleAccept && modalInfoC.handleAccept();
  }, [modalInfoC, setModalInfoCD]);

  const handleBox = useCallback((event: Event) => {
    event.stopPropagation();
  }, []);

  return {
    modalInfoC,
    handleClose,
    handleBox,
    handleCancel,
    handleAccept,
    objectProps: objectProps as ModalInfoStructure,
  };
};

export default useModalInfo;
