import "./blockDetails.styles.scss";

import { useTranslation } from "react-i18next";

import { Badge, Button } from "../../../atoms";
import { TitleLabel } from "../../../molecules";
import MagicMap from "../../../organisms/private/magicMap/MagicMap";
import { BlockDetailsDefaultProps, BlockDetailsProps } from "./blockDetails.type";
import useBlockDetails from "./hooks/useBlockDetails";

const BlockDetails = (props: BlockDetailsProps) => {
  const {
    onDiscardReservation,
    blockPropertiesLabels,
    lat,
    lng,
    locationId,
    locationName,
  } = useBlockDetails(props);

  const { t } = useTranslation(["home"]);

  return (
    <div className="mg-container-detail">
      <h1 className="mr-3">{locationName}</h1>
      <Badge type="success"> {locationId} </Badge>

      <div className="px-3 mt-3 mg-detail-location">
        {blockPropertiesLabels.map((propertyLabel, index) => (
          <TitleLabel key={index} {...propertyLabel} />
        ))}
      </div>
      <div className="my-5">
        <MagicMap lat={lat} lng={lng} />
      </div>

      <div className="mb-3 ml-1">
        <TitleLabel
          title={t("home:block-details.remove")}
          description={t("home:block-details.description")}
        />
      </div>
      <Button
        leftIcon="cross"
        isFullWidth={false}
        color="error"
        onClick={onDiscardReservation}
      >
        {t("home:block-details.discard")}
      </Button>
    </div>
  );
};

BlockDetails.defaultProps = BlockDetailsDefaultProps;

export default BlockDetails;
