import "./referredListStatus.styles.scss";

import { Typhography } from "../../atoms";
import useReferredListStatus from "./hooks/useReferredListStatus";
import { IReferredListStatusProps } from "./referredListStatus.types";

const ReferredListStatus = (props: IReferredListStatusProps) => {
  const { status, rewardClaimed } = props;

  const { Icon, handleClick, handleKeyDown, statusClassName, text } =
    useReferredListStatus(props);

  if (status === "PAID" && !rewardClaimed)
    return (
      <div
        className={`mf-referred_list_status btn ${statusClassName}`}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {Icon}
        <Typhography type="p-l-r" style={{ textTransform: "capitalize" }}>
          {text}
        </Typhography>
      </div>
    );

  return (
    <div className={`mf-referred_list_status ${statusClassName}`}>
      {Icon}
      <Typhography type="p-l-r" style={{ textTransform: "capitalize" }}>
        {text}
      </Typhography>
    </div>
  );
};

export default ReferredListStatus;
