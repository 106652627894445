import { useCallback, useEffect, useRef, useState } from "react";
import { PlayerReference, PlayerState } from "video-react";

const usePlayer = () => {
  const playerRef = useRef<PlayerReference | null>();

  const [playerState, setPlayerState] = useState<PlayerState>();

  const handlePlayerReady = (player: PlayerReference) => {
    playerRef.current = player;

    player.subscribeToStateChange((state) => setPlayerState(state));
  };

  const toggleFullscreen = useCallback(() => {
    if (!playerRef.current || !playerState?.hasStarted) return;

    if (!playerState.hasStarted) return;

    if (playerState.paused && playerState.isFullscreen)
      playerRef.current.toggleFullscreen();

    if (!playerState.paused && !playerState.isFullscreen)
      playerRef.current.toggleFullscreen();
  }, [playerState?.paused, playerState?.isFullscreen, playerState?.hasStarted]);

  useEffect(() => {
    toggleFullscreen();
  }, [toggleFullscreen]);

  return {
    playerState,
    handlePlayerReady,
  };
};

export default usePlayer;
