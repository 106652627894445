import { Box } from "@mui/material";
import { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

import { usePaymentMutation } from "../../../../hooks";
import { useModalInfoDispatch } from "../../../../store/useDispatch";
import { lightTheme } from "../../../../themes";
import { IconCustom, Typhography } from "../../../atoms";

type Props = {
  id: string;
  date: Date | string;
  amount: number;
  code?: string;
};

export const PendingPayment = (props: Props) => {
  const { date, amount, id, code } = props;
  const navigate = useNavigate();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { deletePayment } = usePaymentMutation();

  const onDeletePayment: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    setModalInfoCD({
      enabled: true,
      modalType: "delete-pending-payment",
      handleAccept: () => {
        deletePayment(id);
      },
    });
  };

  const navigateZelleInfo = () => {
    if (!code) return;
    navigate({ pathname: "/zelle", search: `?code=${code}` });
  };

  return (
    <Box
      border={1}
      py={1}
      px={2}
      borderColor={lightTheme.palette.success.main}
      display={"flex"}
      width={"100%"}
      maxWidth={300}
      justifyContent={"space-between"}
      borderRadius={2}
      onClick={navigateZelleInfo}
      style={{ cursor: code ? "pointer" : "default" }}
    >
      <Box display={"flex"} gap={3} alignItems={"center"}>
        <Typhography type="p-l-b">
          {new Date(date).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Typhography>
        <Typhography type="p-l-b">${amount}</Typhography>
      </Box>

      <Box
        style={{ cursor: "pointer" }}
        padding={1}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        onClick={onDeletePayment}
      >
        <IconCustom typeIcon="cross-close" width={14} height={14} />
      </Box>
    </Box>
  );
};
