import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { ReactComponent as AlarmClock } from "../../../assets/icons/alarm-clock.svg";
import { ReactComponent as AlignLeft } from "../../../assets/icons/align-left.svg";
import { ReactComponent as AmazonLogo } from "../../../assets/icons/amazon-logo.svg";
import { ReactComponent as AngleDown } from "../../../assets/icons/angle-down.svg";
import { ReactComponent as AngleLeft } from "../../../assets/icons/angle-left.svg";
import { ReactComponent as AngleRight } from "../../../assets/icons/angle-right.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as Asterik } from "../../../assets/icons/asterik.svg";
import { ReactComponent as Bug } from "../../../assets/icons/bug.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as CheckAlt } from "../../../assets/icons/check-alt.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Confetti } from "../../../assets/icons/confetti.svg";
import { ReactComponent as CopyAlt } from "../../../assets/icons/copy-alt.svg";
import { ReactComponent as CreditCard } from "../../../assets/icons/credit-card.svg";
import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";
import { ReactComponent as CrossClose } from "../../../assets/icons/cross-close.svg";
import { ReactComponent as Cube } from "../../../assets/icons/cube.svg";
import { ReactComponent as Diamond } from "../../../assets/icons/diamond.svg";
import { ReactComponent as Dollar } from "../../../assets/icons/dollar.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as EyeCrossed } from "../../../assets/icons/eye-crossed.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/facebook.svg";
import { ReactComponent as Flame } from "../../../assets/icons/flame.svg";
import { ReactComponent as FlexiCustomerSupport } from "../../../assets/icons/flexi-customer-support.svg";
import { ReactComponent as FlowerTulip } from "../../../assets/icons/flower-tulip.svg";
import { ReactComponent as Glasses } from "../../../assets/icons/glasses.svg";
import { ReactComponent as Globe } from "../../../assets/icons/globe.svg";
import { ReactComponent as HandHoldingHeart } from "../../../assets/icons/hand-holding-heart.svg";
import { ReactComponent as Hashtag } from "../../../assets/icons/hashtag.svg";
import { ReactComponent as Interrogation } from "../../../assets/icons/interrogation.svg";
import { ReactComponent as Key } from "../../../assets/icons/key.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/linkedin-free-icon-font.svg";
import { ReactComponent as List } from "../../../assets/icons/list.svg";
import { ReactComponent as LocationAlt } from "../../../assets/icons/location-alt.svg";
import { ReactComponent as Lock } from "../../../assets/icons/lock.svg";
import { ReactComponent as MagicFlexIcon } from "../../../assets/icons/magic-flex-icon.svg";
import { ReactComponent as MagicWand } from "../../../assets/icons/magic-wand.svg";
import { ReactComponent as MagicFlexIconThunders } from "../../../assets/icons/magicflex-icon.svg";
import { ReactComponent as ManHead } from "../../../assets/icons/man-head.svg";
import { ReactComponent as MapMarker } from "../../../assets/icons/map-marker.svg";
import { ReactComponent as Marker } from "../../../assets/icons/marker.svg";
import { ReactComponent as MarkerPackage } from "../../../assets/icons/marker-packages.svg";
import { ReactComponent as MiniatureQR } from "../../../assets/icons/miniature-qr.svg";
import { ReactComponent as Numeral } from "../../../assets/icons/numeral.svg";
import { ReactComponent as Package } from "../../../assets/icons/package.svg";
import { ReactComponent as Pause } from "../../../assets/icons/pause.svg";
import { ReactComponent as Pharmacy } from "../../../assets/icons/pharmacy.svg";
import { ReactComponent as Play } from "../../../assets/icons/play.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as ReactangleScroller } from "../../../assets/icons/reactangle-scroller.svg";
import { ReactComponent as Rocket } from "../../../assets/icons/rocket.svg";
import { ReactComponent as RotateRight } from "../../../assets/icons/rotate-right.svg";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as SearchAlt } from "../../../assets/icons/search-alt.svg";
import { ReactComponent as Settings } from "../../../assets/icons/settings.svg";
import { ReactComponent as SignOut } from "../../../assets/icons/sign-out.svg";
import { ReactComponent as Stop } from "../../../assets/icons/stop.svg";
import { ReactComponent as Stopwatch } from "../../../assets/icons/stopwatch.svg";
import { ReactComponent as TimeCheck } from "../../../assets/icons/time-check.svg";
import { ReactComponent as TimeQuarterTo } from "../../../assets/icons/time-quarter-to.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Tunder } from "../../../assets/icons/tunder.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as UserAdd } from "../../../assets/icons/user-add.svg";

const Icons = {
  "alarm-clock": AlarmClock,
  "align-left": AlignLeft,
  "angle-down": AngleDown,
  "angle-left": AngleLeft,
  "angle-right": AngleRight,
  "amazon-logo": AmazonLogo,
  "arrow-right": ArrowRight,
  "check-alt": CheckAlt,
  "copy-alt": CopyAlt,
  "credit-card": CreditCard,
  "cross-close": CrossClose,
  "eye-crossed": EyeCrossed,
  "flexi-customer-support": FlexiCustomerSupport,
  "flower-tulip": FlowerTulip,
  "hand-holding-heart": HandHoldingHeart,
  "location-alt": LocationAlt,
  "magic-flex-icon": MagicFlexIcon,
  "magic-wand": MagicWand,
  "magicflex-icon": MagicFlexIconThunders,
  "man-head": ManHead,
  "map-marker": MapMarker,
  "marker-packges": MarkerPackage,
  "reactangle-scroller": ReactangleScroller,
  "rotate-right": RotateRight,
  "search-alt": SearchAlt,
  "sign-out": SignOut,
  "time-check": TimeCheck,
  "time-quarter-to": TimeQuarterTo,
  "user-add": UserAdd,
  add: Add,
  asterik: Asterik,
  bug: Bug,
  calendar: Calendar,
  check: Check,
  clock: Clock,
  confetti: Confetti,
  cross: Cross,
  cube: Cube,
  diamond: Diamond,
  dollar: Dollar,
  eye: Eye,
  facebook: Facebook,
  flame: Flame,
  glasses: Glasses,
  globe: Globe,
  hashtag: Hashtag,
  interrogation: Interrogation,
  key: Key,
  linkedin: Linkedin,
  list: List,
  lock: Lock,
  marker: Marker,
  numeral: Numeral,
  package: Package,
  pause: Pause,
  pharmacy: Pharmacy,
  play: Play,
  plus: Plus,
  rocket: Rocket,
  search: Search,
  settings: Settings,
  stop: Stop,
  stopwatch: Stopwatch,
  trash: Trash,
  tunder: Tunder,
  twitter: Twitter,
  radar: "",
  upload: Upload,
  miniatureQr: MiniatureQR,
};

export default Icons;
