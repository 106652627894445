import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getUserById, updateUser } from "./functions";
import { UpdateUserRequest } from "./types";

export const useUserQuery = (userId?: string) => {
  const query = useQuery(["users", userId], () => getUserById(userId), {
    enabled: !!userId,
  });

  return {
    user: query.data,
    userQuery: query,
  };
};

export const useUserMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (params: { id: string; data: UpdateUserRequest }) =>
      updateUser(params.id, params.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });

  return {
    updateUser: mutation.mutateAsync,
    updateUserMutation: mutation,
  };
};
