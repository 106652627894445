import { TFunction } from "i18next";
import * as Yup from "yup";

import {
  RegisterAmzAccountFormValues,
  RegisterAmzAccountSubmit,
} from "./RegisterAmzAccount.types";

export const getFormik = (
  onSubmit: RegisterAmzAccountSubmit,
  t: TFunction<"common"[]>,
) => {
  return {
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("common:form.invalid", { name: t("common:form.email-address") }))
        .required(t("common:form.required", { name: t("common:form.email-address") })),
      password: Yup.string().required(
        t("common:form.required", { name: t("common:form.password") }),
      ),
    }),
  };
};
