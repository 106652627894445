import { combineReducers } from "@reduxjs/toolkit";

import amzAccount from "./reducers/amzAccount/amzAccount";
import amzAccounts from "./reducers/amzAccounts/amzAccounts";
import arrived from "./reducers/arrived/arrived";
import bot from "./reducers/bot/bot";
import instantOffers from "./reducers/instantOffers";
import logoIntro from "./reducers/logoIntro/logoIntro";
import menu from "./reducers/menu/menu";
import modalInfoC from "./reducers/modalInfo/modalInfoC";
import newRefNotification from "./reducers/newRefNotification/newRefNotification";
import notificationC from "./reducers/noficationC/notificationC";
import offers from "./reducers/offers/offers";
import orders from "./reducers/orders/orders";
import payment from "./reducers/payment/payment";
import register from "./reducers/register";
import setUpFilters from "./reducers/setUpFilters/setUpFilters";
import spinnerFullScreen from "./reducers/spinnerFullScreen/spinnerFullScreen";
import stateGet from "./reducers/stateGet/stateGet";
import timePikerState from "./reducers/timerPiker/timePikerState";
import ui from "./reducers/ui/ui";
import user from "./reducers/user/user";

const rootReducer = combineReducers({
  amzAccount,
  user,
  offers,
  bot,
  notificationC,
  setUpFilters,
  orders,
  modalInfoC,
  menu,
  amzAccounts,
  stateGet,
  spinnerFullScreen,
  instantOffers,
  payment,
  register,
  timePikerState,
  arrived,
  logoIntro,
  ui,
  newRefNotification,
});

export default rootReducer;
