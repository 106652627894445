import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useLatestReferred } from "../../../../hooks";
import useRefNotificationDispatch from "../../../../store/useDispatch/useNewRefNotificationDispatch";
import { useAmzAccountSelector, useUserSelector } from "../../../../store/useSelector";
import useRefNotificationSelector from "../../../../store/useSelector/useNewRefNotificationSelector";

const useNavbarPhoto = () => {
  const navigate = useNavigate();
  const { amzAccount } = useAmzAccountSelector();
  const { user } = useUserSelector();
  //* This execute the close event
  const handleClick = useCallback(() => {
    navigate("/referral");
  }, [navigate]);

  const avatar = useMemo(() => amzAccount?.photo, [amzAccount]);

  const { latestReferredQuery } = useLatestReferred();

  const { lastCheckTime, notificationsCount } = useRefNotificationSelector();
  const { setLastCheckTimeD, setNotificationsCountD } = useRefNotificationDispatch();

  useEffect(() => {
    // Checks that list is already populated by endpoint
    if (!latestReferredQuery.data || !latestReferredQuery.data.length) return;

    const lastReferredTime = new Date(latestReferredQuery.data[0].startDate).getTime();

    //Check if lastDate from notification state is less than the date from the last user of the list
    if (lastCheckTime < lastReferredTime) {
      const newReferred = latestReferredQuery.data.filter(
        (referred) => lastCheckTime <= new Date(referred.startDate).getTime(),
      ); //store in variable the date from every user in the list to compare them and check if a notification should be added

      setNotificationsCountD(newReferred.length);
      setLastCheckTimeD(lastReferredTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestReferredQuery.data]);

  return {
    avatar,
    handleClick,
    notificationsCount,
    avatarFallback: user?.firstname.charAt(0),
  };
};

export default useNavbarPhoto;
