import "./typhography.styles.scss";

import omit from "lodash/omit";

import { typhographyList } from "./typhography.list";
import { TyphographyProps } from "./typhography.types";

const Typhography = (props: TyphographyProps) => {
  const classNameTyphography = typhographyList[props?.type || "deafult"];

  if (props.render === "p") {
    return (
      <p
        {...omit(props, ["type", "children", "className"])}
        className={classNameTyphography + " " + props.className}
      >
        {props.children}
      </p>
    );
  } else {
    return (
      <span
        {...omit(props, ["type", "children", "className"])}
        className={classNameTyphography + " " + props.className}
      >
        {props.children}
      </span>
    );
  }
};

Typhography.defaultProps = {
  type: "overline-b",
  children: "Example",
  render: "p",
} as Partial<TyphographyProps>;

export default Typhography;
