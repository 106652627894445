import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Button, InputCustom } from "../../atoms";
import OtpRenewTimer from "../../molecules/otpRenewTimer/OtpRenewTimer";
import useVerifyOtpForm from "./hooks/useVerifyOtpForm";
import { VerifyOtpFormDefaultProps } from "./verifyOtpForm.constants";
import { VerifyOtpFormProps } from "./verifyOtpForm.types";

const VerifyOtpForm = (props: VerifyOtpFormProps) => {
  const { onRenewOtp, renewOtp, ...rest } = props;
  const { getTimeString, formikConfig } = useVerifyOtpForm(rest);
  const { t } = useTranslation(["auth"]);

  return (
    <div>
      <Formik {...formikConfig}>
        <Form>
          <InputCustom
            inputProps={{
              type: "text",
              label: t("auth:verify-otp.code"),
              name: "otp",
              placeholder: "XXX-XXX",
            }}
            subLabel={t("auth:verify-otp.code-label")}
          />
          <p className="magicflex-textligh-overline">
            {t("auth:verify-otp.expiration-time", { time: getTimeString() })}
          </p>
          <Button type="submit" className="mt-5">
            {t("auth:verify-otp.verify")}
          </Button>
          {onRenewOtp && <OtpRenewTimer {...renewOtp} onRenew={onRenewOtp} />}
        </Form>
      </Formik>
    </div>
  );
};

VerifyOtpForm.defaultProps = VerifyOtpFormDefaultProps;

export default VerifyOtpForm;
