import { OfferLog } from "../../../hooks/useOffers/useOffers.types";
import { CalendarOffer, OffersAF } from "../../types/offers.types";

export const setOfferLogs: OffersAF<OfferLog[]> = (state, action) => {
  state.offersLogs = action.payload || [];
};

export const setCalendarOffers: OffersAF<CalendarOffer[]> = (state, action) => {
  state.calendarOffers = action.payload || [];
};

export const startLoading: OffersAF = (state) => {
  state.loading = true;
};

export const stopLoading: OffersAF = (state) => {
  state.loading = false;
};

export const setError: OffersAF<string> = (state, action) => {
  state.error = action.payload;
};

export const deleteOffersLogs: OffersAF = (state) => {
  state.offersLogs = [];
};

export const deleteCalendarOffers: OffersAF = (state) => {
  state.calendarOffers = [];
};

export const deleteCalendarOffer: OffersAF<number> = (state, action) => {
  state.calendarOffers = state.calendarOffers.filter(
    (offer) => offer.startTime !== action.payload,
  );
};

export const addCalendarOffer: OffersAF<CalendarOffer> = (state, action) => {
  action.payload && state.calendarOffers.push(action.payload);
};

export const addCalendarOffers: OffersAF<CalendarOffer[]> = (state, action) => {
  state.calendarOffers = [...state.calendarOffers, ...(action.payload || [])];
};

export const deleteOfferLog: OffersAF<number> = (state, action) => {
  state.offersLogs = state.offersLogs?.filter(
    (offer) => offer.blockId !== action.payload,
  );
};

export const addOffersLogs: OffersAF<OfferLog[]> = (state, action) => {
  state.offersLogs = [...state.offersLogs, ...(action.payload || [])];
};

export const reset: OffersAF = (state) => {
  state.offersLogs = [];
  state.loading = false;
  state.error = "";
  state.calendarOffers = [];
};
