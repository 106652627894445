import "./imageCustom.styles.scss";

import omit from "lodash/omit";

import { ImageCustomProps } from "./imageCustom.type";
import Images from "./imageList";
// For add more iconss https://www.flaticon.com/uicons?word=info inside the inconslist
const ImageCustom = (props: ImageCustomProps): JSX.Element => {
  const Image = Images[props?.typeImage || "flexi-error"];
  const className = props?.className || "";
  return (
    <img
      {...omit(props, ["typeImage", "image", "alt", "className"])}
      src={Image}
      alt={props.alt || ""}
      className={`${className}`}
    />
  );
};

ImageCustom.defaultProps = {
  className: "imageFlexi",
  alt: "Flexi gesture error",
  image: "flexi-error",
} as Partial<ImageCustomProps>;

export default ImageCustom;
