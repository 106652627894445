import i18n from "i18next";

import { ModalInfoProps } from "../../../components/organisms/modalInfo/modalInfo.types";
import { useModalInfoDispatch } from "../../../store/useDispatch";
import { IError, ObjectErros } from "./useGet.types";

const typeError = (error: IError): ModalInfoProps => {
  const errors: ObjectErros = {
    "G-LOCATIONS": {
      enabled: true,
      modalInfoProps: {
        type: "danger",
        ...i18n.t("popup:get-locations-error", { returnObjects: true, ns: ["popup"] }),
        image: {
          typeImage: "flexi-error",
        },
      },
    },
    G_CALENDAR_OFFERS: {
      enabled: false,
    },
    "G-MEMBERSHIP": {
      enabled: false,
      modalInfoProps: {
        type: "success",
        ...i18n.t("popup:no-membership-found", { returnObjects: true, ns: ["popup"] }),
        image: {
          typeImage: "flexi-error",
        },
      },
    },
    G_ACTIVE_MEMBERSHIP: {
      enabled: false,
    },
    G_INSTANT_OFFERS_SAVED_FILTERS: {
      enabled: false,
    },
    "G-AMZ-SELECTED": {
      enabled: false,
    },
    MEMBERSHIP_REQUIRED: {
      enabled: false,
      modalInfoProps: {
        type: "success",
        ...i18n.t("popup:no-membership-found", { returnObjects: true, ns: ["popup"] }),
        image: {
          typeImage: "flexi-error",
        },
      },
    },
    "G-SELECT-AMZ-ACCOUNT": {
      enabled: false,
    },
    DEFAULT: {
      enabled: true,
      modalInfoProps: {
        type: "danger",
        ...i18n.t("popup:unexpected-error", {
          code: error.code,
          returnObjects: true,
          ns: ["popup"],
        }),
        image: {
          typeImage: "flexi-danger",
        },
      },
    },
    "R-LOAD-STATIONS": {
      enabled: false,
    },
    "G-BOT-STATE": {
      enabled: false,
    },
    "G-VM-STATE": {
      enabled: false,
    },
    newReferred: {
      enabled: false,
    },
    "G-WALLET": {
      enabled: false,
    },
  };
  return errors[error.code] ?? errors["DEFAULT"];
};

const useControlErrorGet = () => {
  const { setModalInfoCD } = useModalInfoDispatch();
  return {
    controlsErrorsGet: (error: IError) => {
      const modalMessageProps = typeError(error);
      modalMessageProps.enabled && setModalInfoCD(modalMessageProps);
    },
  };
};
export default useControlErrorGet;
