import { useCallback, useEffect, useState } from "react";

import { validateNumberMinutes } from "./useClock.functions";

const useMinutes = () => {
  const [minutesCounter, setMinutesCounter] = useState(0);
  const [minutes, setMinutes] = useState("00");

  const handlePressAddMinutes = useCallback(() => {
    setMinutesCounter((prev) => (prev < 59 ? ++prev : 0));
  }, []);

  const handlePressLessMinutes = useCallback(() => {
    setMinutesCounter((prev) => (prev < 1 ? 59 : --prev));
  }, []);

  const handleChangeMinutes = useCallback((value: string) => {
    validateNumberMinutes({
      value,
      setNumber: setMinutesCounter,
      setString: setMinutes,
    });
  }, []);

  const changeMinutes = useCallback((value: number) => {
    setMinutesCounter(value);
  }, []);

  useEffect(() => {
    const value =
      Number(minutesCounter) < 10 ? "0" + minutesCounter : `${minutesCounter}`;
    setMinutes(value);
  }, [minutesCounter]);

  return {
    minutes: minutes,
    handlePressAddMinutes,
    handlePressLessMinutes,
    handleChangeMinutes: handleChangeMinutes,
    changeMinutes,
  };
};

export default useMinutes;
