import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useModalInfoDispatch, useStateSpinnerFullScreen } from "../../store/useDispatch";
import { useAmzAccountSelector } from "../../store/useSelector";
import { useMembershipQuery } from "./queries";

type Params = {
  spinnerFullScreen?: boolean;
};

export const useGetActiveMembership = (props?: Params) => {
  const { t } = useTranslation(["popup"]);
  const navigate = useNavigate();

  const { setSpinnerFullScreenD, cleanSpinnerFullScreenD } = useStateSpinnerFullScreen();
  const { amzAccount } = useAmzAccountSelector();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { membership, membershipQuery } = useMembershipQuery(amzAccount?._id);

  useEffect(() => {
    if (!props?.spinnerFullScreen) return;

    if (membershipQuery.isLoading) {
      setSpinnerFullScreenD({
        color: "green",
        enabled: true,
      });
    } else {
      cleanSpinnerFullScreenD();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipQuery.isLoading, props?.spinnerFullScreen]);

  return {
    activeMembership: membership,
    showErrorActiveMembership: () => {
      setModalInfoCD({
        enabled: true,
        modalInfoProps: {
          type: "success",
          ...t("popup:premium-required", { returnObjects: true }),
          image: {
            typeImage: "flexi-error",
          },
        },
        handleAccept: () => {
          navigate("/premium/payment-type");
        },
      });
    },
  };
};
