import { CalendarOffer } from "../../store/types/offers.types";

export const sortCalendarOffersByTime = (calendarOffers: CalendarOffer[]) => {
  if (!calendarOffers || calendarOffers.length === 0) return [];
  if (calendarOffers.length === 1) return calendarOffers;
  return calendarOffers.sort((a, b) => a.startTime - b.startTime);
};

export const filterCalendarOffersByTime = (
  calendarOffers: CalendarOffer[],
): CalendarOffer[] => {
  if (!calendarOffers || calendarOffers.length === 0) return [];
  const now = new Date();

  const filter = (offer: CalendarOffer) => {
    const startTime = new Date(offer.startTime * 1000);

    return startTime.getTime() >= now.getTime() - 5 * 1000 * 60;
  };

  return calendarOffers.filter(filter);
};
