import { createSlice } from "@reduxjs/toolkit";

import { AmzAccountsState } from "../../types/amzAccounts.types";
import * as reducers from "./actions";

const initialState: AmzAccountsState = {
  amzAccounts: [],
  loading: false,
  error: "",
};

const amzAccountsSlice = createSlice({
  name: "amzAccounts",
  initialState,
  reducers,
});

export const {
  addAmzAccount,
  addAmzAccounts,
  deleteAmzAccount,
  resetState,
  setAmzAccounts,
  setError,
  startLoading,
  stopLoading,
} = amzAccountsSlice.actions;

export default amzAccountsSlice.reducer;
