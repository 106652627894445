import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useUserSelector } from "../store/useSelector";
import { useUserMutation } from "./users";

export const useClarity = () => {
  const { user } = useUserSelector();
  const location = useLocation();
  const { updateUser } = useUserMutation();

  const updateUserClarity = async (data: { id: string }) => {
    if (!user?._id) return;

    const clarityId = data.id;

    await updateUser({ id: user._id, data: { clarityId } });
  };

  useEffect(() => {
    if (user?.email) {
      window
        .clarity("identify", user.email, undefined, location.pathname, user.email)
        ?.then(updateUserClarity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, location.pathname]);
};
