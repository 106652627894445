import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { usePaymentsQuery } from "../../../../hooks";
import { useAmzAccountSelector } from "../../../../store/useSelector";
import { Typhography } from "../../../atoms";
import { PendingPayment } from "./PendingPayment";

export const PendingPayments = () => {
  const { t } = useTranslation(["common"]);
  const { amzAccount } = useAmzAccountSelector();
  const { pendingPayments } = usePaymentsQuery(amzAccount?._id);

  if (!pendingPayments || !pendingPayments.length) return null;

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} mb={3}>
        <Typhography type="h-2">{t("common:pending-payments")}</Typhography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={{
          xs: "center",
          sm: "flex-start",
        }}
        gap={2}
        flexWrap={"wrap"}
      >
        {pendingPayments?.map((payment, index) => (
          <PendingPayment
            key={index}
            id={payment._id}
            date={payment.createdAt}
            amount={payment.amount}
            code={payment.description?.includes("MF") ? payment.description : undefined}
          />
        ))}
      </Box>
    </Box>
  );
};
