import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Space } from "..";
import Typhography from "../typhography/Typhography";

const CreateAccountLink = () => {
  const { t } = useTranslation(["auth"]);

  return (
    <div className="has-text-centered">
      <div className="is-flex is-justify-content-center">
        <Typhography type="overline-b" className="mf-text-secondary">
          {t("auth:login.no-account")}
        </Typhography>
      </div>
      <Link to="/auth/register" className="magicflex-create-account">
        {t("auth:login.create-account")} <Space amount={0.6} />
        <span className="is-right is-size-7">
          <FontAwesomeIcon icon="chevron-right" />
        </span>
      </Link>
    </div>
  );
};

export default CreateAccountLink;
