//https://codepen.io/uimax/pen/KgdgGa

import "./progressBar.styles.scss";

interface Props {
  progress: number;
}

const ProgressBar = (props: Props) => {
  const { progress } = props;

  return (
    <div className="progress progress-striped active">
      <div style={{ width: `${progress}%` }} className="progress-bar">
        <span>{progress}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
