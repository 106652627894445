import { useContext } from "react";

import { IconCustom } from "../../../atoms";
import { TypeIcon } from "../../../atoms/icons/icons.type";
import { WalletTrxContext } from "../WalletTransactionItem";
import { IWalletTrxItemIconProps } from "../walletTransactionItem.types";

const icons: {
  [key: string]: TypeIcon;
} = {
  deposit: "pharmacy",
  package: "cube",
  "instant-offer": "flame",
};

const WalletTrxItemIcon = (props: IWalletTrxItemIconProps) => {
  const { type } = useContext(WalletTrxContext);

  return (
    <div className={`mf-wallet-transaction_info_icon ${type}`}>
      <IconCustom
        typeIcon={icons[props.type || type]}
        width={16}
        height={16}
        color="white"
      />
    </div>
  );
};

export default WalletTrxItemIcon;
