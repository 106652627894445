import { useInstantOffersDispatch } from "../../../../../store/useDispatch";
import { AmzAccountsItemProps } from "../amzAccountsItem.types";

const useAmzAccountsItem = (params: AmzAccountsItemProps) => {
  const { email, onDelete, onSelect, selected } = params;
  const { radarIconFooterDisabledD } = useInstantOffersDispatch();
  const selectedClasses = selected ? "selected" : "";

  const handleSelect = () => {
    if (!selected) {
      radarIconFooterDisabledD();
      onSelect && onSelect();
    }
  };

  return { email, onDelete, onSelect: handleSelect, selectedClasses };
};

export default useAmzAccountsItem;
