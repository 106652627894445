import { useTranslation } from "react-i18next";

import { Button } from "../../../atoms";
import { TitleModalSwitch } from "../../../molecules";
import { getLocationModalTitle } from "../../filters/searchFilters/searchFilters.types";
import { ISelectStationsProps } from "./InstantOffers.types";

const InstantOffersSelectStations = (props: ISelectStationsProps) => {
  const { btnText, ...btnProps } = props;
  const { t } = useTranslation(["filters", "instant-offers"]);

  return (
    <>
      <div className="py-3 mb-3">
        <TitleModalSwitch {...getLocationModalTitle(t)} className="mb-5" />
        <Button color="primary" {...btnProps}>
          {btnText || t("instant-offers:select-stations")}
        </Button>
      </div>
    </>
  );
};

export default InstantOffersSelectStations;
