import { useCallback, useEffect, useState } from "react";

import { validateNumberHour } from "./useClock.functions";

const useHours = () => {
  const [hourCounter, setHourCounter] = useState(1);

  const [hour, setHour] = useState("01");

  const handlePressAddHours = useCallback(() => {
    setHourCounter((prev) => (prev < 12 ? ++prev : 1));
  }, []);

  const handlePressLessHours = useCallback(() => {
    setHourCounter((prev) => (prev > 1 ? --prev : 12));
  }, []);

  const handleChangeHours = useCallback((value: string) => {
    validateNumberHour({
      value,
      setNumber: setHourCounter,
      setString: setHour,
    });
  }, []);

  const changeHour = useCallback((value: number) => {
    setHourCounter(value);
  }, []);

  useEffect(() => {
    const value = Number(hourCounter) < 10 ? "0" + hourCounter : `${hourCounter}`;
    setHour(value);
  }, [hourCounter]);

  return {
    hour,
    handlePressLessHours,
    handlePressAddHours,
    handleChangeHours,
    changeHour,
  };
};
export default useHours;
