import { magicAPI } from "../../api";
import { GetUserByIDResponse, UpdateUserRequest } from "./types";

export const getUserById = async (userId?: string) => {
  if (!userId) throw new Error("No user ID provided");

  const response = await magicAPI.get<GetUserByIDResponse>(`/users/${userId}`);

  if (response.status !== 200) {
    throw new Error("Error fetching user");
  }

  return response.data;
};

export const updateUser = async (userId: string, data: UpdateUserRequest) => {
  const response = await magicAPI.put(`/users/${userId}`, data);

  if (response.status !== 200) {
    throw new Error("Error updating user");
  }

  return response.data;
};
