import "./timePiker.styles.scss";

import { Box, Modal } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClockPicker } from "@mui/x-date-pickers/ClockPicker/ClockPicker";
import { ClockPickerView } from "@mui/x-date-pickers/internals/models";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Button, IconCustom, Typhography } from "../../atoms";
import { DisplayTime, HeaderModalPlus } from "../../molecules/index";
import { TimePikerProps } from "./timerPiker.types";
import useTimePiker from "./useTimePiker";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 6,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const TimePicker = (props: TimePikerProps) => {
  const {
    date,
    open,
    displayTime,
    onBack,
    onChange,
    onFinish,
    onClose,
    onContinue,
    typeTime,
    selectedDay,
  } = useTimePiker(props);
  const { isEditing } = displayTime;

  const themePink = createTheme({ palette: { primary: { main: "#00C363" } } });
  const day = selectedDay ? moment().day(selectedDay).format("dddd") : "  ";

  const { t } = useTranslation(["common", "filters"]);

  return (
    <Modal
      open={open}
      onClose={() => null}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style }}>
        <HeaderModalPlus
          onClose={onClose}
          title={`${day} ${
            typeTime === "start"
              ? t("filters:start-at.starting-time")
              : t("filters:start-at.ending-time")
          }`}
          subtitle={t("filters:start-at.on-days", {
            days: day,
            action:
              typeTime === "start"
                ? t("filters:start-at.start")
                : t("filters:start-at.end"),
          })}
          iconTitle={"alarm-clock"}
        />
        <DisplayTime {...displayTime} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={themePink}>
            <ClockPicker
              ampm={true}
              date={date}
              view={isEditing as ClockPickerView | undefined}
              onChange={onChange}
            />
          </ThemeProvider>
        </LocalizationProvider>
        <div
          className={
            typeTime === "start"
              ? "is-pulled-right marginButtonOptions"
              : "containerOptionsTimePiker marginButtonOptions"
          }
        >
          {typeTime === "start" ? (
            <Button
              text={t("common:continue")}
              rightIcon="angle-right"
              onClick={onContinue}
            />
          ) : (
            <>
              <div
                role="button"
                tabIndex={0}
                onKeyUp={onBack}
                className="containerLinkBack"
                onClick={onBack}
              >
                <IconCustom typeIcon={"angle-left"} width={26} height={26} />
                <Typhography type={"p-s-b"} render={"span"}>
                  {t("filters:start-at.back")}
                </Typhography>
              </div>
              <Button
                text={t("common:finish")}
                className="buttonFinishTime"
                onClick={onFinish}
              />
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

TimePicker.defaultProps = {
  open: false,
} as Partial<TimePikerProps>;

export default TimePicker;
