export enum PaymentType {
  PPB40 = "PPB40",
  PPB80 = "PPB80",
  PAS45 = "PAS45",
  PAS80 = "PAS80",
  PAS140 = "PAS140",
}

export interface PostCreatePaymentIntentResponse {
  _id: string;
  amount: number;
  amzUserId: string;
  createdAt: Date;
  description?: string;
  membershipId: string;
  membershipTypeId: string;
  paymentMethod: string;
  state: string;
  type: string;
  updatedAt: Date;
  userId: string;
}

export type PostCreatePaymentIntentData = { amzUserId: string; code: string };
