import { IStartAtDayCheckProps } from "../../../molecules/startAtDayCheck/startAtDayCheck.types";
import { IStartAtTime } from "../setUpDays/hooks/formik/useFormikDays.types";

const initialStart: IStartAtTime = {
  hour: 5,
  minutes: 0,
};

const initialEnd: IStartAtTime = {
  hour: 18,
  minutes: 0,
};

export const initialDays: IStartAtDayCheckProps[] = [0, 1, 2, 3, 4, 5, 6].map((day) => ({
  day: { day, start: initialStart, end: initialEnd },
  selected: false,
  handleSelect: () => {
    //
  },
  handleChangeTime: () => {
    //
  },
}));
