import "./referredIcon.styles.scss";

type Props = {
  className?: string;
};

const ReferredIcon = (props: Props) => {
  return <span className={`mf-referred_status_item_icon ${props.className}`} />;
};

export default ReferredIcon;
