import { IconCustomProps } from "../../atoms/icons/icons.type";

type MessageStatusType = {
  empty: MessageStatus;
  looking: MessageStatus;
};

type MessageStatus = { icon: IconCustomProps; title: string; message: string };

export const messageStatusType: MessageStatusType = {
  empty: {
    icon: { typeIcon: "confetti" },
    title: "Your reveservations list is empty!",
    message:
      "Everything is set, just click the start button and let’s find some blocks fitting your filters.",
  },
  looking: {
    icon: { typeIcon: "search-alt" },
    title: "Looking for blocks",
    message: "The spinning icon at the bottom means the search is running.",
  },
};

export type BotStatusMessageProps = {
  type: keyof MessageStatusType;
};

export const BotStatusMessageDefaultProps: MessageStatus = {
  icon: { typeIcon: "search-alt" },
  message: "The spinning icon at the bottom means the search is running.",
  title: "Looking for blocks",
};
