import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";

const ChangePasswordScreen = lazy(
  /* webpackChunkName: "ChangePasswordScreen" */ () =>
    import("../../components/pages/private/changePassword/ChangePassword"),
);

const VerifyOtpScreen2 = lazy(
  /* webpackChunkName: "VerifyOtpScreen2" */ () =>
    import("../../components/pages/private/verifyOtp/VerifyOtp"),
);

const ChangePasswordRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route index element={<ChangePasswordScreen />} />
        <Route path="/verify-otp" element={<VerifyOtpScreen2 />} />
      </Routes>
    </Suspense>
  );
};
export default ChangePasswordRoutes;
