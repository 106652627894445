import { createSlice } from "@reduxjs/toolkit";

import { PaymentState } from "../../types/payment.types";
import * as reducers from "./actions";

const initialState: PaymentState = {
  amzUserId: undefined,
  membershipTypeId: undefined,
  amount: undefined,
  paymentType: undefined,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers,
});

export const { initPayment, cleanPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
