import { createSlice } from "@reduxjs/toolkit";

import { UserState } from "../../types/user.types";
import * as reducers from "./actions";

const initialState: UserState = {
  loading: false,
  user: undefined,
  error: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers,
});

export const { deleteUser, setUser } = userSlice.actions;

export default userSlice.reducer;
