import "./slider.styles.scss";

import { ISliderCustomProps } from "./sliderCustom.types";

const SliderCustom = (props: ISliderCustomProps) => {
  return <input type="range" className={props.disabled ? "disabled" : ""} {...props} />;
};

SliderCustom.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: 0,
} as ISliderCustomProps;

export default SliderCustom;
