import loading from "../../../assets/images/loading.gif";
import { ISpinnerMagicProps } from "./spinnerMagic.types";

const SpinnerMagic = (props: ISpinnerMagicProps) => {
  return (
    <>
      <img src={loading} alt="loading" height={props.height} width={props.width} />
    </>
  );
};

SpinnerMagic.defaultProps = {
  size: "midle",
  color: "green",
  height: 75,
  width: 75,
} as Partial<ISpinnerMagicProps>;
export default SpinnerMagic;
