import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { envs } from "../../../../../config/env";
import { usePaymentSelector } from "../../../../../store/useSelector";
import { CheckoutForm } from "../../../../templates";
import SettingsLayout from "../../../settings/settingsLayout/SettingsLayout";
import useCreatePayment from "./hooks/useCreatePayment";

const stripePromise = loadStripe(envs.STRIPE_PUBLIC_KEY);

const WalletCheckoutScreen = () => {
  const [clientSecret, setClientSecret] = useState("");
  const { createPayment, paymentIntentId, loading } = useCreatePayment();
  const { amount, paymentType, membershipTypeId } = usePaymentSelector();
  const { t, i18n } = useTranslation(["membership"]);
  const creatingPayment = useRef(false);

  useEffect(() => {
    if (loading) return;

    const createPaymentIntent = () => {
      if (creatingPayment.current) return;
      creatingPayment.current = true;

      if (paymentType === "wallet")
        createPayment({
          amount,
          membership: membershipTypeId,
        });

      setTimeout(() => {
        creatingPayment.current = false;
      }, 1000);
    };

    createPaymentIntent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentIntentId) setClientSecret(paymentIntentId);
  }, [paymentIntentId]);

  const appearance: Appearance = {
    theme: "stripe",
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
    locale: i18n.language as "es" | "en",
  };

  return (
    <SettingsLayout
      title={t("membership:checkout.title")}
      openMenuOnBack={false}
      backUrl="/premium/charge"
    >
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </SettingsLayout>
  );
};
export default WalletCheckoutScreen;
