import "./blockOption.styles.scss";

import { Key } from "react";

import { LabelRadio } from "../";
import ConceptOption from "../conceptOption/ConceptOptionCheck";
import { ConceptOptionProps } from "../conceptOption/conceptOptionCheck.type";
import { DayLabelRadioProps } from "../labelRadio/dayLabelRadio.types";
import { LabelRadioProps } from "../labelRadio/labelRadio.types";
import { defaultProps, FormSetUpPriceProps } from "./blocksOptionsList.types";

const BlocksOptionsList = (props: FormSetUpPriceProps): JSX.Element => {
  const { blockOptions, blockType, className, enabledPrice } = props;

  return (
    <div className={"mg-scroll-container " + className ?? ""}>
      {blockOptions?.map(
        (
          blockOptionProps: LabelRadioProps | DayLabelRadioProps | ConceptOptionProps,
          key: Key,
        ) => {
          const conceptProps = blockOptionProps as ConceptOptionProps;
          const labelRadiotProps = blockOptionProps as LabelRadioProps;
          if (blockType === "input") {
            return (
              <div className="container-block" key={`${key}-${labelRadiotProps.smTitle}`}>
                <LabelRadio {...labelRadiotProps} />
              </div>
            );
          } else {
            // Render for Stations.
            return (
              <div
                className="container-block"
                key={`${key}-${conceptProps.typography.smTitle}`}
              >
                <ConceptOption {...conceptProps} enabledPrice={enabledPrice} />
              </div>
            );
          }
        },
      )}
    </div>
  );
};
BlocksOptionsList.defaultProps = defaultProps;

export default BlocksOptionsList;
