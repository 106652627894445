import { useTranslation } from "react-i18next";

import { SliderCustom, Typhography } from "../../../atoms";
import { TitleModalSwitch } from "../../../molecules";
import { ISliderProps } from "./InstantOffers.types";

const InstantOffersSlider = (props: ISliderProps) => {
  const { t } = useTranslation(["instant-offers", "common"]);

  return (
    <>
      <div className="py-3 mb-5">
        <div className="is-flex is-justify-content-space-between mb-2">
          <TitleModalSwitch
            modalTitle={{
              title: t("instant-offers:slider-title"),
              description: t("instant-offers:slider-description"),
              icon: {
                typeIcon: "map-marker",
                width: 14,
                height: 14,
              },
            }}
            switch={{
              show: false,
            }}
            className="mb-1"
          />

          <Typhography render="span" type="p-r-b">
            {props.value} {t("common:mile", { count: Math.floor(props.value) })}
          </Typhography>
        </div>
        <SliderCustom {...props} />
      </div>
    </>
  );
};

export default InstantOffersSlider;
