import "./setUpDays.style.scss";

import { Separator } from "../../../atoms";
import { BlocksOptionsList, Modal, TitleModalSwitch } from "../../../molecules";
import useSetUpDay from "./hooks/useSetUpDay";
import { SetUpDayProps } from "./setUpDay.types";

const SetUpDay = (props: SetUpDayProps): JSX.Element => {
  const { titles, ...modal } = props;
  const [title1] = titles;

  const { handleCloseModal, list } = useSetUpDay({
    onClose: props.onClose as () => void,
  });

  return (
    <Modal
      {...modal}
      title="Blocks Start At"
      contentClassName="px-4"
      onCloseClick={handleCloseModal}
    >
      <div className="my-3">
        <TitleModalSwitch {...title1} switch={{ show: false }} />
      </div>
      <Separator />

      <div className="pt-2">
        <BlocksOptionsList blockOptions={list} />
      </div>
    </Modal>
  );
};

SetUpDay.defaultProps = {
  titles: [
    {
      modalTitle: {
        title: "Blocks start at",
        description: "Capture block starting at a specific time",
        icon: {
          typeIcon: "alarm-clock",
          width: 14,
          height: 14,
        },
      },
    },
    {
      modalTitle: {
        title: "Enable Advanced Settings",
        description: "Define the starting time for specific days over the week",
        icon: {
          typeIcon: "settings",
          width: 14,
          height: 14,
        },
      },
    },
  ],
} as Partial<SetUpDayProps>;

export default SetUpDay;
