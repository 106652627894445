/* eslint-disable no-case-declarations */
import { Form, Formik } from "formik";

import { PhoneInput } from "../../atoms";
import Button from "../../atoms/buttons/Button";
import InputCustom from "../../atoms/inputCustom/InputCustom";
import SelectCustom from "../../atoms/selectCustom/SelectCustom";
import { CheckTermsOfServices, LabelRadio } from "../../molecules";
import {
  FormDynamicProps,
  FormField,
  FormInput,
  FormLabelRadio,
  FormSelect,
} from "./formDynamic.types";
import useFormDynamic from "./hooks/useFormDynamic";

const Field = ({ field }: { field: FormField }) => {
  const { fieldType, name, label = "", ...rest } = field;

  switch (fieldType) {
    case "input":
      const { type, placeholder } = rest as FormInput;

      if (type === "phone") {
        return <PhoneInput key={name} inputProps={{ name }} label={label} />;
      }

      return (
        <InputCustom
          key={name}
          inputProps={{
            name,
            label,
            type,
            placeholder,
          }}
        />
      );

    case "select":
      const { selectProps } = field as FormSelect;
      return (
        <SelectCustom
          key={name}
          label={label}
          {...rest}
          selectProps={{ ...selectProps, name }}
        >
          {(rest as FormSelect).options?.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </SelectCustom>
      );
    case "labelRadio":
      const { labelRadio } = rest as FormLabelRadio;
      const props: typeof labelRadio = {
        ...labelRadio,
        input: { name, value: rest.value },
      };
      return <LabelRadio {...props} />;

    case "checkTermsOfService":
      return <CheckTermsOfServices name={name} label={label} value={rest.value} />;
    default:
      throw new Error(`type ${fieldType} not implemented`);
  }
};

const FormDynamic = (props: FormDynamicProps) => {
  const { fields: formFields, onSubmit, submitBtn } = props;

  const { formik } = useFormDynamic({ formFields, onSubmit });

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          {formFields.map((field, idx) => (
            <Field key={field.name + idx} field={field} />
          ))}
          <div className="my-4">
            <Button type="submit">{submitBtn.label}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormDynamic;
