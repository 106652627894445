import gsap from "gsap";
import { useRef } from "react";

const useAnimation = () => {
  const elementRef = useRef<any>();
  const tl = useRef(gsap.timeline()).current;

  return {
    elementRef,
    controller: tl,
    to: (config: gsap.TweenVars) => {
      if (!elementRef.current) return;
      tl.to(elementRef.current, config);
      tl.play();
    },
    stop: () => {
      tl.clear();
    },
  };
};

export default useAnimation;
