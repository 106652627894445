import "./notification.styles.scss";

import React from "react";

import useNotification from "./hooks/useNotification";

const Notifications = (): JSX.Element => {
  const { notificationC, handleClose } = useNotification();
  const newProps = notificationC;

  if (!newProps?.enabled) {
    return <></>;
  }

  return (
    <div className={`container-modal`}>
      <div
        className={`notification box  m-5 is-${newProps.type} is-light notificont`}
        style={newProps?.stylesContent}
      >
        <p className={`title is-5`}>{newProps.title}</p>
        <button className={"delete"} onClick={handleClose}></button>
        <div className="content">{newProps?.body}</div>
      </div>
    </div>
  );
};

Notifications.defaultProps = {
  enabled: true,
  type: "warning",
  title: "Example",
  body: (
    <p>
      <strong>John Smith</strong> <small>@johnsmith</small> <small>31m</small>
    </p>
  ),
  stylesContent: {},
};

export default Notifications;
