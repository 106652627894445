import { createSlice } from "@reduxjs/toolkit";

interface UIState {
  progressBar: {
    show: boolean;
    value: number;
  };
  maintenance: {
    show: boolean;
  };
  acceptedBalanceExpiration: boolean;
}

const slice = createSlice({
  name: "ui",
  initialState: {
    progressBar: {
      show: false,
      value: 0,
    },
    maintenance: {
      show: false,
    },
    acceptedBalanceExpiration: false,
  },
  reducers: {
    showProgressBar(state: UIState, action) {
      state.progressBar.show = true;
      state.progressBar.value = action.payload || 0;
    },
    hideProgressBar(state: UIState) {
      state.progressBar.show = false;
    },
    updateProgressBar(state: UIState, action) {
      state.progressBar.value = action.payload;
    },
    setShowMaintenance(state: UIState, action) {
      state.maintenance.show = action.payload;
    },
    setAcceptedBalanceExpiration(state: UIState, action) {
      state.acceptedBalanceExpiration = action.payload;
    },
  },
});

export const uiActions = slice.actions;

export default slice.reducer;
