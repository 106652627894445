import WizardPageIndicator from "../../molecules/wizardPageIndicator/WizardPageIndicator";
import WizardHOC from "./Wizard";
import WizardPage from "./WizardPage";

const Wizard = Object.assign(WizardHOC, {
  PageIndicator: WizardPageIndicator,
  Page: WizardPage,
});

export { Wizard };

export default Wizard;
