import { createSlice } from "@reduxjs/toolkit";

import { IInstantOffersState } from "../../types/instantOffers.types";
import * as reducers from "./actions";

const initialState: IInstantOffersState = {
  isActive: false,
  radarIconEnabled: false,
  playAnimation: false,
};

const instantOffersSlice = createSlice({
  name: "instantOffers",
  initialState,
  reducers,
});

export const {
  activate,
  deactivate,
  radarIconFooterEnabled,
  radarIconFooterDisabled,
  clear,
  setPlayAnimation,
} = instantOffersSlice.actions;

export default instantOffersSlice.reducer;
