import { Box } from "@mui/material";
import _ from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Typhography } from "../../../atoms";
import { WalletTrxContext } from "../WalletTransactionItem";
import { IWalletTrxItemPriceProps } from "../walletTransactionItem.types";

const WalletTrxItemPrice = (props: IWalletTrxItemPriceProps) => {
  const { t } = useTranslation(["common"]);
  const { amount, balance } = useContext(WalletTrxContext);

  let price = props.amount || amount;

  const isPositive = price > 0;

  const sign = isPositive ? "+" : "-";
  price = _.round(Math.abs(price), 2);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"end"} gap={0.5}>
      <Typhography
        type="h-3"
        className={`mf-wallet-transaction_price ${isPositive ? "positive" : "negative"}`}
        render="span"
        style={{ textAlign: "end" }}
      >
        {sign}${price}
      </Typhography>
      {balance && (
        <Box>
          <Typhography type="p-r-r" className="mf-text-secondary" render="span">
            {t("common:balance")}:{" "}
          </Typhography>
          <Typhography type="p-r-b" render="span">
            {balance.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Typhography>
        </Box>
      )}
    </Box>
  );
};

export default WalletTrxItemPrice;
