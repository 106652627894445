import { BlocksOptions } from "../../../stationsModal/formLocation.types";
import {
  onChangeEvent,
  transformText,
} from "../../../stationsModal/stationsModal.functions";
import { GenerateBlocks } from "../../../stationsModal/StationsModal.types";

export const generateBlocks = (elements: GenerateBlocks): BlocksOptions => {
  const { stations, onChange } = elements;
  return stations.map((station) => {
    const { smTitle, bodyText } = transformText(station.areaName);
    return {
      priceInput: {
        value: station?.price || 0,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const price = Number(e.target.value);

          const max = e.target.getAttribute("max");
          if (max && price > Number(max)) return;

          onChangeEvent({
            station: { ...station, price },
            onChange,
          });
        },
      },
      typography: {
        smTitle,
        bodyText,
      },
      enabled: station?.state,
      onChange: ({ enabled }) => {
        onChangeEvent({
          station: { ...station, state: enabled },
          onChange,
        });
      },
    };
  });
};
