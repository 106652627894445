import { addDays } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { envs } from "../../config/env";
import { useModalInfoDispatch } from "../../store/useDispatch";
import { Helpers } from "../../utils/helpers";
import { useWalletMutation, useWalletQuery } from "../membership";

const enabledBalanceExpiration = envs.ENABLED_BALANCE_EXPIRATION;
const expirationDate = envs.BALANCE_EXP_START_DATE;
const expirationDays = envs.BALANCE_EXP_DAYS;

export const useBalanceExpirationPopup = () => {
  const { t } = useTranslation(["popup"]);
  const { wallet } = useWalletQuery();
  const { acceptWalletExpiration } = useWalletMutation();
  const { setModalInfoCD } = useModalInfoDispatch();

  const openPopup = () => {
    if (!enabledBalanceExpiration) return;

    const now = addDays(new Date(), 30);

    if (now < expirationDate) return;

    setModalInfoCD({
      enabled: true,
      modalInfoProps: {
        type: "warning",
        image: { typeImage: "flexi-danger" },
        ...t("popup:balance-expiration-notice", {
          returnObjects: true,
          date: Helpers.formatDate(expirationDate, { month: "long", day: "numeric" }),
          days: expirationDays,
        }),
      },

      handleAccept: () => acceptWalletExpiration(),
      handleCancel: () => acceptWalletExpiration(),
      handleClose: () => acceptWalletExpiration(),
    });
  };

  useEffect(() => {
    if (wallet && !wallet.expirationAccepted) openPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);
};
