/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./conceptOption.styles.scss";

import { useTranslation } from "react-i18next";

import { Check, Input, Typhography, TypographyOption } from "../../atoms";
import useCheck from "../../atoms/check/useCheck";
import { ConceptOptionProps } from "./conceptOptionCheck.type";

const ConceptOption = (props: ConceptOptionProps): JSX.Element => {
  const { enabledS, handlePress } = useCheck(props);
  const { t } = useTranslation();
  return (
    <div
      role="button"
      tabIndex={0}
      className="is-flex is-justify-content-space-between is-align-items-center concept-option_container pl-1"
      onClick={handlePress}
    >
      <div className="is-flex">
        <div className="mr-2">
          <Check enabled={enabledS} />
        </div>
        <div>
          <TypographyOption {...props.typography} />
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={"concept-option_price " + (enabledS ? "selected" : "")}
        onClick={(e) => e.stopPropagation()}
      >
        {props.enabledPrice && enabledS && (
          <div>
            <Typhography
              type="overline-b"
              style={{ justifyContent: "center" }}
              className={"mg-sub-title"}
            >
              {t("price")}
            </Typhography>
            <Input
              style={{
                height: "2em",
                padding: "0px 4px",
                marginTop: "4px",
                marginLeft: "0px",
                textAlign: "center",
              }}
              {...props.priceInput}
              type="number"
              max={999}
              min={0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ConceptOption.defaultProps = {
  typography: {
    bodyText: "Example",
    smTitle: "Example",
  },
} as ConceptOptionProps;

export default ConceptOption;
