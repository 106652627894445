import { useState } from "react";
import { useSelector } from "react-redux";

import useSetConfigQueries from "../useSetConfigQueries";
import { IuseSetConfigQueries } from "../useSetConfigQueries.types";
import useVerifyMaintenance from "../useVerifyMaintenance";
import { IData, IusePost, IusePostElements, PostElements } from "./usePost.types";
import postData from "./usePostfunction";

const usePost = (elements: IusePostElements): IusePost => {
  const amzUserId = useSelector((state: Storage) => state?.amzAccount?.amzAccount?._id);

  const [dataPost, setDataPost] = useState<IData>({
    loading: false,
    error: "",
    data: {},
    status: 0,
    success: false,
  });

  useSetConfigQueries({
    data: dataPost,
    typeError: "post",
    ...elements,
  } as IuseSetConfigQueries);

  useVerifyMaintenance({
    requestCompleted: dataPost.status !== 0,
  });

  return {
    post: async ({ url, config, code, data }: PostElements): Promise<void> => {
      const headers = config?.headers || {};
      // console.log({ headers });
      await postData({
        setDataPost,
        url,
        config: {
          headers: {
            amzUserId,
            "Content-Type": "application/json",
            ...headers,
          },
          withCredentials: true,
        },
        code,
        data,
      });
    },
    cleanPost: () => {
      setDataPost({
        loading: false,
        error: "",
        data: {},
        status: 0,
        success: false,
      });
    },
    dataPost,
  };
};

export default usePost;
