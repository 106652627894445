import { useMemo } from "react";

import { useModalInfoDispatch } from "../../../../../store/useDispatch";
import { useFiltersSelector } from "../../../../../store/useSelector";
import useSearchFiltersScreen from "../../../../pages/private/filters/searchFilters/hooks/useSearchFiltersScreen";
import useForm from "./setUpDaysForm/useForm";
import { validateRange } from "./setUpDaysForm/useForm.functions";
import usePostSaveStartAt from "./usePostSaveStartAt";
import { UseSetUpDay } from "./useSetUpDay.types";

const useSetUpDay = ({ onClose }: UseSetUpDay) => {
  const { saveStartAtFilter } = usePostSaveStartAt();
  const { startAt } = useFiltersSelector();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { getFilters } = useSearchFiltersScreen();

  const days = useMemo(() => {
    if (!startAt?.days) return [];

    return startAt.days?.map((day) => {
      return { ...day, day: Number(day.day) };
    });
  }, [startAt]);

  const { list } = useForm({ days });

  const handleCloseModal = () => {
    const isInvalid = days.some((day) => {
      const valid = validateRange(day.start, day.end);

      return !valid;
    });

    if (isInvalid) {
      setModalInfoCD({
        enabled: true,
        handleCancel: () => {
          getFilters();
          onClose();
        },
        modalInfoProps: {
          title: "Invalid range",
          body: "Some of the ranges are invalid",
          buttonText: "Edit",
          subButtomText: "Discard",
          type: "warning",
          image: {
            typeImage: "flexi-danger",
          },
        },
      });
      return;
    }

    saveStartAtFilter(days);
    onClose();
  };

  return {
    list,
    handleCloseModal,
  };
};

export default useSetUpDay;
