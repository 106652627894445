import { TFunction } from "i18next";

import { FormField } from "../../../organisms/formDynamic/formDynamic.types";

export const getFormFields = (t: TFunction<"common"[]>): FormField[] => [
  {
    fieldType: "input",
    label: t("common:form.name"),
    type: "text",
    name: "firstname",
    placeholder: "John",
    validations: [
      {
        type: "required",
        message: t("common:form.required", { name: t("common:form.name") }),
      },
    ],
  },

  {
    fieldType: "input",
    label: t("common:form.email-address"),
    type: "text",
    name: "email",
    placeholder: "yourexample@gmail.com",
    validations: [
      {
        type: "required",
        message: t("common:form.required", { name: t("common:form.email-address") }),
      },
    ],
  },
  {
    fieldType: "input",
    label: t("common:form.phone"),
    type: "phone",
    name: "phone",
    placeholder: "555 555 5555",
    validations: [
      {
        type: "required",
        message: t("common:form.required", { name: t("common:form.phone") }),
      },
    ],
  },
  {
    fieldType: "input",
    label: t("common:form.phone-secondary"),
    type: "phone",
    name: "phone_b",
    placeholder: "555 555 5555",
    validations: [
      {
        type: "notEqualsTo",
        notEqualsTo: ["phone"],
        message: t("common:form.not-equals-to", {
          name: t("common:form.phone-secondary"),
          other: t("common:form.phone"),
        }),
      },
    ],
  },
];
