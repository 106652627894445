import { MenuItemProps } from "../../molecules/menuItem/menuItem.type";

export type MenuSectionProps = {
  title: string;
  items: MenuItemProps[];
  className?: string;
};

export const MenuSetionDefaultProps: MenuSectionProps = {
  title: "Account Settings",
  items: [
    {
      icon: { typeIcon: "man-head" },
      label: "Account details",
    },
    {
      icon: { typeIcon: "key" },
      label: "Change Password",
    },
  ],
};
