import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useConfirmPayment from "../../../../templates/private/subscription/checkout/hooks/useConfirmPayment";

const usePaymentConfirm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { confirmPayment, error, success } = useConfirmPayment(() => {
    navigate("/", { replace: true });
  });

  useEffect(() => {
    const paymentId = searchParams.get("payment_intent_client_secret");

    if (paymentId) confirmPayment(paymentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    success,
  };
};

export default usePaymentConfirm;
