import "./referralBtn.styles.scss";

import { IconCustom, Typhography } from "../../atoms";
import { ReferralBtnProps } from "./referralBtn.types";

const ReferralBtn = (props: ReferralBtnProps) => {
  const { children, icon, onClick } = props;

  return (
    <div
      className="mf-referral-btn"
      tabIndex={0}
      onClick={onClick}
      role="button"
      onKeyUp={onClick}
    >
      <div className="mf-referral-btn_icon_container">
        <IconCustom typeIcon={icon} className="mf-referral-btn_icon" />
      </div>

      <Typhography type="p-r-b" className="mf-referral-btn_text">
        {children}
      </Typhography>
    </div>
  );
};

export default ReferralBtn;
