import { useMemo } from "react";

import { getForm, reset } from "../formDynamic.functions";
import { UseFormDynamicArgs } from "./useFormDynamic.types";

const useFormDynamic = (args: UseFormDynamicArgs) => {
  const { initialValues, validationSchema } = useMemo(() => {
    reset();
    return getForm(args.formFields);
  }, [args.formFields]);

  return {
    formik: {
      initialValues,
      validationSchema,
      onSubmit: args.onSubmit,
    },
  };
};

export default useFormDynamic;
