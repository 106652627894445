import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectOffersLogs = (state: RootState) => state.offers.offersLogs;
const selectError = (state: RootState) => state.offers.error;
const selectLoading = (state: RootState) => state.offers.loading;
const selectCalendarOffers = (state: RootState) => state.offers.calendarOffers;

const useOffersSelector = () => {
  return {
    calendarOffers: useAppSelector(selectCalendarOffers),
    offersLogs: useAppSelector(selectOffersLogs),
    error: useAppSelector(selectError),
    loading: useAppSelector(selectLoading),
  };
};

export default useOffersSelector;
