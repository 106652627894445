import { useWindowSize } from "../../../hooks";
import { Modal } from "../../molecules";
import { MenuSection } from "../../organisms";
import { MenuDefaultProps } from "./menu.constants";
import { MenuProps } from "./menu.type";

const Menu = (props: MenuProps) => {
  const { isOpen, sections, onMenuClose } = props;

  const { height } = useWindowSize();

  return (
    <Modal isOpen={isOpen} onClose={onMenuClose}>
      <div style={{ overflow: "auto", height: height - 70 }}>
        {sections.map((section, index) => (
          <MenuSection key={index} className="mb-5" {...section} />
        ))}
      </div>
    </Modal>
  );
};

Menu.defaultProps = MenuDefaultProps;

export default Menu;
