import { ActionModalInfo, ModalInfoC } from "../../types/modalInfoC.type";
import { CLEAN_MODAL_INFO_C, SET_MODAL_INFO_C } from "./actions";
// reducer
const initialState: ModalInfoC = {
  enabled: false,
  handleAccept: () => null,
  handleCancel: () => null,
  handleClose: () => null,
};

export default function modalInfoC(
  state: ModalInfoC = initialState,
  action: ActionModalInfo,
): ModalInfoC {
  let stateCopy = { ...state };
  switch (action?.type) {
    case SET_MODAL_INFO_C: {
      stateCopy = action?.payload?.modalInfoC as ModalInfoC;
      return stateCopy;
    }
    case CLEAN_MODAL_INFO_C: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
