import { useCallback } from "react";

import { envs } from "../../../../../config/env";
import useGet from "../../../../../https/hooks/get/useGet";
import { useUserSelector } from "../../../../../store/useSelector";

const useGetAmzAccount = () => {
  const { user } = useUserSelector();
  const { data, get } = useGet({
    spinnerFullScreen: true,
  });

  const getAmzAccount = useCallback(() => {
    user?.selectedAmzAccount &&
      get({
        code: "G-AMZ-SELECTED",
        url: envs.URL_API + "/V1/authAmz/selected",
        pathData: "data.data",
      });
  }, [user?.selectedAmzAccount]);

  return {
    getAmzAccountResp: data,
    getAmzAccount,
  };
};

export default useGetAmzAccount;
