import { envs } from "../../config/env";
import usePost from "../../https/hooks/post/usePost";

const usePostAddMembership = () => {
  const { dataPost, post } = usePost({ spinnerFullScreen: true });

  const addMembership = (paymentId: string) => {
    post({
      url: envs.URL_API + "/V1/membership",
      data: {
        accountPaymentId: paymentId,
      },
      code: "P_ADD_MEMBERSHIP",
    });
  };

  return {
    addMembershipResp: dataPost,
    addMembership,
  };
};

export default usePostAddMembership;
