import { useEffect, useState } from "react";

import { IChoice, ITabs } from "./navTabs.types";

const useNavTabs = (tabs: Array<ITabs>, tabSelected: number) => {
  const [choice, setChoice] = useState<IChoice>({
    opt: tabSelected,
    child: [],
  });

  useEffect(() => {
    setChoice((prev) => ({ ...prev, child: tabs[0].child }));
  }, []);

  const onClick = (tab: ITabs, key: number) => {
    setChoice({ opt: key, child: tab.child });
    tab.onClick({ tab, key });
  };

  // update choice content when tabs change
  useEffect(() => {
    setChoice((prev) => ({ ...prev, child: tabs[prev.opt].child }));
  }, [tabs]);

  return {
    choice,
    onClick,
  };
};
export default useNavTabs;
