import { load } from "./environment-loader";

export enum PaymentProviders {
  STRIPE = "stripe",
  ZELLE = "zelle",
}

export const envs = {
  APP_URL: load("APP_URL").required().asString(),
  BALANCE_EXP_DAYS: load("BALANCE_EXP_DAYS").default(30).asNumber(),
  BALANCE_EXP_START_DATE: load("BALANCE_EXP_START_DATE").required().asDate(),
  ENABLED_BALANCE_EXPIRATION: load("ENABLED_BALANCE_EXPIRATION")
    .default(false)
    .asBoolean(),
  PAYMENT_PROVIDER: load("PAYMENT_PROVIDER")
    .required()
    .asEnum(Object.values(PaymentProviders)),
  NODE_ENV: load("NODE_ENV")
    .required()
    .asEnum(["dev", "development", "prod", "production"]),
  URL_API: load("URL_API").required().asString(),
  KEY_CRYPT: load("KEY_CRYPT").required().asString(),
  GET_BOT_STATE_TRIES: load("GET_BOT_STATE_TRIES").required().default(20).asNumber(),
  GOOGLE_MAPS_API_KEY: load("GOOGLE_MAPS_API_KEY").required().asString(),
  URL_WS: load("URL_WS").required().asString(),
  STRIPE_PUBLIC_KEY: load("STRIPE_PUBLIC_KEY").required().asString(),
  LINK_REGISTER: load("LINK_REGISTER").required().asBoolean(),
  ACTIVATE_BOT: load("ACTIVATE_BOT").asBoolean(),

  ZELLE_EMAIL: load("ZELLE_EMAIL").required().asString(),
};

export const isProduction = envs.NODE_ENV === "production" || envs.NODE_ENV === "prod";
export const isDevelopment = envs.NODE_ENV === "development" || envs.NODE_ENV === "dev";
export const isNotProduction = !isProduction;
export const isNotDevelopment = !isDevelopment;
