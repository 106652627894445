import { MouseEventHandler } from "react";

import { IconCustomProps } from "../../atoms/icons/icons.type";

export type MenuItemProps = {
  icon?: IconCustomProps;
  label: string;
  link?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  disabled?: boolean;
};

export const MenuItemDefaultProps: MenuItemProps = {
  label: "Account details",
};
