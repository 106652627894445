import { IconCustom, Typhography } from "../../atoms";
import { ITransactionDetailProps } from "./transactionDetail.types";

const TransactionDetail = (props: ITransactionDetailProps) => {
  return (
    <div className={`is-flex ${props.className}`}>
      <IconCustom typeIcon={props.icon} width={18} height={18} />
      <div className="pl-2">
        <Typhography type="overline-b">{props.title}</Typhography>
        {props.children}
      </div>
    </div>
  );
};
export default TransactionDetail;
