import { TimePikerState, TimePikerStateProps } from "../../types/timePiker.type";
import { CLEAN_TIME_PIKER_PROPS, SET_TIME_PIKER_PROPS } from "./actions";
// reducer
const initialState: TimePikerStateProps = {
  useRedux: false,
};

const timePikerState: TimePikerState = (state = initialState, action) => {
  let stateCopy = { ...state };
  switch (action?.type) {
    case SET_TIME_PIKER_PROPS: {
      stateCopy = action?.payload?.timePikerProps as TimePikerStateProps;
      return stateCopy;
    }
    case CLEAN_TIME_PIKER_PROPS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
export default timePikerState;
