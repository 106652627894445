import "./button.style.scss";

import IconCustom from "../icons/IconCustom";
import { ButtonDefaultProps, ButtonProps } from "./button.type";
import useButton from "./hooks/useButton";

const Button = (props: ButtonProps) => {
  const {
    isLoading,
    leftIcon,
    rightIcon,
    children,
    isFullWidth,
    color,
    text,
    className,
    ...rest
  } = props;

  const { cssClasses } = useButton({ isFullWidth, color, isLoading });

  return (
    <button className={cssClasses + " mg-button " + className} {...rest}>
      {leftIcon && (
        <IconCustom typeIcon={leftIcon} width={14} height={14} fill={"currentColor"} />
      )}
      <span className="mf-paragraph-large-bold mx-2">{text || children}</span>
      {rightIcon && <IconCustom typeIcon={rightIcon} width={14} height={14} />}
    </button>
  );
};

Button.defaultProps = ButtonDefaultProps;

export default Button;
