import {
  useAmzAccountDispatch,
  useModalInfoDispatch,
  useUserDispatch,
} from "../../store/useDispatch";

const useSession = () => {
  const { setModalInfoCD } = useModalInfoDispatch();
  const { resetD } = useAmzAccountDispatch();
  const { deleteUserD } = useUserDispatch();

  const logout = () => {
    const onAccept = () => {
      resetD();
      deleteUserD();
    };

    setModalInfoCD({
      enabled: true,
      modalType: "expired-session",
      handleAccept: onAccept,
      handleCancel: onAccept,
      handleClose: onAccept,
    });
  };

  return {
    logout,
  };
};

export default useSession;
