import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { PricingTemplate } from "../../../../templates";
import SettingsLayout from "../../../settings/settingsLayout/SettingsLayout";

const PricingScreen = () => {
  const { state } = useLocation() as { state: { openMenu: boolean } };
  const { t } = useTranslation(["membership"]);

  return (
    <div>
      <SettingsLayout
        title={t("membership:pricing.title")}
        openMenuOnBack={state?.openMenu}
        backUrl="/"
      >
        <PricingTemplate />
      </SettingsLayout>
    </div>
  );
};
export default PricingScreen;
