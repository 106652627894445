import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import FormDynamic from "../../../organisms/formDynamic/FormDynamic";
import { getFormFields } from "./editMagicAccount.constants";
import { EditMagicAccFormValues, EditMagicAccountProps } from "./editMagicAccount.types";

const EditMagicAccount = (props: EditMagicAccountProps) => {
  const { values, onSubmit } = props;

  const { t } = useTranslation(["common"]);

  const fields = useMemo(() => {
    return getFormFields(t).map((field) => {
      if (field.name in values) {
        field.value = values[field.name as keyof EditMagicAccFormValues];
      }
      return field;
    });
  }, [values, t]);

  return (
    <FormDynamic
      fields={fields}
      onSubmit={onSubmit}
      submitBtn={{
        label: t("common:save-changes"),
      }}
    />
  );
};

export default EditMagicAccount;
