import useModal from "../../../../../molecules/modal/hook/useModal";
const useStartAtModal = () => {
  const modal = useModal({
    isOpen: false,
  });

  return { ...modal };
};

export default useStartAtModal;
