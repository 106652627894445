import { cleanModalInfoC, setModalInfoC } from "../reducers/modalInfo/actions";
import { SetModalInfoC } from "../types/modalInfoC.type";
import useActions from "./useActions";

// Here is nesesary to configure all the actions
const useDispatch = () => {
  const setModalInfoCD: SetModalInfoC = useActions(setModalInfoC);
  const cleanModalInfoCD = useActions(cleanModalInfoC);
  return {
    setModalInfoCD,
    cleanModalInfoCD,
  };
};

export default useDispatch;
