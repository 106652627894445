import "./check.styles.scss";

import { IconCustom } from "../icons";
// import "./switch.styles.scss";
import { CheckProps } from "./check.types";

const Check = (props: CheckProps): JSX.Element => {
  const color = props.enabled ? "#00C363" : "#EDF0F3";
  const width = props.enabled ? 24 : 18;

  return (
    <>
      {/* <label className="mg-label-check">
        <input type="checkbox" className="mg-input-checkbox" />
        <span className="mg-checkbox" />
      </label> */}
      <div className="mg-button-check">
        <IconCustom typeIcon="check-alt" color={color} width={width} />
      </div>
    </>
  );
};

Check.defaultProps = {
  icon: {
    typeIcon: "check",
  },
  enabled: true,
} as Partial<CheckProps>;

export default Check;
