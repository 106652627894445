/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./modalInfo.styles.scss";

import { Button, IconModalTitle, TitleModalInfo, Typhography } from "../../atoms";
import { color, ModalInfoProps } from "./modalInfo.types";
import useModalInfo from "./useModalInfo";

const ModalInfo = (): JSX.Element => {
  const { objectProps, handleClose, handleCancel, handleBox, handleAccept, modalInfoC } =
    useModalInfo();

  if (!modalInfoC.enabled) {
    return <></>;
  }
  return (
    <div
      role="button"
      tabIndex={0}
      className={`container-modal container-modal-darck mf-modal-info`}
      onClick={handleClose}
    >
      <div tabIndex={0} role="button" className={`box modal-info`} onClick={handleBox}>
        <IconModalTitle
          icon={objectProps?.icon}
          image={objectProps.image}
          type={objectProps.type}
        />
        <div className="modal-info-title">
          <TitleModalInfo>{objectProps.title}</TitleModalInfo>
        </div>
        <div className="modal-info-body">
          <p className="body-info-text">{objectProps.body}</p>
        </div>
        <Button
          onClick={handleAccept}
          text={objectProps.buttonText}
          color={objectProps?.type as color}
        />
        {objectProps?.subButtomText && (
          <div
            tabIndex={0}
            role="button"
            className="modal-info-link"
            onClick={handleCancel}
          >
            <Typhography type="p-r-b">{objectProps?.subButtomText}</Typhography>
          </div>
        )}
      </div>
    </div>
  );
};

ModalInfo.defaultProps = {
  modalType: "arrived",
} as Partial<ModalInfoProps>;

export default ModalInfo;
