import { magicAPI } from "../../api";
import { VerifyTokenResponse } from "./types";

const endpoints = {
  verifyToken: (token: string) => `/auth/login/${token}`,
};

export const verifyToken = async (token: string) => {
  const { data } = await magicAPI.get<VerifyTokenResponse>(endpoints.verifyToken(token));

  if (!data.user) throw new Error("No user");

  return data.user;
};
