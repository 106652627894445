import { Modal } from "../../../molecules";
import { SetUpLocations } from "../../../organisms";
import { IStationsModalProps } from "./StationsModal.types";
import useSearch from "./useSearch";

const StationsModal = (props: IStationsModalProps) => {
  const { stations, onChange, enablePrice, generateBlocks, ...modal } = props;

  const form = useSearch({ stations, onChange, generateBlocks });

  return (
    <Modal {...modal} contentClassName="px-1">
      <SetUpLocations {...form} enabledPrice={enablePrice} />
    </Modal>
  );
};

export default StationsModal;
