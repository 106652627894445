import "./separator.styles.scss";

import React from "react";

import { SeparatorProps } from "./separator.type";

const Separator = (props: SeparatorProps) => {
  const { text, className } = props;

  const typeLine = text ? "mf-separator-line-text" : "mf-separator-line";

  return (
    <div className={"is-flex is-align-items-center " + className}>
      <span className="mf-overline-bold">{text}</span>
      <div className={`ml-1 ${typeLine}`}></div>
    </div>
  );
};

Separator.defaultProps = {
  text: "",
};

export default Separator;
