import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";

import useTimePikerDispatch from "../../../store/useDispatch/useTimePikerDispatch";
import { useTimePikerSelector } from "../../../store/useSelector";
import { DisplayTimeProps } from "../../molecules/displayTime/displayTIme.types";
import { TimePikerProps } from "./timerPiker.types";

const useTimePiker = (params: TimePikerProps) => {
  const { timePikerState } = useTimePikerSelector();
  const { setTimePikerD } = useTimePikerDispatch();
  const props = timePikerState?.useRedux ? timePikerState : (params as TimePikerProps);

  const [typeTime, setTypeTime] = useState<"start" | "end">("start");

  const [rangeTime, setRangeTime] = useState(() => {
    const startHour = props.rangeTime?.start || new Date();
    const endHour = props.rangeTime?.end || new Date();
    return {
      start: moment(startHour, "h:mm A").format("h:mm A"),
      end: moment(endHour, "h:mm A").format("h:mm A"),
    };
  });

  const [date, setDate] = useState<Dayjs | null>(() => {
    const startHour = props.rangeTime?.start || new Date();
    return dayjs(moment(startHour, "h:mm A").format());
  });

  const [isEditing, setIsEditing] = useState<"hours" | "minutes">("hours");

  const [meridiem, setMeridiem] = useState<string>(() => {
    return dayjs(date).format("A");
  });

  const hour = dayjs(date).format("hh");
  const minuts = dayjs(date).format("mm");

  const [open, setOpen] = useState<boolean>(() => {
    return props?.open as boolean;
  });

  useEffect(() => {
    setRangeTime((prev) => ({
      ...prev,
      [typeTime]: `${hour}:${minuts} ${meridiem}`,
    }));
  }, [hour, meridiem, minuts, typeTime]);

  const onClose = () => {
    setOpen(false);
    setTimePikerD({ ...timePikerState, open: false });
    props.onClose && props.onClose();
  };

  const onChange = (newValue: Dayjs | null) => {
    setDate(newValue);
    props.onChange && props.onChange(newValue);
  };

  const onFinish = () => {
    setOpen(false);
    setTimePikerD({ ...timePikerState, open: false });
    props.onFinish && props.onFinish(rangeTime);
  };

  const onContinue = () => {
    setTypeTime("end");
    setIsEditing("hours");
    const meridaemMoment = rangeTime?.end
      ? rangeTime?.end?.split(" ")[1]
      : dayjs(new Date()).format("A");
    setMeridiem(meridaemMoment);
    const hourMoment = rangeTime?.end ? moment(rangeTime?.end, "hh:mm a")?.format() : "";
    const hourDay = hourMoment ? dayjs(hourMoment) : dayjs(new Date());
    setDate(hourDay);
  };

  const onBack = () => {
    const hourMoment = moment(rangeTime.start, "hh:mm a").format();
    setMeridiem(rangeTime.start.split(" ")[1]);
    setDate(dayjs(hourMoment));
    setTypeTime("start");
    setIsEditing("hours");
  };

  const displayTime: DisplayTimeProps = {
    isEditing,
    setIsEditing,
    hour,
    minuts,
    meridiem,
    setMeridiem,
  };

  useEffect(() => {
    if (timePikerState && timePikerState?.useRedux) setOpen(!!timePikerState.open);
  }, [timePikerState]);

  return {
    setTypeTime,
    typeTime,
    date,
    setDate,
    open,
    onBack,
    onChange,
    onFinish,
    onContinue,
    displayTime,
    onClose,
    selectedDay: props.selectedDay,
  };
};

export default useTimePiker;
