import "./aboutMagic.styles.scss";

import { useTranslation } from "react-i18next";

import { IconCustom, Typhography } from "../../atoms";
import { MediaIconsBlock } from "../../molecules";
import Styles from "./aboutMagic.styles";
import { AboutMagicProps } from "./AboutMagic.types";

const AboutMagic = (props: AboutMagicProps): JSX.Element => {
  const { listIcons } = props;

  const { t } = useTranslation(["informative"]);

  return (
    <section className="mg-about-section">
      <IconCustom typeIcon="magic-flex-icon" width={131} height={30} />
      <Typhography type="p-s-b" className={"mg-content-item"} style={Styles}>
        {t("informative:about", { returnObjects: true })[0]}
      </Typhography>
      <Typhography type="p-s-b" className={"mg-content-item"} style={Styles}>
        {t("informative:about", { returnObjects: true })[1]}
      </Typhography>
      <div className="mg-content-item">
        <MediaIconsBlock listIcons={listIcons} />
      </div>
    </section>
  );
};

AboutMagic.defaultProps = {
  listIcons: [
    /*     {
      iconProps: { typeIcon: "linkedin", width: 38, height: 38 },
    },
    {
      iconProps: { typeIcon: "facebook", width: 38, height: 38 },
    },
    {
      iconProps: { typeIcon: "twitter", width: 38, height: 38 },
    }, */
  ],
} as Partial<AboutMagicProps>;

export default AboutMagic;
