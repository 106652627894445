import { IconCustom } from "../icons";

const SelectIcon = ({ icon, iconUrl }: any) => {
  if (!icon && !iconUrl) return null;
  let IconComponent = null;

  if (iconUrl) {
    IconComponent = <img src={iconUrl} alt="country-icon" width={34} />;
  } else if (icon) {
    IconComponent = <IconCustom {...icon} />;
  }

  return <span className="icon is-left ml-2">{IconComponent}</span>;
};

export default SelectIcon;
