import { createSlice } from "@reduxjs/toolkit";

import { OffersState } from "../../types/offers.types";
import * as reducers from "./actions";

const initialState: OffersState = {
  loading: false,
  offersLogs: [],
  error: "",
  calendarOffers: [],
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers,
});

export const {
  deleteOffersLogs,
  setError,
  startLoading,
  stopLoading,
  reset,
  setOfferLogs,
  addOffersLogs,
  deleteOfferLog,
  addCalendarOffer,
  addCalendarOffers,
  deleteCalendarOffer,
  deleteCalendarOffers,
  setCalendarOffers,
} = offersSlice.actions;

export default offersSlice.reducer;
