import { ErrorMessage, useField } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFieldStyles } from "../../../hooks";
import { SelectCustomProps } from "./selectCustom.types";
import SelectIcon from "./SelectIcon";

const NormalSelect = (props: SelectCustomProps) => {
  const { label, defaultOption, children, selectProps, icon, iconUrl, onChange } = props;

  const [field, meta] = useField(selectProps);
  const { fieldStyle } = useFieldStyles(meta);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (onChange) {
      onChange(field.value);
    }
  }, [field.value, onChange]);

  const hasIcon = icon || iconUrl;

  return (
    <div className="field">
      <label className="label" htmlFor={selectProps.id || selectProps.name}>
        <span className="magicflex-text-label">{label}</span>
      </label>
      <div className={"control is-expanded " + (hasIcon ? "has-icons-left" : "")}>
        <div className="select is-fullwidth">
          <select
            className={"magicflex-input-mixin input " + (hasIcon ? "pl-6" : "")}
            style={fieldStyle}
            {...selectProps}
            {...field}
          >
            <option value="" disabled>
              {defaultOption || t("common:select-option")}
            </option>
            {children}
          </select>
        </div>

        <SelectIcon icon={icon} iconUrl={iconUrl} />
      </div>
      <ErrorMessage
        name={selectProps.name}
        className="has-text-danger"
        component="span"
      />
    </div>
  );
};

export default NormalSelect;
