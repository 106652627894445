import { magicAPI } from "../../api";

type SetBlocksLostLimitReached = {
  limitReached: boolean;
  humanCheckResolved: boolean;
};

export const getBlocksLostLimitReached = async (): Promise<boolean> => {
  // const { data } = await magicAPI.get("/botConfig/lostLimitReached");

  // return data.data;

  return false;
};

export const setBlocksLostLimitReached = async (
  params: SetBlocksLostLimitReached,
): Promise<boolean> => {
  const { limitReached, humanCheckResolved } = params;

  await magicAPI.post("/botConfig/lostLimitReached", {
    limitReached,
    humanCheckResolved,
  });

  return limitReached;
};
