import { useEffect } from "react";

import { useModalInfoDispatch } from "../../store/useDispatch";

export const useIOWarning = () => {
  const { setModalInfoCD } = useModalInfoDispatch();

  useEffect(() => {
    setModalInfoCD({
      enabled: true,
      modalType: "only-instant-offers-available",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
