import { useState } from "react";

const optionsUrl = {
  subscription: "/subscription/payment",
  forUse: "/premium/charge",
};

const useSelectPaymentType = () => {
  const [optionSelected, setOptionSelected] =
    useState<keyof typeof optionsUrl>("subscription");

  const isSelected = (option: keyof typeof optionsUrl) => optionSelected === option;

  const selectOption = (option: keyof typeof optionsUrl) => {
    setOptionSelected(option);
  };

  const getOptionUrl = () => optionsUrl[optionSelected];

  return {
    isSelected,
    selectOption,
    getOptionUrl,
  };
};

export default useSelectPaymentType;
