import "../labelRadio/labelCheckbox.styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useField } from "formik";
import { Link } from "react-router-dom";

import { Space } from "../../atoms";
import { CheckTermsOfServicesProps } from "./checkTermsOfServices.types";

const CheckTermsOfServices = (props: CheckTermsOfServicesProps) => {
  const [field, meta] = useField(props);

  return (
    <div className="touch-area">
      <div className="grid-block">
        <div className="col-1">
          <input
            {...field}
            type="checkbox"
            className={
              "mg-input-checkbox " + (meta.error && meta.touched ? " is-invalid" : "")
            }
          />
        </div>
        <div className="col-2">
          <Link to="/info/terms-conditions" className="magicflex-create-account">
            {props.label} <Space amount={0.6} />
            <span className="is-right is-size-7">
              <FontAwesomeIcon icon="chevron-right" />
            </span>
          </Link>
        </div>
      </div>
      <div>
        <ErrorMessage name={props.name} component="span" className="has-text-danger" />
      </div>
    </div>
  );
};

export default CheckTermsOfServices;
