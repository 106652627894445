/* action types */
import { Day } from "../../../components/organisms/filters/setUpDays/hooks/formik/useFormikDays.types";
import {
  ActionSetDayHour,
  ActionSetStartAtEnd,
  ActionSetStartAtStart,
  ActionSetUpDays,
  ActionSetUpStartPrice,
  ActionSetUpStation,
  Days,
  StartPrice,
  Stations,
} from "../../types/filters.types";
/* Used only in reducer */
export const SET_UP_PRICE = "magicFlex/SET_UP_PRICE";
export const CLEAN_SET_UP_PRICE = "magicFlex/CLEAN_SET_UP_PRICE";

/* Action Creators */
export const setUpStartPrice = (startPrice: StartPrice): ActionSetUpStartPrice => {
  return {
    type: SET_UP_PRICE,
    payload: {
      startPrice,
    },
  };
};
export const cleanSetUpPrice = () => {
  return {
    type: CLEAN_SET_UP_PRICE,
  };
};

/* Used only in reducer */
export const SET_DAYS = "magicFlex/SET_DAYS";
export const SET_START_AT_START = "magicFlex/SET_START_AT_START";
export const SET_START_AT_END = "magicFlex/SET_START_AT_END";
export const CLEAN_DAYS = "magicFlex/CLEAN_DAYS";

export const setStartAtStart = (day: Partial<Day>): ActionSetStartAtStart => {
  return {
    type: SET_START_AT_START,
    payload: {
      day,
    },
  };
};

export const setStartAtEnd = (day: Partial<Day>): ActionSetStartAtEnd => {
  return {
    type: SET_START_AT_END,
    payload: {
      day,
    },
  };
};

/* Action Creators */
export const setUpDays = (days: Days): ActionSetUpDays => {
  return {
    type: SET_DAYS,
    payload: {
      days,
    },
  };
};
export const cleanDays = (): ActionSetUpDays => {
  return {
    type: CLEAN_DAYS,
  };
};

export const SET_DAY_HOUR = "magicFlex/SET_DAY_HOUR";
export const CLEAN_DAY_HOUR = "magicFlex/CLEAN_DAY_HOUR";

export const setDayHour = (day: Day): ActionSetDayHour => {
  return {
    type: SET_DAY_HOUR,
    payload: {
      day,
    },
  };
};

/* Used only in reducer */

export const SET_STATION = "magicFlex/SET_STATION";
export const CLEAN_STATION = "magicFlex/CLEAN_STATION";
export const setStation = (stations: Stations): ActionSetUpStation => {
  return {
    type: SET_STATION,
    payload: {
      stations,
    },
  };
};

export const clearStation = () => {
  return {
    type: CLEAN_STATION,
  };
};
