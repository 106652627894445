import { ThemeProvider } from "@mui/material";

import { lightTheme } from "./lightTheme";

interface Props {
  children: React.ReactNode;
}

export const AppThemeProvider = (props: Props) => {
  return <ThemeProvider theme={lightTheme}>{props.children}</ThemeProvider>;
};
