import { OfferLog } from "../../../hooks/useOffers/useOffers.types";
import { OrdersAF } from "../../types/orders.types";

export const setActiveOrders: OrdersAF<OfferLog[]> = (state, action) => {
  state.activeOrders = action.payload || [];
};

export const startLoading: OrdersAF = (state) => {
  state.loading = true;
};

export const stopLoading: OrdersAF = (state) => {
  state.loading = false;
};

export const setError: OrdersAF<string> = (state, action) => {
  state.error = action.payload;
};

export const deleteActiveOrders: OrdersAF = (state) => {
  state.activeOrders = [];
};

export const addActiveOrders: OrdersAF<any> = (state, action) => {
  state.activeOrders = [...state.activeOrders, ...(action.payload || [])];
};

export const reset: OrdersAF = (state) => {
  state.activeOrders = [];
  state.loading = false;
  state.error = "";
};
