import { useCallback } from "react";

import { NavbarBottomLabelProps } from "../navbarBottomLabel.types";

const useNavbarBottomLabel = (props: NavbarBottomLabelProps) => {
  const { icon, text, position, className, onClick, disabled } = props;

  const handleClick = useCallback(() => {
    !disabled && onClick && onClick();
  }, [disabled, onClick]);

  const containerClassName = [
    "is-flex",
    "is-align-items-center",
    className,
    disabled ? "mf-text-secondary" : "",
    `is-flex-direction-row-${position === "left" ? "" : "reverse"}`,
  ].join(" ");

  const textClassName = position === "left" ? "pl-2" : "pr-2 has-text-right";

  return {
    className: containerClassName,
    icon: disabled ? { ...icon, className: "mf-text-secondary" } : icon,
    onClick: handleClick,
    text,
    textClassName,
  };
};

export default useNavbarBottomLabel;
