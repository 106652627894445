import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";
import ChargeWalletScreen from "../../components/pages/private/subscription/charge/ChargeScreen";
import PricingScreen from "../../components/pages/private/subscription/pricing/PricingScreen";
import WalletCheckoutScreen from "../../components/pages/private/subscription/walletCheckout/WalletCheckoutScreen";

const PremiumRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="payment-type" element={<PricingScreen />} />
        <Route path="charge" element={<ChargeWalletScreen />} />
        <Route path="checkout" element={<WalletCheckoutScreen />} />
      </Routes>
    </Suspense>
  );
};
export default PremiumRoutes;
