import axios from "axios";

type Coord = {
  latitude: number;
  longitude: number;
  accuracy: number;
};

type CurrentPosition = {
  coords: Coord;
};

export const getUserPosition = async () => {
  if ("geolocation" in navigator) {
    try {
      const position: CurrentPosition = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
        });
      });

      return {
        latitude: position?.coords.latitude,
        longitude: position?.coords.longitude,
        accuracy: position?.coords.accuracy,
      };
    } catch (error) {
      return null;
    }
  }

  return null;
};

export const getUserIP = async () => {
  try {
    const { data } = await axios.get("https://jsonip.com");

    return data.ip;
  } catch (error) {
    console.log(error);
  }

  return null;
};
