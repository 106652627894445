import AppleMap from "../../../assets/images/apple-map.png";
import AvatarEmptyMd from "../../../assets/images/avatar-empty-md.png";
import BannerPromo from "../../../assets/images/banner-promo.png";
import FlexiCatchingBoxes from "../../../assets/images/flexi-catching-boxes.png";
import FlexiMaintenance from "../../../assets/images/flexi-maintenance.png";
import GoogleLogo from "../../../assets/images/google-maps-logo.png";
import FlexiExcited from "../../../assets/images/image5.png";
import FlexiSuccess from "../../../assets/images/image6.png";
import FlexiDanger from "../../../assets/images/image7.png";
import FlexiError from "../../../assets/images/image8.png";
import FlexiLove from "../../../assets/images/image9.png";
import RegisterLinkDemo from "../../../assets/images/register-link-demo.png";
import StartBot from "../../../assets/images/start-bot-anim.gif";
import Waze from "../../../assets/images/waze.png";

const Images = {
  "apple-app": AppleMap,
  "avatar-empty-md": AvatarEmptyMd,
  "banner-promo": BannerPromo,
  "flexi-catching-boxes": FlexiCatchingBoxes,
  "flexi-danger": FlexiDanger,
  "flexi-error": FlexiError,
  "flexi-exited": FlexiExcited,
  "flexi-love": FlexiLove,
  "flexi-maintenance": FlexiMaintenance,
  "flexi-success": FlexiSuccess,
  "google-maps": GoogleLogo,
  "register-link-demo": RegisterLinkDemo,
  "start-bot-anim": StartBot,
  waze: Waze,
};

export default Images;
