export let dataExample = [
  {
    collages: [
      {
        name: "Oxford",
      },
      {
        name: "Exfort",
      },
    ],
    students: [
      {
        names: ["JohanNavarro|", "Navarro"],
      },
      {
        names: ["Santiago", "Lopez"],
      },
    ],
  },
  {
    collages: [
      {
        name: "Cambrige",
      },
      {
        name: "Exfort",
      },
    ],
    students: [
      {
        names: ["Edgar^:", "Lopez"],
      },
      {
        names: ["Esteban", "Agudelo"],
      },
    ],
  },
];

export const exampleOnSearch = (searchResult) => {
  console.log("searchResult>>>", searchResult);
};
