import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectBot = (state: RootState) => state.bot;

const useBotSelector = () => {
  return useAppSelector(selectBot);
};

export default useBotSelector;
