import { createSlice } from "@reduxjs/toolkit";

import { OrdersState } from "../../types/orders.types";
import * as reducers from "./actions";

const initialState: OrdersState = {
  loading: false,
  activeOrders: [],
  error: "",
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers,
});

export const {
  addActiveOrders,
  deleteActiveOrders,
  setActiveOrders,
  setError,
  startLoading,
  stopLoading,
  reset,
} = ordersSlice.actions;

export default ordersSlice.reducer;
