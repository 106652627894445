import { BackBtn, LogoMagic } from "../../atoms";
import { LogoHeaderProps } from "./logoHeader.types";

const LogoHeader = (props: LogoHeaderProps) => {
  const { backRoute, hideBtn, backBtnText } = props;

  return (
    <div className="magicflex-logo-header">
      <div className="magicflex-logo-header-btn">
        <BackBtn text={backBtnText} route={backRoute} hidden={hideBtn} />
      </div>
      <div className="magicflex-logo-header-text">
        <LogoMagic />
      </div>
    </div>
  );
};

LogoHeader.defaultProps = {
  backBtnText: "",
  hideBtn: false,
  backRoute: "",
};

export default LogoHeader;
