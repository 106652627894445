import CountrySelect from "./CountrySelect";
import NormalSelect from "./NormalSelect";
import { SelectCustomProps } from "./selectCustom.types";

const select = {
  normal: NormalSelect,
  country: CountrySelect,
};

const SelectCustom = (props: SelectCustomProps) => {
  const { selectType } = props;

  const SelectComponent = select[selectType || "normal"];

  return <SelectComponent {...props} />;
};

export default SelectCustom;
