import { cleanPayment, initPayment } from "../reducers/payment/payment";
import useActions from "./useActions";

const usePaymentDispatch = () => {
  return {
    initPaymentD: useActions(initPayment),
    cleanPaymentD: useActions(cleanPayment),
  };
};

export default usePaymentDispatch;
