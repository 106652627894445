import "./navBarBottomLabel.scss";

import { IconCustom, NavbarBottomText } from "../../atoms";
import RadarAnimation from "../radarAnimation/RadarAnimation";
import useNavbarBottomLabel from "./hooks/useNavbarBottomLabel";
import { navbarBottomLabelDefaultProps } from "./navbarBottomLabel.constants";
import { NavbarBottomLabelProps } from "./navbarBottomLabel.types";

const NavbarBottomLabel = (props: NavbarBottomLabelProps) => {
  const { icon, text, textClassName, ...rest } = useNavbarBottomLabel(props);

  return (
    <div {...rest}>
      {icon.typeIcon === "radar" ? (
        <RadarAnimation type="icon" onClick={rest.onClick} />
      ) : (
        <IconCustom {...icon} />
      )}
      <NavbarBottomText className={textClassName}>{text}</NavbarBottomText>
    </div>
  );
};

NavbarBottomLabel.defaultProps = navbarBottomLabelDefaultProps;

export default NavbarBottomLabel;
