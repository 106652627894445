import "./menuItem.styles.scss";

import { IconCustom } from "../../atoms";
import useMenuItem from "./hooks/useMenuItem";
import { MenuItemDefaultProps, MenuItemProps } from "./menuItem.type";

const MenuItem = (props: MenuItemProps) => {
  const { icon, label, containerAttr } = useMenuItem(props);

  return (
    <div {...containerAttr}>
      <div className="is-flex is-align-items-center">
        {icon && <IconCustom width={14} height={14} {...icon} />}
        <span className="mf-overline-bold ml-2">{label}</span>
      </div>
      <IconCustom typeIcon="angle-right" width={14} height={14} />
    </div>
  );
};

MenuItem.defaultProps = MenuItemDefaultProps;

export default MenuItem;
