import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { createPaymentIntent, deletePayment, getPendingPayments } from "./functions";
import { PostCreatePaymentIntentData } from "./types";

export const usePaymentsQuery = (amzUserId?: string) => {
  const query = useQuery(
    ["payments", { state: "pending", amzUserId }],
    () => getPendingPayments(amzUserId),
    {
      enabled: !!amzUserId,
      refetchOnWindowFocus: true,
    },
  );

  return {
    pendingPayments: query.data,
    pendingPaymentsQuery: query,
  };
};

export const usePaymentIntentMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: PostCreatePaymentIntentData) => createPaymentIntent(data),
    onSuccess: () => queryClient.invalidateQueries(["payments"]),
  });

  return {
    createPaymentIntent: mutation.mutate,
    createPaymentIntentMutation: mutation,
  };
};

export const usePaymentMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (paymentId: string) => deletePayment(paymentId),
    onSuccess: () => queryClient.invalidateQueries(["payments"]),
  });

  return {
    deletePayment: mutation.mutateAsync,
    deletePaymentMutation: mutation,
  };
};
