export const copyToClipboard = (text: string) => {
  if (navigator.clipboard?.writeText) {
    return navigator.clipboard?.writeText(text);
  }

  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};
