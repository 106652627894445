import { useCallback, useEffect } from "react";

import { useUIDispatch } from "../../../store/useDispatch";
import { useUISelector } from "../../../store/useSelector";
import useTimeOut from "../../timeOut/useTimeOut";

const secondsAdd = 2;

const updateSecondsInterval = 2;

const useProgressBar = () => {
  const { progressBar } = useUISelector();

  const { showProgressBarD, hideProgressBarD, updateProgressBarD } = useUIDispatch();

  const timeout = useTimeOut();

  const updateProgress = (value: number) => {
    updateProgressBarD(value);
    timeout.clear();
  };

  const startProgress = useCallback(() => {
    showProgressBarD(0);

    timeout.start(
      () => updateProgressBarD(progressBar.value + secondsAdd),
      1000 * updateSecondsInterval,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressBar.value, updateProgressBarD, showProgressBarD]);

  const hideProgress = () => {
    hideProgressBarD();
    timeout.clear();
  };

  const updateInterval = useCallback(() => {
    if (progressBar.value === 48) return timeout.clear();

    if (progressBar.value === 100 || progressBar.show === false) return timeout.clear();

    if (!progressBar.show || progressBar.value > 97) return;

    timeout.start(() => {
      updateProgressBarD(progressBar.value + secondsAdd);
    }, 1000 * updateSecondsInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressBar, updateProgressBarD]);

  useEffect(() => {
    updateInterval();
  }, [updateInterval]);

  return {
    updateProgress,
    hideProgress,
    startProgress,
    processValue: progressBar.value,
  };
};

export default useProgressBar;
