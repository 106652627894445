import "./startAtDayCheck.styles.scss";

import { useTranslation } from "react-i18next";

import { IconCustom, Typhography } from "../../atoms";
import RadioInput from "../radioInput/RadioInput";
import useStartAtDayCheck from "./hooks/useStartAtDayCheck";
import { IStartAtDayCheckProps } from "./startAtDayCheck.types";

const StartAtDayCheck = (props: IStartAtDayCheckProps) => {
  const { selectDay, openSelectRange, dayStr, endDate, startDate, day, selected } =
    useStartAtDayCheck(props);

  const { t } = useTranslation(["common"]);

  return (
    <div
      className="mf-start-at_check"
      tabIndex={0}
      role="button"
      onKeyUp={openSelectRange}
      onClick={openSelectRange}
    >
      <RadioInput
        input={{
          type: "checkbox",
          name: day.toString(),
          checked: selected,
          onChange: selectDay,
        }}
      />
      <div
        role="button"
        tabIndex={0}
        onKeyUp={selectDay}
        className="mf-start-at_check_info ml-2"
        onClick={selectDay}
      >
        <div className="">
          <Typhography type="p-l-b">{dayStr}</Typhography>
          {selected && (
            <Typhography type="p-r-r">
              {t("common:from")}{" "}
              <span className="has-text-weight-bold ">{startDate}</span>{" "}
              {t("common:to").toLowerCase()}{" "}
              <span className="has-text-weight-bold ">{endDate}</span>
            </Typhography>
          )}
        </div>
        <IconCustom typeIcon="angle-right" width={16} height={16} />
      </div>
    </div>
  );
};

export default StartAtDayCheck;
