/* action types */
import { ActionModalInfo, ModalInfoC } from "../../types/modalInfoC.type";
/* Used only in reducer */
export const SET_MODAL_INFO_C = "magicFlex/SET_MODAL_INFO_C";
export const CLEAN_MODAL_INFO_C = "magicFlex/CLEAN_MODAL_INFO_C";

/* Action Creators */
export const setModalInfoC = (modalInfoC: ModalInfoC): ActionModalInfo => {
  return {
    type: SET_MODAL_INFO_C,
    payload: {
      modalInfoC,
    },
  };
};
export const cleanModalInfoC = () => {
  return {
    type: CLEAN_MODAL_INFO_C,
  };
};
