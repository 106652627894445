import { useCallback, useEffect, useState } from "react";

import {
  ConceptOptionProps,
  IonClick,
} from "../../molecules/conceptOption/conceptOptionCheck.type";

const useCheck = ({
  onChange,
  enabled,
}: Pick<ConceptOptionProps, "onChange" | "enabled">) => {
  const [enabledS, setEnabledS] = useState(false);

  const handlePress: IonClick = useCallback(
    (event) => {
      setEnabledS((prev) => {
        onChange && onChange({ event, enabled: !prev });
        return !prev;
      });
    },
    [onChange],
  );

  useEffect(() => {
    setEnabledS(enabled || false);
  }, [enabled]);

  return { enabledS, handlePress };
};

export default useCheck;
