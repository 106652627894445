import { useTranslation } from "react-i18next";
import { ImageCustom, Typhography } from "../../../atoms";

const MaintenanceTemplate = () => {
  const { t } = useTranslation(["support"]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typhography type="h-1" className="is-uppercase mb-4">
        {t("support:maintenance.title")}
      </Typhography>

      <ImageCustom
        typeImage="flexi-maintenance"
        className=""
        style={{
          width: "100%",
          minWidth: "200px",
          maxWidth: "650px",
        }}
      />

      <Typhography type="h-2" className="mb-3 mt-5">
        {t("support:maintenance.message")}
      </Typhography>

      <Typhography type="h-2" className="mb-6">
        {t("support:maintenance.message2")}
      </Typhography>

      <Typhography type="h-4" className="is-uppercase">
        {t("support:maintenance.message3")}
      </Typhography>
    </div>
  );
};
export default MaintenanceTemplate;
