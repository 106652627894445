import { useTranslation } from "react-i18next";

import { Button } from "../../../atoms";
import { AmzAccountsList } from "../../../organisms";
import { AmzAccountsProps } from "./amzAccounts.types";

const AmzAccounts = (props: AmzAccountsProps) => {
  const { amzAccountsList, onAddAmzAccount, isLoading } = props;

  const { t } = useTranslation(["register"]);

  return (
    <div>
      <AmzAccountsList {...amzAccountsList} />

      <Button className="mt-5" onClick={onAddAmzAccount} isLoading={isLoading}>
        {t("register:add-account")}
      </Button>
    </div>
  );
};

export default AmzAccounts;
