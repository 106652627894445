import "./mediaIconsBlock.styles.scss";

import { Link } from "react-router-dom";

import { IconCustom } from "../../atoms";
import { MediaIconsBlockProps } from "./mediaIconsBlock.types";

const MediaIconsBlock = (props: MediaIconsBlockProps): JSX.Element => {
  const { listIcons } = props;

  return (
    <div className="mg-icons-block">
      {listIcons.map((icon, key) => {
        const { iconProps } = icon;
        const classLink = icon?.linkProps?.to ? "" : "mg-not-has-link";
        return (
          <Link
            {...icon?.linkProps}
            to={icon?.linkProps?.to || "#"}
            className={classLink}
            key={key + "-icons-block"}
          >
            <IconCustom {...iconProps} />
          </Link>
        );
      })}
    </div>
  );
};

MediaIconsBlock.defaultProps = {
  listIcons: [
    {
      iconProps: { typeIcon: "linkedin", width: 38, height: 38 },
    },
    {
      iconProps: { typeIcon: "facebook", width: 38, height: 38 },
    },
    {
      iconProps: { typeIcon: "twitter", width: 38, height: 38 },
    },
  ],
} as Partial<MediaIconsBlockProps>;

export default MediaIconsBlock;
