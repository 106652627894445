import { useEffect, useMemo } from "react";

import { useInstanOffersQuery } from "../../../../../hooks/instant-offers";
import useInstantOffersDispatch from "../../../../../store/useDispatch/useInstantOffersDispatch";
import { useAmzAccountSelector } from "../../../../../store/useSelector";
import { SavedFilter } from "../instantOffersScreen.types";

export const useGetSavedFilters = (): SavedFilter => {
  const { amzAccount } = useAmzAccountSelector();

  const { ioFiltersQuery } = useInstanOffersQuery(amzAccount?._id);

  const { activateD, deactivateD, radarIconFooterEnabledD } = useInstantOffersDispatch();

  const savedFilters = useMemo<SavedFilter>(() => {
    if (ioFiltersQuery.data) {
      const filter = ioFiltersQuery.data;

      const active = ["START", "PAUSE"].includes(filter.status);

      return {
        status: filter.status,
        areasSelected: filter.areasSelected || [],
        radius: filter.radius || 0,
        active,
        activeState: active,
      };
    }

    return {
      active: false,
      areasSelected: [],
      radius: 0,
      status: "STOP",
      activeState: false,
    };
  }, [ioFiltersQuery.data]);

  useEffect(() => {
    if (ioFiltersQuery.data) {
      savedFilters.active ? activateD() : deactivateD();
      savedFilters.activeState && radarIconFooterEnabledD();
    } else deactivateD();
  }, [
    ioFiltersQuery.data,
    savedFilters.activeState,
    savedFilters.active,
    activateD,
    deactivateD,
    radarIconFooterEnabledD,
  ]);

  return savedFilters;
};
