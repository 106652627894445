import "./reservedBlock.styles.scss";

import { useTranslation } from "react-i18next";

import { Badge } from "../../atoms";
import useReservedBlock from "./hooks/useReservedBlock";
import { ReservedBlockProps } from "./reservedBlock.type";

const ReservedBlock = (props: ReservedBlockProps) => {
  const {
    timeDisplayLabel,
    handleClick,
    locationId,
    locationName,
    startPrice,
    endPrice,
    expetecdTip,
  } = useReservedBlock(props);
  const { t } = useTranslation(["home"]);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={handleClick}
      className="mf-reserved-block-container is-flex-direction-column px-2 py-3 is-flex is-justify-content-space-between has-background-white"
      onClick={handleClick}
    >
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div>
          <p className="mr-3 mf-paragraph is-inline">{locationName}</p>
          <Badge type="success">{locationId}</Badge>
        </div>
        <h3>
          ${startPrice} {expetecdTip > 0 && `- $${endPrice}`}
        </h3>
      </div>
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <p className="mf-small mf-text-secondary">{timeDisplayLabel}</p>

        <p className="has-text-right mf-overline-bold is-uppercase">
          <span className="mf-text-secondary">{t("home:schedule.expected-tips")}: </span>{" "}
          ${expetecdTip}
        </p>
      </div>
    </div>
  );
};

ReservedBlock.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
  startPrice: 47,
  endPrice: 78,
  expetecdTip: 30,
  locationId: "CO10",
  locationName: "Laguna Niguel",
} as Partial<ReservedBlockProps>;

export default ReservedBlock;
