import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { envs } from "../../../../../config/env";
import { usePaymentIntentMutation, useUserQuery } from "../../../../../hooks";
import { useDisablePayment } from "../../../../../hooks/membership/useDisablePayment";
import { PaymentType } from "../../../../../hooks/payments/types";
import { MembershipName } from "../../../../../store/types/amzAccount.types";
import {
  useModalInfoDispatch,
  usePaymentDispatch,
} from "../../../../../store/useDispatch";
import { useAmzAccountSelector, useUserSelector } from "../../../../../store/useSelector";
import { Helpers } from "../../../../../utils/helpers";
import useGetMembershipTypes from "../payment/hooks/useGetMembershipTypes";

export const useChargeWallet = () => {
  const { onAction: onActionStripe } = useChargeWalletStripe();
  const { onAction: onActionZelle } = useChargeWalletZelle();
  const { user } = useUserSelector();
  const { userQuery } = useUserQuery(user?._id);

  return {
    onAction:
      (userQuery.data?.paymentProvider || envs.PAYMENT_PROVIDER) === "zelle"
        ? onActionZelle
        : onActionStripe,
  };
};

export const useChargeWalletStripe = () => {
  const { t } = useTranslation(["popup"]);
  const { getMembershipTypes, membershipTypesResp } = useGetMembershipTypes();
  const { initPaymentD } = usePaymentDispatch();
  const navigate = useNavigate();
  const { setModalInfoCD } = useModalInfoDispatch();

  useEffect(() => {
    getMembershipTypes();
  }, []);

  const startPayment = (amount: number) => {
    const membershipType = membershipTypesResp.data?.find(
      (membershipType: any) => membershipType.membershipName === MembershipName.WALLET,
    );

    initPaymentD({
      amount,
      paymentType: "wallet",
      membershipTypeId: membershipType?._id,
    });

    navigate("/premium/checkout");
  };

  const handleSubmit = (values: { amount: string; price: string }) => {
    const amount = Number(values.amount) || Number(values.price);

    setModalInfoCD({
      enabled: true,
      type: "warning",
      modalInfoProps: {
        ...(t("popup:confirm-charge-amount", { returnObjects: true, amount }) as any),
        image: {
          typeImage: "flexi-danger",
        },
      },
      handleAccept: () => startPayment(amount),
    });
  };

  const { onSubmit: onAction } = useDisablePayment({
    handleSubmit,
  });

  return {
    onAction,
  };
};

export const useChargeWalletZelle = () => {
  const { t } = useTranslation(["popup"]);
  const navigate = useNavigate();
  const { setModalInfoCD } = useModalInfoDispatch();
  const { createPaymentIntentMutation } = usePaymentIntentMutation();
  const { amzAccount } = useAmzAccountSelector();

  const startPayment = async (amount: number) => {
    if (!amzAccount?._id) {
      //TODO: add error message
      return;
    }

    const type = amount === 40 ? PaymentType.PPB40 : PaymentType.PPB80;

    const code = Helpers.generatePaymentCode(type);

    const paymentIntent = await createPaymentIntentMutation.mutateAsync({
      amzUserId: amzAccount._id,
      code,
    });

    if (!paymentIntent) {
      //TODO: add error message
      return;
      // setModalInfoCD({
      //   enabled: true,
      //   type: "error",
      //   modalInfoProps: {
      //     ...(t("popup:payment-error", { returnObjects: true }) as any),
      //     image: {
      //       typeImage: "flexi-danger",
      //     },
      //   },
      // });
    }

    navigate({
      pathname: "/zelle",
      search: `?code=${code}`,
    });
  };

  const handleSubmit = (values: { amount: string; price: string }) => {
    const amount = Number(values.amount) || Number(values.price);

    setModalInfoCD({
      enabled: true,
      type: "warning",
      modalInfoProps: {
        ...(t("popup:confirm-charge-amount", { returnObjects: true, amount }) as any),
        image: {
          typeImage: "flexi-danger",
        },
      },
      handleAccept: () => startPayment(amount),
    });
  };

  const { onSubmit: onAction } = useDisablePayment({
    handleSubmit,
  });

  return {
    onAction,
  };
};
