import { LabelCustomProps } from "./labelCustom.types";

const LabelCustom = (props: LabelCustomProps) => {
  const { label, subLabel, children } = props;
  return (
    <label className="label">
      <span className="magicflex-text-label">{label}</span>
      <h4 className="magicflex-textligh-overline">{subLabel}</h4>
      {children}
    </label>
  );
};

export default LabelCustom;
