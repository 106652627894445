import { useCallback } from "react";

import { envs } from "../../../config/env";
import useGet from "../../../https/hooks/get/useGet";
import { useAmzAccountSelector, useUserSelector } from "../../../store/useSelector";

const isBotEnabled = envs.ACTIVATE_BOT;

export const useGetVMState = () => {
  const { amzAccount } = useAmzAccountSelector();
  const { user } = useUserSelector();

  const { data, get, clearResp } = useGet({ spinnerFullScreen: false });

  const getVMState = useCallback(() => {
    if (!amzAccount?._id || !user?._id || !isBotEnabled) return;

    get({
      url: envs.URL_API + "/V1/createBot",
      code: "G-VM-STATE",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amzAccount?._id, user?._id]);

  return {
    getVMState,
    vmStateResp: data,
    clearVMStateResp: clearResp,
  };
};
