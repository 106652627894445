/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./navbarPhoto.stiles.scss";

import { Avatar } from "@mui/material";

import { IconCustom } from "../";
import useNavbarPhoto from "./hooks/useNavbarPhoto";

const NavbarPhoto = (): JSX.Element => {
  const { avatar, handleClick, notificationsCount, avatarFallback } = useNavbarPhoto();

  return (
    <figure
      onKeyUp={handleClick}
      className="image mg-avatar-navbar"
      style={{ width: 38, height: 38 }}
      onClick={handleClick}
    >
      <div style={{ position: "relative" }}>
        <Avatar src={avatar}>{avatarFallback}</Avatar>

        {notificationsCount > 0 ? (
          <div className="notification">
            <span className="notification-number">{notificationsCount}</span>
          </div>
        ) : (
          <div className="avatar-qr">
            <IconCustom typeIcon={"miniatureQr"} width={"19"} height={"19"} />
          </div>
        )}
      </div>
    </figure>
  );
};

export default NavbarPhoto;
