import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Typhography } from "../../atoms";
import { TransactionDetails } from "../../organisms";
import { ITransactionDetailProps } from "../transactionDetail/transactionDetail.types";
import { IDetails } from "./walletTransactions.types";

const Amount = (props: any) => {
  const isNegative = props.amount < 0;

  const sign = isNegative ? "-" : "+";

  const className = isNegative ? "mf-danger" : "mf-success";

  const amount = _.round(Math.abs(props.amount), 2);

  return (
    <>
      <Typhography type="p-r-b" className={className}>
        {sign}${Math.abs(amount)}
      </Typhography>
    </>
  );
};

const Details = (props: IDetails) => {
  const { details } = props;
  const { t } = useTranslation(["common", "home"]);

  if (!details) return null;

  const { amount, id, date, description, paymentMethod, duration } = details;

  const transactionDetails: ITransactionDetailProps[] = [
    {
      icon: "calendar",
      title: t("common:date"),
      children: (
        <Typhography type="p-r-r" className="mf-text-secondary">
          {moment(date).format("MMM DD, YYYY - hh:mm a")}
        </Typhography>
      ),
    },
    {
      icon: "clock",
      title: t("common:duration"),
      className: duration ? "" : "is-hidden",
      children: duration ? (
        <Typhography type="p-r-r" className="mf-text-secondary">
          {t("home:wallet.duration", { count: duration.includes("1") ? 1 : 2, duration })}
        </Typhography>
      ) : null,
    },
    {
      icon: "dollar",
      title: t("common:amount"),
      children: <Amount amount={amount} />,
    },
    {
      icon: "align-left",
      title: t("common:description"),
      children: (
        <Typhography type="p-r-r" className="mf-text-secondary">
          {description}
        </Typhography>
      ),
    },
    {
      icon: "credit-card",
      title: t("common:payment-method"),
      children: (
        <Typhography type="p-r-r" className="mf-text-secondary">
          {amount < 0 ? t("common:flexi-wallet") : paymentMethod}
        </Typhography>
      ),
    },
    {
      icon: "numeral",
      title: "ID",
      children: (
        <Typhography type="p-r-r" className="mf-text-secondary">
          {_.truncate(id, { length: 25 })}
        </Typhography>
      ),
    },
  ];

  return <TransactionDetails {...props} details={transactionDetails} />;
};

export default Details;
