import MagicFlexText from "../../../assets/icons/logo-magic-text.svg";

const LogoMagic = () => {
  return (
    <div className="is-centered">
      <div className="field">
        <div className="magicflex-logo-mixin is-fullwidth">
          {/* Change Icon */}
          <img src={MagicFlexText} alt={""} />
        </div>
      </div>
    </div>
  );
};

export default LogoMagic;
