import "./pricingTemplate.styles.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Typhography } from "../../../../atoms";
import { PayOption } from "../../../../molecules";
import useSelectPaymentType from "./hooks/useSelectPaymentType";

const PricingTemplate = () => {
  const { getOptionUrl, isSelected, selectOption } = useSelectPaymentType();

  const navigate = useNavigate();

  const { t } = useTranslation(["membership", "common"]);

  const onSubmit = () => {
    navigate(getOptionUrl(), {
      state: { openMenu: false, backUrl: "/premium/payment-type" },
    });
  };

  return (
    <div className="mf-pricing">
      <div className="mb-5">
        <Typhography type="overline-b" className="mb-1">
          {t("membership:pricing.get")}
        </Typhography>
        <Typhography type="p-s-r" className="mf-text-secondary">
          {t("membership:pricing.description")}
        </Typhography>
      </div>

      {/* <PayOfferCard /> */}

      <PayOption
        active={isSelected("subscription")}
        //badges={[{ type: "success", children: "Save 25%" }]}
        title={t("membership:pricing.weekly")}
        description={t("membership:pricing.weekly-description")}
        className="mb-4"
        onClick={() => selectOption("subscription")}
      >
        <Typhography type="h-2" render="span" className="mf-text-secondary">
          $
        </Typhography>
        <Typhography type="h-1" render="span" className="mx-1 is-size-3">
          45
        </Typhography>
        <Typhography type="p-s-r" className="mf-text-secondary" render="span">
          /{t("common:week")}
        </Typhography>
      </PayOption>

      <PayOption
        active={isSelected("forUse")}
        title={t("membership:wallet.pay-per-block")}
        description={t("membership:pricing.wallet-description")}
        className="mb-6"
        onClick={() => selectOption("forUse")}
      >
        <Typhography type="h-2" render="span" className="mf-text-secondary">
          $
        </Typhography>
        <Typhography type="h-1" render="span" className="mx-1 is-size-3">
          1
        </Typhography>
        <Typhography type="p-s-r" className="mf-text-secondary" render="span">
          {t("membership:pricing.per-hour")}
        </Typhography>
      </PayOption>

      <Button onClick={onSubmit}>{t("common:continue")}</Button>
    </div>
  );
};
export default PricingTemplate;
