import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUISelector } from "../../../../store/useSelector";
import { MaintenanceTemplate } from "../../../templates";

const MaintenancePage = () => {
  const navigate = useNavigate();

  const { maintenance } = useUISelector();

  useEffect(() => {
    if (!maintenance.show) navigate("/");
  }, [maintenance.show]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 100px)",
      }}
    >
      <MaintenanceTemplate />
    </div>
  );
};

export default MaintenancePage;
