import "./blockButtonMap.styles.scss";

import { Button, ImageCustom, Typhography } from "../../atoms";
type IBlockButtonMapProps = {
  lat: number;
  lng: number;
};
const BlockButtonMap = (props: IBlockButtonMapProps) => {
  const { lat, lng } = props;
  const googleMapPath = `https://maps.google.com/?q=${lat},${lng}`;
  const wazeMapPath = `https://waze.com/ul?ll=${lat},${lng}=10`;
  const appleMapPath = `http://maps.apple.com/?q=${lat},${lng}`;
  const routeChange = (path: string) => {
    window.open(path);
  };

  return (
    <div className="containerBlockMap">
      <Button
        color="google-maps"
        onClick={() => {
          routeChange(googleMapPath);
        }}
      >
        <ImageCustom
          typeImage={"google-maps"}
          alt={"google maps icon"}
          className={"googleMapImage"}
        />
      </Button>
      <Button
        color="waze"
        onClick={() => {
          routeChange(wazeMapPath);
        }}
        className="waze-btn"
      >
        <ImageCustom typeImage={"waze"} alt={"waze maps icon"} className={"waze"} />
      </Button>
      <Button
        color="success-lighter"
        onClick={() => {
          routeChange(appleMapPath);
        }}
      >
        <div className={"appleMapsContent"}>
          <ImageCustom
            typeImage={"apple-app"}
            alt={"apple maps icon"}
            className={"appleMapsImage"}
          />
          <Typhography type="h-4">Maps</Typhography>
        </div>
      </Button>
    </div>
  );
};

BlockButtonMap.defaultProps = {
  lat: 10,
  lng: 10,
} as Partial<IBlockButtonMapProps>;
export default BlockButtonMap;
