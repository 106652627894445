import { useEffect } from "react";

import { useStateGetDispatch, useStateSpinnerFullScreen } from "../../store/useDispatch";
import useControlErrorGet from "./get/useControlErrorGet";
import useControlErrorPost from "./post/useControlErrorPost";
import useSession from "./useSession";
import { IuseSetConfigQueries } from "./useSetConfigQueries.types";

const useSetConfigQueries = (elements: IuseSetConfigQueries) => {
  const { setStateGetD } = useStateGetDispatch();
  const { setSpinnerFullScreenD, cleanSpinnerFullScreenD } = useStateSpinnerFullScreen();
  const { data, spinnerFullScreen, keyStorage, typeError } = elements;

  const { controlsErrorsGet } = useControlErrorGet();
  const { controlsErrorsPost } = useControlErrorPost();
  const { logout } = useSession();
  //! Check if have any error after to try GET data from de DB.
  useEffect(() => {
    if (data?.error?.code && typeError === "get") {
      if (data.data?.message === "Access token is missing") return logout();

      controlsErrorsGet(data?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsErrorsGet, data, typeError]);

  //! Check if have any error after to try POST data from de DB.
  useEffect(() => {
    if (data?.error?.code && typeError === "post") {
      if (data.data?.message === "Access token is missing") return logout();

      controlsErrorsPost(data?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsErrorsPost, data, typeError]);

  //! If want save the data in redux, shall send the key for save
  useEffect(() => {
    if (keyStorage) {
      setStateGetD({ key: keyStorage, data });
    }
  }, [setStateGetD, data, keyStorage]);

  //! If want show spinner full screen, shall send spinnerFullScreen in true
  useEffect(() => {
    if (spinnerFullScreen && data.loading) {
      setSpinnerFullScreenD({
        enabled: true,
        color: "white",
      });
    }
  }, [setSpinnerFullScreenD, data, spinnerFullScreen]);

  //! Disabled spinner full screnn when end the process
  useEffect(() => {
    if (spinnerFullScreen && !data.loading) {
      cleanSpinnerFullScreenD();
    }
  }, [cleanSpinnerFullScreenD, data, spinnerFullScreen]);
};

export default useSetConfigQueries;
