import { IconCustom } from "../../icons";
import { RoundedBtnProps } from "./roundedBtn.types";

const RoundedBtn = (props: RoundedBtnProps) => {
  const { children, icon, className, onClick, ...rest } = props;

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onClick}
      onClick={onClick}
      className={`is-flex is-justify-content-space-between is-align-items-center px-3 py-2 magicflex-rounded-btn ${className}`}
      {...rest}
    >
      <IconCustom {...icon} />

      <span className={`text ${icon ? "pl-2" : ""}`}>{children}</span>
    </div>
  );
};

RoundedBtn.defaultProps = {
  children: "RoundedButton",
} as RoundedBtnProps;

export default RoundedBtn;
