import "./addBalanceBtn.styles.scss";

import { useTranslation } from "react-i18next";

import IconCustom from "../../icons/IconCustom";
import Typhography from "../../typhography/Typhography";
import { IAddBalanceBtnProps } from "./addBalanceBtn.types";

const AddBalanceBtn = (props: IAddBalanceBtnProps) => {
  const { t } = useTranslation(["home"]);

  return (
    <button onClick={props.onClick} className="mf-balance-btn">
      <IconCustom typeIcon="add" />
      <Typhography
        type="p-l-b"
        className="ml-1 is-uppercase has-text-white"
        render="span"
      >
        {props.children || t("home:wallet.add-balance")}
      </Typhography>
    </button>
  );
};

export default AddBalanceBtn;
