import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Membership } from "../../../hooks/membership/membership.functions";
import { useMembershipQuery } from "../../../hooks/membership/queries";
import { useAmzAccountSelector } from "../../../store/useSelector";

const useValidateMembership = () => {
  const navigate = useNavigate();
  const { amzAccount } = useAmzAccountSelector();
  const { membershipQuery } = useMembershipQuery(amzAccount?._id);

  const goToAddMembership = useCallback(() => {
    console.log("goToAddMembership");
    navigate("/premium/payment-type", { replace: true });
  }, [navigate]);

  const validateMembership = useCallback(
    (membership?: string | Membership) => {
      if (typeof membership === "string") return goToAddMembership();
    },
    [goToAddMembership],
  );

  useEffect(() => {
    if (membershipQuery.isError) {
      validateMembership();
      return;
    }

    if (membershipQuery.isFetched) validateMembership(membershipQuery.data);
  }, [
    membershipQuery.isFetched,
    membershipQuery.isError,
    membershipQuery.data,
    validateMembership,
  ]);

  return {
    loading: !membershipQuery.isFetched, // use status to check loading because initial state is 0
  };
};

export default useValidateMembership;
