import * as yup from "yup";

import { initialValues, returnInputsConfigYup } from "./useFormikDays.types";
const inputsGeneration = () => {
  return [];
};

const inputsConfigYup = (defualtValues: initialValues): returnInputsConfigYup => {
  const validationInputs = {
    days: yup.array().required("The days are required"),
  };
  const initialValues = {
    days: defualtValues?.days || [],
  };
  const validationSchema = yup.object().shape(validationInputs);
  return { initialValues, validationSchema };
};

export { inputsConfigYup, inputsGeneration };
