import "./payOfferCard.styles.scss";

import { IconCustom, ImageCustom } from "../../atoms";
import { IPayOfferCardProps } from "./payOfferCard.types";

const PayOfferCard = (props: IPayOfferCardProps) => {
  return (
    <ImageCustom typeImage={props.img} className="mf-offer-card_img" alt={props.img} />
  );
};

PayOfferCard.defaultProps = {
  className: "",
  description: "",
  title: "",
  img: "banner-promo",
} as IPayOfferCardProps;

export default PayOfferCard;
