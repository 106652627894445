import { TFunction } from "i18next";
import { ComponentProps } from "react";

import { Station } from "../../../../store/types/filters.types";
import { TitleModalSwitch } from "../../../molecules";
import { TitleModalSwitchProps } from "../../../molecules/titleModalSwitch/titleModalSwitch.types";

export const getLocationModalTitle = (
  t: TFunction<("filters" | "common")[]>,
): TitleModalSwitchProps => {
  return {
    modalTitle: {
      title: t("filters:location-title"),
      description: t("filters:location-description"),
      icon: {
        typeIcon: "marker",
        width: 14,
        height: 14,
      },
    },
    switch: {
      show: false,
    },
  };
};

export const getStartAtModalTitle = (
  t: TFunction<("filters" | "common")[]>,
): TitleModalSwitchProps => {
  return {
    modalTitle: {
      title: t("filters:start-at-title"),
      description: t("filters:start-at-description"),
      icon: {
        typeIcon: "alarm-clock",
        width: 14,
        height: 14,
      },
    },
    switch: {
      show: false,
    },
  };
};

export interface SearchFiltersProps {
  stations: Station[];
  openModal: (modal: keyof SearchFiltersState) => void;
  titleModal: Array<ComponentProps<typeof TitleModalSwitch>>;
}

export type SearchFiltersState = {
  locations?: boolean;
  startTime?: boolean;
};
