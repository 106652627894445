import { envs } from "../../config/env";
import usePost from "../../https/hooks/post/usePost";

const usePostAddCharge = () => {
  const { dataPost, post } = usePost({ spinnerFullScreen: true });

  const addCharge = (paymentId: string, amount: number) => {
    post({
      url: envs.URL_API + "/V1/wallet/transactions",
      data: {
        description: "Balance Top Up",
        type: "income",
        paymentId,
        amount,
      },
      code: "P-ADD-CHARGE",
    });
  };

  return {
    addChargeResp: dataPost,
    addCharge,
  };
};

export default usePostAddCharge;
