import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import actions from "./actions";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    "notificationC",
    "setUpFilters",
    "orders",
    "offers",
    "modalInfoC",
    "spinnerFullScreen",
    "stateGet",
    "bot",
    "amzAccounts",
    "timePikerState",
    "ui",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
  devTools: {
    actionCreators: actions,
  },
});

export const persistor = persistStore(store);

export default store;
