import { useTranslation } from "react-i18next";

import { Typhography } from "../../../atoms";

const InstantOffersDescription = () => {
  const { t } = useTranslation(["instant-offers"]);
  const descriptionParts = t("instant-offers:description", { returnObjects: true });

  return (
    <>
      <Typhography type="p-s-r" className="mf-text-secondary mb-3">
        {descriptionParts[0]}{" "}
        <Typhography render="span" type="p-s-b" className="mf-text-secondary">
          {descriptionParts[1]}
        </Typhography>{" "}
        {descriptionParts[2]}
      </Typhography>

      <Typhography type="p-s-r" className="mf-text-secondary">
        {descriptionParts[3]}{" "}
        <Typhography render="span" type="p-s-b" className="mf-text-secondary">
          {descriptionParts[4]}
        </Typhography>{" "}
        {descriptionParts[5]}
      </Typhography>
    </>
  );
};

export default InstantOffersDescription;
