import {
  AmzAccount,
  AmzAccountExtraData,
} from "../../hooks/useAmzAccount/useAmzAccount.types";
import { ActionFunction } from "./store.types";

export type Device = {
  _id: string;
};

export enum MembershipName {
  WALLET = "WALLET",
  SUBSCRIPTION = "SUBSCRIPTION",
  REFERRAL = "REFERRAL",
}

export interface AmzAccountState {
  amzAccount?: AmzAccount;
  extraData?: AmzAccountExtraData;
  devices?: Device[];
}

export type AmzAccountAF<T = any> = ActionFunction<AmzAccountState, T>;
