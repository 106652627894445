import { Separator } from "../../../atoms";
import { BlocksOptionsList, Modal, TitleModalSwitch } from "../../../molecules";
import useSetUpPrice from "./hooks/useSetUpPrice";
import { SetUpStartPriceProps } from "./setUpStartPrice.types";

const SetUpStartPrice = (props: SetUpStartPriceProps): JSX.Element => {
  const { titleModal, onClose } = props;
  const { list, handleCloseModalPrice } = useSetUpPrice({ onClose });
  return (
    <Modal title="Block Price" isOpen={true} onClose={handleCloseModalPrice}>
      <div className="contaner-switch-title">
        <TitleModalSwitch {...titleModal} />
      </div>
      <Separator />
      <div className="contaner-switch-title">
        <BlocksOptionsList blockOptions={list} />
      </div>
    </Modal>
  );
};

SetUpStartPrice.defaultProps = {
  titleModal: {
    switch: {
      show: false,
    },
    modalTitle: {
      title: "Block Start Price",
      description: "Please select a price that you want used for search the blocks",
      icon: {
        typeIcon: "dollar",
        width: 14,
        height: 14,
      },
    },
  },
  isOpen: false,
} as Partial<SetUpStartPriceProps>;

export default SetUpStartPrice;
