import { ITransactionDetailProps } from "../../molecules/transactionDetail/transactionDetail.types";

export const transactions: ITransactionDetailProps[] = [
  {
    icon: "list",
    title: "ID",
    children: "ch_21340294901",
  },
  {
    icon: "calendar",
    title: "Date",
    children: "Aug 10, 2022 - 03:30 p.m.",
  },
  {
    icon: "dollar",
    title: "Amount",
    children: "+$50",
  },
  {
    icon: "align-left",
    title: "Description",
    children: "Balance Top Up",
  },
  {
    icon: "credit-card",
    title: "Payment Method",
    children: "Visa **** 1234",
  },
];
