import { Navigate, Outlet } from "react-router-dom";

import { AccessRoute } from "./route.types";

const PublicRoute = (props: AccessRoute) => {
  const { children, isAuthenticated, redirectTo = "/" } = props;

  if (isAuthenticated) return <Navigate to={redirectTo} replace />;

  return children ? children : <Outlet />;
};

export default PublicRoute;
