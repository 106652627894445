import { useEffect, useMemo } from "react";
import io from "socket.io-client";

const useSocket = (serverUrl: string) => {
  const socket = useMemo(() => io(serverUrl), [serverUrl]);

  useEffect(() => {
    if (!socket.connected) socket.connect();

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return {
    socket,
  };
};

export default useSocket;
