import axios from "axios";

import { envs } from "../config/env";

const magicAPI = axios.create({
  baseURL: envs.URL_API + "/V1",
  withCredentials: true,
});

export default magicAPI;
