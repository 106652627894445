import "./referral.styles.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";

import { envs } from "../../../../config/env";
import useRefNotificationSelector from "../../../../store/useSelector/useNewRefNotificationSelector";
import { QRCode, Typhography } from "../../../atoms";
import { ReferralBtn } from "../../../molecules";
import { copyToClipboard } from "./referral.functions";

type Props = {
  refCode: string;
  firstName: string;
};

const ReferralTemplate = (props: Props) => {
  const { firstName, refCode } = props;
  const { notificationsCount } = useRefNotificationSelector();
  const { t } = useTranslation(["referred", "common"]);
  const navigate = useNavigate();

  const inviteUrl = `${envs.APP_URL}/invite/${refCode}`;

  return (
    <div className="mf-referral">
      <Typhography type="h-1" className="has-text-centered">
        {firstName}
      </Typhography>

      <div className="is-flex is-justify-content-center is-align-items-center mt-4">
        <div className="mf-referral-qr">
          <QRCode data={inviteUrl} />
          <Typhography type="h-2">MagicFlex</Typhography>
        </div>
      </div>

      <div className="mf-referral-code">
        <Typhography type="p-r-b">{t("referred:referral.title")}:</Typhography>
        <Typhography type="p-l-r">{refCode}</Typhography>
      </div>

      <div className="is-flex is-justify-content-space-around mt-5">
        <div>
          <ReferralBtn icon="copy-alt" onClick={() => copyToClipboard(inviteUrl)}>
            {t("common:copy")}
          </ReferralBtn>
        </div>
        <RWebShare
          data={{
            text: "MagicFlex Invitation",
            url: inviteUrl,
            title: "MagicFlex",
          }}
        >
          <ReferralBtn icon="upload">{t("common:share")}</ReferralBtn>
        </RWebShare>
        <div style={{ position: "relative" }}>
          {notificationsCount > 0 && (
            <div className="notification">
              <p className="notification-number">{notificationsCount}</p>
            </div>
          )}
          <ReferralBtn icon="user-add" onClick={() => navigate("/referred")}>
            {t("referred:referral.referred")}
          </ReferralBtn>
        </div>
      </div>
    </div>
  );
};
export default ReferralTemplate;
