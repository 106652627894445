import { useTranslation } from "react-i18next";

import { Separator } from "../../atoms";
import ReservedBlock from "../reservedBlock/ReservedBlock";
// import useArrived from "./hooks/useArrived";
import { UpcomingBlockProps } from "./upcomingBlock.types";

const UpcomingBlock = (props: UpcomingBlockProps) => {
  // const { disabled, handleClick } = useArrived(props);
  const { t } = useTranslation(["home"]);

  return (
    <div className="mb-4">
      <Separator text={t("home:schedule.upcoming-block")} className="mb-1" />
      <ReservedBlock {...props} />
      {/* <ArrivedBtn onClick={handleClick} disabled={disabled} /> */}
    </div>
  );
};

export default UpcomingBlock;
