import { deleteUser, setUser } from "../reducers/user/user";
import useActions from "./useActions";

const useUserDispatch = () => {
  return {
    setUserD: useActions(setUser),
    deleteUserD: useActions(deleteUser),
  };
};

export default useUserDispatch;
