import type { TFuncKey, TFunction } from "i18next";

import { GetModalTypes } from "./modalInfo.types";

export const getTypesModals = (translation: TFunction<"popup"[]>): GetModalTypes => {
  const t = (key: TFuncKey<"popup"[]>): any => translation(key, { returnObjects: true });

  return {
    "amazon-human-verify": {
      type: "warning",
      ...t("popup:amazon-human-verify"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    arrived: {
      type: "success",
      ...t("popup:arrived"),
      icon: {
        typeIcon: "marker-packges",
        width: 13,
        height: 13,
      },
    },
    "arrived-confirmed": {
      type: "success",
      ...t("popup:arrived-confirmed"),
      image: {
        typeImage: "flexi-success",
      },
    },
    "balance-expiration-notice": {
      type: "warning",
      ...t("popup:balance-expiration-notice"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "block-reserved": {
      type: "success",
      ...t("popup:block-reserved"),
      image: {
        typeImage: "flexi-success",
      },
    },
    "bot-error": {
      type: "danger",
      ...t("popup:bot-error"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "bot-start-error": {
      type: "danger",
      ...t("popup:bot-start-error"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "change-password-error": {
      type: "danger",
      ...t("popup:change-password-error"),
      image: {
        typeImage: "flexi-error",
      },
    },
    default: {
      type: "success",
      title: "Example",
      body: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      buttonText: "Accept and Continue",
      subButtomText: "or Go Back",
      icon: {
        typeIcon: "marker-packges",
        width: 13,
        height: 13,
      },
    },
    "delete-amz-account": {
      type: "danger",
      ...t("popup:delete-amz-account"),
      image: {
        typeImage: "flexi-error",
      },
    },
    //TODO: move to http delete hook
    "delete-amz-account-error": {
      type: "danger",
      ...t("popup:delete-amz-account-error"),
      image: {
        typeImage: "flexi-error",
      },
    },
    "delete-pending-payment": {
      type: "warning",
      ...t("popup:delete-pending-payment"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "discard-block": {
      type: "danger",
      ...t("popup:discard-block"),
      image: {
        typeImage: "flexi-error",
      },
    },
    "discard-block-error": {
      type: "danger",
      ...t("popup:discard-block-error"),
      image: {
        typeImage: "flexi-error",
      },
    },
    "expired-session": {
      ...t("popup:expired-session"),
      type: "warning",
      image: {
        typeImage: "flexi-danger",
      },
    },
    "log-out": {
      type: "danger",
      ...t("popup:log-out"),
      icon: {
        typeIcon: "sign-out",
        fill: "#FF6464",
        width: 13,
        height: 13,
      },
    },
    "login-error": {
      type: "danger",
      ...t("popup:login-error"),
      image: {
        typeImage: "flexi-error",
      },
    },
    "missing-io-station": {
      type: "warning",
      ...t("popup:missing-io-station"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "no-stations-selected": {
      type: "warning",
      ...t("popup:no-stations-selected"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "only-instant-offers-available": {
      type: "warning",
      ...t("popup:only-instant-offers-available"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "payment-blocked-time": {
      type: "warning",
      ...t("popup:payment-blocked-time"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "payment-success": {
      type: "success",
      ...t("popup:payment-success"),
      image: {
        typeImage: "flexi-success",
      },
    },
    "payment-unavailable-yet": {
      type: "warning",
      ...t("popup:payment-unavailable-yet"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "register-error": {
      type: "danger",
      ...t("popup:register-error"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "report-error": {
      type: "danger",
      ...t("popup:report-error"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "report-success": {
      type: "success",
      ...t("popup:report-success"),
      image: {
        typeImage: "flexi-success",
      },
    },
    "start-at-today-not-selected": {
      type: "warning",
      ...t("popup:start-at-today-not-selected"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "verify-email-error": {
      type: "danger",
      ...t("popup:verify-email-error"),
      image: {
        typeImage: "flexi-danger",
      },
    },
    "verify-otp-error": {
      type: "danger",
      ...t("popup:verify-otp-error"),
      image: {
        typeImage: "flexi-danger",
      },
    },
  };
};
