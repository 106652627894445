import { cleanNotificationC, setNotificationC } from "../reducers/noficationC/actions";
import useActions from "./useActions";

// Here is nesesary to configure all the actions
const useDispatch = () => {
  const setNotificationCD = useActions(setNotificationC);
  const cleanNotificationCD = useActions(cleanNotificationC);

  return {
    setNotificationCD: setNotificationCD as typeof setNotificationC,
    cleanNotificationCD,
  };
};

export default useDispatch;
