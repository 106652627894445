import useAnimation from "../../../../hooks/useAnimation/useAnimation";
import { IconCustom } from "../../icons";

const RefreshBtn = ({ onClick }: any) => {
  const animate = useAnimation();

  const handleClick = () => {
    onClick();
    animate.to({ rotate: "+=360", duration: 1 });
  };

  return (
    <div
      ref={animate.elementRef}
      className="mg-tabs_icon "
      role={"button"}
      tabIndex={0}
      onKeyUp={handleClick}
      onClick={handleClick}
    >
      <IconCustom typeIcon="rotate-right" width={16} height={16} color="#78ABF8" />
    </div>
  );
};

export default RefreshBtn;
