import "./navTabs.styles.scss";

import { RefreshBtn, Typhography } from "../../atoms";
import { INavTabs } from "./navTabs.types";
import useNavTabs from "./useNavTabs";

const NavTabs = (props: INavTabs) => {
  const { tabs, selectedTab: tabSelected } = props;
  const { choice, onClick } = useNavTabs(tabs, tabSelected || 0);

  return (
    <>
      <div className="tabs mg-tabs">
        <ul>
          {tabs.map((tab, key) => {
            const isSelected = choice.opt === key;
            return (
              <li className={isSelected ? "is-active" : ""} key={`tab-${key}`}>
                <Typhography type="overline-b" onClick={() => onClick(tab, key)}>
                  {tab.title}
                </Typhography>
                {isSelected && tab.onRefresh && <RefreshBtn onClick={tab.onRefresh} />}
              </li>
            );
          })}
        </ul>
      </div>
      {choice.child}
    </>
  );
};

NavTabs.defaultProps = {
  tabs: [
    {
      child: <p>1</p>,
      title: "Schedule",
      onClick: () => {
        //
      },
    },
    {
      child: <p>2</p>,
      title: "Event Log",
      onClick: () => {
        //
      },
    },
  ],
  selectedTab: 1,
} as INavTabs;

export default NavTabs;
