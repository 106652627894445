import { envs } from "../../../../../../config/env";
import usePost from "../../../../../../https/hooks/post/usePost";
import { Station } from "../../../../../../store/types/filters.types";

const useSaveStationsFilter = () => {
  const { dataPost, post } = usePost({ spinnerFullScreen: false });

  const saveStationsFilter = (stations: Station[]) => {
    if (stations?.length === 0) return;

    post({
      url: envs.URL_API + "/V1/userFilters/stationsSelected",
      code: "P-STATIONS-FILTERS",
      data: { stationsSelected: stations },
    });
  };

  return {
    saveStationsFilterResp: dataPost,
    saveStationsFilter,
  };
};

export default useSaveStationsFilter;
