/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "./styles.scss";

import useRadarAnimation from "./hooks/useRadarAnimation";

interface RadarAnimationProps {
  onClick?: () => void;
  type?: "icon" | "effects";
}

const RadarAnimation = (props: RadarAnimationProps) => {
  const { urlImage, show, animationRadarRef } = useRadarAnimation(props);

  if (props?.type === "icon") {
    return (
      <img
        className={"video-icon"}
        onClick={props?.onClick}
        src={urlImage}
        alt={"animation flexi"}
      />
    );
  }

  if (props?.type === "effects" && show) {
    return (
      <div className="container-animation" id="animation-radar" ref={animationRadarRef}>
        <img src={urlImage} className={"animation-flexi-radar"} alt={""} />
      </div>
    );
  }
  return <></>;
};

export default RadarAnimation;
