export type TypographyOptionProps = {
  smTitle: string;
  bodyText: string;
  className?: string;
};

export const defaultProps: TypographyOptionProps = {
  smTitle: "Example",
  bodyText: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
};
