import {
  ActionGetGeneric,
  IStateGetGeneric,
  IUpdateGetState,
} from "../../types/stateGet.types";
import {
  CLEAN_GET_PARTICULAR,
  CLEAN_STATE_GET,
  SET_STATE_GET,
  UPDATE_STATE_GET,
} from "./actions";
import { updateStateElement } from "./stateGet.functions";

// reducer
const initialState: IStateGetGeneric = {};

export default function stateGet(state = initialState, action: ActionGetGeneric) {
  const stateCopy = { ...state };
  switch (action?.type) {
    case SET_STATE_GET: {
      const key = action?.payload?.getState.key as string;
      const data = action?.payload?.getState?.data;
      stateCopy[key] = data;
      return stateCopy;
    }
    case UPDATE_STATE_GET: {
      const getState = action?.payload?.getState as IUpdateGetState;
      const newobject = updateStateElement({ getState, state });
      return newobject;
    }
    case CLEAN_GET_PARTICULAR: {
      const key = action?.payload?.getState.key as string;
      delete initialState[key];
      return initialState;
    }
    case CLEAN_STATE_GET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
