import {
  addCalendarOffer,
  addCalendarOffers,
  addOffersLogs,
  deleteCalendarOffer,
  deleteCalendarOffers,
  deleteOfferLog,
  deleteOffersLogs,
  reset,
  setCalendarOffers,
  setError,
  setOfferLogs,
  startLoading,
  stopLoading,
} from "../reducers/offers/offers";
import useActions from "./useActions";

const useOffersDispatch = () => {
  return {
    addOffersLogsD: useActions(addOffersLogs),
    deleteOfferLogD: useActions(deleteOfferLog),
    deleteOffersLogsD: useActions(deleteOffersLogs),
    resetD: useActions(reset),
    setErrorD: useActions(setError),
    setOfferLogsD: useActions(setOfferLogs),
    startLoadingD: useActions(startLoading),
    stopLoadingD: useActions(stopLoading),
    addCalendarOfferD: useActions(addCalendarOffer),
    addCalendarOffersD: useActions(addCalendarOffers),
    deleteCalendarOfferD: useActions(deleteCalendarOffer),
    deleteCalendarOffersD: useActions(deleteCalendarOffers),
    setCalendarOffersD: useActions(setCalendarOffers),
  };
};

export default useOffersDispatch;
