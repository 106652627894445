import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { useModalInfoDispatch } from "../../store/useDispatch";
import useBotDispatch from "../../store/useDispatch/useBotDispatch";
import useAmzAccountSelector from "../../store/useSelector/useAmzAccountSelector";
import { ERROR_REGEXP, RUNNING_REGEXP, STOP_REGEXP } from "./botSocket.constants";
import useSocket from "./useSocket";

const useBotSocket = (serverUrl: string) => {
  const queryClient = useQueryClient();
  const { socket } = useSocket(serverUrl);
  const { amzAccount } = useAmzAccountSelector();
  const { stopBotD, startBotD } = useBotDispatch();
  const { setModalInfoCD } = useModalInfoDispatch();

  useEffect(() => {
    amzAccount?._id && socket?.emit("subscribe", amzAccount?._id);

    return () => {
      amzAccount?._id && socket?.emit("unsubscribe", amzAccount?._id);
    };
  }, [amzAccount?._id, socket]);

  useEffect(() => {
    if (!amzAccount?._id) return;

    stopBotD();

    socket?.emit("action", {
      channel: amzAccount._id,
      action: "STATUS",
    });
  }, [amzAccount?._id, socket, startBotD]);

  useEffect(() => {
    socket?.on("status", (message?: { status?: string; code: string }) => {
      const status = message?.status;
      const code = message?.code;

      const setStatus = () => {
        if (status && STOP_REGEXP.test(status)) {
          queryClient.refetchQueries(["bot-config", "lost-limit-reached"]);
          stopBotD();
        } else if (status && RUNNING_REGEXP.test(status)) startBotD();
      };

      if (code && ERROR_REGEXP.test(code)) {
        return setModalInfoCD({
          enabled: true,
          modalType: "bot-error",
          handleClose: setStatus,
          handleAccept: setStatus,
          handleCancel: setStatus,
        });
      }

      setStatus();
    });

    return () => {
      socket?.off("status");
    };
  }, [socket, setModalInfoCD, stopBotD]);

  return {
    socket,
  };
};

export default useBotSocket;
