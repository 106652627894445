import "./eventLog.styles.scss";

import { Badge } from "../../atoms";
import Typhography from "../../atoms/typhography/Typhography";
import {
  ILocationStateP,
  IStatusConceptP,
  ITimelineStatusProps,
  StatusTypes,
} from "./TimelineStatus.types";
import TimelineStatusIcon from "./TimelineStatusIcon";

const tiltePipeLine = (status: StatusTypes) => {
  const titlesStatus = {
    failed: "Capture failed",
    stoped: "System Paused",
    success: "Captured successfully",
    process: "System Started",
  };
  return titlesStatus[status || "failed"] ?? titlesStatus["failed"];
};

const StatusConcept = (props: IStatusConceptP): JSX.Element => {
  const { hasTitle, time, titleStatus } = props;
  if (!hasTitle) {
    return (
      <div className="mg-pipeline-title-status">
        <Typhography type="overline-r">on {time}</Typhography>
      </div>
    );
  } else {
    return (
      <div className="mg-pipeline-title-status">
        <Typhography type="p-s-b" className="mf-text-secondary">
          {titleStatus}
        </Typhography>
        <Typhography type="p-s-r" className="mg-pipeline-time mf-text-secondary">
          on {time}
        </Typhography>
      </div>
    );
  }
};

const LocationState = (props: ILocationStateP) => {
  const { status, location, code, hasTitle } = props;
  if (!hasTitle) {
    return (
      <div className="mg-pipeline-subtitle-status">
        <Typhography type="p-r-r">{tiltePipeLine(status)}</Typhography>
      </div>
    );
  } else {
    return (
      <div className="mg-pipeline-subtitle-status">
        <Typhography type="p-r-r">{location}</Typhography>
        <Badge type="success">{code}</Badge>
      </div>
    );
  }
};

interface IDateStateProps {
  hasTitle: boolean;
  blockTime: string;
}

const DateState = (props: IDateStateProps) => {
  const { hasTitle, blockTime } = props;

  if (!hasTitle) {
    return null;
  }

  return (
    <div className="">
      <Typhography type="p-s-r" className="mf-text-secondary">
        {blockTime}
      </Typhography>
    </div>
  );
};

const TimelineStatus = (props: ITimelineStatusProps) => {
  const hasTitle = !["stoped", "process"].includes(props.status);
  const titleStatus = hasTitle ? tiltePipeLine(props.status) : "";
  const priceClass = props.status === "success" ? "mf-success" : "";

  return (
    <>
      <div className={`mg-pipeline-status mg-pipeline-status-${props.status}`}>
        <TimelineStatusIcon statusType={props.status} />
        <div className="mg-pipeline-block">
          <div className="mgp-col-1">
            <StatusConcept
              hasTitle={hasTitle}
              time={props.time}
              titleStatus={titleStatus}
            />
            <LocationState
              hasTitle={hasTitle}
              location={props.location}
              code={props.code}
              status={props.status}
            />
            <DateState hasTitle={hasTitle} blockTime={props.blockTime ?? ""} />
          </div>

          {hasTitle && (
            <div className="mg-pipeline-cost mgp-col-2">
              <Typhography type="h-3" className={priceClass}>
                {props.rangeCost}
              </Typhography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

TimelineStatus.defaultProps = {
  status: "failed",
  time: "Mar 24, 02:00 pm",
  location: "Aventura Hollywood",
  code: "C450",
  rangeCost: "$46-$75",
} as Partial<ITimelineStatusProps>;

export default TimelineStatus;
