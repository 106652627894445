import moment from "moment";

type TimeLabel = {
  date1: Date;
  date2: Date;
  format1: string;
  format2: string;
};

export const getTimeLabel = (timeLabel: TimeLabel) => {
  const { date1, date2, format1, format2 } = timeLabel;

  const fromDate = moment(date1).format(format1);
  const toDate = moment(date2).format(format2);

  return `${fromDate} ${toDate}`;
};

export const getResponsiveFormat = (width: number) => {
  const mediumW = width > 400;
  const smallW = width > 368;

  let format1 = "ddd DD, hh:mm a";

  if (mediumW || smallW)
    format1 = mediumW ? "dddd DD, [from] hh:mm a" : "dddd DD, hh:mm a";

  const format2 = mediumW ? "[to] hh:mm a" : "[-] hh:mm a";

  return [format1, format2];
};

// Tuesday 23, from 10:30 am to 01:30 pm
// Tuesday 23, 10:30 am - 01:30 pm
// Tue 23, 10:30 am - 01:30 pm
