import useMenuDispatch from "../../../../store/useDispatch/useMenuDispatch";

const useNavbar = () => {
  const { toggleMenu } = useMenuDispatch();

  const openMenu = () => toggleMenu();

  return { openMenu };
};

export default useNavbar;
