import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useParams = <T extends object>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo<T>(() => {
    const params: any = Object.fromEntries(searchParams.entries());

    Object.keys(params).forEach((key) => {
      if (params[key] === "undefined") {
        params[key] = undefined;
        return;
      }

      if (params[key] === "null") {
        params[key] = null;
        return;
      }

      if (params[key] === "true") {
        params[key] = true;
        return;
      }

      if (params[key] === "false") {
        params[key] = false;
        return;
      }

      if (!isNaN(params[key])) {
        params[key] = Number(params[key]);
      }
    });

    return params as T;
  }, [searchParams]);

  const setParams = (values: Partial<T>) => {
    Object.entries(values).forEach(([key, value]) => {
      if (value == undefined || value == null) {
        searchParams.delete(key);
        setSearchParams(searchParams);
        return;
      }

      searchParams.set(key, String(value));
      setSearchParams(searchParams);
    });
  };

  return [params, setParams] as const;
};

export type Pagination = {
  page: number;
  limit: number;
};

export const usePagination = (args: Pagination = { limit: 20, page: 1 }) => {
  const [page, setPage] = useState(args.page);
  const [limit, setLimit] = useState(args.limit);

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleLimitChange = (value: number) => {
    setLimit(value);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  return {
    page,
    limit,
    handlePageChange,
    handleLimitChange,
    nextPage,
    prevPage,
  };
};
