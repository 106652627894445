import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { VerifyCaptchaForm } from "../../../organisms";
import { FormField } from "../../../organisms/formDynamic/formDynamic.types";
import {
  getFormFields,
  verifyCaptchaDefaultProps as defaultProps,
} from "./verifyCaptcha.constants";
import { VerifyCaptchaProps } from "./verifyCaptcha.types";

const VerifyCaptcha = (props: VerifyCaptchaProps) => {
  const { onSubmit, captchaUrl, password, email } = props;
  const { t } = useTranslation(["common"]);

  const fields = useMemo<FormField[]>(() => {
    return getFormFields(t).map((field) => {
      if (field.name === "password") return { ...field, value: password };
      if (field.name === "email") return { ...field, value: email };
      return field;
    });
  }, [t, password, email]);

  return (
    <div>
      <VerifyCaptchaForm captchaUrl={captchaUrl} fields={fields} onSubmit={onSubmit} />
    </div>
  );
};

VerifyCaptcha.defaultProps = defaultProps;

export default VerifyCaptcha;
