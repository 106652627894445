import { BuilderText } from "../../../organisms";

const TermsConditionsTemplate = () => {
  return (
    <div className="mb-5">
      <BuilderText type="termsConditions" />
    </div>
  );
};

export default TermsConditionsTemplate;
