import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";
import { useVerifyToken } from "../../hooks/auth/useVerifyToken";
import { useUserSelector } from "../../store/useSelector";
import PublicRoute from "../PublicRoute";
import ResetPasswordRoutes from "./ResetPasswordRoutes";
const LoginScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "LoginScreen" */ "../../components/pages/public/login/LoginScreen"
    ),
);
const RegisterScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "RegisterScreen" */ "../../components/pages/public/register/RegisterScreen"
    ),
);

const AuthRoutes = () => {
  useVerifyToken();
  const { user } = useUserSelector();

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<PublicRoute isAuthenticated={!!user} />}>
          <Route path="login" element={<LoginScreen />} />
          <Route path="register" element={<RegisterScreen />} />
        </Route>

        <Route path="reset-password/*" element={<ResetPasswordRoutes />} />

        <Route path="*" element={<Navigate to="login" />} />
      </Routes>
    </Suspense>
  );
};

export default AuthRoutes;
