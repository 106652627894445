import { clean, init, setCaptcha, setCvf, setOtp } from "../reducers/register";
import useActions from "./useActions";

const useRegisterDispatch = () => {
  return {
    cleanD: useActions(clean),
    initD: useActions(init),
    setCaptchaD: useActions(setCaptcha),
    setOtpD: useActions(setOtp),
    setCvfD: useActions(setCvf),
  };
};

export default useRegisterDispatch;
