import { IInstantOffersAF } from "../../types/instantOffers.types";

export const activate: IInstantOffersAF = (state) => {
  state.isActive = true;
};

export const deactivate: IInstantOffersAF = (state) => {
  state.isActive = false;
};

export const radarIconFooterEnabled: IInstantOffersAF = (state) => {
  state.radarIconEnabled = true;
};

export const radarIconFooterDisabled: IInstantOffersAF = (state) => {
  state.radarIconEnabled = false;
};

export const setPlayAnimation: IInstantOffersAF<boolean> = (state, action) => {
  state.playAnimation = action.payload ?? false;
};

export const clear: IInstantOffersAF = (state) => {
  state.isActive = false;
  state.radarIconEnabled = false;
};
