import { getDay } from "date-fns";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useSocketContext from "../../contexts/socketContext/hooks/useSocketContext";
import { Station } from "../../store/types/filters.types";
import { useBotDispatch, useModalInfoDispatch } from "../../store/useDispatch";
import {
  useAmzAccountSelector,
  useBotSelector,
  useFiltersSelector,
} from "../../store/useSelector";
import { useBotConfig } from "../botConfig";
import { useGetActiveMembership } from "../membership";
import useBotService from "./hooks/useBotServiceState";
import { getStopPopup } from "./useBot.constants";

const useBot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["popup"]);
  const { amzAccount } = useAmzAccountSelector();
  const { lostLimitReachedQuery, lostLimitReachedMutation } = useBotConfig();
  const { startBotD, stopBotD, setStartingServiceD } = useBotDispatch();
  const { isRunning, startingService } = useBotSelector();
  const { filters, startAt } = useFiltersSelector();
  const { botSocket } = useSocketContext();
  const { updateBotServiceState, isBotServiceUp, isVMUp, error, clearVMStateResp } =
    useBotService();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { activeMembership, showErrorActiveMembership } = useGetActiveMembership();

  const startBot = useCallback(() => {
    if (!amzAccount || isRunning) return;

    startBotD();

    botSocket?.emit("action", {
      channel: amzAccount._id,
      action: "START",
    });
  }, [amzAccount, botSocket, startBotD, isRunning]);

  const stopBot = useCallback(() => {
    if (!amzAccount) return;

    stopBotD();

    botSocket?.emit("action", {
      channel: amzAccount._id,
      action: "STOP",
    });
  }, [amzAccount, botSocket, stopBotD]);

  const toggleActiveBot = useCallback(() => {
    if (isRunning) return setModalInfoCD({ ...getStopPopup(t), handleAccept: stopBot });

    if (!activeMembership) return showErrorActiveMembership();

    const start = () => {
      if (isBotServiceUp) return startBot();
      setStartingServiceD(true);
      updateBotServiceState();
    };

    // Check if any station is selected
    const anyStationSelected = filters?.data.stations.some(
      (station: Station) => station.state,
    );

    if (!anyStationSelected)
      return setModalInfoCD({ enabled: true, modalType: "no-stations-selected" });

    const checkLostLimitReached = () => {
      if (lostLimitReachedQuery.data) {
        return setModalInfoCD({
          enabled: true,
          handleAccept: () => {
            lostLimitReachedMutation.mutate({
              limitReached: false,
              humanCheckResolved: true,
            });
            start();
          },
          handleCancel: () => {
            lostLimitReachedMutation.mutate({
              limitReached: false,
              humanCheckResolved: false,
            });
            start();
          },
          modalType: "amazon-human-verify",
        });
      }

      start();
    };

    // Check if today is selected in start at
    const today = getDay(new Date());

    const todayIsSelected = startAt.days.some((day) => day.day === today);

    if (!todayIsSelected)
      return setModalInfoCD({
        enabled: true,
        modalType: "start-at-today-not-selected",
        handleAccept: checkLostLimitReached,
        handleCancel: () => navigate("/search-filters"),
      });
    else checkLostLimitReached();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lostLimitReachedQuery.data,
    isRunning,
    activeMembership,
    startBot,
    stopBot,
    t,
    filters,
    setModalInfoCD,
    isBotServiceUp,
  ]);

  useEffect(() => {
    if (isVMUp && isBotServiceUp && startingService) {
      startBot();
      setStartingServiceD(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVMUp, isBotServiceUp, startBot, startingService]);

  useEffect(() => {
    if (error && startingService) {
      setStartingServiceD(false);
      clearVMStateResp();

      setModalInfoCD({ enabled: true, modalType: "bot-start-error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setStartingServiceD, setModalInfoCD, startingService]);

  return {
    toggleActiveBot,
    canActiveBot: !!amzAccount,
    isBotRunning: isRunning,
  };
};

export default useBot;
