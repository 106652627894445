import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";

const ReportIssueScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportIssueScreen" */ "../../components/pages/private/reportIssue/ReportIssue"
    ),
);

const SupportRoutes = () => {
  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/report" element={<ReportIssueScreen />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default SupportRoutes;
