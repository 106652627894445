import { FieldMetaProps } from "formik";
import { useEffect, useState } from "react";

import styles from "./fieldStyles.styles";

const useFieldStyles = (meta: FieldMetaProps<any>) => {
  const [fieldStyle, setFieldStyle] = useState<React.CSSProperties>();

  useEffect(() => {
    if (meta.touched) {
      setFieldStyle(meta.error ? styles.fieldError : styles.fieldSuccess);
    } else {
      setFieldStyle(styles.field);
    }
  }, [meta.error, meta.touched]);

  return { fieldStyle };
};

export default useFieldStyles;
