import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";
import AmzRegisterLayout from "../../components/layouts/amzRegister/AmzRegisterLayout";

const RegisterAmzAccountScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "RegisterAmzAccountScreen" */ "../../components/pages/settings/registerAmzAccountScreen/RegisterAmzAccountScreen"
    ),
);
const AmzAccountsScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "AmzAccountsScreen" */ "../../components/pages/settings/amzAccountsScreen/AmzAccountsScreen"
    ),
);
const AmzVerifyOtpScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "AmzVerifyOtpScreen" */ "../../components/pages/settings/amzVerifyOtpScreen/AmzVerifyOtpScreen"
    ),
);
const AmzVerifyCaptcha = lazy(
  () =>
    import(
      /* webpackChunkName: "AmzVerifyCaptcha" */ "../../components/pages/settings/amzVerifyCaptcha/AmzVerifyCaptcha"
    ),
);

const AmzVerifyCvfScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "AmzVerifyCvf" */ "../../components/pages/settings/amzVerifyCvf/AmzVerifyCvf"
    ),
);

const LinkRegisterScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "LinkRegisterScreen" */ "../../components/pages/private/linkRegister/LinkRegister"
    ),
);

const AmzAccountsRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route index element={<AmzAccountsScreen />} />
        <Route path="/" element={<AmzRegisterLayout />}>
          <Route path="/register" element={<RegisterAmzAccountScreen />} />
          <Route path="/verify-otp" element={<AmzVerifyOtpScreen />} />
          <Route path="/verify-captcha" element={<AmzVerifyCaptcha />} />
          <Route path="/verify-captcha-cvf" element={<AmzVerifyCvfScreen />} />
        </Route>
        <Route path="/register-link" element={<LinkRegisterScreen />} />
        <Route path="*" element={<Navigate to="./" />} />
      </Routes>
    </Suspense>
  );
};

export default AmzAccountsRoutes;
