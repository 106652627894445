import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useGetConfirmPayment from "../../../../../../hooks/useVerifyPayment/useGetConfirmPayment";
import usePostAddCharge from "../../../../../../hooks/useVerifyPayment/usePostAddCharge";
import usePostAddMembership from "../../../../../../hooks/useVerifyPayment/usePostAddMembership";
import {
  useModalInfoDispatch,
  usePaymentDispatch,
} from "../../../../../../store/useDispatch";
import { usePaymentSelector } from "../../../../../../store/useSelector";

const useConfirmPayment = (onError?: () => void) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["membership", "common"]);

  const { amount } = usePaymentSelector();
  const { cleanPaymentD } = usePaymentDispatch();
  const { setModalInfoCD } = useModalInfoDispatch();

  const { confirmPayment, confirmPaymentResp } = useGetConfirmPayment();
  const { addMembership, addMembershipResp } = usePostAddMembership();
  const { addCharge, addChargeResp } = usePostAddCharge();

  useEffect(() => {
    if (confirmPaymentResp.success) {
      addMembership(confirmPaymentResp.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPaymentResp.success]);

  useEffect(() => {
    if (addMembershipResp.success && amount) {
      addCharge(confirmPaymentResp.data, amount || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMembershipResp.success, confirmPaymentResp.data]);

  useEffect(() => {
    if ((amount && addChargeResp.success) || addMembershipResp.success) {
      const accept = () => {
        cleanPaymentD();
        navigate("/", { replace: true });
      };

      setModalInfoCD({
        enabled: true,
        modalType: "payment-success",
        handleAccept: accept,
        handleCancel: accept,
        handleClose: accept,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, addChargeResp.success, addMembershipResp.success]);

  useEffect(() => {
    if ((amount && addChargeResp.error) || addMembershipResp.error) {
      setModalInfoCD({
        enabled: true,
        handleAccept: onError,
        handleCancel: onError,
        handleClose: onError,
        modalInfoProps: {
          type: "danger",
          title: t("membership:checkout.payment-error"),
          buttonText: t("common:accept"),
          body: t("common:unexpected-error"),
          image: {
            typeImage: "flexi-error",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, addChargeResp.error, addMembershipResp.error]);

  return {
    confirmPayment,
    success: amount ? addChargeResp.success : addMembershipResp.success,
    error: amount ? addChargeResp.error : addMembershipResp.error,
  };
};

export default useConfirmPayment;
