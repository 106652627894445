import { Box, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useFilterBufferTime } from "../../../hooks";
import { TitleModalSwitch } from "../../molecules";

const BUFFER_TIMES = [0, 5, 10, 15, 20, 25, 30];

export const BufferTimeSelector = () => {
  const { t } = useTranslation(["filters"]);
  const { bufferTimeQuery, bufferTimeMutation } = useFilterBufferTime();

  const handleBufferTimeChange = (bufferTime: number) => {
    bufferTimeMutation.mutate(bufferTime);
  };

  return (
    <Box className="contaner-switch-title">
      <TitleModalSwitch
        modalTitle={{
          title: t("filters:buffer-time"),
          description: t("filters:buffer-time-description"),
          icon: {
            typeIcon: "time-check",
            width: 14,
            height: 14,
          },
        }}
        switch={{ show: false }}
      />

      <Select
        sx={{
          fontWeight: "bold",
        }}
        value={bufferTimeQuery.data || 0}
        onChange={(e) => handleBufferTimeChange(e.target.value as number)}
        fullWidth
        variant="standard"
      >
        {BUFFER_TIMES.map((time) => (
          <MenuItem key={time} value={time}>
            {t("filters:buffer-minutes", { count: time })}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
