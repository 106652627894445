import "./badge.styles.scss";

import React from "react";

import { BadgeProps } from "./badge.type";

const Badge = (props: BadgeProps) => {
  const { children, type } = props;

  return (
    <span
      className={[
        `mf-bg-${type}-light`,
        "px-2",
        "has-text-centered",
        "has-text-white",
        "mf-overline-bold",
        "badge-text",
      ].join(" ")}
    >
      {children}
    </span>
  );
};

Badge.defaultProps = {
  children: "Success",
  type: "success",
} as Partial<BadgeProps>;

export default Badge;
