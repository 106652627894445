import "./dayLabelRadio.styles.scss";

import { IconCustom, Typhography } from "../../atoms";
import HourInput from "../hourInput/HourInput";
import { DayLabelRadioProps } from "./dayLabelRadio.types";
import LabelRadio from "./LabelRadio";

const DayLabelRadio = (props: DayLabelRadioProps) => {
  const { input, timeInput, timeInputEnd, error, ...rest } = props;

  const checked = input?.checked;

  return (
    <>
      <div className="mf-day-label-radio">
        <LabelRadio
          {...rest}
          type="normal"
          input={input}
          className="is-size-6 mg-input-checkbox"
        />

        {checked && (
          <div
            role="button"
            tabIndex={0}
            onKeyUp={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="is-flex is-align-items-center containerTimer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <HourInput {...timeInput} error={error?.message} />
            <div className="mx-1 is-flex is-align-items-center">
              <IconCustom typeIcon="arrow-right" width={18} />
            </div>
            <HourInput {...timeInputEnd} error={error?.message} />
          </div>
        )}
      </div>
      {checked && error && (
        <div>
          <Typhography type="p-s-r" className="mf-danger has-text-right mt-1">
            {error.message}
          </Typhography>
        </div>
      )}
    </>
  );
};

export default DayLabelRadio;
