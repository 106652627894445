import "./payOption.styles.scss";

import { Badge, Typhography } from "../../atoms";
import { IPayOptionProps } from "./payOption.types";

const PayOption = (props: IPayOptionProps) => {
  const { active, title, description, badges, children, className, onClick } = props;

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onClick}
      className={`mf-pay-option ${className} ${active ? "active" : ""}`}
      onClick={onClick}
    >
      {badges ? (
        <div className="mf-pay-option_badges">
          {badges.map((badge, idx) => (
            <Badge key={idx} {...badge} />
          ))}
        </div>
      ) : null}
      <Typhography type="h-2">{title}</Typhography>
      <div className="py-2">{children}</div>
      <Typhography type="p-s-r" className="mf-text-secondary">
        {description}
      </Typhography>
    </div>
  );
};

PayOption.defaultProps = {
  title: "Weekly Subscription",
  description: "Includes 7 days of unlimited bot usage.",
  active: false,
  children: (
    <>
      <Typhography type="h-2" render="span" className="mf-text-secondary">
        $
      </Typhography>
      <Typhography type="h-1" render="span" className="mx-1 is-size-3">
        45
      </Typhography>
      <Typhography type="p-s-r" className="mf-text-secondary" render="span">
        /week
      </Typhography>
    </>
  ),
} as IPayOptionProps;

export default PayOption;
