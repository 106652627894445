import { IconCustom } from "../../atoms";
import { messageStatusType } from "./botStatusMessage.type";
import {
  BotStatusMessageDefaultProps,
  BotStatusMessageProps,
} from "./botStatusMessage.type";

const BotStatusMessage = (props: BotStatusMessageProps) => {
  const { type } = props;

  const { icon, message, title } = messageStatusType[type];

  return (
    <div className="has-text-centered">
      <IconCustom {...icon} className="mf-text-secondary" width={88} height={88} />

      <p className="mf-large-bold mt-4 mb-2">{title}</p>
      <p className="mf-regular mb-4">{message}</p>
    </div>
  );
};

BotStatusMessage.defaultProps = BotStatusMessageDefaultProps;

export default BotStatusMessage;
