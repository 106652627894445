import {
  activate,
  clear,
  deactivate,
  radarIconFooterDisabled,
  radarIconFooterEnabled,
  setPlayAnimation,
} from "../reducers/instantOffers";
import useActions from "./useActions";

const useInstantOffersDispatch = () => {
  return {
    activateD: useActions(activate),
    deactivateD: useActions(deactivate),
    radarIconFooterEnabledD: useActions(radarIconFooterEnabled),
    radarIconFooterDisabledD: useActions(radarIconFooterDisabled),
    clearD: useActions(clear),
    setPlayAnimationD: useActions(setPlayAnimation),
  };
};

export default useInstantOffersDispatch;
