import "react-phone-input-2/lib/style.css";
import "./phoneInput.styles.scss";

import { ErrorMessage, useField } from "formik";
import PhoneInput2 from "react-phone-input-2";

import { useFieldStyles } from "../../../hooks";
import LabelCustom from "../labelCustom/LabelCustom";
import styles from "./phoneInput.styles";
import { PhoneInputProps } from "./phoneInput.types";

const PhoneInput = (props: PhoneInputProps) => {
  const { label } = props;

  const [field, meta] = useField({
    name: props.inputProps?.name || "phone",
    type: "tel",
  });

  const { fieldStyle } = useFieldStyles(meta);

  const handleChange = (value: string) => {
    field.onChange({ target: { value, name: field.name } });
  };

  return (
    <div className="field">
      <LabelCustom label={label || ""} />
      <PhoneInput2
        containerClass="control"
        inputClass="input magicflex-input-mixin is-danger"
        buttonStyle={styles.button}
        inputStyle={fieldStyle}
        specialLabel=""
        inputProps={{ name: field.name }}
        {...field}
        onChange={handleChange}
        country="us"
      />
      <ErrorMessage name={field.name} className="has-text-danger" component="span" />
    </div>
  );
};

export default PhoneInput;
