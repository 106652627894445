import { EventLocation } from "./StationsModal.types";

export const onChangeEvent = async (event: EventLocation) => {
  const { station, onChange } = event;
  onChange(station);
};

export const transformText = (areaName: string) => {
  return {
    smTitle: areaName.split("(")[1].split(")").join(""),
    bodyText: areaName.split("(")[0].split("-")[0],
  };
};
