import axios from "axios";

import { IData, PostDataElements as PostDataElements } from "./usePost.types";

export const postData = async (elements: PostDataElements): Promise<void> => {
  const { setDataPost, url, config, code, data } = elements;

  setDataPost((prev: any) => ({ ...prev, loading: true }));

  try {
    const res = await axios.post(url, data, config);

    if (res?.data) {
      setDataPost(
        (prev: IData): IData => ({
          ...prev,
          loading: false,
          data: res.data,
          status: res.status,
          success: true,
        }),
      );
    } else {
      setDataPost(
        (prev: IData): IData => ({
          ...prev,
          loading: false,
          data: {},
          error: {
            code,
            message: "No data found.",
          },
          status: res.status,
        }),
      );
    }
  } catch (error: any) {
    setDataPost({
      loading: false,
      data: [],
      error: {
        error,
        //this throws an error: toJSON is not a function
        // ...error.toJSON(),
        code:
          error.response?.status === 402 || error.response?.status === 403
            ? "MEMBERSHIP_REQUIRED"
            : code,
      },
      status: error.response?.status || 500,
      success: false,
    });
  }
};
export default postData;
