import { ValidateNumberElements } from "./useClock.types";

export const validateStringStatus = (value: string) => {
  return value === "__" || value === "0_" || value === "1_";
};

export const validateNumberMinutes = (elements: ValidateNumberElements) => {
  const { value, setNumber, setString } = elements;
  const isStringValid = validateStringStatus(value);

  if (!isStringValid) setNumber(Number(value));

  setString(value);
};

export const validateNumberHour = (elements: ValidateNumberElements) => {
  const { value, setNumber, setString } = elements;
  const valueS = value.replace("_", "");
  const isValueOver23 = Number(valueS[0]) === 2 && Number(valueS[1]) > 3;

  const isValue0 = Number(valueS[0]) === 0 && Number(valueS[1]) === 0;

  if (isValueOver23 || isValue0) return;

  const isStringValid = validateStringStatus(value);

  if (!isStringValid) setNumber(Number(value));

  setString(value);
};
