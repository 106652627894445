import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useIOWarning } from "../../../../../hooks";
import { ChargeTemplate } from "../../../../templates";
import SettingsLayout from "../../../settings/settingsLayout/SettingsLayout";

export type Location = { state: { openMenu: boolean; backUrl?: string } };

const ChargeWalletScreen = () => {
  const { state } = useLocation() as Location;
  const { t } = useTranslation(["common"]);
  useIOWarning();

  return (
    <div>
      <SettingsLayout
        title={t("common:payment-method")}
        openMenuOnBack={state?.openMenu}
        backUrl={state?.backUrl || "/"}
      >
        <ChargeTemplate />
      </SettingsLayout>
    </div>
  );
};
export default ChargeWalletScreen;
