import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useWindowSize } from "../../../hooks";
import { useBotConfig } from "../../../hooks/botConfig";
import { useModalInfoDispatch } from "../../../store/useDispatch";
import { NavbarBottom } from "../../organisms";

const BotLayout = () => {
  const { lostLimitReachedMutation, lostLimitReachedQuery } = useBotConfig();
  const { setModalInfoCD } = useModalInfoDispatch();

  const { height } = useWindowSize();

  useEffect(() => {
    if (!lostLimitReachedQuery.data) return;

    setModalInfoCD({
      enabled: true,
      handleAccept: () => {
        lostLimitReachedMutation.mutate({
          limitReached: false,
          humanCheckResolved: true,
        });
      },
      handleCancel: () => {
        lostLimitReachedMutation.mutate({
          limitReached: false,
          humanCheckResolved: false,
        });
      },
      handleClose: () => {
        lostLimitReachedMutation.mutate({
          limitReached: false,
          humanCheckResolved: false,
        });
      },
      modalType: "amazon-human-verify",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostLimitReachedQuery.data]);

  return (
    <div
      className="is-flex is-flex-direction-column"
      style={{
        minHeight: height,
      }}
    >
      <Outlet />
      <NavbarBottom />
    </div>
  );
};
export default BotLayout;
