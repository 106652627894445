import { useState } from "react";
import { useSelector } from "react-redux";

import useSetConfigQueries from "../useSetConfigQueries";
import { IuseSetConfigQueries } from "../useSetConfigQueries.types";
import useVerifyMaintenance from "../useVerifyMaintenance";
import getData from "./useGet.function";
import { GetElements, IData, IuseGet, IuseGetElements } from "./useGet.types";

const useGet = (elments: IuseGetElements): IuseGet => {
  const AmzUserId = useSelector((state: Storage) => state?.amzAccount?.amzAccount?._id);

  const [data, setData] = useState<IData>({
    loading: false,
    error: "",
    data: {},
    status: 0,
    success: false,
  });

  useSetConfigQueries({
    data,
    typeError: "get",
    ...elments,
  } as IuseSetConfigQueries);

  useVerifyMaintenance({
    requestCompleted: data.status !== 0,
  });

  return {
    get: ({ url, code, pathData, config }: GetElements): void => {
      getData({
        setData,
        url,
        config: {
          headers: {
            AmzUserId,
          },
          withCredentials: true,
          ...config,
        },
        code,
        pathData,
      });
    },
    data,
    clearResp: (): void =>
      setData({ loading: false, error: "", data: {}, status: 0, success: false }),
  };
};

export default useGet;
