import { envs } from "../../../../../config/env";
import usePost from "../../../../../https/hooks/post/usePost";

type DiscardBlockParams = {
  startTime: number;
  price?: number;
  serviceAreaId?: string;
};

const useDiscardBlock = () => {
  const { post, dataPost } = usePost({ spinnerFullScreen: true });

  const discardBlock = async (data: DiscardBlockParams) => {
    post({
      code: "P-DISCARD-BLOCK",
      data,
      url: envs.URL_API + "/V1/getOffers/cancelBlock",
    });
  };

  return {
    discardBlock,
    discardBlockResp: dataPost,
  };
};

export default useDiscardBlock;
