import { useEffect } from "react";

import { useModalInfoDispatch } from "../../../../store/useDispatch";

const REFRESH_TIME = 1000 * 60 * 60 * 24 * 7; // 7 days

export const useMissingStationPopup = () => {
  const { setModalInfoCD } = useModalInfoDispatch();

  useEffect(() => {
    const missingLocationLastShown = localStorage.getItem("missingLocationLastShown");

    if (
      missingLocationLastShown &&
      new Date().getTime() - new Date(missingLocationLastShown).getTime() < REFRESH_TIME // 7 days
    ) {
      return;
    }

    localStorage.setItem("missingLocationLastShown", new Date().toISOString());

    setModalInfoCD({
      enabled: true,
      modalType: "missing-io-station",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
