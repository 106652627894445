import _ from "lodash";
import moment from "moment";

import { useTimePikerDispatch } from "../../../../store/useDispatch";
import { IStartAtDayCheckProps } from "../startAtDayCheck.types";

const format = "hh:mm a";

const useStartAtDayCheck = (props: IStartAtDayCheckProps) => {
  const { selected, handleSelect, handleChangeTime } = props;
  const { start, end, day } = props.day;
  const dayStr = _.capitalize(moment().day(day).format("dddd"));

  const { setTimePikerD } = useTimePikerDispatch();

  const startDate = moment({ hour: start.hour, minutes: start.minutes }).format(format);
  const endDate = moment({ hour: end.hour, minutes: end.minutes }).format(format);

  const openSelectRange = () => {
    if (selected) return;

    setTimePikerD({
      open: true,
      selectedDay: day,
      onFinish: (rangeTime) => {
        const start = moment(rangeTime.start, "hh:mm A").toDate();
        const end = moment(rangeTime.end, "hh:mm A").toDate();
        handleChangeTime(start, end);
      },
      rangeTime: {
        start: moment({ hour: start.hour, minutes: start.minutes }).toDate(),
        end: moment({ hour: end.hour, minutes: end.minutes }).toDate(),
      },
    });
  };

  const selectDay = () => {
    handleSelect(props.day);
  };

  return {
    selectDay,
    openSelectRange,
    dayStr,
    startDate,
    endDate,
    selected,
    day,
  };
};

export default useStartAtDayCheck;
