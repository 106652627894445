import { useMemo } from "react";

import { CrispAdapter } from "../../../../adapters";
import { useAmzAccountSelector, useUserSelector } from "../../../../store/useSelector";
import IconCustom from "../../icons/IconCustom";
import { TypeIcon } from "../../icons/icons.type";
import SpinnerMagic from "../../spinnerMagic/SpinnerMagic";
import { IHelpButtonProps } from "./helpButton.types";
import useCheckChatReady from "./hooks/useCheckChatReady";

const HelpButton = (props: IHelpButtonProps) => {
  const { isReady } = useCheckChatReady();
  const { user } = useUserSelector();
  const { amzAccount } = useAmzAccountSelector();

  const typeIcon = useMemo(
    (): TypeIcon => (isReady ? "flexi-customer-support" : "magic-flex-icon"),
    [isReady],
  );

  if (!isReady) {
    return <SpinnerMagic height={Number(props.height)} width={Number(props.width)} />;
  }

  return (
    <IconCustom
      {...props}
      onClick={() =>
        CrispAdapter.getInstance(user?.email).openChat(amzAccount?.email, {
          userEmail: user?.email ?? "",
        })
      }
      typeIcon={typeIcon}
      className={`mf-help-btn ${props.className || ""}`}
    />
  );
};

HelpButton.defaultProps = {
  onClick: () => CrispAdapter.getInstance().openChat(),
  typeIcon: "flexi-customer-support",
  width: 42,
  height: 42,
} as IHelpButtonProps;

export default HelpButton;
