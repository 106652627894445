import "./navbar.styles.scss";

import { HelpButton, NavbarPhoto, Typhography } from "../../atoms";
import { NavbarUserInfo } from "../../molecules";
import useNavbar from "./hooks/useNavbar";
import { NavbarBottomMessage, NavbarProps } from "./navbar.types";

const NavbarMessage = (props: NavbarBottomMessage) => {
  const { color, className, text, onClick } = props;

  return (
    <div
      role="button"
      tabIndex={0}
      className={`mf-navbar-bottom mf-bg-${color || "info"}`}
      onClick={onClick}
      onKeyUp={onClick}
    >
      <Typhography
        type="p-s-r"
        className={"has-text-centered is-block has-text-white " + className}
      >
        {text}
      </Typhography>
    </div>
  );
};

const Navbar = (props: NavbarProps) => {
  const { message } = props;
  const { openMenu } = useNavbar();

  return (
    <div className={`mx-2 mf-navbar ${message ? "mb-4" : ""}`}>
      <div
        className={
          "mf-navbar-top is-flex has-background-white is-justify-content-space-between is-align-items-center py-3 px-3 mt-2 " +
          (message ? "mf-navbar-top-message" : "")
        }
      >
        <div className="is-flex">
          <NavbarPhoto />
          <div
            role="button"
            tabIndex={0}
            onKeyUp={openMenu}
            className="pl-2 mg-nav-menu"
            onClick={openMenu}
          >
            <NavbarUserInfo />
          </div>
        </div>

        <div className="is-flex is-align-items-center is-justify-content-center">
          {/* <StatusSwitch /> */}
          <HelpButton className="ml-1" />
        </div>
      </div>
      {message && <NavbarMessage {...message} />}
    </div>
  );
};

export default Navbar;
