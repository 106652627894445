import { envs } from "../../../../../config/env";
import usePost from "../../../../../https/hooks/post/usePost";
import { Day } from "./formik/useFormikDays.types";

const usePostSaveStartAt = () => {
  const { dataPost, post } = usePost({ spinnerFullScreen: true });

  const saveStartAtFilter = (days: Day[]) => {
    post({
      code: "P-DAY",
      url: envs.URL_API + "/V1/userFilters/startAt",
      data: {
        startAt: days,
      },
    });
  };

  return { saveStartAtFilter, saveStartAtFilterResp: dataPost };
};

export default usePostSaveStartAt;
