import { ConceptOptionProps } from "../conceptOption/conceptOptionCheck.type";
import { LabelRadioProps } from "../labelRadio/labelRadio.types";

export type BlocksOptionsInput = Array<LabelRadioProps>;
export type BlocksOptionsCheck = Array<ConceptOptionProps>;
export interface FormSetUpPriceProps {
  blockType?: "input" | "check";
  blockOptions: BlocksOptionsInput | BlocksOptionsCheck;
  className?: string;
  enabledPrice?: boolean;
}

export const defaultProps: FormSetUpPriceProps = {
  blockType: "input",
  blockOptions: [
    {
      input: {
        onChange: () => null,
        name: "time",
        value: 4.5,
        type: "radio",
      },
      smTitle: "4:30",
      bodyText: "Four Hours and a half",
    },
    {
      input: {
        onChange: () => null,
        name: "time",
        value: 4.5,
        type: "checkbox",
      },
      bodyText: "Tuesday",
    },
  ],
};
