import moment from "moment";
import { useTranslation } from "react-i18next";

import { Station } from "../../../../../store/types/filters.types";
import { useFiltersSelector } from "../../../../../store/useSelector";
import { isDaysAtSameTime } from "./useSelectedFilters.functions";

const useSelectedStations = (stations?: Station[]) => {
  const counterStations = stations?.filter((s) => s.state).length;
  const { t } = useTranslation(["filters"]);

  if (counterStations === 0)
    return { selectedStationsLabel: t("filters:no-location-selected") };

  const selectedStationsLabel =
    counterStations === stations?.length
      ? t("filters:all-location")
      : `(${counterStations}) ${t("filters:location-selected")}`;

  return {
    selectedStationsLabel,
  };
};

const useSelectedDuration = () => {
  const { filters } = useFiltersSelector();
  const { t } = useTranslation(["filters"]);

  const startPrice = filters?.data?.price;

  return startPrice > 0
    ? `($${startPrice}) ${t("filters:price-selected")}`
    : t("filters:all-price");
};

const useStartAt = () => {
  const { startAt } = useFiltersSelector();
  const { t } = useTranslation(["common", "filters"]);

  let startAtLabel = t("common:disabled");

  if (!startAt.days || startAt.days.length === 0) return { startAtLabel };

  const atSameTime = isDaysAtSameTime(startAt.days);
  const first = startAt.days[0].start;

  const minutes = first.minutes >= 10 ? first.minutes : `0${first.minutes}`;

  const hour = first.hour >= 10 ? first.hour : `0${first.hour}`;

  if (startAt.days.length === 7) {
    startAtLabel = atSameTime
      ? t("filters:selected-days.everyday", { hour, minutes })
      : t("filters:selected-days.everyday-diff");
  } else {
    const daysLabel = startAt.days
      .map((day) => `${moment().day(day.day).format("ddd")}`)
      .join(", ");

    startAtLabel = atSameTime
      ? t("filters:selected-days.day", { days: daysLabel, hour, minutes })
      : t("filters:selected-days.day-diff", { days: daysLabel });
  }

  return {
    startAtLabel,
  };
};

export { useSelectedDuration, useSelectedStations, useStartAt };
