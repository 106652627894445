import { TitleLabelProps } from "../../../molecules/titleLabel/titleLabel.types";

export type BlockPropertyLabel = TitleLabelProps;

export type BlockDetailsProps = {
  blockId: number;
  blockPropertiesLabels: BlockPropertyLabel[]; //TODO: only values, not title and icon
  locationName: string;
  locationId: string;
  lat: number;
  lng: number;
  serviceAreaId?: string;
  price?: number;
};

export const BlockDetailsDefaultProps: BlockDetailsProps = {
  blockPropertiesLabels: [
    {
      icon: "dollar",
      title: "Expected Price",
      description: "$47 - $78",
    },
    {
      icon: "hand-holding-heart",
      title: "Expected Tip",
      description: "$30",
    },
    {
      icon: "clock",
      title: "Arriving time",
      description: "Today, from 2:30 p.m. to 4:30 p.m.",
    },
  ],
  locationName: "Laguna Niguel",
  locationId: "CO10",
  lat: 33.5,
  lng: -117.7,
  blockId: 1,
};
