import { cleanTimePiker, setTimePiker } from "../reducers/timerPiker/actions";
import { SetActionTimePiker } from "../types/timePiker.type";
import useActions from "./useActions";

// Here is nesesary to configure all the actions
const useTimePikerDispatch = () => {
  const setTimePikerD: SetActionTimePiker = useActions(setTimePiker);
  const cleanTimePikerD = useActions(cleanTimePiker);
  return {
    setTimePikerD,
    cleanTimePikerD,
  };
};

export default useTimePikerDispatch;
