import { MenuProps } from "./menu.type";

export const MenuDefaultProps: MenuProps = {
  isOpen: true,
  sections: [
    {
      title: "Account Settings",
      items: [
        {
          icon: { typeIcon: "man-head" },
          label: "Account details",
        },
        {
          icon: { typeIcon: "package" },
          label: "Amazon Accounts",
        },
      ],
    },
    {
      title: "About",
      items: [
        {
          icon: { typeIcon: "man-head" },
          label: "About",
        },
      ],
    },
  ],
};
