import "./amzAccountsItem.styles.scss";

import React from "react";

import { IconCustom } from "../../../atoms";
import MenuItem from "../../menuItem/MenuItem";
import { amzAccountsItemDefaultProps } from "./amzAccountsItem.constants";
import { AmzAccountsItemProps } from "./amzAccountsItem.types";
import useAmzAccountsItem from "./hooks/useAmzAccountsItem";

const AmzAccountsItem = (props: AmzAccountsItemProps) => {
  const { email, onDelete, onSelect, selectedClasses } = useAmzAccountsItem(props);

  return (
    <div className={"mf-amz-accounts-item-container " + selectedClasses}>
      <MenuItem
        label={email}
        onClick={onSelect}
        className="mf-amz-accounts-item-info py-4 "
      />

      <div className="mf-amz-accounts-item-buttons">
        <IconCustom typeIcon="trash" width={18} onClick={onDelete} />
      </div>
    </div>
  );
};

AmzAccountsItem.defaultProps = amzAccountsItemDefaultProps;

export default AmzAccountsItem;
