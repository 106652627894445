import { BadgeProps } from "../../atoms/badge/badge.type";

type Label = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

type Input = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface LabelRadioProps {
  type?: "normal" | "membership" | "day";
  label?: Label;
  smTitle?: string;
  bodyText: string;
  badge?: BadgeProps;
  input?: Input;
  className?: string;
}

export const defaultProps: LabelRadioProps = {
  label: {},
  input: {},
  smTitle: "",
  bodyText: "",
  type: "normal",
};
