import "./titleModalInfo.styles.scss";

import { TitleModalInfoProps } from "./titleModalInfo.types";
const TitleModalInfo = (props: TitleModalInfoProps): JSX.Element => {
  return <p className="titel-modal-info">{props.children || props.title}</p>;
};

TitleModalInfo.defaultProps = {
  title: "Example",
} as Partial<TitleModalInfoProps>;

export default TitleModalInfo;
