export const getStyleRotation = (element: HTMLElement) => {
  let rotate = 0;

  const transform = element.style.getPropertyValue("transform");

  if (transform.includes("rotate")) {
    rotate = Number(transform.split("rotate(")[1].split("deg)")[0]);
  }

  return rotate;
};

export const calcRotationDuration = (rotate: number) => {
  if (rotate % 60 === 0)
    return {
      rotate,
      duration: 0,
    };

  const rest = rotate % 60;

  return {
    rotate: rotate - rest,
    duration: rest / 59,
  };
};
