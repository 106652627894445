import { Outlet } from "react-router-dom";

import useRegisterDevice from "./hooks/useRegisterDevice";

const AmzRegisterLayout = () => {
  useRegisterDevice();

  return <Outlet />;
};
export default AmzRegisterLayout;
