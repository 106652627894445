import { IconCustom } from "../../atoms";
import useNavbarUserInfo from "./hooks/useNavbarUserInfo";
import { navbarUserInfoDefaultProps, NavbarUserInfoProps } from "./navbarUserInfo.types";

const NavbarUserInfo = (props: NavbarUserInfoProps) => {
  const { fullName, email } = useNavbarUserInfo();
  return (
    <>
      <div className="is-flex">
        <h6 className="is-size-6 magicflex-text-sora has-text-weight-bold">
          {fullName || props.fullName}
        </h6>
        <IconCustom typeIcon="angle-down" width={24} height={24} />
      </div>
      <p className="is-size-7" style={{ lineHeight: "0.6rem" }}>
        {email || props.email}
      </p>
    </>
  );
};

NavbarUserInfo.defaultProps = navbarUserInfoDefaultProps;

export default NavbarUserInfo;
