import "./welcomeFeatureItem.styles.scss";

import { IconCustom } from "../../atoms";
import { WelcomeFeatureItemProps } from "./welcomeFeatureItem.types";

const WelcomeFeatureItem = (props: WelcomeFeatureItemProps): JSX.Element => {
  const { icon, title, description } = props;

  return (
    <div className="is-flex has-text-left is-size-7 py-1">
      <IconCustom typeIcon={icon} className="has-text-black" />

      <div className="pl-3 ">
        <h6 className="welcome-feture-item-title has-text-black has-text-weight-bold magicflex-text-sora is-uppercase">
          {title}
        </h6>
        <p className="magicflex-text-secondary">{description}</p>
      </div>
    </div>
  );
};

WelcomeFeatureItem.defaultProps = {
  title: "Title",
  description: "Description",
} as Partial<WelcomeFeatureItemProps>;

export default WelcomeFeatureItem;
