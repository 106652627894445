import { useCallback } from "react";
import { useSelector } from "react-redux";

import useNotificationsDispatch from "../../../../store/useDispatch/useNotificationsDispatch";
import { ReturnUseNotificationType, StorgaeType } from "../notification.type";

const useNotification = (): ReturnUseNotificationType => {
  const notificationC = useSelector((state: StorgaeType) => state?.notificationC);
  const { setNotificationCD } = useNotificationsDispatch();

  const handleClose = useCallback(() => {
    setNotificationCD({ ...notificationC, enabled: false });
  }, [notificationC, setNotificationCD]);

  return { notificationC, handleClose };
};

export default useNotification;
