import type { PaymentAF, PaymentState } from "../../types/payment.types";

export const initPayment: PaymentAF<PaymentState> = (state, action) => {
  if (!action.payload) return;
  state.amzUserId = action.payload.amzUserId;
  state.membershipTypeId = action.payload.membershipTypeId;
  state.amount = action.payload.amount;
  state.paymentType = action.payload.paymentType;
};

export const cleanPayment: PaymentAF = (state) => {
  state.amzUserId = undefined;
  state.membershipTypeId = undefined;
  state.amount = undefined;
  state.paymentType = undefined;
};
