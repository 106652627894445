import { AmzAccount } from "../../../hooks/useAmzAccount/useAmzAccount.types";
import { AmzAccountAF, Device } from "../../types/amzAccount.types";
import { Stations } from "../../types/filters.types";

export const setExtraData: AmzAccountAF<any> = (state, action) => {
  state.extraData = action.payload;
};

export const setAmzAccount: AmzAccountAF<AmzAccount> = (state, action) => {
  state.amzAccount = action.payload;
};

export const deleteAmzAccount: AmzAccountAF = (state) => {
  state.amzAccount = undefined;
  state.extraData = undefined;
};

export const addStations: AmzAccountAF<Stations> = (state, action) => {
  if (state.amzAccount) state.amzAccount.stationsAvailables = action.payload || [];
};

export const setDevices: AmzAccountAF = (state, action) => {
  state.devices = action.payload;
};

export const addDevice: AmzAccountAF<Device> = (state, action) => {
  if (!action.payload) return;

  if (!state.devices) {
    state.devices = [action.payload];
    return;
  }

  const exists = state.devices.find((d) => d._id === action.payload?._id);

  if (!exists) state.devices.push(action.payload);
};

export const reset: AmzAccountAF = (state) => {
  state.amzAccount = undefined;
  state.extraData = undefined;
  state.devices = undefined;
};
