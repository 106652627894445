const field: React.CSSProperties = {
  alignItems: "center",
  backgroundColor: "hsl(0deg, 0%, 100%)",
  border: "2px solid transparent",
  borderRadius: "10px",
  boxShadow: "4px 4px 0px rgb(217 223 228 / 25%)",
  color: "hsl(0deg, 0%, 21%)",
  display: "inline-flex",
  fontFamily: '"Lato", sans-serif',
  fontSize: "1rem",
  height: "2.5em",
  justifyContent: "flex-start",
  lineHeight: "1.5",
  margin: "0",
  maxWidth: "100%",
  paddingBottom: "calc(0.5em - 2px)",
  paddingRight: "calc(0.75em - 2px)",
  paddingTop: "calc(0.5em - 2px)",
  position: "relative",
  verticalAlign: "top",
  WebkitAppearance: "none",
  width: "100%",
};

const fieldError: React.CSSProperties = {
  ...field,
  border: "2px solid #ff6464",
};

const fieldSuccess: React.CSSProperties = {
  ...field,
  border: "2px solid #00c363",
};

const styles = {
  field,
  fieldError,
  fieldSuccess,
};

export default styles;
