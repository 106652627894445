import React from "react";

import Typhography from "../typhography/Typhography";

type Props = {
  title: string;
};

const Title = ({ title }: Props) => {
  return <Typhography type="h-1">{title}</Typhography>;
};

export default Title;
