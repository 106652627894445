import { useMemo } from "react";

import { RootState } from "../types/store.types";
import { UseTimePikerSelector } from "../types/timePiker.type";
import useAppSelector from "./useSelector";

const selectTimePikerState = (state: RootState) => state.timePikerState;

const useTimePikerSelector: UseTimePikerSelector = () => {
  const timePikerState = useAppSelector(selectTimePikerState);

  return useMemo(() => {
    return {
      timePikerState,
    };
  }, [timePikerState]);
};

export default useTimePikerSelector;
