import { useEffect } from "react";

import { envs } from "../../../config/env";
import { useSocket } from "../../../hooks";
import { useUserSelector } from "../../../store/useSelector";

const useRegisterSocket = () => {
  const { user } = useUserSelector();
  const { socket } = useSocket(envs.URL_WS + "/register");

  useEffect(() => {
    user && socket.connected && socket.emit("subscribe", user._id);

    return () => {
      user && socket.connected && socket.emit("unsubscribe", user._id);
    };
  }, [socket, socket.connected, user]);

  return { socket };
};

export default useRegisterSocket;
