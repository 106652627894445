import { useEffect, useMemo } from "react";

import { envs } from "../../../../../../config/env";
import useGet from "../../../../../../https/hooks/get/useGet";
import { Stations } from "../../../../../../store/types/filters.types";

const useGetStations = () => {
  const { data, get } = useGet({ spinnerFullScreen: true });

  useEffect(() => {
    get({
      url: envs.URL_API + "/V1/userFilters/getFilters",
      code: "G-USER-FILTERS",
      pathData: "data.data.stations",
    });
  }, []);

  const savedStations: Stations = useMemo(
    () => (data.success ? data.data : []),
    [data.data, data.success],
  );

  return {
    savedStations,
  };
};

export default useGetStations;
