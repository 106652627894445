import { envs } from "../../config/env";
import useGet from "../../https/hooks/get/useGet";

const API_URL = envs.URL_API + "/V1";

const useOfferApi = () => {
  const { get: getCalendarOffers, data: calendarOffersResp } = useGet({});

  return {
    calendarOffersResp,
    getCalendarOffers: () =>
      getCalendarOffers({
        url: API_URL + "/getOffers/calendar",
        code: "G_CALENDAR_OFFERS",
      }),
  };
};

export default useOfferApi;
