import moment from "moment";

import { useMembershipQuery } from "../../../../../../hooks/membership/queries";
import { useAmzAccountSelector } from "../../../../../../store/useSelector";

const useMembershipTime = () => {
  const { amzAccount } = useAmzAccountSelector();
  const { membership } = useMembershipQuery(amzAccount?._id);

  const now = moment();
  const expirationTime = moment(membership?.expirationDate);
  const hourDiff = expirationTime.diff(now, "hours");

  const remainingTime = {
    days: Math.floor(hourDiff / 24),
    hours: hourDiff % 24,
  };

  return {
    remainingTime,
    expirationTime,
    hourDiff,
  };
};

export default useMembershipTime;
