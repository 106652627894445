import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useWindowSize } from "../../../../../hooks";
import { PaymentType } from "../../../../../hooks/payments/types";
import { Button, Typhography } from "../../../../atoms";
import { RadioInput } from "../../../../molecules";
import { PendingPayments } from "../PendingPayments";
import { useSubscriptionPayment } from "./hooks";

const SubscriptionPayment = () => {
  const { t } = useTranslation(["membership", "common"]);
  const { height } = useWindowSize();

  const { onSubmit } = useSubscriptionPayment();

  return (
    <div>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          type: PaymentType.PAS45,
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required(
            t("common:form.required", { name: t("common:pass") }),
          ),
        })}
      >
        {({ values, handleChange }) => (
          <Form>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={height - 130}
            >
              <Box>
                <Typhography type="h-2" className="mb-5">
                  {t("common:pass")}
                </Typhography>
                <Box mb={5}>
                  <Typhography type="h-2" className="mb-5">
                    {t("membership:subscription.select")}
                  </Typhography>
                  <RadioInput.Formik
                    className="my-3 py-2 px-3"
                    input={{
                      name: "type",
                      value: PaymentType.PAS45,
                      checked: values.type === PaymentType.PAS45,
                      onChange: handleChange,
                    }}
                  >
                    <div className="is-flex is-align-items-center ml-3">
                      <Box minWidth={110}>
                        <Typhography type="h-1" className="mf-text-secondary mr-3">
                          1 {t("common:week")}:
                        </Typhography>
                      </Box>
                      <Typhography type="h-2" className="mf-text-secondary pt-1">
                        $
                      </Typhography>
                      <Typhography type="h-1" className="is-size-4">
                        {45}
                      </Typhography>
                    </div>
                  </RadioInput.Formik>

                  <RadioInput.Formik
                    className="my-3 py-2 px-3"
                    input={{
                      name: "type",
                      value: PaymentType.PAS80,
                      checked: values.type === PaymentType.PAS80,
                      onChange: handleChange,
                    }}
                  >
                    <div className="is-flex is-align-items-center ml-3">
                      <Box minWidth={110}>
                        <Typhography type="h-1" className="mf-text-secondary mr-3">
                          2 {t("common:week", { count: 2 })}:
                        </Typhography>
                      </Box>
                      <Typhography type="h-2" className="mf-text-secondary pt-1">
                        $
                      </Typhography>
                      <Typhography type="h-1" className="is-size-4">
                        {80}
                      </Typhography>
                    </div>
                  </RadioInput.Formik>
                  <RadioInput.Formik
                    className="my-3 py-2 px-3"
                    input={{
                      name: "type",
                      value: PaymentType.PAS140,
                      checked: values.type === PaymentType.PAS140,
                      onChange: handleChange,
                    }}
                  >
                    <div className="is-flex is-align-items-center ml-3">
                      <Box minWidth={110}>
                        <Typhography type="h-1" className="mf-text-secondary mr-3">
                          1 {t("common:month")}:
                        </Typhography>
                      </Box>
                      <Typhography type="h-2" className="mf-text-secondary pt-1">
                        $
                      </Typhography>
                      <Typhography type="h-1" className="is-size-4">
                        {140}
                      </Typhography>
                    </div>
                  </RadioInput.Formik>
                </Box>

                <PendingPayments />
              </Box>

              <Button type="submit">{t("common:continue")}</Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubscriptionPayment;
