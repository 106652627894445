import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useRegisterSocketContext } from "../../../../contexts/registerSocket/RegisterSocketContext";
import {
  useModalInfoDispatch,
  useRegisterDispatch,
  useStateSpinnerFullScreen,
} from "../../../../store/useDispatch";
import useSaveAmzUser from "../../../pages/settings/registerAmzAccountScreen/hooks/useSaveAmzUser";
import { showRegisterError } from "../../../pages/settings/registerAmzAccountScreen/registerAmzAcc.functions";

const useRegisterDevice = () => {
  const { t } = useTranslation(["register", "common"]);
  const { registerSocket } = useRegisterSocketContext();
  const { saveUser, isUserSaved } = useSaveAmzUser();

  const navigate = useNavigate();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { cleanSpinnerFullScreenD } = useStateSpinnerFullScreen();
  const { cleanD } = useRegisterDispatch();

  const goToAccounts = useCallback(() => {
    navigate("/accounts", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeviceRegistered = useCallback(() => {
    registerSocket.on("registerDevice", async (data) => {
      cleanD();
      if (data.error) {
        cleanSpinnerFullScreenD();
        // showRegisterError({
        //   error: data.error?.description || t("register:register-error"),
        //   setModalInfoCD,
        //   t,
        // });

        return goToAccounts();
      }

      if (data.amzUser) saveUser(data.amzUser);
    });
  }, [registerSocket, cleanSpinnerFullScreenD, setModalInfoCD, goToAccounts]);

  useEffect(() => {
    if (isUserSaved) {
      cleanSpinnerFullScreenD();
      goToAccounts();
    }
  }, [isUserSaved, goToAccounts, cleanSpinnerFullScreenD]);

  useEffect(() => {
    onDeviceRegistered();

    return () => {
      registerSocket?.removeAllListeners("registerDevice");
    };
  }, [registerSocket, onDeviceRegistered]);
};

export default useRegisterDevice;
