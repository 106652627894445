import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useModalInfoDispatch } from "../../../../../store/useDispatch";
import { useFiltersDispatch } from "../../../../../store/useDispatch";
import { useFiltersSelector } from "../../../../../store/useSelector";
import { IStartAtDayCheckProps } from "../../../../molecules/startAtDayCheck/startAtDayCheck.types";
import usePostSaveStartAt from "../../setUpDays/hooks/usePostSaveStartAt";
import { initialDays } from "../startAt.constants";
import { validateRange } from "../startAt.functions";

type Props = {
  onClose?: () => void;
};

const useSelectDays = (props: Props) => {
  const { onClose } = props;
  const [daysChecks, setDayChecks] = useState<IStartAtDayCheckProps[]>([]);
  const { startAt } = useFiltersSelector();
  const { setStartAtD } = useFiltersDispatch();

  const { saveStartAtFilter } = usePostSaveStartAt();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { t } = useTranslation(["filters", "common"]);

  const selectDay = (day: number) => {
    setDayChecks((prev) =>
      prev.map((dayCheck) => {
        if (dayCheck.day.day === day)
          return { ...dayCheck, selected: !dayCheck.selected };

        return dayCheck;
      }),
    );
  };

  const changeTime = (day: number, start: Date, end: Date) => {
    setDayChecks((prev) =>
      prev.map((dayCheck) => {
        if (dayCheck.day.day === day)
          return {
            ...dayCheck,
            day: {
              ...dayCheck.day,
              start: { hour: start.getHours(), minutes: start.getMinutes() },
              end: { hour: end.getHours(), minutes: end.getMinutes() },
            },
          };

        return dayCheck;
      }),
    );
  };

  const getDaysChecks = useCallback(() => {
    const daysChecks = initialDays.map<IStartAtDayCheckProps>((day) => {
      const index = startAt.days.findIndex((dayCheck) => dayCheck.day === day.day.day);

      if (index === -1)
        return {
          ...day,
          selected: false,
          handleChangeTime: (start, end) => changeTime(day.day.day, start, end),
          handleSelect: () => selectDay(day.day.day),
        };

      return {
        selected: true,
        day: {
          ...day.day,
          end: startAt.days[index].end,
          start: startAt.days[index].start,
        },
        handleChangeTime: (start, end) => changeTime(day.day.day, start, end),
        handleSelect: () => selectDay(day.day.day),
      };
    });

    setDayChecks(() => daysChecks);
  }, [startAt.days]);

  const validateTimeRange = () => {
    const isInvalid = daysChecks.some((day) =>
      day.selected ? !validateRange(day.day.start, day.day.end) : false,
    );

    return !isInvalid;
  };

  const saveDays = () => {
    const isValid = validateTimeRange();

    if (isValid) {
      const days = daysChecks
        .filter(({ selected }) => selected)
        .map(({ day }) => ({
          day: day.day,
          start: day.start,
          end: day.end,
        }));
      setStartAtD(days);
      saveStartAtFilter(days);
      onClose?.();
      return;
    }

    setModalInfoCD({
      enabled: true,
      handleCancel: () => {
        onClose?.();
        getDaysChecks();
      },
      modalInfoProps: {
        title: t("filters:start-at.invalid-range"),
        body: t("filters:start-at.invalid-range-description"),
        buttonText: t("common:edit"),
        subButtomText: t("common:discard"),
        type: "warning",
        image: {
          typeImage: "flexi-danger",
        },
      },
    });
  };

  useEffect(() => {
    getDaysChecks();
  }, [getDaysChecks]);

  return {
    daysChecks,
    saveDays,
  };
};

export default useSelectDays;
