import "./BalanceInfo.styles.scss";

import _ from "lodash";
import { useTranslation } from "react-i18next";

import { AddBalanceBtn, Typhography } from "../../atoms";
import { IBalanceInfoProps } from "./balanceInfo.types";

const BalanceInfo = (props: IBalanceInfoProps) => {
  const balance = _.round(Math.abs(props.balance), 2) || 0;
  const isNegative = props.balance < 0;

  const { t } = useTranslation(["home"]);

  return (
    <div className="mf-bi_container">
      <div className="pl-4 mb-2">
        <Typhography type="p-s-b" className="mf-text-secondary">
          {t("home:wallet.current-balance")}
        </Typhography>
        <Typhography type="h-1" className={`${isNegative ? "negative" : ""}`}>
          {isNegative && "-"}${balance}
        </Typhography>
      </div>
      <AddBalanceBtn onClick={props.onClick} />
    </div>
  );
};
export default BalanceInfo;
