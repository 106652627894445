import omit from "lodash/omit";

import { IconCustomProps } from "./icons.type";
import Icons from "./iconslist";
// For add more iconss https://www.flaticon.com/uicons?word=info inside the inconslist
const IconCustom = (props: IconCustomProps): JSX.Element => {
  const Icon = Icons[props?.typeIcon || "interrogation"];

  return <Icon {...omit(props, ["icon", "typeIcon"])} />;
};

IconCustom.defaultProps = {
  width: "25",
  height: "25",
  typeIcon: "interrogation",
  alt: "",
  fill: "#37465C",
} as Partial<IconCustomProps>;

export default IconCustom;
