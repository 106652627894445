/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CSSProperties } from "react";

import { CloseBtnProps } from "./closeBtn.types";

const CloseBtn = (props: CloseBtnProps) => {
  const { color, size, moveLeft, moveTop, onClick } = props;

  const xMarkStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    left: `${size! * 0.4}rem`,
    backgroundColor: color,
    width: "0.16rem",
    height: `${size}rem`,
    borderRadius: "4px",
  };

  const xMarkConternerStyle: CSSProperties = {
    position: "relative",
    left: `${moveLeft}rem`,
    top: `${moveTop}rem`,
    width: `${size! * 0.84}rem`,
    height: `${size! * 0.82}rem`,
    cursor: "pointer",
  };

  return (
    <div role="button" tabIndex={0} onClick={onClick} style={xMarkConternerStyle}>
      <div style={{ ...xMarkStyle, transform: "rotate(45deg)" }}> </div>
      <div style={{ ...xMarkStyle, transform: "rotate(-45deg)" }}> </div>
    </div>
  );
};

CloseBtn.defaultProps = {
  color: "#A6B4C5",
  size: 2.5,
  moveLeft: 0,
  moveTop: -0.4,
} as CloseBtnProps;

export default CloseBtn;
