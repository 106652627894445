import "./switch.styles.scss";

import { defaultProps, InputProps } from "./switch.types";

const Switch = (props: InputProps): JSX.Element => {
  const { classNames, ...rest } = props;

  return (
    <label className={"switch " + classNames?.switch}>
      <input {...rest} type="checkbox" />
      <span className={"slider round " + classNames?.slider} />
    </label>
  );
};

Switch.defaultProps = defaultProps;

export default Switch;
