const windowT: any = window;

export class CrispAdapter {
  private static token: string | null = null;
  private static instance: CrispAdapter | null = null;

  private constructor() {
    this.setUpEvents();
  }

  public static getInstance(newToken?: string) {
    if (!CrispAdapter.instance) {
      CrispAdapter.instance = new CrispAdapter();
    }

    if (newToken && newToken !== CrispAdapter.token) {
      CrispAdapter.token = newToken;
      windowT.CRISP_TOKEN_ID = newToken;
      CrispAdapter.instance.resetSession();
    }

    return CrispAdapter.instance;
  }

  public openChat(email?: string, data: Record<string, string> = {}) {
    if (email) {
      this.setUserEmail(email);
    }

    if (data) {
      this.setSessionData(data);
    }

    windowT.$crisp.push(["do", "chat:open"]);
  }

  private hideCrispBtn() {
    const crispBtn = windowT.document.querySelector("#crisp-chatbox > div > a");
    crispBtn.setAttribute("style", "display: none !important");
  }

  private hideRunInCrispLink() {
    const runInCrispLink = windowT.document.querySelector(
      "#crisp-chatbox > div > div > div.cc-gye0 > div > div.cc-15ak > div.cc-acjo > a",
    );

    runInCrispLink.setAttribute("style", "display: none !important");
  }

  private showCrispBtn() {
    const crispBtn = windowT.document.querySelector("#crisp-chatbox > div > a");
    crispBtn.setAttribute("style", "display: block !important");
  }

  private setUpEvents() {
    windowT.$crisp.push([
      "on",
      "chat:opened",
      () => {
        this.hideRunInCrispLink();
        this.showCrispBtn();
      },
    ]);

    windowT.$crisp.push([
      "on",
      "chat:closed",
      () => {
        this.hideCrispBtn();
      },
    ]);
  }

  private setSessionData(data: Record<string, string>) {
    if (!data) return;

    const parsedData = this.parseData(data);

    windowT.$crisp.push(["set", "session:data", [parsedData]]);
  }

  private setUserEmail(email: string) {
    windowT.$crisp.push(["set", "user:email", [email]]);
  }

  private parseData(data: Record<string, string>): Array<[string, string]> {
    return Object.entries(data).map(([key, value]) => [key, value]);
  }

  private resetSession() {
    windowT.$crisp.push(["do", "session:reset"]);
  }

  closeSession() {
    try {
      CrispAdapter.token = null;
      CrispAdapter.instance = null;
      windowT.CRISP_TOKEN_ID = null;
      this.resetSession();
    } catch (error) {
      console.log(error);
    }
  }
}
