import { useTranslation } from "react-i18next";

import useInstantOffersSelector from "../../../../store/useSelector/useInstantOffersSelector";
import { Typhography } from "../../../atoms";
import Switch from "../../../atoms/switch/Switch";
import { ISwitchProps } from "./InstantOffers.types";

const InstantOffersSwitch = (props: ISwitchProps) => {
  const { onChange } = props;

  const { isActive } = useInstantOffersSelector();

  const { t } = useTranslation(["instant-offers", "common"]);

  return (
    <>
      <Typhography type="h-2" className="mf-text-secondary has-text-centered mb-2">
        {t("instant-offers:status")}
      </Typhography>
      <Typhography type="h-2" className="has-text-centered">
        {isActive ? t("common:enabled") : t("common:disabled")}
      </Typhography>

      <div className="is-flex is-justify-content-center mt-5">
        <Switch
          checked={isActive}
          classNames={{
            slider: "instant-offers",
            switch: "instant-offers",
          }}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default InstantOffersSwitch;
