import "./radioInput.styles.scss";

import { useField } from "formik";

import { IRadioInputProps } from "./radioInput.types";

const RadioInputFormik = (props: IRadioInputProps) => {
  const [field] = useField(props.input.name || "");

  return <RadioInput {...props} field={field} />;
};

const RadioInput = (props: IRadioInputProps) => {
  return (
    <label>
      <div className={`mf-radio-input ${props.className}`}>
        <input type="radio" {...props.field} {...props.input} />

        {props.children}
      </div>
    </label>
  );
};

RadioInput.defaultProps = {
  input: {},
  children: null,
};

RadioInput.Formik = RadioInputFormik;

export default RadioInput;
