import "./labelCheckbox.styles.scss";
import "./labelRadio.styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useField } from "formik";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Space, Typhography, TypographyOption } from "../../atoms";
import RadioInput from "../radioInput/RadioInput";
import DayLabelRadio from "./DayLabelRadio";
import { defaultProps, LabelRadioProps } from "./labelRadio.types";

const NormalLabelRadio = (props: LabelRadioProps) => {
  return (
    <div className="touch-area">
      <div className="grid-block">
        <div className="col-1">
          <input {...props.input} className="mg-input-checkbox" />
        </div>
        <div className="col-2">
          <TypographyOption
            smTitle={props.smTitle}
            bodyText={props.bodyText}
            className={props.className}
          />
        </div>
      </div>
    </div>
  );
};

const CheckTermsOfServicesLabelRadio = (props: LabelRadioProps) => {
  const [field, meta] = useField({ name: "acceptTerms" });

  const { t } = useTranslation(["common"]);

  return (
    <div className="touch-area">
      <div className="grid-block">
        <div className="col-1">
          <input
            {...field}
            type={"checkbox"}
            name="acceptTerms"
            className={
              "mg-input-checkbox " + (meta.error && meta.touched ? " is-invalid" : "")
            }
          />
          <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
        </div>
        <div className="col-2">
          <Link to="/info/terms-conditions" className="magicflex-create-account">
            {t("common:terms-conditions")} <Space amount={0.6} />
            <span className="is-right is-size-7">
              <FontAwesomeIcon icon="chevron-right" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

const MembershipLabelRadio = (props: LabelRadioProps) => {
  return (
    props.input && (
      <>
        {props.label?.children && (
          <Typhography type="p-l-b">{props.label.children}</Typhography>
        )}
        <RadioInput.Formik className="my-3 py-2 px-3" input={props.input}>
          <div className="is-flex is-align-items-center ml-3">
            <Typhography type="h-1" className="mf-text-secondary mr-2">
              {props.smTitle}
            </Typhography>
            <Typhography type="h-1" className="is-size-4">
              {props.bodyText}
            </Typhography>
          </div>
        </RadioInput.Formik>
      </>
    )
  );
};
/**
 * This component load the days options when the option.type === day
 */
const LabelRadio = (props: LabelRadioProps): JSX.Element => {
  const options = {
    normal: NormalLabelRadio,
    membership: MembershipLabelRadio,
    termsOfService: CheckTermsOfServicesLabelRadio,
    day: DayLabelRadio,
  };

  const RenderLabel = options[
    props?.type || "normal"
  ] as typeof DayLabelRadio as typeof NormalLabelRadio;

  return (
    <label htmlFor={props.input?.name} {...props.label}>
      <RenderLabel {...props} />
    </label>
  );
};
LabelRadio.defaultProps = defaultProps;

export default LabelRadio;
