import { magicAPI } from "../../api";
import { IStartTime } from "./filter.types";

export const getStartTime = async (): Promise<IStartTime> => {
  const { data } = await magicAPI.get("/userFilters/startTime");

  return data.data;
};

export const setStartTime = async (startTime: IStartTime["startTime"]) => {
  const { data } = await magicAPI.post("/userFilters/startTime", {
    startTime,
  });

  return data.data;
};

export const setStartTimeEnabled = async (enabled: boolean) => {
  const { data } = await magicAPI.post("/userFilters/startTime", {
    enabled,
  });

  return data.data;
};

export const getBufferTime = async (): Promise<number> => {
  const { data } = await magicAPI.get("/userFilters/bufferTime");

  return data.data;
};

export const setBufferTime = async (bufferTime: number) => {
  const { data } = await magicAPI.post("/userFilters/bufferTime", {
    bufferTime,
  });

  return data.data;
};
