import { toWords } from "number-to-words";

import { BlocksOptions } from "../../formSetUpStartPrice.types";
import { IonChange } from "./setUpStartPriceForm.type";

// Generate a inputs for a prices.
export const generateBlocks = (
  onChangeEvent: IonChange,
  price: number,
): BlocksOptions => {
  const list: BlocksOptions = [];
  for (let value = 15; value <= 140; value += 5) {
    list.push({
      input: {
        onChange: (e) => onChangeEvent(e),
        name: "price",
        value,
        type: "radio",
        checked: Number(price) === value,
      },
      smTitle: `${toWords(value)} dolars`,
      bodyText: "$" + new Intl.NumberFormat("en-US").format(value),
    });
  }

  return list;
};
