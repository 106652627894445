import { useTranslation } from "react-i18next";

import { FilterHeader } from "../../../molecules";
import StationsModal from "../../filters/stationsModal/StationsModal";
import Description from "./Description";
import { IInstantOffersProps } from "./InstantOffers.types";
import SelectStationsButton from "./SelectStations";
import Slider from "./Slider";
import Switch from "./Switch";

const InstantOffers = (props: IInstantOffersProps) => {
  const { t } = useTranslation(["instant-offers"]);

  return (
    <>
      <div className="container mb-3">
        <div className="mt-5 px-5 pb-5">
          <FilterHeader title={t("instant-offers:title")} />

          <main className="px-0">
            <Description />
            <SelectStationsButton {...props.stationsLabel} />

            <Slider {...props.slider} />

            <Switch {...props.switch} />
          </main>
        </div>
      </div>
      <StationsModal {...props.modal} />
    </>
  );
};
export default InstantOffers;
