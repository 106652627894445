import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { BackBtnProps } from "./backBtn.types";

const BackBtn = (props: BackBtnProps) => {
  const { hidden, route, text } = props;

  const navigate = useNavigate();

  const backNavigate = () => (route ? navigate(route) : navigate(-1));

  if (hidden) {
    return <div style={{ height: "24px" }}></div>;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={backNavigate}
      className="is-inline-block has-text-black"
      onClick={backNavigate}
    >
      <span className="icon is-size-7">
        <FontAwesomeIcon icon="chevron-left" />
      </span>

      {text && <span className="has-text-weight-bold">{text}</span>}
    </div>
  );
};

BackBtn.defaultProps = {
  hidden: false,
  route: "",
  text: "",
} as BackBtnProps;

export default BackBtn;
