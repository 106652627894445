import * as Yup from "yup";

import { VerifyOtpFormValues } from "../verifyOtpForm.types";
import { GetFormikConfig } from "./useVerifyOtpForm.types";

export const timeToString = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const getFormikConfig: GetFormikConfig = (onSubmit) => {
  const initialValues: VerifyOtpFormValues = {
    otp: "",
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("Code is required"),
  });

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};
