import { uiActions } from "../reducers/ui/ui";
import useActions from "./useActions";

const useUIDispatch = () => {
  return {
    hideProgressBarD: useActions(uiActions.hideProgressBar) as () => void,
    showProgressBarD: useActions(uiActions.showProgressBar) as (value: number) => void,
    updateProgressBarD: useActions(uiActions.updateProgressBar) as (
      value: number,
    ) => void,
    setShowMaintenanceD: useActions(uiActions.setShowMaintenance) as (
      value: boolean,
    ) => void,
    setAcceptedBalanceExpirationD: useActions(uiActions.setAcceptedBalanceExpiration) as (
      value: boolean,
    ) => void,
  };
};

export default useUIDispatch;
