import getAPI from "./getAPI";

const customFetch = async (
  url,
  { body, headers, method = "GET" } = { method: "GET" },
) => {
  return getAPI({
    method,
    resource: url,
    body: body ? JSON.stringify(body) : undefined,
    redirect: "follow",
    extraHeader: headers,
  });
};

const serviceAPI = {
  registerUser: async (user) =>
    customFetch("/V1/auth/register", {
      method: "POST",
      body: user,
    }),

  getUser: async () => customFetch("/V1/auth/getData"),

  loginUser: async (credentials, headers) =>
    customFetch("/V1/auth/login", {
      method: "POST",
      body: credentials,
      headers,
    }),

  forgotPassword: async (email) =>
    customFetch("/V1/auth/forgotPassword", {
      method: "POST",
      body: { email },
    }),

  verifyOTP: async (otp, email) =>
    customFetch("/V1/auth/verifyOtp", {
      method: "POST",
      body: { otp, email },
    }),

  verifyAmzOtp: async (otp, email) =>
    customFetch("/V1/authAmz/amzOtpLogin", {
      method: "POST",
      body: { otp, email },
    }),

  changePassword: async (newpassword, email) =>
    customFetch("/V1/auth/changePassword", {
      method: "POST",
      body: { newpassword, email },
    }),

  selectAmzAccount: async (email) =>
    customFetch("/V1/authAmz/selectAmzAccount", {
      headers: { email },
    }),

  deleteAmzAccount: async (id) =>
    customFetch(`/V1/authAmz/${id}`, {
      method: "DELETE",
    }),

  registerAmzAccount: async (credentials) =>
    customFetch("/V1/authAmz/amzregister", {
      method: "POST",
      body: credentials,
    }),

  getAmzAccounts: async () => customFetch("/V1/authAmz/getAmzUsers"),

  getElegibleAreas: async (amzUserId) =>
    customFetch("/V1/serviceAreaIds/getElegibleServicesAreasPool", {
      headers: { amzUserId },
    }),

  discardReservation: async (amzUserId, blockId) =>
    customFetch("/V1/getOffers/cancelBlock", {
      method: "DELETE",
      headers: { amzUserId, blockId },
    }),

  logout: async () => customFetch("/V1/auth/logout"),

  botState: async (selectedAccountId) =>
    customFetch("/V1/auth/botState", {
      headers: { amzuserid: selectedAccountId },
    }),

  deleteScheduleOrder: async (scheduleOrderId, selectedAccountId) =>
    customFetch(`/V1/scheduleOrder/${scheduleOrderId}`, {
      method: "DELETE",
      headers: { amzuserid: selectedAccountId },
    }),

  createScheduleOrder: async (scheduleBody) =>
    customFetch("/V1/scheduleOrder/", {
      method: "POST",
      body: scheduleBody,
    }),

  getActiveOrders: async (amzUserId) => customFetch(`/V1/scheduleOrder/${amzUserId}`),

  deleteBotOrder: async (amzUserId) =>
    customFetch(`/V1/scheduleOrder/bot/${amzUserId}`, {
      method: "DELETE",
    }),

  amzPerson: async (amzUserId) =>
    customFetch("/V1/authAmz/amzPerson", {
      headers: { amzUserId },
    }),

  getGeoCode: async (amzUserId, serviceAreaId) =>
    customFetch("/V1/serviceAreaIds/getGeoCode", {
      headers: { amzUserId, area: serviceAreaId },
    }),

  setAreaIndex: async (index) =>
    customFetch("/V1/serviceAreaIds/setAreaState", {
      headers: { index },
    }),

  setAreaState: async () => customFetch("/V1/auth/locationState"),

  setPrice: async (price) =>
    customFetch("/V1/auth/priceSelect", {
      method: "POST",
      body: { data: price },
    }),

  setPriceState: async () => customFetch("/V1/auth/priceState"),

  setDuration: async (duration) =>
    customFetch("/V1/auth/selectDuration", {
      method: "POST",
      body: { data: duration },
    }),

  setDurationState: async () => customFetch("/V1/auth/durationState"),

  setAutomated: async (hours, minutes, timeState) =>
    customFetch("/V1/auth/selectedAutomated", {
      method: "POST",
      body: { data: hours + " " + minutes + " " + timeState },
    }),

  setAutomatedState: async () => customFetch("/V1/auth/automatedState"),

  createPayment: async (amount, currency, paymentMethod) =>
    customFetch("/V1/accountPayment", {
      method: "POST",
      body: { amount, currency, paymentMethod },
    }),

  confirmPayment: async (paymentId) =>
    customFetch(`/V1/accountPayment/confirm/${paymentId}`),

  createMembership: async (type, paymentId) =>
    customFetch("/V1/membership", {
      method: "POST",
      body: { type, accountPaymentId: paymentId },
    }),
};
export default serviceAPI;
