import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "../../../../hooks";
import { BlockDetailsProps } from "../../../templates/private/blockDetails/blockDetails.type";
import { getResponsiveFormat, getTimeLabel } from "../reservedBlock.functions";
import { ReservedBlockProps } from "../reservedBlock.type";

const useReservedBlock = (params: ReservedBlockProps) => {
  const {
    startDate,
    endDate,
    locationId,
    locationName,
    startPrice,
    lat,
    lng,
    endPrice,
    expetecdTip,
    blockId,
    serviceAreaId,
  } = params;
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const { t } = useTranslation(["home"]);

  const getTimeDisplayLabel = useCallback(() => {
    const [format1, format2] = getResponsiveFormat(width);

    return getTimeLabel({
      date1: startDate,
      date2: endDate,
      format1,
      format2,
    });
  }, [startDate, endDate, width]);

  const navigateState: BlockDetailsProps = {
    blockId,
    lat,
    lng,
    locationId,
    locationName,
    serviceAreaId,
    price: startPrice,
    //TODO: send only values, not title and icon
    blockPropertiesLabels: [
      {
        icon: "dollar",
        title: t("home:schedule.expected-price"),
        description: `${startPrice}`,
      },
      {
        icon: "hand-holding-heart",
        title: t("home:schedule.expected-tips"),
        description: `${expetecdTip}`,
      },
      {
        icon: "clock",
        title: t("home:schedule.arriving-time"),
        description: getTimeDisplayLabel() || "",
      },
    ],
  };

  const handleClick = () => {
    navigate("/block-details", {
      state: navigateState,
    });
  };

  return {
    timeDisplayLabel: getTimeDisplayLabel(),
    handleClick,
    locationId,
    locationName,
    startPrice,
    expetecdTip,
    endPrice,
  };
};

export default useReservedBlock;
