import _ from "lodash";

const getLength = (width: number) => {
  if (width < 400) return Math.floor(width * (25 / 400));
  if (width < 500) return Math.floor(width * (35 / 500));
  return 100;
};

export const getEmail = (name: string, width: number) => {
  return _.truncate(name, {
    length: getLength(width),
  });
};

export const getName = (fullName: string, width: number) => {
  if (!fullName) return "";

  const nameSplit = fullName.split(" ");

  if (nameSplit.length === 1) return nameSplit[0];

  if (nameSplit.length === 2) return `${nameSplit[0]} ${nameSplit[1]}`;

  return _.truncate(`${nameSplit[0]} ${nameSplit[2]}`, {
    length: getLength(width) / 2,
  });
};
