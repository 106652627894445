import { envs } from "../../config/env";
import useGet from "../../https/hooks/get/useGet";

const API_URL = envs.URL_API;

const useAmzAccountsApi = () => {
  const { data, get } = useGet({ spinnerFullScreen: true });

  return {
    amzAccountsResp: data,
    loadAmzAccounts: () =>
      get({
        code: "G-AMZ-ACCOUNTS",
        url: API_URL + "/V1/authAmz/getAmzUsers",
        pathData: "data.data",
      }),
  };
};

export default useAmzAccountsApi;
