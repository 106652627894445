import { magicAPI } from "../../api";
import { SavedFilter } from "../../components/pages/private/instantOffers/instantOffersScreen.types";

const endpoints = {
  instantOfferUserConfig: "/instantOffer/config",
  switchStatus: "/instantOffer",
};

/**
 * Return IO (Instant Offer) User Config
 * @param id amz user id
 * @returns
 */
export const getIOUserConfig = async (id?: string): Promise<SavedFilter> => {
  if (!id) throw new Error("No id");

  const { data } = await magicAPI.get(endpoints.instantOfferUserConfig + `/${id}`);

  if (!data.data) throw new Error("No data");

  return data.data;
};

export const switchStatus = async (id?: string) => {
  if (!id) throw new Error("No id");

  const { data } = await magicAPI.put(`${endpoints.switchStatus}`);

  return data;
};
