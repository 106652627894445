import { useQuery } from "@tanstack/react-query";

import { getMembership } from "./membership.functions";

export const useMembershipQuery = (amzUserId?: string) => {
  const query = useQuery(["membership", amzUserId], () => getMembership(), {
    enabled: !!amzUserId,
  });

  return {
    membershipQuery: query,
    membership: query.data,
  };
};
