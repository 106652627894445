import { useEffect, useRef, useState } from "react";

import { Station } from "../../../../../../store/types/filters.types";
import useGetStations from "./useGetStations";
import useSaveStationsFilter from "./useSaveStationsFilter";

const useStations = () => {
  const { savedStations } = useGetStations();
  const [stations, setStations] = useState<Station[]>([]);
  const changedStations = useRef<Station[]>([]);
  const { saveStationsFilter } = useSaveStationsFilter();

  const updateChanges = (station: Station) => {
    const index = changedStations.current.findIndex((s) => s.areaId === station.areaId);

    if (index === -1) changedStations.current.push(station);
    else changedStations.current[index] = station;
  };

  const selectStation = (station: Station) => {
    updateChanges(station);
    const newStations = stations.map((s) => {
      if (s.areaId === station.areaId) return { ...station };
      return s;
    });

    setStations(newStations);
  };

  useEffect(() => {
    setStations(savedStations);
  }, [savedStations]);

  return {
    stations,
    selectStation,
    saveStations: () => {
      saveStationsFilter([...changedStations.current]);
      changedStations.current = [];
    },
  };
};

export default useStations;
