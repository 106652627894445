import { useEffect } from "react";

import { useHours, useMinutes } from "../../../atoms/iputTime/hooks";
import { HourInputProps, InputEvent } from "../hourInput.types";

const useHourInput = (props: HourInputProps) => {
  const { hour, changeHour, handleChangeHours } = useHours();
  const { minutes, changeMinutes, handleChangeMinutes } = useMinutes();

  const toHourNumber = (hour: string): number => {
    const hourNumber = parseInt(hour);
    return isNaN(hourNumber) ? 0 : hourNumber;
  };

  const toMinutesNumber = (minutes: string): number => {
    const minutesNumber = parseInt(minutes);
    return isNaN(minutesNumber) ? 0 : minutesNumber;
  };

  const handleHourChange = (e: InputEvent) => {
    const value = e.target.value;
    handleChangeHours(value);
    props.onChange({
      hour: toHourNumber(value),
      minutes: toMinutesNumber(minutes),
    });
  };

  const handleMinutesChange = (e: InputEvent) => {
    const value = e.target.value;
    handleChangeMinutes(value);
    props.onChange({
      hour: toHourNumber(hour),
      minutes: toMinutesNumber(value),
    });
  };

  useEffect(() => {
    changeMinutes(props.minutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeHour(props.hour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hour,
    handleHourChange,
    minutes: minutes,
    handleMinutesChange,
  };
};

export default useHourInput;
