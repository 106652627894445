import { useTranslation } from "react-i18next";

import { Badge, Typhography } from "../../atoms";
import useTransactionDetails from "../../organisms/transactionDetails/hooks/useTransactionDetails";
import WalletTrxItem from "../walletTransactionItem";
import Details from "./Details";
import useGetTransactionDetails from "./hooks/useGetTransactionDetails";
import { IWalletTransactionsProps } from "./walletTransactions.types";

const WalletTransactions = (props: IWalletTransactionsProps) => {
  const { transactions } = props;
  const { isOpen, handleOpen, handleClose } = useTransactionDetails();

  const { getTransactionDetails, details } = useGetTransactionDetails();
  const { t } = useTranslation(["common", "home"]);

  const handleClick = (id: string) => {
    getTransactionDetails(id);
    handleOpen();
  };

  return (
    <div>
      {transactions.map((transaction, idx) => (
        <WalletTrxItem key={idx} {...transaction} className="my-3" onClick={handleClick}>
          <WalletTrxItem.Info>
            <WalletTrxItem.Icon />
            <WalletTrxItem.Description>
              <WalletTrxItem.Date />

              <div className="is-flex is-align-items-center">
                <Typhography type="p-l-r" render="span" className="mr-2">
                  {transaction.type === "deposit"
                    ? t("home:wallet.deposit")
                    : transaction.stationName}
                </Typhography>
                {transaction.stationId ? (
                  <Badge type="success"> {transaction.stationId} </Badge>
                ) : null}
              </div>

              {transaction.duration ? (
                <Typhography type="p-r-r" className="mf-text-secondary">
                  {t("home:wallet.duration", {
                    count: transaction.duration.includes("1") ? 1 : 2,
                    duration: transaction.duration,
                  })}
                </Typhography>
              ) : null}
            </WalletTrxItem.Description>
          </WalletTrxItem.Info>
          <WalletTrxItem.Price />
        </WalletTrxItem>
      ))}

      <Details details={details} isOpen={isOpen} onClose={handleClose} />
    </div>
  );
};
export default WalletTransactions;
