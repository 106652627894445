import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectAmzUserId = (state: RootState) => state.payment.amzUserId;
const membershipTypeId = (state: RootState) => state.payment.membershipTypeId;
const paymentType = (state: RootState) => state.payment.paymentType;
const amount = (state: RootState) => state.payment.amount;

const usePaymentSelector = () => {
  return {
    amzUserId: useAppSelector(selectAmzUserId),
    membershipTypeId: useAppSelector(membershipTypeId),
    paymentType: useAppSelector(paymentType),
    amount: useAppSelector(amount),
  };
};

export default usePaymentSelector;
