import get from "lodash/get";
import update from "lodash/update";

import { IProcessAny } from "../../types/stateGet.types";

const processArrayObjectToArrayObjectUpdateObject = ({
  getState,
  state,
}: IProcessAny) => {
  const pathValue = getState.pathValue as string;
  const updateObject = getState.updateObject;
  const uniqKey = getState.uniqKey as string;

  const newobject = update(state, pathValue, (oldArray) => {
    const newArray: Array<any> = oldArray;
    updateObject.forEach((elemetNew: any) => {
      oldArray.forEach((elemetOld: any, key: number) => {
        const key1 = get(elemetNew, uniqKey);
        const key2 = get(elemetOld, uniqKey);
        if (key1 === key2) {
          newArray[key] = elemetNew;
        }
      });
    });
    return newArray as any;
  });

  return newobject;
};
const processElementObjectToElementObjectUpdateObject = ({
  getState,
  state,
}: IProcessAny) => {
  const pathValue = getState.pathValue as string;
  const updateObject = getState.updateObject;
  const newobject = update(state, pathValue, () => updateObject as any);
  return newobject;
};

export const updateStateElement = (elemets: IProcessAny) => {
  const { getState, state } = elemets;

  if (getState?.type === "array") {
    return processArrayObjectToArrayObjectUpdateObject({
      getState,
      state,
    });
  }
  if (getState?.type === "element") {
    return processElementObjectToElementObjectUpdateObject({
      getState,
      state,
    });
  }
};
