import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";

const SubscriptionStatusScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "SubscriptionStatusScreen" */
      "../../components/pages/private/subscription/status/SubscriptionStatusScreen"
    ),
);
const PaymentScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "PaymentScreen" */ "../../components/pages/private/subscription/payment/PaymentScreen"
    ),
);
const CheckoutScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "CheckoutScreen" */ "../../components/pages/private/subscription/checkout/CheckoutScreen"
    ),
);

const SubscriptionRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="status" element={<SubscriptionStatusScreen />} />
        <Route path="payment" element={<PaymentScreen />} />
        <Route path="checkout" element={<CheckoutScreen />} />
      </Routes>
    </Suspense>
  );
};
export default SubscriptionRoutes;
