import {
  useSetUpFilters,
  useStateGetDispatch,
} from "../../../../../../store/useDispatch";
import { useFiltersSelector } from "../../../../../../store/useSelector";
import { IonChange } from "./setUpStartPriceForm.type";
import { generateBlocks } from "./useForm.functions";

const useForm = () => {
  const { price } = useFiltersSelector();
  const { setUpStartPriceD } = useSetUpFilters();
  const { updateStateGetD } = useStateGetDispatch();

  //It is the event of the check button duration form button
  const onChangeEvent: IonChange = (event) => {
    const startPrice = Number(event.target.value);
    setUpStartPriceD(startPrice);
    try {
      updateStateGetD({
        pathValue: "filters.data.price",
        updateObject: startPrice,
        type: "element",
      });
    } catch (error: any) {
      //TODO Solve problem with error
    }
  };

  return { list: generateBlocks(onChangeEvent, price) };
};

export default useForm;
