import "./titleModalSwithc.styles.scss";

import Switch from "../../atoms/switch/Switch";
import ModalTitle from "../modalTitle/ModalTitle";
import { TitleModalSwitchProps } from "./titleModalSwitch.types";

const TitleModalSwitch = (props: TitleModalSwitchProps): JSX.Element => {
  const { switch: switchProps, modalTitle, className } = props;
  const { show, ...input } = switchProps || {};
  return (
    <div className={"wrapper mb-1 " + (className ?? "")}>
      <div className="colum-1">
        <div className="title-x">
          <ModalTitle {...modalTitle} />
        </div>
        {switchProps?.show && (
          <div className="switch-x">
            <Switch {...input} />
          </div>
        )}
      </div>
    </div>
  );
};

TitleModalSwitch.defaultProps = {
  modalTitle: {
    title: "Title",
    description: "Description",
  },
  switch: {
    show: true,
  },
} as Partial<TitleModalSwitchProps>;

export default TitleModalSwitch;
