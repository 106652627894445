import { useEffect, useRef } from "react";

import { useSetUpFilters } from "../../../../../../store/useDispatch";
import { useFiltersSelector } from "../../../../../../store/useSelector";
import useFormikDays from "../formik/useFormikDays";
import { Day } from "../formik/useFormikDays.types";
import { generateBlocks, onChangeEvent } from "./useForm.functions";

const useForm = ({ days }: { days: Day[] }) => {
  const { formik } = useFormikDays();
  const { setStartAtD: setUpDaysD, setStartAtEndD, setStartAtStartD } = useSetUpFilters();
  const { startAt } = useFiltersSelector();
  const firstRender = useRef(true);

  const { getFieldProps } = formik;

  // set initial values from db
  useEffect(() => {
    if (days.length > 0 && firstRender.current) {
      formik.setFieldValue(
        "days",
        days.map((day) => day.day.toString()),
      );
      firstRender.current = false;
      return;
    }
  }, [days]);

  useEffect(() => {
    const value =
      formik.values.days?.map((d) => {
        // eslint-disable-next-line eqeqeq
        const savedDay = startAt.days?.find((day) => day.day == d);
        return {
          day: d,
          start: {
            hour: savedDay?.start.hour || 5,
            minutes: savedDay?.start.minutes || 0,
          },
          end: {
            hour: savedDay?.end.hour || 20,
            minutes: savedDay?.end.minutes || 0,
          },
        };
      }) || [];

    onChangeEvent({ value: value as unknown as Day[], setUpDaysD });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.days, setUpDaysD]);

  return {
    list: generateBlocks({
      setStartAtStartD,
      setStartAtEndD,
      getFieldProps,
      days: startAt.days || [],
    }),
    formik,
  };
};

export default useForm;
