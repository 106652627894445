import { ActionNotification, NotificationC } from "../../types/notificationC.types";
import { CLEAN_NOTIFICATION_C, SET_NOTIFICATION_C } from "./actions";
// reducer
const initialState: NotificationC = {
  enabled: false,
  type: undefined,
  title: "",
  body: undefined,
  stylesContent: {},
};

export default function notificationC(
  state: NotificationC = initialState,
  action: ActionNotification,
): NotificationC {
  let stateCopy = { ...state };
  switch (action?.type) {
    case SET_NOTIFICATION_C: {
      stateCopy = action?.payload?.notificationC as NotificationC;
      return stateCopy;
    }
    case CLEAN_NOTIFICATION_C: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
