import "./referredListName.styles.scss";

import { Typhography } from "../../atoms";
import { getNameAndLastName } from "./referredList.functions";

type Props = {
  name: string;
  index: number;
};

const ReferredListName = (props: Props) => {
  const { name, index } = props;

  return (
    <div className="mf-referred_list_name">
      <Typhography type="p-l-b" render="span">
        {index}.
      </Typhography>{" "}
      <Typhography type="p-l-r" render="span" style={{ textTransform: "capitalize" }}>
        {getNameAndLastName(name)}.
      </Typhography>
    </div>
  );
};

export default ReferredListName;
