import axios from "axios";

import { magicAPI } from "../../api";
import { envs } from "../../config/env";
import { GetReferredResponse, IReferred } from "./referred.types";

export const getLastedReferred = async (amzUserId?: string): Promise<IReferred[]> => {
  const url = envs.URL_API + `/V1/referred?page=${1}&limit=${10}`;

  const { data } = await axios.get(url, {
    headers: {
      AmzUserId: amzUserId,
    },
    withCredentials: true,
  });

  return data.data;
};

export const getReferredByAmzUser = async (
  amzUserId?: string,
  config?: {
    pagination: {
      page: number;
      limit: number;
    };
    filters?: {
      status?: string;
    };
  },
) => {
  if (!amzUserId) throw new Error("amzUserId is required");

  const { pagination, filters } = config ?? { pagination: { page: 1, limit: 10 } };
  const { page, limit } = pagination;

  const resp = await magicAPI.get<GetReferredResponse>(`/referred/${amzUserId}`, {
    params: {
      page,
      limit,
      status: filters?.status,
    },
  });

  return resp.data;
};
