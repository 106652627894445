import { Navigate, Outlet } from "react-router-dom";

import { useUISelector } from "../store/useSelector";

const Maintenance = () => {
  const { maintenance } = useUISelector();

  if (maintenance.show) return <Navigate to="/maintenance" replace />;

  return <Outlet />;
};

export default Maintenance;
