import "./referredStatus.styles.scss";

import { useTranslation } from "react-i18next";

import { IReferredStatus } from "../../../hooks/referred/referred.types";
import { ReferredStatusItem } from "../../atoms";

type Props = {
  toggleStatusFilter: (status: IReferredStatus) => void;
  selectedStatus?: IReferredStatus;
  categoriesCount: Record<IReferredStatus, number>;
};

const ReferredStatus = (props: Props) => {
  const { toggleStatusFilter, selectedStatus, categoriesCount } = props;

  const { t } = useTranslation(["referred"]);

  const toggleStatus = (status: IReferredStatus) => () => {
    toggleStatusFilter(status);
  };

  const handleKeyToggleStatus = (status: IReferredStatus) => (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") toggleStatusFilter(status);
  };

  return (
    <div className="mf-referred_status">
      <ReferredStatusItem
        activeStatus={selectedStatus}
        handleKeyToggleStatus={handleKeyToggleStatus}
        toggleStatus={toggleStatus}
        status="PENDING"
        title={t("referred:status.referral")}
        count={categoriesCount.PENDING}
      />
      <ReferredStatusItem
        activeStatus={selectedStatus}
        handleKeyToggleStatus={handleKeyToggleStatus}
        toggleStatus={toggleStatus}
        status="PAID"
        title={t("referred:status.active")}
        count={categoriesCount.PAID}
      />
    </div>
  );
};

export default ReferredStatus;
