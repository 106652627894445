import useUserDispatch from "../../store/useDispatch/useUserDispatch";
import { useUserSelector } from "../../store/useSelector";
import { User } from "./useUser.types";

const useUser = () => {
  const { user, loading } = useUserSelector();
  const { setUserD, deleteUserD } = useUserDispatch();

  const setUser = (user?: User) => {
    if (!user) return;
    setUserD(user);
  };

  const deleteUser = () => {
    if (user) deleteUserD();
  };

  return {
    user,
    setUser,
    deleteUser,
    loading,
  };
};

export default useUser;
