import { useNavigate } from "react-router-dom";

import ModalHeader from "../modalHeader/ModalHeader";
import { IFilterHeaderProps } from "./filterHeader.types";

const FilterHeader = (props: IFilterHeaderProps) => {
  const navigate = useNavigate();

  const close = (e: any) => {
    props.closeBtn?.onClick?.(e);
    navigate("/");
  };

  return <ModalHeader title={props.title} closeBtn={{ onClick: close }} />;
};

export default FilterHeader;
