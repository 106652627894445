import { CloseBtn, HelpButton, Title } from "../../atoms";
import { IModalHeaderProps } from "./modalHeader.types";

const ModalHeader = (props: IModalHeaderProps) => {
  return (
    <header
      className={`is-flex is-justify-content-space-between pb-3 ${props.className || ""}`}
    >
      <div className="pt-2">{props.title && <Title title={props.title} />}</div>

      <div className="is-flex is-align-items-end is-justify-content-center">
        <HelpButton className="mr-2" color="#A6B4C5" />
        <CloseBtn {...props.closeBtn} />
      </div>
    </header>
  );
};

export default ModalHeader;
