import { Outlet } from "react-router-dom";

import { Spinner } from "../../components/atoms";
import { useMembershipQuery } from "../../hooks/membership/queries";
import { useAmzAccountSelector } from "../../store/useSelector";
import useValidateMembership from "./hooks/useValidateMembership";

type Props = {
  children?: React.ReactNode;
};

const SubscriptionRoute = (props: Props) => {
  const { amzAccount } = useAmzAccountSelector();
  const { membership } = useMembershipQuery(amzAccount?._id);
  const { loading } = useValidateMembership();

  if (loading) {
    return <></>;
  }

  if (!membership) {
    return <Spinner />;
  }

  return props.children ? <>{props.children}</> : <Outlet />;
};

export default SubscriptionRoute;
