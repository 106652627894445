import { TFunction } from "i18next";

import { ModalInfoProps } from "../../components/organisms/modalInfo/modalInfo.types";

export const getStopPopup = (t: TFunction<"popup"[]>): ModalInfoProps => ({
  enabled: true,
  modalInfoProps: {
    type: "warning",
    ...t("popup:stop-bot", { returnObjects: true }),
    image: {
      typeImage: "flexi-danger",
    },
  },
});

export const getFirstStartPopup = (t: TFunction<"popup"[]>): ModalInfoProps => ({
  enabled: true,
  modalInfoProps: {
    type: "success",
    ...t("popup:flexi-access", { returnObjects: true }),
    image: {
      typeImage: "flexi-catching-boxes",
      style: {
        top: "-100px",
        width: "100%",
      },
    },
  },
});
