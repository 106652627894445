type ValidValue = string | number | boolean | string[];

export class EnvironmentLoader {
  private value: string | undefined;
  private isRequired: boolean = false;
  private defaultValue: ValidValue = "";
  private name: string;

  constructor(name: string) {
    this.name = name;
    this.value = process.env[name];
  }

  static load(name: string) {
    return new EnvironmentLoader(`REACT_APP_${name}`);
  }

  private validateRequired() {
    const noValue = this.isVoid();

    if (this.isRequired && noValue) {
      throw new Error(`Environment variable is required ${this.name}`);
    }
  }

  private isVoid() {
    return this.value === undefined || this.value === null || this.value === "";
  }

  required() {
    this.isRequired = true;

    return this;
  }

  default(value: ValidValue) {
    const noValue = this.isVoid();

    if (noValue) {
      this.defaultValue = value;
    }

    return this;
  }

  asString() {
    this.validateRequired();
    return String(this.value || this.defaultValue);
  }

  asNumber() {
    this.validateRequired();
    return Number(this.value || this.defaultValue);
  }

  asBoolean(): boolean {
    this.validateRequired();
    const boolValue: boolean =
      this.value !== undefined
        ? this.value === "true"
        : (this.defaultValue as boolean) || false;

    return boolValue;
  }

  asArray() {
    this.validateRequired();
    return this.value?.split(",") || this.defaultValue || [];
  }

  asEnum<T extends string>(values: T[]): T {
    this.validateRequired();
    const included = values.includes(this.value as T);

    if (!included) {
      throw new Error(
        `Environment variable is not in the list of valid values ${this.name}`,
      );
    }

    return this.value as T;
  }

  asDate(): Date {
    this.validateRequired();

    const date = new Date(this.value || (this.defaultValue as string | number));

    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date format ${this.name}`);
    }

    return date;
  }
}

export const load = EnvironmentLoader.load;
