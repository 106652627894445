import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { envs } from "../../../../../../config/env";
import {
  useModalInfoDispatch,
  useStateSpinnerFullScreen,
} from "../../../../../../store/useDispatch";
import useConfirmPayment from "./useConfirmPayment";

const useCheckoutForm = () => {
  const { t } = useTranslation(["common", "membership"]);
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const { setModalInfoCD } = useModalInfoDispatch();
  const { setSpinnerFullScreenD, cleanSpinnerFullScreenD } = useStateSpinnerFullScreen();

  const { confirmPayment } = useConfirmPayment();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: envs.APP_URL + "/payment-confirm",
      },
      redirect: "if_required",
    });

    setLoading(false);

    if (error) {
      setModalInfoCD({
        enabled: true,
        modalInfoProps: {
          type: "danger",
          title: t("membership:checkout.payment-error"),
          buttonText: t("common:accept"),
          body: error.message || t("common:unexpected-error"),
          image: {
            typeImage: "flexi-error",
          },
        },
      });
    }

    if (paymentIntent?.status === "succeeded") {
      paymentIntent.client_secret && confirmPayment(paymentIntent.client_secret);
    }
  };

  useEffect(() => {
    if (loading)
      setSpinnerFullScreenD({
        color: "green",
        enabled: true,
      });
    else cleanSpinnerFullScreenD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    handleSubmit,
    loading: loading || !stripe || !elements,
  };
};

export default useCheckoutForm;
