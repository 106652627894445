import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    info: {
      main: "#78ABF8",
      light: "#99CBFF",
      lighter: "#EBF5FF",
    },
    success: {
      main: "#00C363",
      light: "#7BDDAF",
      lighter: "#DDF2EC",
    },
    error: {
      main: "#FF6464",
      light: "#F1A7A7",
      lighter: "#FCEDED",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#F2A818",
      light: "#F9DB9F",
      lighter: "#FEF8EC",
    },
    primary: {
      main: "#37465C",
    },
    secondary: {
      main: "#77879A",
    },
    text: {
      primary: "#37465C",
      secondary: "#77879A",
    },
    background: {
      default: "#F5F7FB",
    },
  },

  components: {
    MuiSelect: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            fontFamily: "Lato",
            textAlign: "center",
            boxShadow: "4px 4px 0px rgba(217, 223, 228, 0.25)",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            height: "40px",
            ":before": {
              borderBottom: "none !important",
            },
          },
        },
      ],
    },
    MuiInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === "success" && {
            ":before": { borderBottomColor: "#00C363" },
          }),
        }),
      },
    },
  },
});
