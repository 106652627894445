import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "universal-cookie";

import { AmzAccount } from "../../../../hooks/useAmzAccount/useAmzAccount.types";
import { useAmzAccountDispatch } from "../../../../store/useDispatch";
import { useUserSelector } from "../../../../store/useSelector";
import useGetAmzAccount from "./api/useGetAmzAccount";

const cookie = new Cookie();

const useLoadAmzAccount = () => {
  const navigate = useNavigate();
  const { user } = useUserSelector();
  const { getAmzAccount, getAmzAccountResp } = useGetAmzAccount();

  const { setAmzAccountD } = useAmzAccountDispatch();

  const goToAccounts = useCallback(() => {
    navigate("/accounts");
  }, [navigate]);

  const setAmzAccount = (amzAccount: AmzAccount) => {
    if (!amzAccount) return goToAccounts();
    setAmzAccountD(amzAccount);
    cookie.set("amzUserId", amzAccount._id, { path: "/" });
  };

  useEffect(() => {
    getAmzAccount();
  }, [getAmzAccount]);

  useEffect(() => {
    if (getAmzAccountResp.status !== 0) {
      getAmzAccountResp.success ? setAmzAccount(getAmzAccountResp.data) : goToAccounts();
    }
  }, [getAmzAccountResp]);

  useEffect(() => {
    if (user && !user.selectedAmzAccount) goToAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};

export default useLoadAmzAccount;
