import { useEffect, useState } from "react";

const checkChatReady = () => (window as any).__SUPPORT_CHAT_READY || false;

const useCheckChatReady = () => {
  const [isReady, setIsReady] = useState(checkChatReady());

  useEffect(() => {
    const check = () => {
      setTimeout(() => {
        const isReady = checkChatReady();

        if (isReady) setIsReady(true);
        else check();
      }, 500);
    };

    check();
  }, []);

  return {
    isReady,
  };
};

export default useCheckChatReady;
