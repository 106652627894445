import "./selectInput.styles.scss";

import { ISelectInputProps } from "./selectInput.types";
const SelectInput = (props: ISelectInputProps): JSX.Element => {
  const { options, select } = props;
  return (
    <select {...select} className={"mg-select"}>
      {options.map((option, key) => {
        return (
          <option className={"mg-option"} value={option.value} key={key}>
            {option.option}
          </option>
        );
      })}
    </select>
  );
};

SelectInput.defaultProps = {
  options: [
    {
      option: "Select",
      value: 0,
    },
    {
      option: "Option 1",
      value: 1,
    },
    {
      option: "Option 2",
      value: 2,
    },
    {
      option: "Option AAAAAAA 3",
      value: 3,
    },
  ],
} as Partial<ISelectInputProps>;

export default SelectInput;
