import { Separator } from "../../atoms";
import { ReservedBlock } from "../../molecules";
import { ReservedBlocksProps } from "./reservedBlocks.type";

const ReservedBlocks = (props: ReservedBlocksProps) => {
  const { reservedBlocks, listTitle, ...rest } = props;

  return (
    <div {...rest}>
      {listTitle && (
        <div className="pb-2">
          <Separator text={listTitle} />
        </div>
      )}

      <div>
        {reservedBlocks.map((block, index) => (
          <ReservedBlock key={index} {...block} />
        ))}
      </div>
    </div>
  );
};

export default ReservedBlocks;
