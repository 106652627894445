import "./titleLabel.styles.scss";

import { IconCustom } from "../../atoms";
import { TitleLabelProps } from "./titleLabel.types";

const TitleLabel = (props: TitleLabelProps): JSX.Element => {
  const { icon, title, description } = props;

  return (
    <div className="is-flex has-text-left py-2">
      {icon && (
        <IconCustom
          typeIcon={icon}
          className="has-text-black mr-3"
          width={20}
          height={20}
        />
      )}
      <div>
        <p className="mf-overline-bold mf-title-label-title">{title}</p>
        <p className="mf-small mf-text-secondary">{description}</p>
      </div>
    </div>
  );
};

TitleLabel.defaultProps = {
  title: "Title",
  description: "Description",
} as Partial<TitleLabelProps>;

export default TitleLabel;
