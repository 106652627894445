import "./app.styles.scss";

import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";

// Common Components
import { Notifications } from "./components/atoms";
import { ProgressBarFullScreen, SpinnerFullScreen } from "./components/molecules";
import RadarAnimation from "./components/molecules/radarAnimation/RadarAnimation";
import { ModalInfo, TimePicker } from "./components/organisms";
import useLocale from "./hooks/locale/useLocale";
import i18n from "./i18n/i18n";
import AppRoutes from "./navigationRoutes/AppRoutes";
import { AppThemeProvider } from "./themes";

const App = () => {
  useLocale();

  return (
    <AppThemeProvider>
      <I18nextProvider i18n={i18n}>
        <ProgressBarFullScreen />
        <SpinnerFullScreen />
        <ModalInfo />
        <RadarAnimation type={"effects"} />
        <TimePicker />
        <Notifications />
        <AppRoutes />
        <Toaster position="bottom-center" reverseOrder={false} />
      </I18nextProvider>
    </AppThemeProvider>
  );
};

export default App;
