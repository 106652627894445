/* action types */
import {
  ICleanParticularGetStateReturn,
  INewGetState,
  INewGetStateReturn,
  IUpdateGetState,
  IUpdateGetStateReturn,
} from "../../types/stateGet.types";

/* Used only in reducer */
export const SET_STATE_GET = "magicFlex/SET_STATE_GET";
export const UPDATE_STATE_GET = "magicFlex/UPDATE_STATE_GET";
export const CLEAN_GET_PARTICULAR = "magicFlex/CLEAN_GET_PARTICULAR";
export const CLEAN_STATE_GET = "magicFlex/CLEAN_STATE_GET";

/* Action Creators */
export const setStateGet = (getState: INewGetState): INewGetStateReturn => {
  return {
    type: SET_STATE_GET,
    payload: {
      getState,
    },
  };
};

export const updateStateGet = (getState: IUpdateGetState): IUpdateGetStateReturn => {
  return {
    type: UPDATE_STATE_GET,
    payload: {
      getState,
    },
  };
};
export const cleanParticularGet = (key: string): ICleanParticularGetStateReturn => {
  return {
    type: CLEAN_GET_PARTICULAR,
    payload: {
      key,
    },
  };
};
export const cleanGet = () => {
  return {
    type: CLEAN_STATE_GET,
  };
};
