import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";
const AboutMagicFlexScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "AboutMagicFlexScreen" */ "../../components/pages/private/informative/AboutMagicFlexScreen"
    ),
);

const TermsAndConditionsScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsAndConditionsScreen" */ "../../components/pages/public/termsAndConditions/TermsAndConditionsScreen"
    ),
);

const InfoRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="about" element={<AboutMagicFlexScreen />} />
        <Route path="terms-conditions" element={<TermsAndConditionsScreen />} />
      </Routes>
    </Suspense>
  );
};

export default InfoRoutes;
