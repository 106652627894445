import { envs } from "../../../config/env";

const isBotEnabled = envs.ACTIVATE_BOT;

export const getClasses = (radarIconEnabled: boolean) => {
  const classNames = [
    "has-text-black",
    "is-relative",
    "is-flex",
    "px-3",
    isBotEnabled ? "is-justify-content-space-between" : "",
    "is-justify-content-center",
    "has-background-white",
    "is-align-items-center",
  ];
  radarIconEnabled ? classNames.push("py-3") : classNames.push("py-4");
  return classNames.join(" ");
};

export const getButtonClasses = (isBotRunning: boolean) => {
  const statusClass = isBotRunning ? "success" : "danger";

  return [
    "magicflex-bar-bottom-button",
    `has-text-white has-background-${statusClass}`,
  ].join(" ");
};
