import { useFormik } from "formik";

import { inputsConfigYup, inputsGeneration } from "./useFormikDays.functions";

const useFormikDays = () => {
  const { initialValues, validationSchema } = inputsConfigYup({});

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      //
    },
  });

  const formPropsSignIn = inputsGeneration();

  return {
    formPropsSignIn,
    formik,
  };
};
export default useFormikDays;
