import {
  addDevice,
  addStations,
  deleteAmzAccount,
  reset,
  setAmzAccount,
  setDevices,
  setExtraData,
} from "../reducers/amzAccount/amzAccount";
import useActions from "./useActions";

const useAmzAccountDispatch = () => {
  return {
    addStationsD: useActions(addStations),
    setAmzAccountD: useActions(setAmzAccount),
    deleteAmzAccountD: useActions(deleteAmzAccount),
    resetD: useActions(reset),
    setExtraDataD: useActions(setExtraData),
    addDeviceD: useActions(addDevice),
    setDevicesD: useActions(setDevices),
  };
};

export default useAmzAccountDispatch;
