import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getBufferTime, setBufferTime } from "./filter.functions";

export const useFilterBufferTime = () => {
  const queryClient = useQueryClient();
  const bufferTimeQuery = useQuery(["filter", "bufferTime"], () => getBufferTime(), {
    staleTime: 1000 * 60 * 10,
  });

  const bufferTimeMutation = useMutation({
    mutationFn: setBufferTime,
    onMutate: async (newBufferTime) => {
      queryClient.setQueryData(["filter", "bufferTime"], newBufferTime);
    },
    onSettled: () => {
      bufferTimeQuery.refetch();
    },
  });

  return {
    bufferTimeQuery,
    bufferTimeMutation,
  };
};
