import { Navigate, Outlet } from "react-router-dom";

import { AccessRoute } from "./route.types";

const ProtectedRoute = (props: AccessRoute): JSX.Element => {
  const { children, isAuthenticated, redirectTo = "/auth/login" } = props;

  if (!isAuthenticated) return <Navigate to={redirectTo} replace />;

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
