import "./typography.styles.scss";

import Typhography from "../typhography/Typhography";
import { defaultProps, TypographyOptionProps } from "./typographyOption.types";
const TypographyOption = (props: TypographyOptionProps): JSX.Element => {
  return (
    <>
      {props.smTitle && (
        <Typhography type={"p-r-r"} className={`mg-sub-title`}>
          {props.smTitle}
        </Typhography>
      )}
      <Typhography type={"p-r-r"} className={`${props.className}`}>
        {props.bodyText}
      </Typhography>
    </>
  );
};

TypographyOption.defaultProps = defaultProps;

export default TypographyOption;
