import {
  addAmzAccount,
  addAmzAccounts,
  deleteAmzAccount,
  resetState,
  setAmzAccounts,
  setError,
  startLoading,
  stopLoading,
} from "../reducers/amzAccounts/amzAccounts";
import useActions from "./useActions";

const useAmzAccountsDispatch = () => {
  return {
    addAmzAccount: useActions(addAmzAccount),
    addAmzAccounts: useActions(addAmzAccounts),
    deleteAmzAccount: useActions(deleteAmzAccount),
    resetState: useActions(resetState),
    setAmzAccounts: useActions(setAmzAccounts),
    setError: useActions(setError),
    startLoading: useActions(startLoading),
    stopLoading: useActions(stopLoading),
  };
};

export default useAmzAccountsDispatch;
