import QRCodeStyling, { Options } from "qr-code-styling";
import { useEffect, useRef } from "react";

import magicFlexIcon from "../../../assets/icons/magicflex-icon-green.svg";
import { envs } from "../../../config/env";

const green = "#00C363";
const black = "#37465C";

const qr = new QRCodeStyling({
  width: 250,
  height: 250,
  data: envs.APP_URL,
  image: magicFlexIcon,
  imageOptions: {
    imageSize: 0.5,
    margin: 5,
  },
  dotsOptions: {
    color: black,
    type: "square",
  },
  cornersSquareOptions: {
    type: "square",
    color: green,
  },
  cornersDotOptions: {
    type: "square",
    color: black,
  },
});

const QRCode = (props: Options) => {
  const qrEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    qrEl.current && qr.append(qrEl.current);
  }, []);

  useEffect(() => {
    qr.update(props);
  }, [props]);

  return <div ref={qrEl} />;
};
export default QRCode;
