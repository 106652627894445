import { createSlice } from "@reduxjs/toolkit";

import { MenuState } from "../../types/menu.type";
import * as reducers from "./actions";

export const initialState: MenuState = {
  isOpen: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers,
});

export const { toggleMenu } = menuSlice.actions;

export default menuSlice.reducer;
