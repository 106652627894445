import CryptoJS from "crypto-js";

import { envs } from "../config/env";

const encryptPassword = (txt: string) => {
  const password = CryptoJS.AES.encrypt(
    JSON.stringify({
      txt,
    }),
    envs.KEY_CRYPT,
  );
  return password.toString();
};

export default encryptPassword;
