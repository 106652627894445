import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAmzAccountDispatch, useModalInfoDispatch } from "../../store/useDispatch";
import { useAmzAccountSelector } from "../../store/useSelector";
import useAmzAccounts from "../useAmzAccounts/useAmzAccounts";
import { AmzAccount } from "./useAmzAccount.types";
import useAmzAccountApi from "./useAmzAccountApi";
import useAmzAccountExtraData from "./useAmzAccountExtraData";

const useAmzAccount = () => {
  const { t } = useTranslation(["register"]);
  const navigate = useNavigate();

  const { deleteAmzAccountD, setAmzAccountD } = useAmzAccountDispatch();
  const { amzAccount, extraData } = useAmzAccountSelector();

  const { loading: extraDataLoading, cleanExtraData } = useAmzAccountExtraData();
  const { selectAmzAccResp, selectAmzAcc } = useAmzAccountApi();

  const { setModalInfoCD } = useModalInfoDispatch();

  const { loadAmzAccounts } = useAmzAccounts();

  const setAmzAccount = (amzAccount?: AmzAccount) => {
    if (amzAccount) setAmzAccountD(amzAccount);
  };

  const deleteAmzAccount = useCallback(() => {
    if (amzAccount) deleteAmzAccountD();
  }, [amzAccount]);

  const selectAmzAccount = (amzAccount: AmzAccount) => {
    selectAmzAcc(amzAccount._id);
  };

  useEffect(() => {
    if (selectAmzAccResp.status === 0) return;

    if (selectAmzAccResp.success) {
      cleanExtraData();
      setAmzAccount(selectAmzAccResp.data);
      new Promise((resolve) => setTimeout(resolve, 10)).then(() =>
        navigate("/", { replace: true }),
      );
    } else {
      setModalInfoCD({
        enabled: true,
        handleAccept: () => loadAmzAccounts(),
        handleClose: () => loadAmzAccounts(),
        handleCancel: () => loadAmzAccounts(),
        modalInfoProps: {
          body: selectAmzAccResp.data.message,
          ...t("register:error-select-account", { returnObjects: true }),
          type: "danger",
          image: {
            typeImage: "flexi-error",
          },
        },
      });
    }
  }, [selectAmzAccResp]);

  return {
    setAmzAccount,
    extraData,
    loading: extraDataLoading || selectAmzAccResp.loading,
    deleteAmzAccount,
    selectAmzAccount,
  };
};

export default useAmzAccount;
