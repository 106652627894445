import "./checkout.styles.scss";

import { PaymentElement } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../atoms";
import useCheckoutForm from "./hooks/useCheckoutForm";

const CheckoutForm = () => {
  const { loading, handleSubmit } = useCheckoutForm();
  const { t } = useTranslation(["membership"]);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" className="mt-3" />
      <Button type="submit" disabled={loading} id="submit">
        {t("membership:checkout.pay-now")}
      </Button>
    </form>
  );
};
export default CheckoutForm;
