import "./blockOption.styles.scss";

import { Separator } from "../../../atoms";
import { BlocksOptionsList, SearchEngine } from "../../../molecules";
import { SetLocationsProps } from "./setUpLocations.types";
import { useMissingStationPopup } from "./useMissingLocationPopup";

const SetUpLocations = (props: SetLocationsProps) => {
  const { searchEngine, list, enabledPrice } = props;

  useMissingStationPopup();

  return (
    <>
      <SearchEngine {...searchEngine} />
      <Separator />
      <div>
        <BlocksOptionsList
          blockType={"check"}
          blockOptions={list}
          className="locations-block_options"
          enabledPrice={enabledPrice}
        />
      </div>
    </>
  );
};

export default SetUpLocations;
