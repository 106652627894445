import { useCallback, useEffect } from "react";

import { useModalInfoDispatch, useStateSpinnerFullScreen } from "../../store/useDispatch";
import useAmzAccountsDispatch from "../../store/useDispatch/useAmzAccountsDispatch";
import useAmzAccountsSelector from "../../store/useSelector/useAmzAccountsSelector";
import serviceAPI from "../../utils/services/servicesAPI";
import { AmzAccount } from "../useAmzAccount/useAmzAccount.types";
import useAmzAccountsApi from "./useAmzAccountsApi";

const useAmzAccounts = () => {
  const { setModalInfoCD } = useModalInfoDispatch();
  const { amzAccountsResp, loadAmzAccounts } = useAmzAccountsApi();
  const { amzAccounts } = useAmzAccountsSelector();
  const {
    setAmzAccounts: setAmzAccountsD,
    deleteAmzAccount: deleteAmzAccountD,
    addAmzAccount: addAmzAccountD,
  } = useAmzAccountsDispatch();

  const { setSpinnerFullScreenD, cleanSpinnerFullScreenD } = useStateSpinnerFullScreen();

  const setAmzAccounts = (amzAccounts: AmzAccount[]) => {
    if (!amzAccounts) return;
    setAmzAccountsD(amzAccounts);
  };

  const deleteAmzAccount = async (id: string) => {
    //TODO: create http delete hook
    setSpinnerFullScreenD({ enabled: true, color: "green" });
    const { request } = await serviceAPI.deleteAmzAccount(id);

    if (request) {
      deleteAmzAccountD(id);
    } else {
      setModalInfoCD({
        enabled: true,
        modalType: "delete-amz-account-error",
      });
    }

    cleanSpinnerFullScreenD();
  };

  const addAmzAccount = (amzAccount: AmzAccount) => {
    addAmzAccountD(amzAccount);
  };

  const reLoadAmzAccounts = useCallback(async () => {
    if (amzAccounts?.length > 0) return;

    loadAmzAccounts();
  }, [amzAccounts.length]);

  useEffect(() => {
    if (amzAccountsResp.success) setAmzAccounts(amzAccountsResp.data);
  }, [amzAccountsResp]);

  return {
    addAmzAccount,
    deleteAmzAccount,
    loading: amzAccountsResp.loading,
    reLoadAmzAccounts,
    loadAmzAccounts,
    setAmzAccounts,
  };
};

export default useAmzAccounts;
