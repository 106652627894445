import moment from "moment";
import { useState } from "react";

const useLocale = () => {
  const [lng, setLng] = useState("en");

  const changeLanguage = (newLng: string) => {
    moment.locale(newLng);
    setLng(newLng);
  };

  return { changeLanguage, lng };
};

export default useLocale;
