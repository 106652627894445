import "./magicMap.styles.scss";

import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { useTranslation } from "react-i18next";

import { envs } from "../../../../config/env";
import { SpinnerMagic } from "../../../atoms";
import { BlockButtonMap } from "../../../molecules";

const key = envs.GOOGLE_MAPS_API_KEY;

const Map = (props) => {
  const { lat, lng } = props;
  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat, lng }}
      defaultOptions={{
        streetViewControl: true,
      }}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  );
};

const GoogleMapT = withScriptjs(withGoogleMap(Map));

const MagicMap = (props) => {
  const { lat, lng } = props;

  const { i18n } = useTranslation();

  return (
    <>
      <GoogleMapT
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${key}&language=${i18n.language}`}
        containerElement={<div />}
        mapElement={<div className={"mg-map"} />}
        loadingElement={<SpinnerMagic size="midle" />}
        lat={lat}
        lng={lng}
      />
      <BlockButtonMap lat={lat} lng={lng} />
    </>
  );
};

export default MagicMap;
