import { useMemo } from "react";

import { useAmzAccount, useUser, useWindowSize } from "../../../../hooks";
import { getEmail, getName } from "../navbarUserInfo.functions";
import { UseNavbarUserInfoReturn } from "./useNavbarUserInfo.types";

const useNavbarUserInfo = (): UseNavbarUserInfoReturn => {
  const { width } = useWindowSize();
  const { user } = useUser();
  const { extraData: person } = useAmzAccount();

  const { firstname: firstName } = user || {};

  const email = useMemo(() => {
    const email = person?.email || user?.email || "";
    return getEmail(email, width);
  }, [width, person?.email, user?.email]);

  const fullName = useMemo(() => {
    const fullName = person ? `${person.firstName} ${person.lastName}` : firstName || "";
    return getName(fullName, width);
  }, [person, firstName, width]);

  return { fullName, email };
};

export default useNavbarUserInfo;
