import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getIOUserConfig, switchStatus } from "./functions";

export const useInstanOffersQuery = (amzUserId?: string) => {
  const queryClient = useQueryClient();

  const ioFiltersQuery = useQuery(
    ["instant-offers", "filters", amzUserId],
    () => getIOUserConfig(amzUserId),
    {
      enabled: !!amzUserId,
    },
  );

  const switchStatusMutation = useMutation(
    ["instant-offers", "status"],
    (id?: string) => switchStatus(id),
    {
      onMutate: (id) => {
        // setSpinnerFullScreenD({ color: "green", enabled: true });
        queryClient.cancelQueries(["instant-offers", "filters"]);

        const previousFilters = queryClient.getQueryData([
          "instant-offers",
          "filters",
          id,
        ]);

        queryClient.setQueryData(["instant-offers", "filters", id], (old: any) => {
          return {
            ...old,
            status: old.status === "START" || old.status === "PAUSE" ? "STOP" : "START",
          };
        });

        return previousFilters;
      },
      onError: (_, __, previousFilters) => {
        queryClient.setQueryData(["instant-offers", "filters"], previousFilters);
      },
    },
  );

  return { ioFiltersQuery, switchStatusMutation };
};
