import "./spinner.styles.scss";

import SpinnerMagic from "../spinnerMagic/SpinnerMagic";

const Spinner = () => {
  return (
    <div className={`mf-spinner`}>
      <div>
        <SpinnerMagic />
      </div>
    </div>
  );
};

export default Spinner;
