import { createContext, useContext } from "react";

import useRegisterSocket from "./hooks/useRegisterSocket";
import { RegisterSocket, RegisterSocketContextType } from "./registerSocketContext.types";

export const RegisterSocketContext = createContext<RegisterSocketContextType>(
  {} as RegisterSocketContextType,
);

const RegisterSocketProvider = (props: any) => {
  const { socket } = useRegisterSocket();

  const registerSocket: RegisterSocket = {
    emit: (ev, data) => socket?.emit(ev, data),
    off: (ev) => socket?.off(ev),
    removeAllListeners: (ev) => socket?.removeAllListeners(ev),
    on: (ev, listeners) => {
      socket?.hasListeners(ev) || socket?.on(ev, listeners);
    },
  };

  return (
    <RegisterSocketContext.Provider value={{ registerSocket }}>
      {props.children}
    </RegisterSocketContext.Provider>
  );
};

export const useRegisterSocketContext = () => useContext(RegisterSocketContext);

export default RegisterSocketProvider;
