import "./subscriptionStatus.styles.scss";

import { useTranslation } from "react-i18next";

import { useMembershipQuery } from "../../../../../hooks/membership/queries";
import useAmzAccountSelector from "../../../../../store/useSelector/useAmzAccountSelector";
import { Typhography } from "../../../../atoms";
import IconCustom from "../../../../atoms/icons/IconCustom";
import useMembershipTime from "./hooks/useMembershipTime";

const SubscriptionStatus = () => {
  const { t } = useTranslation(["common", "membership"]);
  const { amzAccount } = useAmzAccountSelector();
  const { membership } = useMembershipQuery(amzAccount?._id);
  const { expirationTime, remainingTime } = useMembershipTime();

  return (
    <div>
      <div>
        <Typhography type="h-4" className="mb-2">
          {t("membership:status.subscription-status")}
        </Typhography>
        <div className="is-flex">
          <IconCustom typeIcon="cube" />
          <Typhography type="p-l-b" className="pl-3">
            ×××× ×××× ×××× {membership?.accountPayments?.[0]?.cardLast4 ?? "××××"}
          </Typhography>
        </div>
        <Typhography type="" className="mf-text-secondary mf-paragraph mt-2">
          {t("membership:status.billing-expiration")}{" "}
          {expirationTime.format("YYYY, MMM DD, hh a")}.
        </Typhography>
        <Typhography type="p-r-r" className="mf-text-secondary mt-2">
          {t("membership:status.remaining-time", {
            days: remainingTime.days,
            hours: remainingTime.hours,
            count: remainingTime.days,
          })}
          .
        </Typhography>

        <div className="my-3">
          {/* 
          <Typhography
            className="mf-info"
            type=""
            onClick={() => console.log("change")}
          >
            Change subscription
          </Typhography> */}
          {/* <Typhography className="mf-danger mt-1" type="" onClick={cancelMembership}>
            Cancel membership
          </Typhography> */}
        </div>
      </div>
      {/* <TitleModalSwitch
        className="mf-ss-switch mt-3"
        modalTitle={{
          title: "Automatic Renewals",
          description:
            "Do you want to enabled automatic renewals on your subscription?",
        }}
      /> */}
    </div>
  );
};

export default SubscriptionStatus;
