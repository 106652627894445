import { newRefNotificationActions } from "../reducers/newRefNotification/newRefNotification";
import useActions from "./useActions";

const { setLastCheckTime, setNotificationsCount, clearNotifications } =
  newRefNotificationActions;

const useRefNotificationDispatch = () => {
  return {
    setLastCheckTimeD: useActions(setLastCheckTime),
    setNotificationsCountD: useActions(setNotificationsCount),
    clearNotificationsD: useActions(clearNotifications),
  };
};

export default useRefNotificationDispatch;
