import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";

const ChangePasswordFormScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordFormScreen" */ "../../components/pages/commons/resetPassword/ChangePasswordFormScreen"
    ),
);
const EmailFormScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "EmailFormScreen" */ "../../components/pages/commons/resetPassword/EmailFormScreen"
    ),
);
const FinishResetScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "FinishResetScreen" */ "../../components/pages/commons/resetPassword/FinishResetScreen"
    ),
);
const VerifyOtpScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "VerifyOtpScreen" */ "../../components/pages/commons/verifyOtp/VerifyOtpScreen"
    ),
);

const ResetPasswordRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route index element={<EmailFormScreen />} />
        <Route path="verify-otp" element={<VerifyOtpScreen />} />
        <Route path="new" element={<ChangePasswordFormScreen />} />
        <Route path="finish" element={<FinishResetScreen />} />
      </Routes>
    </Suspense>
  );
};

export default ResetPasswordRoutes;
