import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import Space from "../space/Space";
import { FooterLinkProps } from "./FooterLink.type";

const FooterLink = (props: FooterLinkProps) => {
  const { children, ...rest } = props;

  return (
    <Link className="magicflex-create-account" {...rest}>
      {children} <Space amount={0.6} />
      <span className="is-right is-size-7">
        <FontAwesomeIcon icon="chevron-right" />
      </span>
    </Link>
  );
};

FooterLink.defaultProps = {
  to: "",
} as Partial<FooterLinkProps>;

export default FooterLink;
