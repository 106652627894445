import { useEffect } from "react";

import { envs } from "../../../../../config/env";
import useGet from "../../../../../https/hooks/get/useGet";
import { GetElements } from "../../../../../https/hooks/get/useGet.types";
import { useAmzAccountDispatch, useSetUpFilters } from "../../../../../store/useDispatch";

const useLoadStations = () => {
  const { data: stationsReq, get } = useGet({});

  const { addStationsD } = useAmzAccountDispatch();
  const { setUpStationD } = useSetUpFilters();

  useEffect(() => {
    if (stationsReq.success) {
      addStationsD(stationsReq.data);
      setUpStationD(stationsReq.data);
    }
  }, [stationsReq]);

  return {
    stationsReq,
    loadStations: (id?: string) => {
      const req: GetElements = {
        code: "R-LOAD-STATIONS",
        url: envs.URL_API + "/V1/userFilters/userStations",
        pathData: "data.data",
      };

      if (id) {
        req.config = {
          headers: {
            AmzUserId: id,
          },
        };
      }
      get(req);
    },
  };
};

export default useLoadStations;
