import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectIsOpen = (state: RootState) => state.menu.isOpen;

const useMenuSelector = () => {
  return {
    isOpen: useAppSelector(selectIsOpen),
  };
};

export default useMenuSelector;
