import { AmzAccount } from "../../../hooks/useAmzAccount/useAmzAccount.types";
import { AmzAccountsAF } from "../../types/amzAccounts.types";

export const deleteAmzAccount: AmzAccountsAF<string> = (state, action) => {
  state.amzAccounts =
    state.amzAccounts.filter((account) => account._id !== action.payload) || [];
};

export const addAmzAccount: AmzAccountsAF<AmzAccount> = (state, action) => {
  action.payload && state.amzAccounts.push(action.payload);
};

export const addAmzAccounts: AmzAccountsAF<AmzAccount[]> = (state, action) => {
  state.amzAccounts = [...state.amzAccounts, ...(action.payload || [])];
};

export const setAmzAccounts: AmzAccountsAF<AmzAccount[]> = (state, action) => {
  state.amzAccounts = action.payload || [];
};

export const startLoading: AmzAccountsAF = (state) => {
  state.loading = true;
};

export const stopLoading: AmzAccountsAF = (state) => {
  state.loading = false;
};

export const setError: AmzAccountsAF<string> = (state, action) => {
  state.error = action.payload || "";
};

export const resetState: AmzAccountsAF = (state) => {
  state.amzAccounts = [];
  state.loading = false;
  state.error = "";
};
