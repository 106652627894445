import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useBot } from "../../../../hooks";
import { useAmzAccountSelector } from "../../../../store/useSelector";
import useInstantOffersSelector from "../../../../store/useSelector/useInstantOffersSelector";
import { getButtonClasses, getClasses } from "../navbarBottom.functions";

const useNavbarBottom = () => {
  const navigate = useNavigate();
  const { radarIconEnabled, isActive } = useInstantOffersSelector();
  const location = useLocation();

  const { amzAccount } = useAmzAccountSelector();

  const { toggleActiveBot, isBotRunning } = useBot();

  const toggleActiveBotCB = useCallback(() => {
    // bot was actived before
    if (location.pathname !== "/") navigate("/");

    setTimeout(toggleActiveBot, 50);
  }, [location.pathname, navigate, toggleActiveBot]);

  return {
    toggleActiveBot: toggleActiveBotCB,
    openScreen: (path: string) => {
      if (location.pathname !== path) navigate(path);
      else navigate("/");
    },
    className: getClasses(radarIconEnabled),
    buttonClassName: getButtonClasses(isBotRunning),
    isActionsActive: amzAccount,
    isBotRunning,
    locationPath: location.pathname,
    radarIconEnabled,
    isActive,
  };
};

export default useNavbarBottom;
