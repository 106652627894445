import { navbarBottomDefaultProps } from "./navbarBottomText.constants";
import { NavbarBottomTextProps } from "./navbarBottomText.types";

const NavbarBottomText = (props: NavbarBottomTextProps) => {
  const { children, text, className, ...rest } = props;

  const texts = (text || children || "")
    .split(" ")
    .map((word) => word.replace(/_/g, " "));

  return (
    <div
      className={`magicflex-text-sora has-text-weight-bold magicflex-bar-bottom-label ${className}`}
      {...rest}
    >
      {texts.map((text, index) => (
        <p key={index}>{text.toUpperCase()}</p>
      ))}
    </div>
  );
};

NavbarBottomText.defaultProps = navbarBottomDefaultProps;

export default NavbarBottomText;
