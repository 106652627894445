import { Day } from "../../../components/organisms/filters/setUpDays/hooks/formik/useFormikDays.types";
import { ActionFilter, SetUpFilters, Stations } from "../../types/filters.types";
import {
  CLEAN_DAYS,
  CLEAN_SET_UP_PRICE,
  CLEAN_STATION,
  SET_DAYS,
  SET_START_AT_END,
  SET_START_AT_START,
  SET_STATION,
  SET_UP_PRICE,
} from "./actions";
// reducer
const initialState: SetUpFilters = {
  startPrice: 0,
  startAt: {
    days: [],
  },
  stations: [],
};

export default function setUpFilters(
  state: SetUpFilters = initialState,
  action: ActionFilter,
): SetUpFilters {
  const stateCopy = { ...state };
  switch (action?.type) {
    case SET_UP_PRICE: {
      stateCopy.startPrice = (action?.payload?.startPrice as number) || 0;
      return stateCopy;
    }
    case CLEAN_SET_UP_PRICE: {
      stateCopy.startPrice = initialState.startPrice;
      return stateCopy;
    }
    case SET_DAYS: {
      stateCopy.startAt = {
        ...stateCopy.startAt,
        days: action?.payload?.days as Day[],
      };
      return stateCopy;
    }
    case CLEAN_DAYS: {
      stateCopy.startAt = {
        ...stateCopy.startAt,
        days: initialState.startAt.days,
      };
      return stateCopy;
    }
    case SET_STATION: {
      stateCopy.stations = action?.payload?.stations as Stations;
      return stateCopy;
    }
    case CLEAN_STATION: {
      stateCopy.stations = initialState.stations;
      return stateCopy;
    }
    case SET_START_AT_START: {
      const payloadDay = action?.payload?.day;

      const days = stateCopy.startAt.days.map((day) =>
        // eslint-disable-next-line eqeqeq
        day.day == payloadDay.day ? { ...day, start: payloadDay.start } : day,
      );

      stateCopy.startAt = { days };
      return stateCopy;
    }
    case SET_START_AT_END: {
      const payloadDay = action?.payload?.day;

      const days = stateCopy.startAt.days.map((day) =>
        // eslint-disable-next-line eqeqeq
        day.day == payloadDay.day ? { ...day, end: payloadDay.end } : day,
      );

      stateCopy.startAt = { days };
      return stateCopy;
    }
    default: {
      return state;
    }
  }
}
