import { IconCustom } from "../../atoms";
import { ITimelineStatusIconProps, StatusType } from "./TimelineStatus.types";

const TimelineStatusIcon = (props: ITimelineStatusIconProps) => {
  const statusTypes: StatusType = {
    failed: {
      classType: "danger",
      icon: {
        typeIcon: "cross",
      },
    },
    stoped: {
      classType: "warning",
      icon: {
        typeIcon: "pause",
      },
    },
    success: {
      classType: "success",
      icon: {
        typeIcon: "cube",
      },
    },
    process: {
      classType: "process",
      icon: {
        typeIcon: "play",
      },
    },
  };
  const status = statusTypes[props.statusType ?? "failed"];
  return (
    <div className={`icon-event-log icon-event-log-${status.classType}`}>
      <IconCustom typeIcon={status.icon.typeIcon} fill="#fff" width={11} height={11} />
    </div>
  );
};
TimelineStatusIcon.defaultProps = {
  statusType: "failed",
};
export default TimelineStatusIcon;
