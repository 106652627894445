import { createSlice } from "@reduxjs/toolkit";

import { IRegisterState } from "../../types/register.types";
import * as reducers from "./actions";

const initialState: IRegisterState = {
  authType: undefined,
  captchaUrl: "",
  email: "",
  captcha: undefined,
  otp: undefined,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers,
});

export const { clean, init, setCaptcha, setOtp, setCvf } = registerSlice.actions;

export default registerSlice.reducer;
