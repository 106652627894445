import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { envs } from "../../../../config/env";
import useGet from "../../../../https/hooks/get/useGet";
import { getTransactionDuration } from "../walletTransactions.functions";
import {
  GetTransactionDetailsResponse,
  ITransactionDetails,
} from "../walletTransactions.types";

const useGetTransactionDetails = () => {
  const { data, get } = useGet({ spinnerFullScreen: true });
  const { t } = useTranslation(["home"]);

  const details = useMemo((): ITransactionDetails => {
    const { _id, createAt, amount, description, type, hoursWorked, paymentMethod } =
      data.data as GetTransactionDetailsResponse;

    const desc =
      type === "income"
        ? description
        : description?.toLowerCase().includes("block")
        ? t("home:wallet.block-captured")
        : t("home:wallet.instant-offer");

    const timeZone = moment.tz.guess();

    return {
      id: _id,
      date: moment(createAt).tz(timeZone).toDate(),
      amount: type === "expense" ? -amount : amount,
      description: desc,
      duration: getTransactionDuration(hoursWorked),
      paymentMethod: paymentMethod || type,
    };
  }, [data.data, t]);

  const getTransactionDetails = async (id: string) => {
    get({
      url: `${envs.URL_API}/V1/wallet/transactions/${id}`,
      code: "G-TRANSACTION-DETAILS",
      pathData: "data.data",
    });
  };

  return {
    getTransactionDetails,
    details,
  };
};

export default useGetTransactionDetails;
