import { User } from "../../../hooks/useUser/useUser.types";
import { UserAF } from "../../types/user.types";

export const setUser: UserAF<User> = (state, action) => {
  state.user = action.payload;
};

export const deleteUser: UserAF = (state) => {
  state.user = undefined;
};
