import { IconCustom } from "../../icons";
import useMagicBtnAnim from "./hooks/useMagicBtnAnim";
import { IMagicBtnProps } from "./magicBtn.types";

const MagicBtn = (props: IMagicBtnProps) => {
  const { isLoading, ...rest } = props;

  const { animatedElRef } = useMagicBtnAnim({ isAnimated: isLoading || false });

  return (
    <div {...rest} ref={animatedElRef}>
      <IconCustom typeIcon="magicflex-icon" width={48} height={48} />
    </div>
  );
};

export default MagicBtn;
