import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  useModalInfoDispatch,
  useOffersDispatch,
} from "../../../../../store/useDispatch";
import { BlockDetailsProps } from "../blockDetails.type";
import useDiscardBlock from "./useDiscardBlock";

const useBlockDetails = (params: BlockDetailsProps) => {
  const {
    blockId,
    blockPropertiesLabels,
    lat,
    lng,
    locationId,
    locationName,
    price,
    serviceAreaId,
  } = params;

  const navigate = useNavigate();
  const { setModalInfoCD } = useModalInfoDispatch();
  const { deleteCalendarOfferD } = useOffersDispatch();
  const { discardBlock, discardBlockResp } = useDiscardBlock();

  const onDiscardReservation = async () => {
    setModalInfoCD({
      enabled: true,
      modalType: "discard-block",
      handleAccept: () => discardBlock({ startTime: blockId, price, serviceAreaId }),
    });
  };

  useEffect(() => {
    if (discardBlockResp.success) {
      deleteCalendarOfferD(blockId);
      navigate("/", { replace: true });
    } else if (discardBlockResp.error)
      setModalInfoCD({ enabled: true, modalType: "discard-block-error" });
  }, [discardBlockResp, blockId, deleteCalendarOfferD, navigate, setModalInfoCD]);

  return {
    onDiscardReservation,
    blockPropertiesLabels,
    lat,
    lng,
    locationId,
    locationName,
  };
};

export default useBlockDetails;
