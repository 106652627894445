import React from "react";

import WizardPageIndicator from "../../molecules/wizardPageIndicator/WizardPageIndicator";
import { WizardProps } from "./wizard.types";

const Wizard = (props: WizardProps) => {
  const { activeStep } = props;

  const children = React.Children.toArray(props.children);

  const currentPage = children[activeStep || 0];

  return (
    <div>
      <WizardPageIndicator activeStep={activeStep || 0} steps={children.length} />
      {currentPage}
    </div>
  );
};
export default Wizard;
