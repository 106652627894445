import { play, setPlayed, stop } from "../reducers/logoIntro/logoIntro";
import useActions from "./useActions";

const useLogoIntroDispatch = () => {
  return {
    playD: useActions(play),
    stopD: useActions(stop),
    setPlayedD: useActions(setPlayed),
  };
};

export default useLogoIntroDispatch;
