import { useNavigate } from "react-router-dom";

import { envs } from "../../../../../config/env";
import { usePaymentIntentMutation, useUserQuery } from "../../../../../hooks";
import { useDisablePayment } from "../../../../../hooks/membership/useDisablePayment";
import { PaymentType } from "../../../../../hooks/payments/types";
import { usePaymentDispatch } from "../../../../../store/useDispatch";
import { useAmzAccountSelector, useUserSelector } from "../../../../../store/useSelector";
import { Helpers } from "../../../../../utils/helpers";
import { FormValues } from "./subscriptionPayment.types";

const typeMap = {
  [PaymentType.PAS45]: "61483aa5e6dcd5bb6b2c9c38",
  [PaymentType.PAS80]: "61483aa5e6dcd5bb6b2c9c39",
  [PaymentType.PAS140]: "61483aa5e6dcd5bb6b2c9c41",
};

export const useSubscriptionPayment = () => {
  const stripe = useSubscriptionPaymentStripe();
  const zelle = useSubscriptionPaymentZelle();

  const { user } = useUserSelector();
  const { userQuery } = useUserQuery(user?._id);

  return (userQuery.data?.paymentProvider || envs.PAYMENT_PROVIDER) === "zelle"
    ? zelle
    : stripe;
};

export const useSubscriptionPaymentStripe = () => {
  const navigate = useNavigate();

  const { initPaymentD } = usePaymentDispatch();

  const handleSubmit = (values: FormValues) => {
    initPaymentD({
      membershipTypeId: typeMap[values.type as keyof typeof typeMap],
      paymentType: "subscription",
    });
    navigate("/subscription/checkout");
  };

  const { onSubmit } = useDisablePayment({
    handleSubmit,
  });

  return {
    onSubmit,
  };
};

export const useSubscriptionPaymentZelle = () => {
  const navigate = useNavigate();

  const { createPaymentIntentMutation } = usePaymentIntentMutation();
  const { amzAccount } = useAmzAccountSelector();

  const handleSubmit = async (values: FormValues) => {
    if (!values?.type) return;

    if (!amzAccount)
      //TODO: Add error message
      return;

    const type = values.type;

    const code = Helpers.generatePaymentCode(type);

    const paymentIntent = await createPaymentIntentMutation.mutateAsync({
      amzUserId: amzAccount?._id,
      code,
    });

    if (!paymentIntent)
      //TODO: Add error message
      return;

    navigate({
      pathname: "/zelle",
      search: `?code=${code}`,
    });
  };

  const { onSubmit } = useDisablePayment({
    handleSubmit,
  });

  return {
    onSubmit,
  };
};
