import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import RegisterSocketProvider from "../../../contexts/registerSocket/RegisterSocketContext";
import { SocketProvider } from "../../../contexts/socketContext/SocketContext";
import { Spinner } from "../../atoms";
import { useLoadAccounts, useLoadAmzAccount } from "./hooks";

const MenuScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "MenuScreen"  */ "../../pages/private/menuScreen/MenuScreen"
    ),
);

const MainLayout = () => {
  useLoadAccounts();
  useLoadAmzAccount();

  return (
    <SocketProvider>
      <Suspense fallback={<Spinner />}>
        <MenuScreen />
      </Suspense>
      <RegisterSocketProvider>
        <Outlet />
      </RegisterSocketProvider>
    </SocketProvider>
  );
};

export default MainLayout;
