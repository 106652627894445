import { useState } from "react";

import { useUser } from "..";
import encryptPassword from "../../tools/encryptPassword";
import { getUserIP, getUserPosition } from "../../utils";
import serviceAPI from "../../utils/services/servicesAPI";

const useAuth = () => {
  const { setUser, deleteUser, user } = useUser();

  const [loading, setLoading] = useState<boolean>(false);

  //TODO: add types
  const login = async (values: any) => {
    const encryptPass = encryptPassword(values.password);

    setLoading(true);

    const setValues = {
      password: encryptPass,
      email: values.email,
    };

    const position = await getUserPosition();
    const ip = await getUserIP();

    const {
      data: user,
      request,
      message,
    } = await serviceAPI.loginUser({ ...setValues, position }, { ip });

    if (request) {
      setUser(user);

      return { user };
    }
    setLoading(false);

    return { message };
  };

  const logout = async () => {
    setLoading(true);
    deleteUser();
    setLoading(false);
    serviceAPI.logout();
  };

  const register = async (args: any) => {
    const encryptedPassword = encryptPassword(args.password);

    const { passwordverify, ...values } = args;

    setLoading(true);

    const { request, message } = await serviceAPI.registerUser({
      ...values,
      password: encryptedPassword,
    });

    setLoading(false);
    return { success: request, message };
  };

  const requestPasswordReset = async (email: string) => {
    setLoading(true);

    const { request, message } = await serviceAPI.forgotPassword(email);

    setLoading(false);

    return { success: request, message };
  };

  const changePassword = async (email: string, password: string) => {
    setLoading(true);
    const { request, message } = await serviceAPI.changePassword(password, email);
    setLoading(false);
    return { success: request, message };
  };

  return {
    setUser,
    login,
    loading,
    logout,
    register,
    requestPasswordReset,
    changePassword,
    isAuthenticated: !!user,
  };
};

export default useAuth;
