import { useTranslation } from "react-i18next";

import { envs } from "../../../config/env";
import { MagicBtn } from "../../atoms";
import { NavbarBottomLabel } from "../../molecules";
import { useGetSavedFilters } from "../../pages/private/instantOffers/hooks/useGetSavedFilters";
import useNavbarBottom from "./hooks/navbarBottom";

const isBotEnabled = envs.ACTIVATE_BOT;

const NavbarBottom = () => {
  useGetSavedFilters();

  const {
    className,
    buttonClassName,
    toggleActiveBot,
    isBotRunning,
    isActionsActive,
    locationPath,
    openScreen,
    radarIconEnabled,
    isActive,
  } = useNavbarBottom();

  const { t } = useTranslation(["home"]);

  return (
    <div className={className}>
      {isBotEnabled && (
        <>
          <NavbarBottomLabel
            onClick={() => openScreen("/search-filters")}
            icon={{ typeIcon: "search", color: "#37465C" }}
            text={t("home:search-filters")}
            className={locationPath === "/search-filters" ? "mf-success" : ""}
            disabled={isBotRunning || !isActionsActive}
          />
          <MagicBtn
            isLoading={isBotRunning}
            className={buttonClassName}
            onClick={toggleActiveBot}
          />
        </>
      )}

      <NavbarBottomLabel
        position="right"
        icon={{
          typeIcon: !radarIconEnabled ? "flame" : "radar",
          color: isActive ? "#00C363" : "#37465C",
        }}
        text={t("home:instant-offers")}
        onClick={() => openScreen("/instant-offers")}
        className={locationPath === "/instant-offers" ? "mf-warning" : ""}
        disabled={!isActionsActive}
      />
    </div>
  );
};

export default NavbarBottom;
