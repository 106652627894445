import { envs } from "../../../../../config/env";
import usePost from "../../../../../https/hooks/post/usePost";
import { useFiltersSelector } from "../../../../../store/useSelector";
import useForm from "./setUpPriceForm/useForm";
import { IUseSetUpPrice } from "./useSetUpPrice.types";

const useSetUpPrice = ({ onClose }: IUseSetUpPrice) => {
  const { startPrice } = useFiltersSelector();
  const { list } = useForm();

  const { post, dataPost } = usePost({ spinnerFullScreen: true });

  /**
   * It is placed because when the modal closes all the states are deleted,
   * then you have to wait a bit for the server to respond and then close,
   * to be able to show the error if there is an error
   **/
  if (Object.keys(dataPost.error).length > 0 || Object.keys(dataPost.data).length > 0) {
    setTimeout(onClose, 100);
  }

  const handleCloseModalPrice = () => {
    post({
      code: "P-START-PRICE",
      url: envs.URL_API + "/V1/userFilters/priceSelected",
      data: { priceSelected: startPrice },
    });
  };

  return { list, handleCloseModalPrice };
};

export default useSetUpPrice;
