export const typhographyList = {
  "h-1": "head head-1",
  "h-2": "head head-2",
  "h-3": "head head-3",
  "h-4": "head head-4",
  "h-5": "head head-5",
  "p-xl-b": "p p-xl p-xl-b",
  //* Paragraph large [ regular, bold ]
  "p-l-r": "p p-l p-l-r",
  "p-l-b": "p p-l p-l-b",
  //* Paragraph regular [ regular, bold ]
  "p-r-r": "p p-r p-r-r",
  "p-r-b": "p p-r p-r-b",
  //* Paragraph small [ regular, bold ]
  "p-s-r": "p p-s p-s-r",
  "p-s-b": "p p-s p-s-b",
  //* Paragraph extra small [ regular, bold ]
  "p-xs-r": "p p-xs p-xs-r",
  "p-xs-b": "p p-xs p-xs-b",
  /* Overline [ regular, bold ] */
  "overline-r": "overline overline-r",
  "overline-b": "overline overline-b",
  /* CTA/Regular */
  "button-text-r": "button-text button-text-r",
  "button-text-b": "button-text button-text-b",

  deafult: "",
};
