import { useState } from "react";

import NormalSelect from "./NormalSelect";
import { SelectCustomProps } from "./selectCustom.types";

const COUNTRIES_FLAG_API_URL = "https://countryflagsapi.com/svg/";

export const CountrySelect = (props: SelectCustomProps) => {
  const [iconUrl, setIconUrl] = useState<string | undefined>(props.iconUrl);

  const handleChange = (value: string) => {
    value && setIconUrl(`${COUNTRIES_FLAG_API_URL}${value.toLowerCase()}`);
  };

  return <NormalSelect {...props} iconUrl={iconUrl} onChange={handleChange} />;
};

export default CountrySelect;
