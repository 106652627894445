import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getBlocksLostLimitReached,
  setBlocksLostLimitReached,
} from "./botConfig.functions";

export const useBotConfig = () => {
  const lostLimitReachedQuery = useQuery(["bot-config", "lost-limit-reached"], () =>
    getBlocksLostLimitReached(),
  );

  const lostLimitReachedMutation = useMutation({
    mutationFn: setBlocksLostLimitReached,
    onSettled: () => lostLimitReachedQuery.refetch(),
  });

  return {
    lostLimitReachedQuery,
    lostLimitReachedMutation,
  };
};
