import { useCallback, useEffect } from "react";

import useAmzAccountDispatch from "../../store/useDispatch/useAmzAccountDispatch";
import useAmzAccountSelector from "../../store/useSelector/useAmzAccountSelector";
import { AmzAccountExtraData } from "./useAmzAccount.types";
import useAmzAccountApi from "./useAmzAccountApi";

const useAmzAccountExtraData = () => {
  const { amzAccount, extraData } = useAmzAccountSelector();
  const { setExtraDataD } = useAmzAccountDispatch();

  const { getExtraData, extraDataResp } = useAmzAccountApi();

  const setExtraData = (amzAccountExtraData: AmzAccountExtraData) => {
    if (amzAccountExtraData) setExtraDataD(amzAccountExtraData);
  };

  const loadExtraData = useCallback(
    (id?: string) => {
      if (!id && !amzAccount) return;

      getExtraData(id);
    },
    [amzAccount],
  );

  const reloadExtraData = useCallback(async () => {
    if (!amzAccount || extraData) return;
    loadExtraData();
  }, [amzAccount, extraData, loadExtraData]);

  useEffect(() => {
    if (extraDataResp.success) setExtraData(extraDataResp.data);
  }, [extraDataResp]);

  return {
    loadExtraData,
    reloadExtraData,
    loading: extraDataResp.loading,
    cleanExtraData: () => setExtraDataD(undefined),
  };
};

export default useAmzAccountExtraData;
