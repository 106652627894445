import "./referredStatusItem.styles.scss";

import { Badge } from "@mui/material";

import ReferredIcon from "../referredIcon/ReferredIcon";
import Typhography from "../typhography/Typhography";
import { IReferredStatusItem } from "./referredStatusItem.types";

const statusValues = {
  PENDING: "referral",
  PAID: "active",
  INACTIVE: "inactive",
};

const ReferredStatusItem = (props: IReferredStatusItem) => {
  const { handleKeyToggleStatus, toggleStatus, activeStatus, status, title, count } =
    props;

  return (
    <Badge
      badgeContent={count}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: "#78ABF8",
          color: "#fff",
        },
      }}
    >
      <div
        className={`mf-referred_status_item btn ${
          activeStatus === status ? "active" : ""
        }`}
        tabIndex={0}
        role="button"
        onKeyDown={handleKeyToggleStatus(status)}
        onClick={toggleStatus(status)}
      >
        <ReferredIcon className={`${statusValues[status]} border mr-1`} />
        <Typhography
          type="h-3"
          className="is-uppercase"
          style={{ textTransform: "capitalize" }}
        >
          {title}
        </Typhography>
      </div>
    </Badge>
  );
};

export default ReferredStatusItem;
