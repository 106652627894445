import auth from "../../locales/es/auth.json";
import common from "../../locales/es/common.json";
import filters from "../../locales/es/filters.json";
import home from "../../locales/es/home.json";
import informative from "../../locales/es/informative.json";
import instantOffers from "../../locales/es/instant-offers.json";
import membership from "../../locales/es/membership.json";
import menu from "../../locales/es/menu.json";
import popup from "../../locales/es/popup.json";
import referred from "../../locales/es/referred.json";
import register from "../../locales/es/register.json";
import support from "../../locales/es/support.json";
import termsConditions from "../../locales/es/terms-conditions.json";

export default {
  auth,
  common,
  filters,
  home,
  informative,
  membership,
  menu,
  popup,
  referred,
  register,
  support,
  "instant-offers": instantOffers,
  "terms-conditions": termsConditions,
};
