import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectRegister = (state: RootState) => state.register;

const useRegisterSelector = () => {
  return useAppSelector(selectRegister);
};

export default useRegisterSelector;
