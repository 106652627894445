import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const instantOffersSelector = (state: RootState) => state.instantOffers;

const useInstantOffersSelector = () => {
  return useAppSelector(instantOffersSelector);
};

export default useInstantOffersSelector;
