import { magicAPI } from "../../api";
import { PostCreatePaymentIntentData, PostCreatePaymentIntentResponse } from "./types";

export const getPendingPayments = async (amzUserId?: string) => {
  if (!amzUserId) throw new Error("amzUserId is required");

  const resp = await magicAPI.get<PostCreatePaymentIntentResponse[]>(
    `/payments/${amzUserId}?state=pending&limit=10`,
  );

  if (resp.status !== 200) {
    throw new Error("Failed to get pending payments");
  }

  if (!resp.data) {
    throw new Error("Failed to get pending payments");
  }

  return resp.data;
};

export const createPaymentIntent = async (data: PostCreatePaymentIntentData) => {
  const resp = await magicAPI.post<PostCreatePaymentIntentResponse>("/payments", data);

  if (resp.status !== 201) {
    throw new Error("Failed to create payment intent");
  }

  if (!resp.data) {
    throw new Error("Failed to create payment intent");
  }

  return resp.data;
};

export const deletePayment = async (paymentId: string) => {
  const resp = await magicAPI.delete(`/payments/${paymentId}`);

  if (resp.status !== 204 && resp.status !== 200) {
    throw new Error("Failed to delete payment");
  }
};
