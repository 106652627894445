import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectAmzAccount = (state: RootState) => state.amzAccount.amzAccount;
const selectExtraData = (state: RootState) => state.amzAccount.extraData;
const selectDevices = (state: RootState) => state.amzAccount.devices;

const useAmzAccountSelector = () => {
  return {
    amzAccount: useAppSelector(selectAmzAccount),
    extraData: useAppSelector(selectExtraData),
    devices: useAppSelector(selectDevices),
  };
};

export default useAmzAccountSelector;
