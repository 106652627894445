import {
  ActionSpinnerFullScreen,
  IStateSpinnerFullScreen,
} from "../../types/spinnerFullScreen.types";
import { CLEAN_SPINNER_FULL_SCREEN, SET_SPINNER_FULL_SCREEN } from "./actions";

// reducer
const initialState: IStateSpinnerFullScreen = {
  color: "white",
};

export default function spinnerFullScreen(
  state: IStateSpinnerFullScreen = initialState,
  action: ActionSpinnerFullScreen,
): IStateSpinnerFullScreen {
  let stateCopy = { ...state };
  switch (action?.type) {
    case SET_SPINNER_FULL_SCREEN: {
      stateCopy = action?.payload?.spinner as IStateSpinnerFullScreen;
      return stateCopy;
    }
    case CLEAN_SPINNER_FULL_SCREEN: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
