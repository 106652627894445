import { envs } from "../../config/env";
import useGet from "../../https/hooks/get/useGet";

const API_URL = envs.URL_API + "/V1";

const useGetConfirmPayment = () => {
  const { data, get } = useGet({ spinnerFullScreen: true });

  return {
    confirmPaymentResp: data,
    confirmPayment: (paymentId: string) => {
      get({
        code: "G_CONFIRM_PAYMENT",
        url: API_URL + "/accountPayment/confirm/" + paymentId,
        pathData: "data.data",
      });
    },
  };
};

export default useGetConfirmPayment;
