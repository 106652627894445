import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Spinner } from "../../components/atoms";
import { MainLayout } from "../../components/layouts";
import BotLayout from "../../components/layouts/bot/BotLayout";
import { useClarity } from "../../hooks";
import { useUserSelector } from "../../store/useSelector";
import ProtectedRoute from "../ProtectedRoute";
import SubscriptionRoute from "../subscription/SubscriptionRoute";
import { ZelleRoutes } from "../zelle";
import AmzAccountsRoutes from "./AmzAccountsRoutes";
import ChangePasswordRoutes from "./ChangePasswordRoutes";
import PremiumRoutes from "./PremiumRoutes";
import SubscriptionRoutes from "./SubscriptionRoutes";
import SupportRoutes from "./SupportRoutes";

const HomeScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "HomeScreen" */
      "../../components/pages/private/homeScreen/HomeScreen"
    ),
);
const BlockDetailsScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "BlockDetailsScreen" */ "../../components/pages/private/blockDetailsScreen/BlockDetailsScreen"
    ),
);
const InstantOffersScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "InstantOffersScreen" */ "../../components/pages/private/instantOffers/InstantOffersScreen"
    ),
);
const SearchFiltersScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "SearchFiltersScreen" */ "../../components/pages/private/filters/searchFilters/SearchFiltersScreen"
    ),
);
const EditMagicAccScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "EditMagicAccScreen" */ "../../components/pages/settings/editMagicAccount/EditMagicAccScreen"
    ),
);

const ReferralScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralScreen" */ "../../components/pages/private/referral/ReferralScreen"
    ),
);

const ReferredScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferredScreen" */ "../../components/pages/private/referred/ReferredScreen"
    ),
);

const MainRoutes = () => {
  const { user } = useUserSelector();

  useClarity();

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route
          element={
            <ProtectedRoute isAuthenticated={!!user}>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route element={<SubscriptionRoute />}>
            <Route element={<BotLayout />}>
              <Route index element={<HomeScreen />} />
              <Route path="instant-offers" element={<InstantOffersScreen />} />
              <Route path="search-filters" element={<SearchFiltersScreen />} />
            </Route>
            <Route path="block-details" element={<BlockDetailsScreen />} />
            {/* <Route path="profile" element={<EditProfile />} /> */}
            {/* <Route path="device/*" element={<DeviceRoutes />} /> */}
            <Route path="wallet" element={<Navigate to="/?tab=wallet" />} />
          </Route>
          <Route path="zelle/*" element={<ZelleRoutes />} />
          <Route path="referral" element={<ReferralScreen />} />
          <Route path="referred" element={<ReferredScreen />} />
          <Route path="edit-account" element={<EditMagicAccScreen />} />
          <Route path="accounts/*" element={<AmzAccountsRoutes />} />
          <Route path="subscription/*" element={<SubscriptionRoutes />} />
          <Route path="premium/*" element={<PremiumRoutes />} />
          <Route path="change-password/*" element={<ChangePasswordRoutes />} />
          <Route path="support/*" element={<SupportRoutes />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
