import { useMemo } from "react";

import { envs } from "../../../../../../config/env";
import usePost from "../../../../../../https/hooks/post/usePost";

const useCreatePayment = () => {
  const { dataPost, post } = usePost({ spinnerFullScreen: true });

  const createPayment = (data: any) => {
    post({
      url: envs.URL_API + "/V1/accountPayment",
      data,
      code: "P-WALLET-PAYMENT",
    });
  };

  const paymentIntentId = useMemo(
    () => dataPost?.data?.data?.paymentIntentId,
    [dataPost.data],
  );

  return {
    loading: dataPost.loading,
    createPayment,
    paymentIntentId,
  };
};

export default useCreatePayment;
