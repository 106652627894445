import { useUISelector } from "../../../store/useSelector";
import { ProgressBar } from "../../atoms";

interface Props {
  progress?: number;
  show?: boolean;
}

const ProgressBarFullScreen = (props: Props) => {
  const { progress, show } = props;

  const { progressBar } = useUISelector();

  if (!(show || progressBar.show)) return null;

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div style={{ width: "90%" }}>
        <ProgressBar progress={progress || progressBar.value} />
      </div>
    </div>
  );
};

export default ProgressBarFullScreen;
