import { UseButtonParams } from "../button.type";

const useButton = (params: UseButtonParams) => {
  const { color, isFullWidth, isLoading } = params;

  const lihterColor = ["transparent", "success-lighter", "primary"];
  const isLihter = lihterColor.find((lihterc) => lihterc === color);
  return {
    cssClasses: [
      "button",
      "mf-button",
      "px-2",
      `mf-bg-${color}`,
      `${isLihter ? "has-text-black" : "has-text-white"}`,
      `${isFullWidth ? "is-fullwidth" : ""}`,
      `${isLoading ? "is-loading" : ""}`,
    ].join(" "),
  };
};

export default useButton;
