import i18n from "i18next";

import { ModalInfoProps } from "../../../components/organisms/modalInfo/modalInfo.types";
import { useModalInfoDispatch } from "../../../store/useDispatch";
import { IError, ObjectErros } from "./usePost.types";

const typeError = (error: IError): ModalInfoProps => {
  const errors: ObjectErros = {
    "P-REGISTER": {
      enabled: false,
    },
    "P-ANIMATED-FALSE": {
      enabled: false,
    },
    "P-ANIMATED-TRUE": {
      enabled: false,
    },
    "P-LOCATIONS": {
      enabled: true,
      modalInfoProps: {
        type: "danger",
        ...i18n.t("popup:save-locations-error", {
          message: error.message,
          code: error.code,
          returnObjects: true,
          ns: ["popup"],
        }),
        image: {
          typeImage: "flexi-error",
        },
      },
    },
    DEFAULT: {
      enabled: true,
      modalInfoProps: {
        type: "danger",
        ...i18n.t("popup:unexpected-error", {
          code: error.code,
          returnObjects: true,
          ns: ["popup"],
        }),
        image: {
          typeImage: "flexi-danger",
        },
      },
    },
    "P-LOAD-BOT-STATUS": {
      enabled: false,
    },
    "P-STOP-BOT": {
      enabled: false,
    },
    MEMBERSHIP_REQUIRED: {
      enabled: false,
      modalInfoProps: {
        type: "success",
        ...i18n.t("popup:no-membership-found", { returnObjects: true, ns: ["popup"] }),
        image: {
          typeImage: "flexi-error",
        },
      },
    },
    P_ARRIVED: {
      enabled: true,
      modalInfoProps: {
        type: "danger",
        ...i18n.t("popup:error-confirm-arrived", { returnObjects: true, ns: ["popup"] }),
        image: {
          typeImage: "flexi-error",
        },
      },
    },
  };
  return errors[error.code] ?? errors["DEFAULT"];
};

const useControlErrorPost = () => {
  const { setModalInfoCD } = useModalInfoDispatch();
  return {
    controlsErrorsPost: (error: IError) => {
      const modalMessageProps = typeError(error);
      modalMessageProps.enabled && setModalInfoCD(modalMessageProps);
    },
  };
};
export default useControlErrorPost;
