/* action types */
import {
  ActionSpinnerFullScreen,
  IStateSpinnerFullScreen,
} from "../../types/spinnerFullScreen.types";
/* Used only in reducer */
export const SET_SPINNER_FULL_SCREEN = "magicFlex/SET_SPINNER_FULL_SCREEN";
export const CLEAN_SPINNER_FULL_SCREEN = "magicFlex/CLEAN_SPINNER_FULL_SCREEN";

/* Action Creators */
export const setSpinnerFullScreen = (
  spinner: IStateSpinnerFullScreen,
): ActionSpinnerFullScreen => {
  return {
    type: SET_SPINNER_FULL_SCREEN,
    payload: {
      spinner,
    },
  };
};
export const cleanSpinnerFullScreen = () => {
  return {
    type: CLEAN_SPINNER_FULL_SCREEN,
  };
};
