import { useCallback, useEffect, useRef, useState } from "react";

import { envs } from "../../../config/env";
import { useBotSelector } from "../../../store/useSelector";
import useGetBotState from "./useGetBotState";
import { useGetVMState } from "./useGetVMState";
import useProgressBar from "./useProgressBar";

const retryTries = envs.GET_BOT_STATE_TRIES || 20;

const useBotService = () => {
  const { getVMState, vmStateResp, clearVMStateResp } = useGetVMState();
  const { getBotState, botStateResp, clearBotStateResp } = useGetBotState();
  const [isVMUp, setIsVMUp] = useState(false);

  const { startingService } = useBotSelector();

  const { startProgress, hideProgress, updateProgress, processValue } = useProgressBar();

  const [isBotServiceUp, setIsBotServiceUp] = useState(false);

  const remainingTries = useRef(retryTries);

  /* user active bot */

  const updateBotServiceState = useCallback(() => {
    if (remainingTries.current === 0) remainingTries.current = retryTries;

    if (startingService) return;

    startProgress();
    getVMState();
  }, [getVMState, startingService, startProgress]);

  //when user is starting bot, update vm state on success and request bot service status
  useEffect(() => {
    if (!startingService) return;

    if (vmStateResp.data.data?.status && vmStateResp.data.data?.creatingVM) {
      setIsVMUp(true);
      processValue < 50 && updateProgress(50);
      setTimeout(getBotState, 10000);
    } else if (
      vmStateResp.data.data?.status &&
      vmStateResp.data.data?.creatingVM === false
    ) {
      setIsVMUp(true);
      processValue < 50 && updateProgress(50);
      getBotState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vmStateResp, startingService]);

  //when user is starting bot, get bot service status
  useEffect(() => {
    if (!startingService) return;

    if (botStateResp.data.data?.state === false && remainingTries.current > 0) {
      remainingTries.current = remainingTries.current - 1;
      clearBotStateResp();
      setTimeout(getBotState, 1000);
    } else if (remainingTries.current === 0) {
      hideProgress();
      setIsBotServiceUp(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botStateResp.data]);

  //update bot service state when bot service status request success
  useEffect(() => {
    if (botStateResp.data.data?.state) {
      updateProgress(100);
      setTimeout(hideProgress, 300);
      setIsBotServiceUp(true);
    }
  }, [botStateResp.data.data?.state]);

  /* app start */

  //at app start, get vm state
  useEffect(() => {
    getVMState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //at app start, get bot service status if vm is up
  useEffect(() => {
    if (startingService || isBotServiceUp) return;

    if (vmStateResp.data.data?.status) {
      clearVMStateResp();
      getBotState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vmStateResp.data.data?.status, startingService]);

  useEffect(() => {
    if (vmStateResp.error) hideProgress();
  }, [vmStateResp.error]);

  return {
    updateBotServiceState,
    isVMUp,
    isBotServiceUp,
    error: vmStateResp.error || remainingTries.current === 0,
    clearVMStateResp,
  };
};

export default useBotService;
