import "./wizard.styles.scss";

import { useEffect, useState } from "react";

import { WizardPageProps } from "./wizard.types";

const WizardPage = (props: WizardPageProps) => {
  const [animationClass, setAnimationClass] = useState("enter");

  useEffect(() => {
    return () => {
      setAnimationClass("");
    };
  }, []);

  return <div className={`mf-wizard-page ${animationClass}`}>{props.children}</div>;
};
export default WizardPage;
