/* eslint-disable jsx-a11y/media-has-caption */
import "./linkRegister.styles.scss";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import VideoPoster from "../../../../assets/images/register-link-demo.png";
import AddAccountVideo from "../../../../assets/videos/add-amazon-account.mp4";
import { AmazonBtn, Button, Typhography } from "../../../atoms";
import InputCustom from "../../../atoms/inputCustom/InputCustom";
import { LinkRegisterVideo } from "../../../molecules";
import { ILinkRegisterProps } from "./linkRegister.types";

const LinkRegisterTemplate = (props: ILinkRegisterProps) => {
  const { linkToAmazon, onSubmit } = props;
  const { t } = useTranslation(["common", "register"]);

  return (
    <div>
      <div className="has-text-centered mb-5">
        <Typhography type="p-l-b">{t("register:link-register.almost-there")}</Typhography>
        <Typhography type="p-r-r" className="mf-text-secondary">
          {t("register:link-register.link-account")}
        </Typhography>
      </div>

      <div className="is-flex is-align-items-center is-justify-content-center">
        <LinkRegisterVideo poster={VideoPoster} src={AddAccountVideo} />
      </div>

      <div className="mf-link_register_button is-flex is-align-items-center is-justify-content-center">
        <AmazonBtn link={linkToAmazon} />
      </div>

      <Formik
        onSubmit={onSubmit}
        initialValues={{
          link: "",
        }}
        validationSchema={Yup.object().shape({
          link: Yup.string().required(t("common:required")),
        })}
      >
        <Form>
          <InputCustom
            inputProps={{
              name: "link",
              type: "text",
              label: t("register:link-register.paste-link"),
            }}
          />
          <Button type="submit" className="mt-5 mb-2">
            {t("common:save-changes")}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

LinkRegisterTemplate.defaultProps = {
  linkToAmazon: "https://www.amazon.com",
  onSubmit: console.log,
} as ILinkRegisterProps;

export default LinkRegisterTemplate;
