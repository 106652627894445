import { useTranslation } from "react-i18next";

import useModal from "../../../../../molecules/modal/hook/useModal";
import { IStationsModalProps } from "../../../stationsModal/StationsModal.types";
import { generateBlocks } from "./stationsModal.functions";
import useStations from "./useStations";

const useStationsModal = (): IStationsModalProps => {
  const { saveStations, selectStation, stations } = useStations();
  const { t } = useTranslation(["filters"]);

  const modal = useModal({
    title: t("filters:stations.title"),
    onClose: saveStations,
  });

  return {
    ...modal,
    enablePrice: true,
    generateBlocks,
    onChange: selectStation,
    stations,
  };
};

export default useStationsModal;
