import "./amazonBtn.styles.scss";

import { useTranslation } from "react-i18next";

import { IconCustom } from "../../icons";
import Typhography from "../../typhography/Typhography";

type Props = {
  link: string;
  className?: string;
};

const AmazonBtn = (props: Props) => {
  const { t } = useTranslation(["common"]);

  return (
    <a
      href={props.link}
      target={"_blank"}
      rel={"noreferrer"}
      className={`mf-amz_btn ${props.className}`}
    >
      <IconCustom typeIcon="amazon-logo" className="mf-amz_btn_icon" />
      <Typhography type="p-l-b" render="span">
        {t("common:login-amz-flex")}
      </Typhography>
    </a>
  );
};
export default AmazonBtn;
