import { useEffect, useState } from "react";

import { useAmzAccounts } from "../../../../../hooks";
import { AmzAccount } from "../../../../../hooks/useAmzAccount/useAmzAccount.types";
import useLoadStations from "./useLoadStations";

const useSaveAmzUser = () => {
  const { addAmzAccount } = useAmzAccounts();
  const { loadStations, stationsReq } = useLoadStations();

  const [isUserSaved, setIsUserSaved] = useState(false);

  const saveUser = (amzUser: AmzAccount) => {
    loadStations(amzUser._id);

    addAmzAccount(amzUser);
  };

  useEffect(() => {
    if (stationsReq.success) setIsUserSaved(true);
  }, [stationsReq.success]);

  return {
    saveUser,
    isUserSaved,
  };
};

export default useSaveAmzUser;
