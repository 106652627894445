import PipeLineStatus from "../pipeLineStatus/TimelineStatus";
import { ITimelineStatusProps } from "../pipeLineStatus/TimelineStatus.types";
interface RecordsTimelinesProps {
  recordsTimelines: Array<ITimelineStatusProps>;
}
const RecordsTimelines = (props: RecordsTimelinesProps) => {
  const { recordsTimelines } = props;
  return (
    <div style={{ padding: "5px" }}>
      {recordsTimelines.map((recordTime, key) => {
        return <PipeLineStatus {...recordTime} key={key} />;
      })}
    </div>
  );
};

export default RecordsTimelines;
