import { useNavigate } from "react-router-dom";

import { useMenuDispatch } from "../../../../store/useDispatch";
import { NavigationHeader } from "../../../molecules";
import { SettingsLayoutProps } from "./SettingsLayout.types";

const SettingsLayout = (props: SettingsLayoutProps) => {
  const { children, header, openMenuOnBack, title, backUrl } = props;

  const { toggleMenu } = useMenuDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    openMenuOnBack && toggleMenu();
    backUrl ? navigate(backUrl) : navigate(-1);
  };

  return (
    <div className="pt-3 px-2">
      <NavigationHeader onBack={goBack} title={title} {...header} />
      <div className="px-4 mt-5">{children}</div>
    </div>
  );
};

SettingsLayout.defaultProps = {
  openMenuOnBack: true,
};

export default SettingsLayout;
