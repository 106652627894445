import "./textArea.styles.scss";

import { ErrorMessage, useField } from "formik";

import { TextAreaProps } from "./textArea.types";

const TextArea = (props: TextAreaProps) => {
  const { label, className, ...rest } = props;
  const [field, meta] = useField(rest);

  let statusClass = "";

  if (meta.touched) statusClass = meta.error ? "error" : "correct";

  return (
    <div className="">
      <label className="text-small">{label}</label>
      <textarea
        rows={6}
        className={`m-1 textarea ${statusClass} ${className}`}
        {...field}
        {...rest}
      />
      <ErrorMessage name={rest.name} component="span" className="has-text-danger" />
    </div>
  );
};

export default TextArea;
