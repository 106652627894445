import "./modalTitle.styles.scss";

import { Badge, IconCustom } from "../../atoms";
import { ModalTitleProps } from "./modalTitle.types";

const ModalTitle = (props: ModalTitleProps): JSX.Element => {
  const { title, description, icon, badge } = props;
  return (
    <div className="is-flex has-text-left is-size-7 py-1">
      {icon && (
        <div className="mg-container-icon">
          <IconCustom {...icon} className={"icon-custom"} />
        </div>
      )}
      <div className={icon ? "pl-3" : ""}>
        <h6 className="is-inline-block mr-2 welcome-feture-item-title has-text-black has-text-weight-bold magicflex-text-sora is-uppercase">
          {title}
        </h6>

        {badge && <Badge {...badge} />}
        <p className="magicflex-text-secondary">{description}</p>
      </div>
    </div>
  );
};

ModalTitle.defaultProps = {
  title: "Title",
  description: "Description",
} as Partial<ModalTitleProps>;

export default ModalTitle;
