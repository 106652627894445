import "./modal.styles.scss";

import { useEffect, useState } from "react";

import ModalHeader from "../modalHeader/ModalHeader";
import { ModalProps } from "./modal.type";

const Modal = (props: ModalProps) => {
  const {
    headerLeftComponent,
    title,
    onClose,
    isOpen: isOpenInitialValue = false,
    contentClassName,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    onClose && onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(isOpenInitialValue);
  }, [isOpenInitialValue]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className={`container-modal-gerneral transparent`}>
      <div className="modal-general box">
        {headerLeftComponent ? (
          <div className="is-flex is-justify-content-space-between is-align-items-center">
            {headerLeftComponent}
            <ModalHeader
              title={title}
              className={"pt-2 pb-3 modal-general-header"}
              closeBtn={{
                onClick: props.onCloseClick ? props.onCloseClick : closeModal,
              }}
            />
          </div>
        ) : (
          <ModalHeader
            title={title}
            className={"pt-2 pb-3 modal-general-header"}
            closeBtn={{
              onClick: props.onCloseClick ? props.onCloseClick : closeModal,
            }}
          />
        )}

        <main className={contentClassName}>{props.children}</main>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  contentClassName: "px-4",
};

export default Modal;
