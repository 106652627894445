import "./transactionDetails.styles.scss";

import { useTranslation } from "react-i18next";

import { CloseBtn, Typhography } from "../../atoms";
import { TransactionDetail } from "../../molecules";
import { transactions } from "./transactionDetails.constant";
import { ITransactionDetailsProps } from "./transactionDetails.types";

const TransactionDetails = (props: ITransactionDetailsProps) => {
  const { details, isOpen: open, onClose } = props;

  const { t } = useTranslation(["home"]);

  if (!open) return null;

  return (
    <div className="mf-transaction-d_container">
      <div className="mf-transaction-d_card">
        <div className="mf-transaction-d_card_header">
          <Typhography type="h-1">{t("home:wallet.transaction-details")}</Typhography>
          <CloseBtn color="#77879A" size={2.2} onClick={onClose} />
        </div>
        <div className="mf-transaction-d_card_body">
          {details.map((detail, idx) => (
            <TransactionDetail
              key={idx}
              {...detail}
              className={`py-3 ${detail.className}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

TransactionDetails.defaultProps = {
  details: transactions,
  onClose: () => console.log("close"),
  isOpen: true,
} as ITransactionDetailsProps;

export default TransactionDetails;
