import { useQuery } from "@tanstack/react-query";

import { useAmzAccountSelector } from "../../store/useSelector";
import { Pagination } from "../utils.hooks";
import { getLastedReferred, getReferredByAmzUser } from "./useReferred.functions";

export const useLatestReferred = () => {
  const { amzAccount } = useAmzAccountSelector();

  const latestReferredQuery = useQuery(
    ["referred", "latest"],
    () => getLastedReferred(amzAccount?._id),
    {
      enabled: !!amzAccount?._id,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
      staleTime: 1000 * 5, // 5 seconds
    },
  );

  return {
    latestReferredQuery,
  };
};

export const useReferredQuery = (
  amzUserId: string,
  config: {
    pagination: Pagination;
    filters?: {
      status?: string;
    };
  },
) => {
  const { pagination } = config;

  const query = useQuery({
    queryKey: ["referred", amzUserId, config.filters, pagination.page],
    queryFn: () => getReferredByAmzUser(amzUserId, config),
    enabled: !!amzUserId,
  });

  return {
    referredList: query.data?.referredList,
    referredListQuery: query,
    total: query.data?.total,
  };
};
