import { createSlice } from "@reduxjs/toolkit";

const logoIntro = createSlice({
  name: "logoIntro",
  initialState: {
    playing: false,
    played: false,
  },
  reducers: {
    play: (state) => {
      state.playing = true;
    },
    stop: (state) => {
      state.playing = false;
    },
    setPlayed: (state, action) => {
      state.played = action.payload;
    },
  },
});

export const { play, stop, setPlayed } = logoIntro.actions;

export default logoIntro.reducer;
