import moment from "moment";

import { IOnChangeArgs } from "../../../../../molecules/hourInput/hourInput.types";
import {
  BlocksOptions,
  IOnChangeEndArgs,
  IOnChangeStartArgs,
} from "../../setUpDay.types";
import { IStartAtTime } from "../formik/useFormikDays.types";
import { EventDays, GenerateBlocksParams } from "./setUpDaysForm.type";

//It is the event of the check button duration form button
export const onChangeEvent = (event: EventDays) => {
  const { value, setUpDaysD } = event;

  setUpDaysD(value);
  //here you call another logic then the user choes a select number
};

export const validateRange = (start: IStartAtTime, end: IStartAtTime) => {
  if (start.hour > end.hour) return false;

  if (start.hour === end.hour && start.minutes >= end.minutes) return false;

  return true;
};

const onChangeStart =
  (onChangeStartArgs: IOnChangeStartArgs) => (args: IOnChangeArgs) => {
    const { day, setStartAtD } = onChangeStartArgs;

    const { hour, minutes } = args;

    setStartAtD({
      day,
      start: {
        hour,
        minutes,
      },
    });
  };

const onChangeEnd = (onChangeEndArgs: IOnChangeEndArgs) => (args: IOnChangeArgs) => {
  const { day, setStartAtD } = onChangeEndArgs;
  const { hour, minutes } = args;

  setStartAtD({
    day,
    end: { hour, minutes },
  });
};

// All the inputs for a duration.
export const generateBlocks = (params: GenerateBlocksParams): BlocksOptions => {
  const { getFieldProps, days, setStartAtEndD, setStartAtStartD } = params;
  const list: BlocksOptions = [];

  for (let i = 0; i < 7; i++) {
    const day = moment().day(i).format("dddd");

    // eslint-disable-next-line eqeqeq
    const savedDay = days?.find((d) => d.day == i);

    const start: IStartAtTime = {
      hour: savedDay?.start.hour || 5,
      minutes: savedDay?.start.minutes || 0,
    };

    const end = {
      hour: savedDay?.end.hour || 20,
      minutes: savedDay?.end.minutes || 0,
    };

    const error = validateRange(start, end)
      ? undefined
      : { message: "Start time must be before end time" };

    list.push({
      timeInput: {
        ...start,
        onChange: onChangeStart({
          setStartAtD: setStartAtStartD,
          day: i,
        }),
      },
      timeInputEnd: {
        ...end,
        onChange: onChangeEnd({
          setStartAtD: setStartAtEndD,
          day: i,
        }),
      },
      type: "day",
      input: {
        name: "days",
        onChange: (e) => getFieldProps("currState").onChange(e),
        value: i,
        type: "checkbox",
        // sometimes the value is not a number, so we need to check it with ==
        // eslint-disable-next-line eqeqeq
        checked: days?.some((day) => day.day == i),
      },
      bodyText: day,
      error,
    });
  }

  return list;
};
