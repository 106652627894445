import {
  cleanGet,
  cleanParticularGet,
  setStateGet,
  updateStateGet,
} from "../reducers/stateGet/actions";
import {
  cleanParticularGetD,
  ISetStateGetD,
  IUpdateStateGetD,
} from "../types/stateGet.types";
import useActions from "./useActions";

const useStateGetDispatch = () => {
  const setStateGetD: ISetStateGetD = useActions(setStateGet);
  const updateStateGetD: IUpdateStateGetD = useActions(updateStateGet);
  const cleanParticularGetD: cleanParticularGetD = useActions(cleanParticularGet);
  const cleanGetD = useActions(cleanGet);
  return { setStateGetD, updateStateGetD, cleanParticularGetD, cleanGetD };
};

export default useStateGetDispatch;
