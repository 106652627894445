import { useNavigate } from "react-router-dom";

import useMenuDispatch from "../../../../store/useDispatch/useMenuDispatch";
import { MenuItemProps } from "../menuItem.type";

const useMenuItem = (props: MenuItemProps) => {
  const { label, icon, className, disabled, link, onClick, ...rest } = props;
  const navigate = useNavigate();
  const { toggleMenu } = useMenuDispatch();

  const containerClassName =
    "mf-menu-item-container " +
    (className ? className : "") +
    (disabled ? " disabled" : "");

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (link) {
      toggleMenu();
      navigate(link);
    }

    onClick?.(e);
  };

  return {
    icon,
    label,
    containerAttr: {
      className: containerClassName,
      onClick: disabled ? undefined : handleClick,
      ...rest,
    },
  };
};

export default useMenuItem;
