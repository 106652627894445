import "./hourInput.styles.scss";

import InputMask from "react-input-mask-format";

import useHourInput from "./hooks/useHourInput";
import { HourInputProps } from "./hourInput.types";

const HourInput = (props: HourInputProps) => {
  const { error } = props;

  const { hour, handleHourChange, minutes, handleMinutesChange } = useHourInput(props);

  return (
    <div className={`mf-hi-container ${error ? "error" : ""}`}>
      <InputMask
        mask={[/[0-2]/, /[0-9]/]}
        defaultValue="01"
        className="mf-hour-input mf-hour-input-text"
        value={hour}
        onChange={handleHourChange}
        style={{ width: "35px" }}
      />
      <span className="mf-hour-input-text">:</span>
      <InputMask
        mask={[/[0-5]/, /[0-9]/]}
        defaultValue="00"
        value={minutes}
        onChange={handleMinutesChange}
        className="mf-hour-input mf-hour-input-text"
      />
    </div>
  );
};

HourInput.defaultProps = {
  hour: 1,
  minutes: 0,
  onChange: () => {
    //
  },
} as HourInputProps;

export default HourInput;
