import WalletTrxItemDate from "./components/Date";
import WalletTrxItemDescription from "./components/Description";
import WalletTrxItemIcon from "./components/Icon";
import WalletTrxItemInfo from "./components/Info";
import WalletTrxItemPrice from "./components/Price";
import WalletTransactionItemHOC from "./WalletTransactionItem";

const WalletTransactionItem = Object.assign(WalletTransactionItemHOC, {
  Date: WalletTrxItemDate,
  Description: WalletTrxItemDescription,
  Icon: WalletTrxItemIcon,
  Info: WalletTrxItemInfo,
  Price: WalletTrxItemPrice,
});

export {
  WalletTransactionItem,
  WalletTrxItemDate,
  WalletTrxItemDescription,
  WalletTrxItemIcon,
  WalletTrxItemInfo,
  WalletTrxItemPrice,
};

export default WalletTransactionItem;
