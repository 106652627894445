import "./homeContent.styles.scss";

import { NavTabs, UpcomingBlock } from "../../molecules";
import { HomeContentProps } from "./homeContent.type";

const HomeContent = (props: HomeContentProps) => {
  const { offersDisplayInfo, navTabs } = props;

  return (
    <div className="mf-home-content px-2 pt-3 mx-0">
      {offersDisplayInfo && offersDisplayInfo.length > 0 && (
        <div className="mf-home-content-offers mb-4">
          <UpcomingBlock {...offersDisplayInfo[0]} />
        </div>
      )}
      <NavTabs {...navTabs} />
    </div>
  );
};

export default HomeContent;
