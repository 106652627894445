import { createSlice } from "@reduxjs/toolkit";

interface RefNotificationState {
  lastCheckTime: number;
  notificationsCount: number;
}

const initialState: RefNotificationState = {
  lastCheckTime: 0,
  notificationsCount: 0,
};

const slice = createSlice({
  name: "newRefNotification",
  initialState,
  reducers: {
    clearNotifications: (state) => {
      state.notificationsCount = 0;
      state.lastCheckTime = state.lastCheckTime + 1;
    },
    setNotificationsCount: (state, action) => {
      state.notificationsCount = action.payload;
    },
    setLastCheckTime: (state, action) => {
      state.lastCheckTime = action.payload;
    },
  },
});

export const newRefNotificationActions = slice.actions;

export default slice.reducer;
