import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useStateSpinnerFullScreen } from "../../store/useDispatch";
import useUser from "../useUser/useUser";
import { verifyToken } from "./functions";

export const useVerifyTokenMutation = () => {
  const mutation = useMutation({
    mutationFn: (token: string) => verifyToken(token),
  });

  return {
    verifyTokenMutation: mutation,
  };
};

export const useVerifyToken = () => {
  const [params] = useSearchParams();
  const { setUser } = useUser();
  const { cleanSpinnerFullScreenD, setSpinnerFullScreenD } = useStateSpinnerFullScreen();

  const { verifyTokenMutation } = useVerifyTokenMutation();

  const verifyToken = useCallback(
    async (token: string) => {
      setSpinnerFullScreenD({ color: "green", enabled: true });

      const userResp = await verifyTokenMutation.mutateAsync(token).finally(() => {
        cleanSpinnerFullScreenD();
      });

      setUser(userResp as any);
    },
    [verifyTokenMutation, setUser, setSpinnerFullScreenD, cleanSpinnerFullScreenD],
  );

  useEffect(() => {
    const token = params.get("token");

    if (token) {
      verifyToken(token);

      params.delete("token");
    }
  }, [params, verifyToken]);

  return {
    verifyToken,
  };
};
