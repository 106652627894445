import "./serachEngine.styles.scss";

import { useTranslation } from "react-i18next";

import IconCustom from "../../atoms/icons/IconCustom";
import { dataExample, exampleOnSearch } from "./example";
import useSearch from "./hooks/useSearch";
import { SearchEngineProps } from "./searchEngine.types";

const SearchEngine = (props: SearchEngineProps) => {
  const { text, onChange } = useSearch(props);
  const { t } = useTranslation();

  return (
    <div className="field mg-search-filter">
      <div className="mg-container-search">
        <div className="input magicflex-input-mixin mg-search-item">
          <IconCustom typeIcon="search" />
          <input
            className="mg-input-search mg-input-text"
            name="search-engine"
            type="text"
            value={text}
            autoComplete={"off"}
            onChange={onChange}
            placeholder={t("search")}
          />
        </div>
      </div>
    </div>
  );
};

SearchEngine.defaultProps = {
  pathsSearch: ["students.0.names.0"],
  data: dataExample,
  value: "",
  onSearch: exampleOnSearch,
} as Partial<SearchEngineProps>;

export default SearchEngine;
