import { useEffect } from "react";

import { envs } from "../../../../../../config/env";
import useGet from "../../../../../../https/hooks/get/useGet";
import { useFiltersDispatch } from "../../../../../../store/useDispatch";

const useSearchFiltersScreen = () => {
  const { get, data } = useGet({
    keyStorage: "filters",
    spinnerFullScreen: true,
  });

  const { setStartAtD } = useFiltersDispatch();

  const getFilters = () => {
    get({
      code: "G-FILTERS",
      url: envs.URL_API + "/V1/userFilters/getFilters",
      pathData: "data.data",
    });
  };

  useEffect(() => {
    data?.data?.startAt && setStartAtD(data.data.startAt);
  }, [data, setStartAtD]);

  return { getFilters };
};

export default useSearchFiltersScreen;
