import { useContext } from "react";

import SocketContext from "../SocketContext";

const useSocketContext = () => {
  const sockets = useContext(SocketContext);

  return sockets;
};

export default useSocketContext;
