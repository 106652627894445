import {
  cleanDays,
  cleanSetUpPrice,
  clearStation,
  setDayHour,
  setStartAtEnd,
  setStartAtStart,
  setStation,
  setUpDays,
  setUpStartPrice,
} from "../reducers/setUpFilters/actions";
import {
  SetDayHourD,
  SetStartAtStartD,
  SetUpDaysD,
  SetUpStartPriceD,
  SetUpStationD,
} from "../types/filters.types";
import useActions from "./useActions";

// Here is necessary to configure all the actions
const useFiltersDispatch = () => {
  const setUpStartPriceD: SetUpStartPriceD = useActions(setUpStartPrice);
  const cleanSetUPriceD = useActions(cleanSetUpPrice);
  const setStartAtD: SetUpDaysD = useActions(setUpDays);
  const cleanStartAtD = useActions(cleanDays);
  const setUpStationD: SetUpStationD = useActions(setStation);
  const cleanStationD = useActions(clearStation);
  const setDayHourD: SetDayHourD = useActions(setDayHour);
  const setStartAtStartD: SetStartAtStartD = useActions(setStartAtStart);
  const setStartAtEndD: SetStartAtStartD = useActions(setStartAtEnd);

  return {
    setUpStartPriceD,
    cleanSetUPriceD,
    setStartAtD,
    cleanStartAtD,
    setUpStationD,
    cleanStationD,
    setDayHourD,
    setStartAtStartD,
    setStartAtEndD,
  };
};

export default useFiltersDispatch;
