import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  Player,
  PlayerProps,
  PlayToggle,
  ReplayControl,
  VolumeMenuButton,
} from "video-react";

import usePlayer from "./hooks/usePlayer";

const LinkRegisterVideo = (props: PlayerProps) => {
  const { handlePlayerReady } = usePlayer();

  return (
    <Player
      ref={(p) => p && handlePlayerReady(p)}
      fluid={false}
      width={300}
      height={250}
      {...props}
    >
      <BigPlayButton position="center" />
      <ControlBar disableDefaultControls>
        <PlayToggle />
        <ReplayControl seconds={5} />
        <ForwardControl seconds={5} />
        <VolumeMenuButton vertical />
      </ControlBar>
    </Player>
  );
};
export default LinkRegisterVideo;
