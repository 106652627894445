import { useTranslation } from "react-i18next";

import { Modal, TitleModalSwitch } from "../../../molecules";
import { ModalProps } from "../../../molecules/modal/modal.type";
import StartAtDayCheck from "../../../molecules/startAtDayCheck/StartAtDayCheck";
import useSelectDays from "./hooks/useSelectDays";

const StartAt = (props: ModalProps) => {
  const { daysChecks, saveDays } = useSelectDays({ onClose: props.onClose });
  const { t } = useTranslation(["filters"]);

  return (
    <Modal
      {...props}
      title={t("filters:start-at-title")}
      contentClassName="px-0"
      onCloseClick={() => saveDays()}
    >
      <div className="my-3 mx-4">
        <TitleModalSwitch
          switch={{ show: false }}
          modalTitle={{
            title: t("filters:start-at-title"),
            description: t("filters:start-at-description"),
            icon: { typeIcon: "alarm-clock", width: 14, height: 14 },
          }}
        />
      </div>

      <div className="pt-2">
        {daysChecks.map((dayCheck, idx) => (
          <StartAtDayCheck key={idx} {...dayCheck} />
        ))}
      </div>
    </Modal>
  );
};

StartAt.defaultProps = {
  isOpen: false,
};

export default StartAt;
