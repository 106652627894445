import axios from "axios";
import get from "lodash/get";

import { GetDataElemets, IData } from "./useGet.types";

export const getData = async (elements: GetDataElemets): Promise<void> => {
  const { setData, url, config, code, pathData } = elements;

  setData((prev: any) => ({ ...prev, loading: true }));

  try {
    const res = await axios.get(url, config);
    if (res?.data) {
      setData(
        (prev: IData): IData => ({
          ...prev,
          loading: false,
          data: pathData ? get(res, pathData) : res.data,
          status: res.status,
          success: true,
        }),
      );
    } else {
      setData(
        (prev: IData): IData => ({
          ...prev,
          loading: false,
          data: {},
          error: {
            code,
            message: "No data found.",
          },
          status: res.status,
        }),
      );
    }
  } catch (error: any) {
    setData(
      (prev: IData): IData => ({
        ...prev,
        loading: false,
        data: error.response?.data || {},
        error: {
          ...error.toJSON(),
          code:
            error.response?.status === 402 || error.response?.status === 403
              ? "MEMBERSHIP_REQUIRED"
              : code,
        },
        status: error.response?.status || 500,
      }),
    );
  }
};
export default getData;
