import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const filters = (state: RootState) => state.stateGet?.filters;
const stations = (state: RootState) => state?.setUpFilters?.stations;
const startPrice = (state: RootState) => state?.setUpFilters?.startPrice;
const price = (state: RootState) => state.stateGet?.filters?.data?.price;
const startAt = (state: RootState) => state.setUpFilters?.startAt;

const useFiltersSelector = () => {
  return {
    filters: useAppSelector(filters),
    stations: useAppSelector(stations),
    startPrice: useAppSelector(startPrice),
    price: useAppSelector(price),
    startAt: useAppSelector(startAt),
  };
};

export default useFiltersSelector;
