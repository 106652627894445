import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../atoms";
import { FilterHeader, TitleModalSwitch } from "../../../molecules";
import { BufferTimeSelector, StartTimeSelector } from "../../../organisms";
import StartAt from "../../../organisms/filters/startAt/StartAt";
import StationsModal from "../stationsModal/StationsModal";
import useStartAtModal from "./hooks/startAtModal/useStartAtModal";
import useStationsModal from "./hooks/stationsModal/useStationModal";
import { useSelectedStations, useStartAt } from "./hooks/useSelectedFilters";
import {
  getLocationModalTitle,
  getStartAtModalTitle,
  SearchFiltersState,
} from "./searchFilters.types";

const SearchFilters = () => {
  const stationsModal = useStationsModal();
  const startAtModal = useStartAtModal();

  const { t } = useTranslation(["filters"]);

  const { startAtLabel } = useStartAt();

  const openModal = (modal: keyof SearchFiltersState) => {
    if (modal === "locations") stationsModal.openModal();

    if (modal === "startTime") startAtModal.openModal();
  };

  const [locationTitle, timeTitle] = useMemo(
    () => [getLocationModalTitle(t), getStartAtModalTitle(t)],
    [t],
  );

  const { selectedStationsLabel } = useSelectedStations(stationsModal.stations);

  return (
    <>
      <StationsModal {...stationsModal} />

      <StartAt {...startAtModal} />

      <div className="container">
        <div className="mt-5 px-5 pb-5">
          <FilterHeader title={t("filters:title")} />

          <div className="contaner-switch-title">
            <TitleModalSwitch {...locationTitle} />
            <Button color="primary" onClick={() => openModal("locations")}>
              {selectedStationsLabel}
            </Button>
          </div>

          <BufferTimeSelector />

          <div className="contaner-switch-title">
            <TitleModalSwitch
              {...{
                ...timeTitle,
                modalTitle: {
                  ...timeTitle.modalTitle,
                },
              }}
            />
            <Button color="primary" onClick={() => openModal("startTime")}>
              {startAtLabel}
            </Button>
          </div>

          {/* <StartTimeSelector /> */}
        </div>
      </div>
    </>
  );
};

export default SearchFilters;
