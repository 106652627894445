import { useState } from "react";

import { ModalProps } from "../modal.type";

const useModal = (props: ModalProps) => {
  const { title } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    title,
    closeModal,
    openModal: () => {
      setIsOpen(true);
    },
    onClose: () => {
      closeModal();
      setIsOpen(false);
      props.onClose?.();
    },
  };
};

export default useModal;
