import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, InputCustom } from "../../atoms";
import { ChangePasswordFormProps } from "./changePasswordForm.types";

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { onSubmit } = props;
  const { t } = useTranslation(["common", "auth"]);

  const formikConfig = {
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit,
    validationSchema: Yup.object({
      password: Yup.string().required(
        t("common:form.required", { name: t("auth:login.password") }),
      ),
      confirmPassword: Yup.string()
        .required(
          t("common:form.required", { name: t("auth:register.confirm-password") }),
        )
        .oneOf(
          [Yup.ref("password")],
          t("common:form.match", {
            name: t("auth:register.confirm-password"),
            other: t("auth:login.password"),
          }),
        ),
    }),
  };

  return (
    <Formik {...formikConfig}>
      <Form>
        <InputCustom
          inputProps={{
            label: t("auth:forgot-password.change.new-password"),
            type: "password",
            name: "password",
          }}
          subLabel={t("auth:forgot-password.change.new-password-label")}
        />

        <InputCustom
          inputProps={{
            label: t("auth:register.confirm-password"),
            type: "password",
            name: "confirmPassword",
          }}
          subLabel={t("auth:forgot-password.change.confirm-label")}
        />

        <Button type="submit">{t("auth:forgot-password.change.button")}</Button>
      </Form>
    </Formik>
  );
};
export default ChangePasswordForm;
