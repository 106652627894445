import { useCallback, useEffect, useRef } from "react";

import { useInstantOffersDispatch } from "../../../../store/useDispatch";
import useInstantOffersSelector from "../../../../store/useSelector/useInstantOffersSelector";
import giftAnimations from "../giftAnimationsList";

const useRadarAnimation = (props: { type?: "icon" | "effects" }) => {
  const animationRadarRef = useRef<HTMLDivElement>(null);

  const { radarIconFooterEnabledD, radarIconFooterDisabledD } =
    useInstantOffersDispatch();
  const { isActive, playAnimation } = useInstantOffersSelector();
  const { setPlayAnimationD } = useInstantOffersDispatch();

  const setActiveState = useCallback(
    (active: boolean) => {
      if (!active) radarIconFooterDisabledD();
    },
    [radarIconFooterDisabledD],
  );

  useEffect(() => {
    if (animationRadarRef?.current) {
      animationRadarRef.current.addEventListener("animationend", function () {
        setPlayAnimationD(false);
        radarIconFooterEnabledD();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRadarRef?.current]);

  useEffect(() => {
    setActiveState(isActive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const urlImage =
    props.type === "effects" ? giftAnimations.flexiRadar : giftAnimations.radar;
  return { urlImage, show: playAnimation, animationRadarRef };
};
export default useRadarAnimation;
