import { useTranslation } from "react-i18next";

import FormDynamic from "../formDynamic/FormDynamic";
import { IVerifyCaptchaFormProps } from "./verifyCaptchaForm.types";

const VerifyCaptchaForm = (props: IVerifyCaptchaFormProps) => {
  const { onSubmit, captchaUrl, fields } = props;

  const { t } = useTranslation(["common"]);

  return (
    <>
      <div className="is-flex is-justify-content-center mb-5">
        <img className="image" src={captchaUrl} alt="captcha" />
      </div>

      <FormDynamic
        fields={fields}
        onSubmit={onSubmit}
        submitBtn={{
          label: t("common:verify"),
        }}
      />
    </>
  );
};

export default VerifyCaptchaForm;
