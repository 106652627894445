import { AmzAccountsListProps } from "./amzAccountsList.types";

export const amzAccountsListDefaultProps: AmzAccountsListProps = {
  amzAccountsItems: [
    {
      email: "jhon@example.com",
    },
    {
      email: "smith@example.com",
      selected: true,
    },
  ],
};
