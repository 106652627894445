import { TFunction } from "i18next";

import { FormField } from "../../../organisms/formDynamic/formDynamic.types";
import { VerifyCaptchaProps } from "./verifyCaptcha.types";

export const verifyCaptchaDefaultProps: VerifyCaptchaProps = {
  onSubmit: (values) => {
    console.log("default", values);
  },
  // placeholder image url
  captchaUrl: "https://via.placeholder.com/150x150",
  password: "",
  email: "",
};

export const getFormFields = (t: TFunction<"common"[]>): FormField[] => [
  {
    name: "captcha",
    fieldType: "input",
    type: "text",
    label: t("common:captcha"),
    validations: [
      {
        type: "required",
        message: t("common:form.required", {
          name: t("common:captcha"),
        }),
      },
    ],
  },
  {
    name: "email",
    fieldType: "input",
    type: "email",
    label: t("common:form.email-address"),
    validations: [
      {
        type: "email",
        message: t("common:form.invalid", {
          name: t("common:form.email-address"),
        }),
      },
      {
        type: "required",
        message: t("common:form.required", {
          name: t("common:form.email-address"),
        }),
      },
    ],
  },
  {
    name: "password",
    fieldType: "input",
    type: "password",
    label: t("common:form.password"),
    validations: [
      {
        type: "required",
        message: t("common:form.required", {
          name: t("common:form.password"),
        }),
      },
    ],
  },
];
