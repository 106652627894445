import { setIsRunning, setStartingService, startBot, stopBot } from "../reducers/bot/bot";
import useActions from "./useActions";

const useBotDispatch = () => {
  return {
    setIsRunningD: useActions(setIsRunning),
    startBotD: useActions(startBot),
    stopBotD: useActions(stopBot),
    setStartingServiceD: useActions(setStartingService),
  };
};

export default useBotDispatch;
