const button: React.CSSProperties = {
  borderRadius: "10px 0 0 10px",
  backgroundColor: "transparent",
  border: "none",
  paddingLeft: "0.5rem",
};

const styles = {
  button,
};

export default styles;
