import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Button, InputCustom } from "../../../atoms";
import { getFormik } from "./registerAmzAccount.functions";
import { RegisterAmzAccountProps } from "./RegisterAmzAccount.types";

const RegisterAmzAccount = (props: RegisterAmzAccountProps) => {
  const { loading, onSubmit } = props;
  const { t } = useTranslation(["common"]);

  const formikConfig = getFormik(onSubmit, t);

  return (
    <div>
      <Formik {...formikConfig}>
        <Form>
          <InputCustom
            inputProps={{
              label: t("common:form.email-address"),
              name: "email",
              placeholder: "yourexample@gmail.com",
              type: "email",
            }}
            subLabel={t("common:form.email-label")}
            iconInput="envelope"
          />
          <InputCustom
            inputProps={{
              label: t("common:form.password"),
              type: "password",
              name: "password",
              placeholder: "******",
            }}
            subLabel={t("common:form.password-label")}
            iconInput="lock"
          />
          <div className="pt-5">
            <Button type="submit" isLoading={loading}>
              {t("common:register")}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default RegisterAmzAccount;
