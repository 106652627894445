import { envs } from "../../../../../config/env";
import useGet from "../../../../../https/hooks/get/useGet";

const useGetAmzAccounts = () => {
  const { data, get } = useGet({ spinnerFullScreen: false });

  const getAmzAccounts = () => {
    get({
      code: "G-AMZ-ACCOUNTS",
      url: envs.URL_API + "/V1/authAmz/getAmzUsers",
      pathData: "data.data",
    });
  };

  return {
    getAmzAccountsResp: data,
    getAmzAccounts,
  };
};

export default useGetAmzAccounts;
