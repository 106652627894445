import "moment/locale/es";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import resources from "./resources";

export const defaultNS = "common";

export enum Languages {
  EN = "en",
  ES = "es",
}

export type Namespaces = typeof resources.en;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "es"],
    ns: [
      "auth",
      "common",
      "filters",
      "home",
      "informative",
      "instant-offers",
      "membership",
      "menu",
      "popup",
      "register",
      "support",
      "terms-conditions",
      "referred",
    ],
    defaultNS,
    detection: {
      order: ["navigator", "localStorage"],
      caches: ["localStorage"],
    },
    nonExplicitSupportedLngs: true,
  });

export const currentLanguage: Languages =
  (i18n.language.split("-")[0] as Languages) || "en";

export default i18n;
