import { Typhography } from "../../atoms";
import { DisplayTimeProps } from "./displayTIme.types";

const DisplayTime = (props: DisplayTimeProps) => {
  const { isEditing, setIsEditing, hour, minuts, meridiem, setMeridiem } = props;
  return (
    <div className={`containerTimePiker`}>
      <button
        className={`timePikerNumer ${isEditing === "hours" && "activeTimePikerNumer"}`}
        onClick={() => setIsEditing("hours")}
        tabIndex={-1}
        type="button"
      >
        <Typhography type={"p-xl-b"}>{hour}</Typhography>
      </button>
      <span>
        <Typhography tabIndex={-1} type={"p-xl-b"}>
          :
        </Typhography>
      </span>
      <button
        className={`timePikerNumer ${isEditing === "minutes" && "activeTimePikerNumer"}`}
        onClick={() => setIsEditing("minutes")}
        tabIndex={-1}
        type="button"
      >
        <Typhography type={"p-xl-b"}>{minuts}</Typhography>
      </button>
      <div className="containerMeridiem">
        <button
          onClick={() => setMeridiem("AM")}
          className={`meridiem meridiem-AM ${meridiem === "AM" && "activeMeridiem"}`}
          type="button"
        >
          <Typhography type={"p-l-b"}>AM</Typhography>
        </button>
        <button
          onClick={() => setMeridiem("PM")}
          className={`meridiem meridiem-PM ${meridiem === "PM" && "activeMeridiem"}`}
          type="button"
        >
          <Typhography type={"p-l-b"}>PM</Typhography>
        </button>
      </div>
    </div>
  );
};
export default DisplayTime;
