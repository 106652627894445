export type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "type"
> & {
  classNames?: {
    switch?: string;
    slider?: string;
  };
};

export type UseSwitchProps = {
  disabled?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onDisableChangeIntent?: () => void;
};

export const defaultProps: InputProps = {
  onChange: (e) => e,
};
