import { useCallback, useEffect, useRef } from "react";

import { timeToString } from "./useVerifyOtpForm.functions";

type IOtpTimerArgs = {
  onTimeout?: () => void;
  time: number;
  setTime: (time: number) => void;
  updateInterval?: number;
  transform?: (time: number) => string;
};

const useTimer = (args: IOtpTimerArgs) => {
  const { time, setTime, updateInterval = 1, onTimeout, transform = timeToString } = args;

  const interval = useRef<number>();

  const getTime = useCallback(() => transform(time), [time, transform]);

  useEffect(() => {
    if (!interval.current && time > 0) {
      interval.current = setInterval(() => {
        setTime(time);
      }, updateInterval * 1000) as unknown as number;
    }

    return () => {
      if (interval.current && time === 0) {
        clearInterval(interval.current);
        interval.current = undefined;
      }
    };
  }, [time]);

  useEffect(() => {
    if (time <= 0) {
      interval.current && clearInterval(interval.current);
      onTimeout && onTimeout();
    }
  }, [time, onTimeout]);

  return {
    getTime,
  };
};

export default useTimer;
