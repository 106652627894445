import { useCallback, useEffect } from "react";
import Cookie from "universal-cookie";

import { useUIDispatch } from "../../store/useDispatch";
import { useUISelector } from "../../store/useSelector";

interface Args {
  requestCompleted: boolean;
}

const cookie = new Cookie();

const useVerifyMaintenance = (args: Args) => {
  const { requestCompleted } = args;

  const { maintenance } = useUISelector();
  const { setShowMaintenanceD } = useUIDispatch();

  const updateMaintenance = useCallback(() => {
    if (!requestCompleted) return;

    const maintenanceCookie = cookie.get("maintenance");

    if (maintenanceCookie === "true" && !maintenance.show) setShowMaintenanceD(true);
    else if (maintenanceCookie === "false" && maintenance.show)
      setShowMaintenanceD(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenance.show, requestCompleted]);

  useEffect(() => {
    updateMaintenance();
  }, [updateMaintenance]);
};

export default useVerifyMaintenance;
