import "./modalHeaderPlus.styles.scss";

import { IconCustom, Typhography } from "../../atoms";
import { TypeIcon } from "../../atoms/icons/icons.type";
interface HeaderModalProps {
  onClose?: () => void;
  title: string;
  subtitle: string;
  iconTitle: TypeIcon;
}
const HeaderModalPlus = (props: HeaderModalProps) => {
  const { onClose, title, subtitle, iconTitle } = props;
  return (
    <div className={`containerHeaderTime`}>
      <IconCustom
        typeIcon={iconTitle ? iconTitle : "alarm-clock"}
        width={14}
        height={14}
        className={`iconRightTitle`}
      />

      <div>
        <Typhography type={"h-5"}>{title ? title : ""}</Typhography>
        <Typhography type={"p-s-r"} color={"#A6B4C5"}>
          {subtitle ? subtitle : ""}
        </Typhography>
      </div>

      <IconCustom
        typeIcon={"cross-close"}
        width={25}
        height={25}
        className={`crossCloseHeader`}
        onClick={onClose && onClose}
      />
    </div>
  );
};
export default HeaderModalPlus;
