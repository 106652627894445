import type {
  ICaptchaMethod,
  IOtpMethod,
  IRegisterState,
  RegisterAF,
} from "../../types/register.types";

export const setOtp: RegisterAF<IOtpMethod> = (state, action) => {
  state.authType = "OTP";
  state.otp = action.payload?.otp;
};

export const setCaptcha: RegisterAF<ICaptchaMethod> = (state, action) => {
  state.authType = "CAPTCHA";
  state.captcha = action.payload?.captcha || state.captcha;
  state.captchaUrl = action.payload?.captchaUrl || state.captchaUrl;
};

export const init: RegisterAF<IRegisterState> = (state, action) => {
  state.email = action.payload?.email || "";
};

export const setCvf: RegisterAF<ICaptchaMethod> = (state, action) => {
  state.authType = "CVF_CAPTCHA";
  state.captcha = action.payload?.captcha || state.captcha;
  state.captchaUrl = action.payload?.captchaUrl || state.captchaUrl;
};

export const clean: RegisterAF = (state) => {
  state.authType = undefined;
  state.captcha = undefined;
  state.captchaUrl = "";
  state.email = "";
  state.otp = undefined;
};
