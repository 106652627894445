import { AmzAccountsItem } from "../../../molecules";
import { amzAccountsListDefaultProps } from "./amzAccountsList.constants";
import { AmzAccountsListProps } from "./amzAccountsList.types";

const AmzAccountsList = (props: AmzAccountsListProps) => {
  const { amzAccountsItems, ...rest } = props;
  return (
    <div {...rest}>
      {amzAccountsItems.map((item, index) => (
        <AmzAccountsItem key={index} {...item} />
      ))}
    </div>
  );
};

AmzAccountsList.defaultProps = amzAccountsListDefaultProps;

export default AmzAccountsList;
