import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useModalInfoDispatch } from "../../../../store/useDispatch";
import { IconCustom, ReferredIcon } from "../../../atoms";
import { IReferredListStatusProps } from "../referredListStatus.types";

const Icons = {
  referral: <ReferredIcon className="referral border md mr-2" />,
  inactive: <ReferredIcon className="inactive border md mr-2" />,
  activeUsed: <IconCustom typeIcon="cross" width={12} height={12} className="mr-2" />,
  active: <IconCustom typeIcon="plus" width={13} height={13} className="mr-2" />,
};

const useReferredListStatus = (props: IReferredListStatusProps) => {
  const { status, rewardClaimed, rewardDays, onClick, _id } = props;

  const { t } = useTranslation(["referred", "popup"]);

  const { setModalInfoCD } = useModalInfoDispatch();

  const handleClick = () => {
    if (onClick) {
      setModalInfoCD({
        enabled: true,
        handleAccept: () => onClick(_id),
        modalInfoProps: {
          ...t("popup:claim-reward", { days: rewardDays || 0, returnObjects: true }),
          type: "success",
          image: {
            typeImage: "flexi-exited",
          },
        },
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const statusClassName = useMemo(() => {
    const usedClassName = rewardClaimed ? "used" : "start";

    if (status === "PENDING") {
      return `referral ${usedClassName}`;
    }

    if (status === "INACTIVE") {
      return `inactive ${usedClassName}`;
    }

    return `active ${usedClassName}`;
  }, [status, rewardClaimed]);

  const [Icon, text] = useMemo(() => {
    if (status === "PENDING") {
      return [Icons.referral, t("referred:status.referral")];
    }

    if (status === "INACTIVE") {
      return [Icons.inactive, t("referred:status.inactive")];
    }

    return rewardClaimed
      ? [Icons.activeUsed, t("referred:used-days", { days: rewardDays || 0 })]
      : [Icons.active, t("referred:start-days", { days: rewardDays || 0 })];
  }, [status, rewardClaimed, t, rewardDays]);

  return { Icon, text, handleClick, statusClassName, handleKeyDown };
};

export default useReferredListStatus;
