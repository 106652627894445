import { envs } from "../../../config/env";
import useGet from "../../../https/hooks/get/useGet";

const useGetBotState = () => {
  const { get, data, clearResp } = useGet({ spinnerFullScreen: false });

  const getBotState = () => {
    get({
      code: "G-BOT-STATE",
      url: envs.URL_API + "/V1/createBot/state",
      config: {
        timeout: 20000, // 20 seconds
      },
    });
  };

  return {
    getBotState,
    botStateResp: data,
    clearBotStateResp: clearResp,
  };
};

export default useGetBotState;
