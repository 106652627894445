import { useEffect } from "react";

import { useAnimation } from "../../../../../hooks";
import { calcRotationDuration, getStyleRotation } from "../magicBtn.functions";
import { UseMagicBtnAnimParams } from "../magicBtn.types";

const useMagicBtnAnim = (args: UseMagicBtnAnimParams) => {
  const animate = useAnimation();

  useEffect(() => {
    if (args.isAnimated) {
      animate.stop();
      animate.to({
        rotate: "+=360",
        duration: 5,
        repeat: -1,
        ease: "linear",
      });
    } else {
      const { rotate, duration } = calcRotationDuration(
        getStyleRotation(animate.elementRef.current),
      );

      animate.stop();
      animate.to({
        rotate: `${rotate}deg`,
        duration,
      });
    }
  }, [args.isAnimated]);

  return {
    animatedElRef: animate.elementRef,
  };
};

export default useMagicBtnAnim;
