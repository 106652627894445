/* action types */

import { SetActionTimePiker } from "../../types/timePiker.type";
/* Used only in reducer */
export const SET_TIME_PIKER_PROPS = "magicFlex/SET_TIME_PIKER_PROPS";
export const CLEAN_TIME_PIKER_PROPS = "magicFlex/CLEAN_TIME_PIKER_PROPS";

/* Action Creators */
export const setTimePiker: SetActionTimePiker = (timePikerProps) => {
  return {
    type: SET_TIME_PIKER_PROPS,
    payload: { timePikerProps: { ...timePikerProps, useRedux: true } },
  };
};
export const cleanTimePiker = () => {
  return {
    type: CLEAN_TIME_PIKER_PROPS,
  };
};
