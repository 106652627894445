import { envs } from "../../config/env";
import useGet from "../../https/hooks/get/useGet";
import { GetElements } from "../../https/hooks/get/useGet.types";

const API_URL = envs.URL_API;

const useAmzAccountApi = () => {
  const { data: extraDataResp, get: getExtraData } = useGet({
    spinnerFullScreen: true,
  });

  const { data: selectAmzAccResp, get: selectAmzAcc } = useGet({
    spinnerFullScreen: true,
  });

  return {
    extraDataResp,
    selectAmzAccResp,

    getExtraData: (id?: string) => {
      const req: GetElements = {
        code: "G-AMZ-ACCOUNT-ED",
        url: API_URL + "/V1/authAmz/amzPerson",
        pathData: "data.data.person",
      };

      if (id) {
        req.config = {
          headers: {
            AmzUserId: id,
          },
        };
      }

      getExtraData(req);
    },
    selectAmzAcc: (id?: string) => {
      const req: GetElements = {
        code: "G-SELECT-AMZ-ACCOUNT",
        url: API_URL + "/V1/authAmz/selectAmzAccount",
        pathData: "data.data",
      };

      if (id) {
        req.config = {
          headers: {
            AmzUserId: id,
          },
        };
      }

      selectAmzAcc(req);
    },
  };
};

export default useAmzAccountApi;
