import { useTranslation } from "react-i18next";

import { AboutMagic } from "../../../organisms";
import SettingsLayout from "../../../pages/settings/settingsLayout/SettingsLayout";

const AboutMagicFlex = () => {
  const { t } = useTranslation(["informative"]);

  return (
    <SettingsLayout header={{ title: t("informative:about-title") }}>
      <AboutMagic />
    </SettingsLayout>
  );
};

export default AboutMagicFlex;
