import { useState } from "react";

const useTransactionDetails = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return { isOpen, handleClose, handleOpen };
};

export default useTransactionDetails;
