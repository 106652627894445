import "./walletTransactionItem.styles.scss";

import { createContext } from "react";

import {
  IWalletTransactionItemProps,
  IWalletTrxContext,
} from "./walletTransactionItem.types";

export const WalletTrxContext = createContext({} as IWalletTrxContext);

const WalletTransactionItem = (props: IWalletTransactionItemProps) => {
  const { id, onClick, children, className, ...rest } = props;

  return (
    <WalletTrxContext.Provider value={rest}>
      <div
        className={`mf-wallet-transaction is-clickable ${className}`}
        role="button"
        tabIndex={0}
        onClick={() => onClick?.(id)}
        onKeyUp={() => onClick?.(id)}
      >
        {children}
      </div>
    </WalletTrxContext.Provider>
  );
};

WalletTransactionItem.defaultProps = {
  amount: 0,
  date: new Date(),
  type: "deposit",
} as IWalletTransactionItemProps;

export default WalletTransactionItem;
