import { useEffect } from "react";

import { useAmzAccountsDispatch } from "../../../../store/useDispatch";
import { useAmzAccountsSelector, useUserSelector } from "../../../../store/useSelector";
import useGetAmzAccounts from "./api/useGetAmzAccounts";

const useLoadAccounts = () => {
  const { amzAccounts } = useAmzAccountsSelector();
  const { user } = useUserSelector();
  const { setAmzAccounts } = useAmzAccountsDispatch();

  const { getAmzAccounts, getAmzAccountsResp } = useGetAmzAccounts();

  useEffect(() => {
    if (amzAccounts.length === 0 && user) getAmzAccounts();
  }, [amzAccounts.length, user]);

  useEffect(() => {
    if (getAmzAccountsResp.success) setAmzAccounts(getAmzAccountsResp.data);
  }, [getAmzAccountsResp]);
};

export default useLoadAccounts;
