import { toggleMenu } from "../reducers/menu/menu";
import useActions from "./useActions";

const useMenuDispatch = () => {
  return {
    toggleMenu: useActions(toggleMenu),
  };
};

export default useMenuDispatch;
