import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useWindowSize } from "../../../../../hooks";
import { Button, Typhography } from "../../../../atoms";
import { PriceRadio } from "../../../../molecules";
import { PendingPayments } from "../PendingPayments";
import { useChargeWallet } from "./hooks";

const ChargeTemplate = () => {
  const { t } = useTranslation(["membership", "common"]);
  const { onAction } = useChargeWallet();
  const { height } = useWindowSize();

  return (
    <Box>
      <Formik
        initialValues={{
          amount: "",
          price: "40",
        }}
        onSubmit={onAction}
        validationSchema={Yup.object().shape({
          price: Yup.number().is([40, 80, 120, -1]),
          amount: Yup.number().when("price", {
            is: (price: string) => price == "-1",
            then: Yup.number()
              .min(40, t("membership:wallet.valid-amount"))
              .max(200, t("membership:wallet.valid-amount"))
              .required(t("common:form.required", { name: t("common:amount") })),
          }),
        })}
      >
        {({ values, setFieldValue, handleChange, setFieldTouched }) => (
          <Form>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={height - 130}
            >
              <Box>
                <Typhography type="h-2" className="mb-5">
                  {t("membership:wallet.pay-per-block")}
                </Typhography>
                <Box mb={5}>
                  <Typhography type="overline-b" className="mb-4">
                    {t("membership:wallet.charge-amount")}
                  </Typhography>
                  <PriceRadio
                    price={40}
                    radioInput={{
                      name: "price",
                      value: "40",
                      checked: values.price === "40",
                      onChange: (e) => {
                        setFieldTouched("amount", false);
                        setFieldValue("amount", "");
                        handleChange(e);
                      },
                    }}
                  />
                  <PriceRadio
                    price={80}
                    radioInput={{
                      name: "price",
                      value: "80",
                      checked: values.price === "80",
                      onChange: (e) => {
                        setFieldTouched("amount", false);
                        setFieldValue("amount", "");
                        handleChange(e);
                      },
                    }}
                  />
                </Box>

                <PendingPayments />
              </Box>

              <Button type="submit">{t("common:continue")}</Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default ChargeTemplate;
