import auth from "../../locales/en/auth.json";
import common from "../../locales/en/common.json";
import filters from "../../locales/en/filters.json";
import home from "../../locales/en/home.json";
import informative from "../../locales/en/informative.json";
import instantOffers from "../../locales/en/instant-offers.json";
import membership from "../../locales/en/membership.json";
import menu from "../../locales/en/menu.json";
import popup from "../../locales/en/popup.json";
import referred from "../../locales/en/referred.json";
import register from "../../locales/en/register.json";
import support from "../../locales/en/support.json";
import termsConditions from "../../locales/en/terms-conditions.json";

export default {
  auth,
  common,
  filters,
  home,
  informative,
  membership,
  menu,
  popup,
  referred,
  register,
  support,
  "instant-offers": instantOffers,
  "terms-conditions": termsConditions,
};
