import forEach from "lodash/forEach";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";

import { ISearchEngine, SearchEngineProps } from "../searchEngine.types";

const searchEngine = ({ data, text, pathsSearch }: ISearchEngine) => {
  const elements: any[] = [];
  forEach(data, (element) => {
    pathsSearch.forEach((pathSearch) => {
      const value = get(element, pathSearch)?.toString() || "";
      const searchClean = text.split(" ").join("").toLowerCase();
      const valueClean = value.split(" ").join("").toLowerCase();
      if (!isEmpty(searchClean) && valueClean.includes(searchClean)) {
        elements.push(element);
        return true;
      }
    });
  });

  const dataArr = new Set(elements);
  const result = [...(dataArr as any)];
  return result;
};

const useSearch = (props: SearchEngineProps) => {
  const [text, setText] = useState(props?.value || "");
  const [data, setData] = useState<any>(props?.data || []);
  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    if (data.length > 0) {
      const onDefault = props?.onDefault ? props?.onDefault : () => null;
      const pathsSearch = props?.pathsSearch || [""];
      const dataFilder = isEmpty(text) ? data : searchEngine({ data, pathsSearch, text });
      onDefault(dataFilder);
    }
  }, [data, props?.onDefault, props?.pathsSearch, text]);

  const onChange = (e: any) => {
    const text = e.target.value;
    setText(text);
    const onSearch = props?.onSearch ? props?.onSearch : () => null;
    const pathsSearch = props?.pathsSearch || [""];
    const dataFilder = isEmpty(text) ? data : searchEngine({ data, pathsSearch, text });
    onSearch(dataFilder);
  };
  return { text, onChange };
};

export default useSearch;
