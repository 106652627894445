import * as Yup from "yup";

import { FormField } from "./formDynamic.types";

let initialValues: { [x: string]: any } = {};
let requiredFields: { [x: string]: any } = {};

export const validateFields = (fields: FormField[]) => {
  fields.forEach((field) => {
    initialValues[field.name] = field.value ?? "";

    if (!field.validations) return;

    let fieldValidation: any = Yup.string();

    field.validations.forEach((validation) => {
      const { type, message } = validation;

      if (type === "required") {
        fieldValidation = fieldValidation.required(message);
      }

      if (type === "email") {
        fieldValidation = fieldValidation.email(message);
      }

      if (type === "pattern") {
        fieldValidation = fieldValidation.matches(RegExp(validation.pattern), message);
      }

      if (type === "equalsTo") {
        fieldValidation = fieldValidation.oneOf(
          validation.equalsTo.map((value) => Yup.ref(value)),
          message,
        );
      }

      if (type === "notEqualsTo") {
        fieldValidation = fieldValidation.notOneOf(
          validation.notEqualsTo.map((value) => Yup.ref(value)),
          message,
        );
      }

      if (type === "termsAndConditions") {
        fieldValidation = Yup.bool().oneOf([true], message);
      }
    });

    requiredFields[field.name] = fieldValidation;
  });

  return Yup.object({ ...requiredFields });
};

export const reset = () => {
  initialValues = {};
  requiredFields = {};
};

export const getForm = (formFields: FormField[]) => {
  return {
    initialValues,
    validationSchema: validateFields(formFields),
  };
};
