import { RootState } from "../types/store.types";
import useAppSelector from "./useSelector";

const selectUser = (state: RootState) => state.user.user;
const selectError = (state: RootState) => state.user.error;
const selectLoading = (state: RootState) => state.user.loading;

const useUserSelector = () => {
  return {
    user: useAppSelector(selectUser),
    error: useAppSelector(selectError),
    loading: useAppSelector(selectLoading),
  };
};

export default useUserSelector;
