import "./navigationHeader.styles.scss";

import { useNavigate } from "react-router-dom";

import { HelpButton, IconCustom } from "../../atoms";
import { navigationHeaderDefaultProps } from "./navigationHeader.constants";
import { NavigationHeaderProps } from "./navigationHeader.types";

const NavigationHeader = (props: NavigationHeaderProps) => {
  const { title, onBack } = props;
  const navigate = useNavigate();
  const hadleClick = () => {
    onBack ? onBack() : navigate(-1);
  };
  return (
    <div className="mf-navigation-header-container">
      <IconCustom
        typeIcon="angle-left"
        className="mf-navigation-header-icon"
        onClick={hadleClick}
      />
      <p className="mf-large-bold mf-navigation-header-title">{title}</p>
      <HelpButton className="mf-navigation-header__help-btn" width={32} height={32} />
    </div>
  );
};

NavigationHeader.defaultProps = navigationHeaderDefaultProps;

export default NavigationHeader;
