import axios from "axios";

import { envs } from "../../config/env";

const getAPI = async ({ method, resource, body, extraHeader }) => {
  const headers = {
    //'Access-Control-Allow-Origin': '*',
    "Content-Type": "application/json",
    ...extraHeader,
  };

  const baseEndPoint = envs.URL_API;

  const requestOptions = {
    url: `${baseEndPoint}${resource}`,
    method: method,
    headers,
    data: body,
    withCredentials: true,
  };

  try {
    const { data } = await axios(requestOptions);

    return { ...data, request: true };
  } catch (error) {
    // console.log(error?.response?.data);

    const data = error?.response?.data
      ? { ...error.response.data, request: false }
      : { request: false };

    return data;
  }
};

export default getAPI;
