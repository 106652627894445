const alphanumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export class Helpers {
  static timeDiff(date1: Date, date2: Date) {
    const diff = date1.getTime() - date2.getTime();
    const diffInHours = diff / (1000 * 60 * 60);
    const diffInDays = diffInHours / 24;
    const diffInMinutes = diff / (1000 * 60);

    return {
      hours: Math.floor(diffInHours),
      days: Math.floor(diffInDays),
      minutes: Math.floor(diffInMinutes),
    };
  }

  static generatePaymentCode(type: string) {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const hour = today.getHours();
    const minute = today.getMinutes();
    const second = today.getSeconds();
    const millisecond = today.getMilliseconds();

    const random1 = Math.floor(Math.random() * alphanumeric.length);
    const random2 = Math.floor(Math.random() * alphanumeric.length);
    const random3 = Math.floor(Math.random() * alphanumeric.length);

    const code = `MF-${alphanumeric[random1]}${month}${day}${hour}${minute}${second}${millisecond}${alphanumeric[random2]}${alphanumeric[random3]}-${type}`;

    return code;
  }

  static formatDate(
    date: Date | string,

    options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    },
    locale = "en-US",
  ) {
    if (typeof date === "string") {
      date = new Date(date);
    }

    const browserLocale = navigator.language;

    return date.toLocaleString(browserLocale || locale, options);
  }
}
