import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAmzAccountSelector } from "../../store/useSelector";
import { acceptWalletExpiration, getWallet } from "./membership.functions";

export const useWalletQuery = () => {
  const { amzAccount } = useAmzAccountSelector();

  const query = useQuery(["wallet", amzAccount?._id], () => getWallet(), {
    enabled: !!amzAccount?._id,
  });

  return {
    wallet: query.data,
    walletQuery: query,
  };
};

export const useWalletMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => acceptWalletExpiration(),
    onMutate: () => {
      queryClient.cancelQueries(["wallet"]);

      const previousWallet = queryClient.getQueryData(["wallet"]);

      queryClient.setQueryData(["wallet"], (old: any) => {
        return {
          ...old,
          expirationAccepted: true,
        };
      });

      return previousWallet;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(["wallet"], context);
    },
  });

  return {
    acceptWalletExpiration: mutation.mutateAsync,
    acceptWalletExpirationMutation: mutation,
  };
};
