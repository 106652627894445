import "./wizardPageIndicator.styles.scss";

import _ from "lodash";
import React from "react";

import { WizardPageIndicatorProps } from "./wizardPageIndicator.types";

const WizardPageIndicator = (props: WizardPageIndicatorProps) => {
  const { activeStep, steps } = props;

  return (
    <div className="mf-page-indicator mb-5">
      {_.range(steps).map((step) => {
        const activeClass = step === activeStep ? "active" : "";

        return (
          <React.Fragment key={step}>
            <div className={`mf-indicator-point ${activeClass}`}></div>
            {step < steps - 1 && (
              <div
                className={`mf-indicator-line ${step === activeStep - 1 ? "active" : ""}`}
              ></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default WizardPageIndicator;
