/* action types */
import { ActionNotification, NotificationC } from "../../types/notificationC.types";
/* Used only in reducer */
export const SET_NOTIFICATION_C = "magicFlex/SET_NOTIFICATION_C";
export const CLEAN_NOTIFICATION_C = "magicFlex/CLEAN_NOTIFICATION_C";

/* Action Creators */
export const setNotificationC = (notificationC: NotificationC): ActionNotification => {
  return {
    type: SET_NOTIFICATION_C,
    payload: {
      notificationC,
    },
  };
};
export const cleanNotificationC = () => {
  return {
    type: CLEAN_NOTIFICATION_C,
  };
};
