import { createContext } from "react";

import { envs } from "../../config/env";
import { useBotSocket } from "../../hooks/useSocket";
import { SocketContext as ISocketContext } from "./socketContext.types";

const API_URL = envs.URL_WS;

const SocketContext = createContext({} as ISocketContext);

export const SocketProvider = ({ children }: any) => {
  const { socket } = useBotSocket(API_URL);

  const value = {
    botSocket: socket,
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketContext;
