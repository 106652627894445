import { useEffect, useRef } from "react";

type Start = (callback: () => void, time: number) => void;

const useTimeOut = () => {
  const timeOut = useRef<NodeJS.Timeout | null>(null);

  const start: Start = (callback, delay) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(callback, delay);
  };

  const clear = () => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
  };

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  return {
    start,
    clear,
  };
};

export default useTimeOut;
