export const getNameAndLastName = (name: string) => {
  const nameSplit = name.split(" ");

  if (nameSplit.length <= 2) return name;

  const firstName = nameSplit[0];
  const lastName = nameSplit[2];

  return `${firstName} ${lastName}`;
};
