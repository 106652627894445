import { envs } from "../../../../../../config/env";
import useGet from "../../../../../../https/hooks/get/useGet";

const useGetMembershipTypes = () => {
  const { data, get } = useGet({ spinnerFullScreen: true });

  return {
    membershipTypesResp: data,
    getMembershipTypes: () => {
      get({
        code: "G_MEMBERSHIP_TYPES",
        url: envs.URL_API + "/V1/membershipType",
        pathData: "data.data",
      });
    },
  };
};

export default useGetMembershipTypes;
