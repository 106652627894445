import moment from "moment";
import { useContext } from "react";

import { Typhography } from "../../../atoms";
import { WalletTrxContext } from "../WalletTransactionItem";
import { IWalletTrxItemDateProps } from "../walletTransactionItem.types";

const WalletTrxItemDate = (props: IWalletTrxItemDateProps) => {
  const { date } = useContext(WalletTrxContext);

  const dateString = moment(props.date || date).format("MMM DD, hh:mm a");

  return (
    <Typhography type="p-s-r" render="span" className="mf-text-secondary">
      {dateString}
    </Typhography>
  );
};
export default WalletTrxItemDate;
