import { useTranslation } from "react-i18next";

import { Typhography } from "../../atoms";
import { IOtpRenewTimerProps } from "./otpRenewTimer.types";

const OtpRenewTimer = (props: IOtpRenewTimerProps) => {
  const { onRenew, isRenewAvailable, getTime } = props;

  const { t } = useTranslation(["common"]);

  const renewText = isRenewAvailable
    ? ""
    : t("common:form.resend-code", { time: getTime() });

  return (
    <div className="mt-5">
      <Typhography
        type="deafult"
        className={`magicflex-textligh-overline  ${
          isRenewAvailable ? "is-clickable" : ""
        }`}
        onClick={isRenewAvailable ? onRenew : undefined}
      >
        {t("common:form.no-receive-code")} {renewText}
      </Typhography>
    </div>
  );
};
export default OtpRenewTimer;
