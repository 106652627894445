import "./modalTitle.styles.scss";

import { SubTitleProps } from "./subtitle.types";

const SubTitle = (props: SubTitleProps): JSX.Element => {
  const { title, description } = props;
  return (
    <div className="is-flex has-text-left is-size-7 py-1">
      <div className="pl-3 ">
        <h6 className="mg-subtitle">{title}</h6>
        <p className="mg-subtitle-body">{description}</p>
      </div>
    </div>
  );
};

SubTitle.defaultProps = {
  title: "Title",
  description: "Description",
} as Partial<SubTitleProps>;

export default SubTitle;
