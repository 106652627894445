import "./spinnerFullScreen.styles.scss";

import useSpinnerFullScreenSelector from "../../../store/useSelector/useSpinnerFullScreenSelector";
import { SpinnerMagic } from "../../atoms";
import { ISpinnerFullScreenProps } from "./spinnerFullScreen.types";

const SpinnerFullScreen = (props: ISpinnerFullScreenProps) => {
  const { spinner } = useSpinnerFullScreenSelector();
  const enabled = spinner?.enabled || props.enabled;
  if (!enabled) {
    return <></>;
  }
  return (
    <div className={`container-elements container-elements-darck`}>
      <SpinnerMagic />
    </div>
  );
};

SpinnerFullScreen.defaultProps = {
  size: "midle",
  enabled: false,
} as Partial<ISpinnerFullScreenProps>;

export default SpinnerFullScreen;
