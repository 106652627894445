import "./referredList.styles.scss";

import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import { IReferred } from "../../../hooks/referred/referred.types";
import { Typhography } from "../../atoms";
import { ReferredListName, ReferredListStatus } from "../../molecules";

type Props = {
  referredPeople: IReferred[];
  getMoreReferred: () => void;
  hasMoreReferred: boolean;
  claimReward: (id: string) => void;
};

export const ReferredList = (props: Props) => {
  const { referredPeople, getMoreReferred, hasMoreReferred, claimReward } = props;

  const { t } = useTranslation(["common"]);

  return (
    <div id="scroll-div" className="mf-referred_list_container">
      <div className="mf-referred_list_title">
        <Typhography type="h-2" className="is-uppercase">
          {t("common:name")}
        </Typhography>
        <Typhography type="h-2" className="is-uppercase">
          {t("common:status")}
        </Typhography>
      </div>

      <div className="mf-referred_list">
        <InfiniteScroll
          dataLength={referredPeople.length}
          next={getMoreReferred}
          hasMore={hasMoreReferred}
          loader={<></>}
          scrollableTarget="scroll-div"
        >
          {referredPeople.map((referred, idx) => (
            <div key={idx} className="mf-referred_list_item">
              <ReferredListName name={referred.name} index={idx + 1} />
              <ReferredListStatus {...referred} onClick={claimReward} />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
