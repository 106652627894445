import "./builderText.styles.scss";

import { useTranslation } from "react-i18next";

import { Typhography } from "../../atoms";
import { BuilderTextProps } from "./builderText.types";
import getContentType from "./texts";

const BuilderText = (props: BuilderTextProps): JSX.Element => {
  const { t } = useTranslation(["terms-conditions"]);

  const contentText = getContentType(t)[props.type];

  return (
    <section className="mg-about-section-policy">
      {contentText.map((paragraph, key) => {
        return (
          <>
            {paragraph.title && (
              <Typhography
                type="overline-b"
                className="mg-paragraph-title-policy"
                key={key + "-title-policy"}
              >
                {paragraph.title}
              </Typhography>
            )}
            {paragraph.subtitle && (
              <Typhography
                type="p-s-b"
                className="mg-paragraph-subtitle-policy"
                key={key + "-subtitle-policy"}
              >
                {paragraph.subtitle}
              </Typhography>
            )}
            <Typhography
              className="mg-paragraph-body-policy"
              type="p-s-r"
              key={key + "-body-policy"}
            >
              {paragraph.body}
            </Typhography>
            {paragraph.items &&
              paragraph.items.map((item, key) => {
                return (
                  <Typhography type="p-s-r" key={key + "-body-policy"}>
                    <li className="mg-item">{item}</li>
                  </Typhography>
                );
              })}
          </>
        );
      })}
    </section>
  );
};

BuilderText.defaultProps = {
  type: "termsConditions",
} as Partial<BuilderTextProps>;

export default BuilderText;
