import React from "react";

import { TypeIcon } from "../icons/icons.type";

export type ButtonProps = UseButtonParams &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    type?: "submit" | "reset" | "button";
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: React.ReactNode;
    text?: React.ReactNode;
    className?: string;
    leftIcon?: TypeIcon;
    rightIcon?: TypeIcon;
  };

export type UseButtonParams = {
  isLoading?: boolean;
  isFullWidth?: boolean;
  color:
    | "success"
    | "info"
    | "warning"
    | "error"
    | "transparent"
    | "success-lighter"
    | "primary"
    | "danger"
    | "google-maps"
    | "waze";
};

export const ButtonDefaultProps: ButtonProps = {
  isLoading: false,
  type: "button",
  color: "success",
  children: "Button label",
  isFullWidth: true,
};
